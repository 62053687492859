import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames =
  | "root"
  | "searchInput"
  | "searchInputContainer"
  | "additionalFilters"
  | "inputField"
  | "filterSelectFormControl"
  | "searchIcon"
  | "selectRoot";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        marginTop: theme.spacing(20 / 8),
        marginBottom: theme.spacing(20 / 8),
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      searchInput: {
        "& input": {
          paddingLeft: 45,
          paddingTop: 14,
          paddingBottom: 14,
        },
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      searchInputContainer: {
        position: "relative",
      },
      searchIcon: {
        position: "absolute",
        left: 15,
        top: "50%",
        transform: "translateY(-50%)",
      },
      additionalFilters: {
        display: "flex",
        marginLeft: "auto",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          marginLeft: 0,
        },
      },
      filterSelectFormControl: {
        minWidth: 140,
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
          marginTop: theme.spacing(1),
        },
      },
      inputField: {
        height: 44,
      },
      selectRoot: {
        padding: "12px 14px",
      },
    })
);
