import { TextField } from "@material-ui/core";
import React, { FC } from "react";
import InputMask from "react-input-mask";

import Props from "./Props";

const PhoneNumberInput: FC<Props> = (props: Props) => {
  const {
    value,
    onChange,
    error,
    label,
    className,
    isInputDisabled,
    name,
    maskCode,
  } = props;
  const mask = `+${maskCode || 1} (999) 999-9999`;

  return (
    <InputMask mask={mask} value={value} onChange={onChange}>
      {(inputProps: any) => (
        <TextField
          fullWidth
          name={name}
          label={label}
          id="filled-phone"
          type="text"
          variant="outlined"
          InputProps={{ disabled: isInputDisabled }}
          error={!!error}
          helperText={error}
          className={className}
          required
          {...inputProps}
        />
      )}
    </InputMask>
  );
};

export default PhoneNumberInput;
