import { DialogActions } from "@material-ui/core";
import React, { FC, useContext } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import ButtonCancel from "../../../../../../common/components/ButtonCancel";
import ButtonSuccess from "../../../../../../common/components/ButtonSuccess";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import HttpStatusCodes from "../../../../../../common/httpStatusCodes";
import { SuccessResponse } from "../../../../../../common/models";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { formatMobileNumberWithoutSymbols } from "../../../../../../utils/phoneNumberUtils";
import { CreateVenueDialogContext } from "../../CreateVenueDialogContextProvider";
import { createVenueAdminInfoForm } from "../../forms/admin";
import { createVenueVenueInfoForm } from "../../forms/venue";
import Props from "./Props";

const CreateVenueDialogActions: FC<Props> = (props) => {
  const { closeDialog, closeAfterSuccessValidation } = props;
  const { showAlert } = useContext(AlertContext);
  const [{ loading: loadingSendCreation }, sendCreationRequest] = useRestApi<
    SuccessResponse,
    FormData
  >("venue/add", "POST");
  const {
    currentStep,
    isNextDisabled,
    isValidateDisabled,
    setCurrentStep,
    adminForm,
    venueForm,
    attachments,
    setAttachments,
  } = useContext(CreateVenueDialogContext);
  const getSecondaryButtonLabel = () =>
    currentStep > 1
      ? dictionary.venues.list.createVenueDialogBackButton
      : dictionary.venues.list.createVenueDialogCancelButton;

  const getPrimaryButtonLabel = () =>
    currentStep < 3
      ? dictionary.venues.list.createVenueDialogNextButton
      : dictionary.venues.list.createVenueDialogValidateButton;

  const handlePressSecondaryButton = () => {
    if (currentStep === 1) {
      closeDialog();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const validateVenueTypes = () => {
    const { bar, restaurant } = venueForm.form.values;
    const isValid = Boolean(bar) || Boolean(restaurant);

    if (!isValid) {
      showAlert(
        dictionary.venues.list.createVenueTypesValidationError,
        "warning"
      );
    }

    return isValid;
  };

  const resetDialogData = () => {
    adminForm.setFormValues(createVenueAdminInfoForm.values);
    venueForm.setFormValues(createVenueVenueInfoForm.values);
    setAttachments([]);
    setCurrentStep(1);
  };

  const sendRequest = () => {
    const adminFormData = adminForm.form.values;
    const venueFormData = venueForm.form.values;
    const formData = new FormData();

    formData.append("address", venueFormData.address);
    formData.append("city", venueFormData.city);
    formData.append("country", venueFormData.country);
    formData.append("state", venueFormData.state);
    formData.append("venueEmail", venueFormData.email);
    formData.append(
      "venuePhone",
      formatMobileNumberWithoutSymbols(venueFormData.phoneNumber)
    );
    formData.append("venueName", venueFormData.name);
    formData.append("userTitle", adminFormData.title);
    formData.append("userCompany", adminFormData.company);
    formData.append("userEmail", adminFormData.email);
    formData.append(
      "userPhone",
      formatMobileNumberWithoutSymbols(adminFormData.phoneNumber)
    );
    formData.append("firstName", adminFormData.firstName);
    formData.append("lastName", adminFormData.lastName);

    if (venueFormData.bar) {
      formData.append("barTypesIds", venueFormData.bar);
    }

    if (venueFormData.restaurant) {
      formData.append("restaurantTypesIds", venueFormData.restaurant);
    }

    if (adminFormData.comment) {
      formData.append("comment", adminFormData.comment);
    }

    for (const attachment of attachments) {
      formData.append("attachments", attachment);
    }

    sendCreationRequest(formData)
      .then((data) => {
        if (data) {
          showAlert(dictionary.venues.list.createVenueSuccess, "success");
          closeAfterSuccessValidation();
          resetDialogData();
        }
      })
      .catch((error) => {
        if (error.response.status === HttpStatusCodes.BAD_REQUEST) {
          showAlert(error.response.data, "error");
        } else if (error.response.data === "Insult word") {
          showAlert(dictionary.notifications.insultingMessageError, "error");
        } else showAlert(dictionary.venues.list.createVenueError, "error");
      });
  };

  const handlePressPrimaryButton = () => {
    if (currentStep === 3) {
      if (!attachments.length) {
        return showAlert(
          dictionary.venues.list.createVenueValidationAttachmentsMessage,
          "warning"
        );
      }

      return sendRequest();
    }

    if (
      (currentStep === 1 && adminForm.validateForm()) ||
      (currentStep === 2 && venueForm.validateForm() && validateVenueTypes())
    ) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <DialogActions>
      <ButtonCancel onClick={handlePressSecondaryButton}>
        {getSecondaryButtonLabel()}
      </ButtonCancel>
      <ButtonSuccess
        onClick={handlePressPrimaryButton}
        disabled={
          currentStep !== 3
            ? isNextDisabled
            : isValidateDisabled || loadingSendCreation
        }>
        {getPrimaryButtonLabel()}
      </ButtonSuccess>
    </DialogActions>
  );
};

export default CreateVenueDialogActions;
