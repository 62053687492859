import React, { FC } from "react";

import ImgMediaCard from "../../../../../../common/components/ImgMediaCard";
import Props from "./Props";

const PromotionImage: FC<Props> = (props: Props) => {
  const { image, categoryName } = props;

  const getPromotionImage = () => {
    if ((!image && !categoryName) || categoryName === "Other") {
      return "/images/promotionCategories/Other.png";
    }

    if (!image) {
      return `/images/promotionCategories/${categoryName}.png`;
    }

    return image;
  };

  return (
    <ImgMediaCard
      height={72}
      width={129}
      image={getPromotionImage()}></ImgMediaCard>
  );
};
export default PromotionImage;
