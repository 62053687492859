import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useEffect, useState } from "react";

import { usePrevious } from "../../../hooks";
import { useAsyncState } from "../../../hooks/useAsyncState";
import { GET_ALL_VENUE_BASIC } from "./api";
import IVenueBasic from "./models/IVenueBasic";
import Props from "./Props";

const VenueAutocompleteSearch: FC<Props> = (props: Props) => {
  const { venueId, onChange, error, label, fullWidth, requried, style } = props;
  const prevVenueId = usePrevious(venueId);
  const [value, setValue] = useState<IVenueBasic | null>(null);
  const [{ response: venues, loading }, setVenues] =
    useAsyncState<IVenueBasic[]>(GET_ALL_VENUE_BASIC);

  const handleAutocompleteChange = (_: any, option: IVenueBasic | null) => {
    onChange(option?.id);
    setValue(option);
  };

  useEffect(() => {
    setVenues().then();
  }, [setVenues]);

  useEffect(() => {
    const shouldSetDefault = venueId !== prevVenueId;

    if (shouldSetDefault && venues && venueId) {
      console.log(">>>> VVVVV", typeof venues, venues);
      setValue(venues.filter((venue) => venue.id === venueId)[0]);
    }
  }, [venueId, prevVenueId, venues]);
  return (
    <Autocomplete
      style={style}
      value={value}
      getOptionSelected={(option) => option.id === venueId}
      id="venue-select-autocomplete"
      loading={loading}
      fullWidth={fullWidth}
      options={venues || []}
      getOptionLabel={(venue: IVenueBasic) => venue.name}
      onChange={handleAutocompleteChange}
      renderInput={(params) => (
        <TextField
          {...params}
          required={!!requried}
          fullWidth
          error={!!error}
          helperText={error}
          label={label}
          value={venueId}
          name="venue"
          type="text"
          variant="outlined"
        />
      )}
    />
  );
};

export default VenueAutocompleteSearch;
