import { Venue, VenueType } from "../../../../../../common/models";
import { VenueForm } from "../VenueForm";

export default class VenueFormHelper {
  public static createVenueFormFromValue(venue: Venue) {
    const buildVenueTypeString = (types: VenueType[]) => {
      if (Array.isArray(types) && types.length) {
        return types
          .map((type: VenueType) => {
            return type.id;
          })
          .join(",");
      }

      return "";
    };
    const form: VenueForm = {
      isValid: true,
      touched: [],
      values: {
        name: "",
        description: "",
      },
      errors: {},
    };

    // Map
    const { pendingChanges } = venue;

    form.values.name = pendingChanges?.name || venue.name || "";
    form.values.description = venue.description || "";
    form.values.city = pendingChanges?.city || venue.location.city || "";
    form.values.country =
      pendingChanges?.country || venue.location.country || "";
    form.values.state = pendingChanges?.state || venue.location.state || "";
    form.values.address = pendingChanges?.address || venue.address || "";
    form.values.address2 = pendingChanges?.address2 || venue.address2 || "";
    form.values.latitude = venue.latitude ? String(venue.latitude) : "";
    form.values.longitude = venue.longitude ? String(venue.longitude) : "";
    form.values.website = pendingChanges?.website || venue.website || "";
    form.values.price = venue.price ? venue.price : 0;
    form.values.bar =
      venue.type && venue.type.bar ? buildVenueTypeString(venue.type.bar) : "";
    form.values.restaurant =
      venue.type && venue.type.restaurant
        ? buildVenueTypeString(venue.type.restaurant)
        : "";

    return form;
  }

  public static saveMap(id: number, form: VenueForm): any {
    // #TODO update object type
    const venue = {
      id,
      name: form.values.name,
      description: form.values.description,
      location: {
        city: form.values.city,
        country: form.values.country,
        state: form.values.state,
      },
      address: form.values.address,
      address2: form.values.address2,
      longitude: form.values.longitude,
      latitude: form.values.latitude,
      website: form.values.website,
      price: form.values.price,
      subtypes: VenueFormHelper.getIdsFromCsv(
        form.values.bar,
        form.values.restaurant
      ),
    };

    return venue;
  }

  public static getIdsFromCsv(
    bar: string | undefined,
    restaurant: string | undefined
  ) {
    const barIds: number[] = bar
      ? VenueFormHelper.parseCsv(bar).map((value) => Number(value))
      : [];

    const restaurantIds: number[] = restaurant
      ? VenueFormHelper.parseCsv(restaurant).map((value) => Number(value))
      : [];

    return barIds.concat(restaurantIds);
  }

  public static parseCsv(value: string | undefined): string[] {
    if (value === undefined) {
      return [];
    }
    return value.split(",");
  }
}
