import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "pdfFileIcon"
  | "menuFileMetadata"
  | "removeMenuButton"
  | "pdfIcon"
  | "saveButton"
  | "uploadMenuButtonContainer"
  | "uploadMenuButton"
  | "menuSectionContainer"
  | "uploaderContainer"
  | "menuFileCard"
  | "warningIconContainer"
  | "label";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      pdfFileIcon: {
        marginRight: theme.spacing(3),
        height: "100%",
        minWidth: 52,
      },
      menuFileMetadata: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      removeMenuButton: {
        zIndex: 1,
        position: "absolute",
        height: 54,
        width: 54,
        backgroundColor: colors.red.lightPink,
        borderRadius: 4,
        top: "25%",
        right: theme.spacing(3),

        "&:hover": {
          backgroundColor: colors.red.lightPinkDarker,
        },
        "& svg > path": {
          fill: colors.red.primary,
        },
      },
      pdfIcon: {
        marginBottom: theme.spacing(2),
      },
      uploaderContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      },
      uploadMenuButtonContainer: {
        paddingTop: 17,
        paddingLeft: theme.spacing(4),
      },
      uploadMenuButton: {
        width: 160,
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
      menuSectionContainer: {
        height: "100%",
        marginTop: theme.spacing(2),
      },
      menuFileCard: {
        display: "flex",
        minHeight: 110,
        width: "60%",
        [theme.breakpoints.down("sm")]: {
          width: "85%",
        },
        maxWidth: 800,
        padding: theme.spacing(3),
        position: "relative",
        margin: theme.spacing(4, 0),
        justifyContent: "flex-start",
      },
      saveButton: {
        marginTop: theme.spacing(4),
      },
      warningIconContainer: {
        paddingLeft: 14,
        display: "flex",
        alignItems: "center",
      },
      label: {
        fontSize: theme.typography.pxToRem(15),
        marginTop: theme.spacing(1),
        fontWeight: 600,
      },
    })
);
