import { CardContent, Grid, Paper } from "@material-ui/core";
import React, { FC } from "react";

import Props from "../../Venues/Props";
import { useStyles } from "../styles";

const TermsOfUse: FC<Props> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <CardContent className={classes.cardContainer}>
          <Grid container justify="center" spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <h1 className={classes.title}>Terms of Use</h1>
                <br />
                <p>Last modified: November 11, 2021</p>
                <br />
                <p>
                  <strong>
                    <u>Acceptance of the Terms of Use</u>
                  </strong>
                </p>
                <br />
                <p>
                  These terms of use are entered into by and between you and
                  Ociety, Inc. (“<strong>Ociety</strong>,” the “
                  <strong>Company</strong>”, “<strong>we</strong>,” or “
                  <strong>us</strong>”). The following terms and conditions,
                  together with any documents they expressly incorporate by
                  reference (collectively, “<strong>Terms of Use</strong>”),
                  govern your access to and use of the website Ociety.com or the
                  Ociety mobile app, including any content, functionality, and
                  services offered on or through the website (collectively, the
                  “<strong>Website</strong>”), whether as a guest or a
                  registered user.
                </p>
                <br />
                <p>
                  Please read the Terms of Use carefully before you start to use
                  the Website.{" "}
                  <strong>
                    By using the Website or by clicking to accept or agree to
                    the Terms of Use when this option is made available to you,
                    you accept and agree to be bound and abide by these Terms of
                    Use and our Privacy Policy, found at{" "}
                    <a href="/privacy-policy">Privacy Policy</a>, incorporated
                    herein by reference.
                  </strong>{" "}
                  If you do not want to agree to these Terms of Use or the
                  Privacy Policy, you must not access or use the Website.{" "}
                </p>
                <br />
                <p>
                  This Website is offered and available to users who are 18
                  years of age or older. By using this Website, you represent
                  and warrant that you are of legal age to form a binding
                  contract with the Company and meet all of the foregoing
                  eligibility requirements. If you do not meet all of these
                  requirements, you must not access or use the Website.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Changes to the Terms of Use</u>
                  </strong>
                </p>
                <br />
                <p>
                  We may revise and update these Terms of Use from time to time
                  in our sole discretion. All changes are effective immediately
                  when we post them, and apply to all access to and use of the
                  Website thereafter.{" "}
                </p>
                <br />
                <p>
                  Your continued use of the Website following the posting of
                  revised Terms of Use means that you accept and agree to the
                  changes. You are expected to check this page from time to time
                  so you are aware of any changes, as they are binding on you.{" "}
                </p>
                <br />
                <p>
                  <strong>
                    <u>Accessing the Website and Account Security</u>
                  </strong>
                </p>
                <br />
                <p>
                  We reserve the right to withdraw or amend this Website, and
                  any service or material we provide on the Website, in our sole
                  discretion without notice. We will not be liable if for any
                  reason all or any part of the Website is unavailable at any
                  time or for any period. From time to time, we may restrict
                  access to some parts of the Website, or the entire Website, to
                  users, including registered users. We may remove any user,
                  including registered users, and/or any or all information
                  about a user from the Website at any time and for any reason
                  in our sole discretion and without any notice or explanation.{" "}
                </p>
                <br />
                <p>You are responsible for both:</p>
                <br />
                <p>
                  <ul className={classes.list}>
                    <li>
                      Making all arrangements necessary for you to have access
                      to the Website.
                    </li>
                    <li>
                      Ensuring that all persons who access the Website through
                      your internet connection are aware of these Terms of Use
                      and comply with them.
                    </li>
                  </ul>
                </p>
                <br />
                <p>
                  To access the Website or some of the resources we offer, you
                  may be asked to provide certain registration details or other
                  information. It is a condition of your use of the Website that
                  all the information you provide on the Website is correct,
                  current, and complete. You agree that all information you
                  provide to register with this Website or otherwise, including,
                  but not limited to, through the use of any interactive
                  features on the Website, is governed by our Privacy Policy,
                  and you consent to all actions we take with respect to your
                  information consistent with our Privacy Policy.
                </p>
                <br />
                <p>
                  If you choose, or are provided with, a user name, password, or
                  any other piece of information as part of our security
                  procedures, you must treat such information as confidential,
                  and you must not disclose it to any other person or entity.
                  You also acknowledge that your account is personal to you and
                  agree not to provide any other person with access to this
                  Website or portions of it using your user name, password, or
                  other security information. You agree to notify us immediately
                  of any unauthorized access to or use of your user name or
                  password or any other breach of security. You also agree to
                  ensure that you exit from your account at the end of each
                  session. You should use particular caution when accessing your
                  account from a public or shared computer so that others are
                  not able to view or record your password or other personal
                  information.
                </p>
                <br />
                <p>
                  We have the right to disable any user name, password, or other
                  identifier, whether chosen by you or provided by us, at any
                  time in our sole discretion for any or no reason, including
                  if, in our opinion, you have violated any provision of these
                  Terms of Use.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Intellectual Property Rights</u>
                  </strong>
                </p>
                <br />
                <p>
                  The Website and its entire contents, features, and
                  functionality (including but not limited to all information,
                  software, text, displays, images, video, and audio, and the
                  design, selection, and arrangement thereof) are owned by the
                  Company, its licensors, or other providers of such material
                  and are protected by U.S. and international copyright,
                  trademark, patent, trade secret, and other intellectual
                  property or proprietary rights laws.
                </p>
                <br />
                <p>
                  These Terms of Use permit you to use the Website for your
                  personal, non-commercial use only. You must not reproduce,
                  distribute, modify, create derivative works of, publicly
                  display, publicly perform, republish, download, store, or
                  transmit any of the material on our Website, except as
                  follows:
                </p>
                <br />
                <p>
                  <ul className={classes.list}>
                    <li>
                      Your computer may temporarily store copies of such
                      materials in RAM incidental to your accessing and viewing
                      those materials.
                    </li>
                    <li>
                      You may store files that are automatically cached by your
                      Web browser for display enhancement purposes.
                    </li>
                    <li>
                      You may print or download one copy of a reasonable number
                      of pages of the Website for your own personal,
                      non-commercial use and not for further reproduction,
                      publication, or distribution.
                    </li>
                    <li>
                      If we provide desktop, mobile, or other applications for
                      download, you may download a single copy to your computer
                      or mobile device solely for your own personal,
                      non-commercial use, provided you agree to be bound by our
                      end user license agreement for such applications.
                    </li>
                    <li>
                      If we provide social media features with certain content,
                      you may take such actions as are enabled by such features.
                    </li>
                  </ul>
                  <ul className={classes.list}>
                    <br />
                    <p>You must not:</p>
                    <br />
                    <li>Modify copies of any materials from this site.</li>
                    <li>
                      Use any illustrations, photographs, video or audio
                      sequences, or any graphics separately from the
                      accompanying text, where you do now own the rights to do
                      so.
                    </li>
                    <li>
                      Delete or alter any copyright, trademark, or other
                      proprietary rights notices from copies of materials from
                      this site.
                    </li>
                  </ul>
                </p>
                <br />
                <p>
                  You must not access or use for any commercial purposes any
                  part of the Website or any services or materials available
                  through the Website.{" "}
                </p>
                <br />
                <p>
                  If you print, copy, modify, download, or otherwise use or
                  provide any other person with access to any part of the
                  Website in breach of these Terms of Use, your right to use the
                  Website will stop immediately and you must, at our option,
                  return or destroy any copies of the materials you have made.
                  No right, title, or interest in or to the Website or any
                  content on the Website is transferred to you, and all rights
                  not expressly granted are reserved by the Company. Any use of
                  the Website not expressly permitted by these Terms of Use is a
                  breach of these Terms of Use and may violate copyright,
                  trademark, and other laws.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Trademarks</u>
                  </strong>
                </p>
                <br />
                <p>
                  The Company name, the term Ociety, the Company logo, and all
                  related names, logos, product and service names, designs, and
                  slogans are trademarks of the Company or its affiliates or
                  licensors. You must not use such marks without the prior
                  written permission of the Company. All other names, logos,
                  product and service names, designs, and slogans on this
                  Website are the trademarks of their respective owners.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Prohibited Uses</u>
                  </strong>
                </p>
                <br />
                <p>
                  You may use the Website only for lawful purposes and in
                  accordance with these Terms of Use. You agree not to use the
                  Website:
                </p>
                <br />
                <p>
                  <ul className={classes.list}>
                    <li>
                      In any way that violates any applicable federal, state,
                      local, or international law or regulation (including,
                      without limitation, any laws regarding the export of data
                      or software to and from the United States or other
                      countries).{" "}
                    </li>
                    <li>
                      For the purpose of exploiting, harming, or attempting to
                      exploit or harm minors in any way by exposing them to
                      inappropriate content, asking for personally identifiable
                      information, or otherwise.
                    </li>
                    <li>
                      To send, knowingly receive, upload, download, use, or
                      re-use any material that does not comply with the Content
                      Standards set out in these Terms of Use.
                    </li>
                    <li>
                      To transmit, or procure the sending of, any advertising or
                      promotional material without our prior written consent,
                      including any “junk mail,” “chain letter,” “spam,” or any
                      other similar solicitation.
                    </li>
                    <li>
                      To impersonate or attempt to impersonate the Company, a
                      Company employee, another user, or any other person or
                      entity (including, without limitation, by using email
                      addresses or screen names associated with any of the
                      foregoing).
                    </li>
                    <li>
                      To engage in any other conduct that restricts or inhibits
                      anyone’s use or enjoyment of the Website, or which, as
                      determined by us, may harm the Company or users of the
                      Website, or expose them to liability.
                    </li>
                  </ul>
                  <br />
                  <p>Additionally, you agree not to:</p>
                  <br />
                  <ul className={classes.list}>
                    <li>
                      Upload or otherwise display on the Website any images that
                      are explicit, misleading, inappropriate, or otherwise not
                      directly related to the ordinary and intended use of the
                      Website;{" "}
                    </li>
                    <li>
                      Remove or attempt to remove your venue from the Website
                      without our prior express written consent;{" "}
                    </li>
                    <li>
                      Use the Website in any manner that could disable,
                      overburden, damage, or impair the site or interfere with
                      any other party’s use of the Website, including their
                      ability to engage in real time activities through the
                      Website.
                    </li>
                    <li>
                      Use any robot, spider, or other automatic device, process,
                      or means to access the Website for any purpose, including
                      monitoring or copying any of the material on the Website.
                    </li>
                    <li>
                      Use any manual process to monitor or copy any of the
                      material on the Website, or for any other purpose not
                      expressly authorized in these Terms of Use, without our
                      prior written consent.
                    </li>
                    <li>
                      Use any device, software, or routine that interferes with
                      the proper working of the Website.
                    </li>
                    <li>
                      Introduce any viruses, Trojan horses, worms, logic bombs,
                      or other material that is malicious or technologically
                      harmful.
                    </li>
                    <li>
                      Attempt to gain unauthorized access to, interfere with,
                      damage, or disrupt any parts of the Website, the server on
                      which the Website is stored, or any server, computer, or
                      database connected to the Website.{" "}
                    </li>
                    <li>
                      Attack the Website via a denial-of-service attack or a
                      distributed denial-of-service attack.
                    </li>
                    <li>
                      Otherwise attempt to interfere with the proper working of
                      the Website.
                    </li>
                  </ul>
                </p>
                <br />
                <p>
                  <strong>
                    <u>User Contributions</u>
                  </strong>
                </p>
                <br />
                <p>
                  The Website may contain message boards, chat rooms, personal
                  web pages or profiles, forums, bulletin boards, and other
                  interactive features (collectively, “
                  <strong>Interactive Services</strong>”) that allow users to
                  post, submit, publish, display, or transmit to other users or
                  other persons (hereinafter, “<strong>post</strong>”) content
                  or materials (collectively, “
                  <strong>User Contributions</strong>”) on or through the
                  Website.
                </p>
                <p>
                  All User Contributions must comply with the Content Standards
                  set out in these Terms of Use.
                </p>
                <br />
                <p>
                  Any User Contribution you post to the site will be considered
                  non-confidential and non-proprietary. By providing any User
                  Contribution on the Website, you grant us and our affiliates
                  and service providers, and each of their and our respective
                  licensees, successors, and assigns the right to use,
                  reproduce, modify, perform, display, distribute, and otherwise
                  disclose to third parties any such material for any purpose.{" "}
                </p>
                <br />
                <p>You represent and warrant that: </p>
                <br />
                <ul className={classes.list}>
                  <li>
                    You own or control all rights in and to the User
                    Contributions and have the right to grant the license
                    granted above to us and our affiliates and service
                    providers, and each of their and our respective licensees,
                    successors, and assigns.
                  </li>
                  <li>
                    All of your User Contributions do and will comply with these
                    Terms of Use.{" "}
                  </li>
                </ul>
                <br />
                <p>
                  You understand and acknowledge that you are responsible for
                  any User Contributions you submit or contribute, and you, not
                  the Company, have full responsibility for such content,
                  including its legality, reliability, accuracy, and
                  appropriateness.
                </p>
                <br />
                <p>
                  We are not responsible or liable to any third party for the
                  content or accuracy of any User Contributions posted by you or
                  any other user of the Website.{" "}
                </p>
                <br />
                <p>
                  <strong>
                    <u>Monitoring and Enforcement; Termination</u>
                  </strong>
                </p>
                <br />
                <p>We have the right to:</p>
                <br />
                <ul className={classes.list}>
                  <li>
                    Remove or refuse to post any User Contributions for any or
                    no reason in our sole discretion.
                  </li>
                  <li>
                    Take any action with respect to any User Contribution that
                    we deem necessary or appropriate in our sole discretion,
                    including if we believe that such User Contribution violates
                    the Terms of Use, including the Content Standards, infringes
                    any intellectual property right or other right of any person
                    or entity, threatens the personal safety of users of the
                    Website or the public, or could create liability for the
                    Company.
                  </li>
                  <li>
                    Disclose your identity or other information about you to any
                    third party who claims that material posted by you violates
                    their rights, including their intellectual property rights
                    or their right to privacy.
                  </li>
                  <li>
                    Take appropriate legal action, including without limitation,
                    referral to law enforcement, for any illegal or unauthorized
                    use of the Website.{" "}
                  </li>
                  <li>
                    Terminate or suspend your access to all or part of the
                    Website for any or no reason, including without limitation,
                    any violation of these Terms of Use.
                  </li>
                  <li>
                    Monitor any use of the Website and collect and compile data
                    and information related to your, your visitors’, and others’
                    use of the Website.
                  </li>
                </ul>
                <br />
                <p>
                  Without limiting the foregoing, we have the right to cooperate
                  fully with any law enforcement authorities or court order
                  requesting or directing us to disclose the identity or other
                  information of anyone posting any materials on or through the
                  Website. YOU AGREE THAT YOU WAIVE AND HOLD HARMLESS THE
                  COMPANY, ITS AFFILIATES, AND ITS AND THEIR RESPECTIVE
                  LICENSORS, AND SERVICE PROVIDERS, AND THEIR RESPECTIVE
                  OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS,
                  LICENSORS, SUPPLIERS, SUCCESSORS, AND ASSIGNS FROM ANY CLAIMS
                  ARISING OUT OF OR RESULTING FROM ANY ACTION TAKEN BY ANY OF
                  THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
                  INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
                  AUTHORITIES.
                </p>
                <br />
                <p>
                  However, we do not undertake to review material before it is
                  posted on the Website, and cannot ensure prompt removal of
                  objectionable material after it has been posted. Accordingly,
                  we assume no liability for any action or inaction regarding
                  transmissions, communications, or content provided by any user
                  or third party. We have no liability or responsibility to
                  anyone for performance or nonperformance of the activities
                  described in this section.{" "}
                </p>
                <br />
                <p>
                  <strong>
                    <u>Content Standards</u>
                  </strong>
                </p>
                <br />
                <p>
                  These content standards apply to any User Contributions and
                  uses of Interactive Services. User Contributions must in their
                  entirety comply with all applicable laws and regulations.
                  Without limiting the foregoing, User Contributions must not:
                </p>
                <br />
                <ul className={classes.list}>
                  <li>
                    Contain any material that is defamatory, obscene, indecent,
                    abusive, offensive, harassing, violent, hateful,
                    inflammatory, or otherwise objectionable.
                  </li>
                  <li>
                    Promote sexually explicit or pornographic material,
                    violence, or discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation, or age.
                  </li>
                  <li>
                    Infringe any patent, trademark, trade secret, copyright, or
                    other intellectual property or other rights of any other
                    person.
                  </li>
                  <li>
                    Violate the legal rights (including the rights of publicity
                    and privacy) of others or contain any material that could
                    give rise to any civil or criminal liability under
                    applicable laws or regulations or that otherwise may be in
                    conflict with these Terms of Use and our Privacy Policy.
                  </li>
                  <li>Be likely to deceive any person.</li>
                  <li>
                    Promote any illegal activity, or advocate, promote, or
                    assist any unlawful act.
                  </li>
                  <li>
                    Cause annoyance, inconvenience, or needless anxiety or be
                    likely to upset, embarrass, alarm, or annoy any other
                    person.
                  </li>
                  <li>
                    Impersonate any person, or misrepresent your identity or
                    affiliation with any person or organization.{" "}
                  </li>
                  <li>
                    Involve commercial activities or sales, such as contests,
                    sweepstakes, and other sales promotions, barter, or
                    advertising.
                  </li>
                  <li>
                    Give the impression that they emanate from or are endorsed
                    by us or any other person or entity, if this is not the
                    case.
                  </li>
                </ul>
                <br />
                <p>
                  <strong>
                    <u>Copyright Policy and Claims of Copyright Infringement</u>
                  </strong>
                </p>
                <br />
                <p>
                  <u>Reporting Claims of Copyright Infringement</u>
                </p>
                <br />
                <p>
                  We take claims of copyright infringement seriously. We will
                  respond to notices of alleged copyright infringement that
                  comply with applicable law. If you believe any materials
                  accessible on or from the Website infringe your copyright, you
                  may request removal of those materials (or access to them)
                  from the Website by submitting written notification to our
                  copyright agent designated below. In accordance with the
                  Online Copyright Infringement Liability Limitation Act of the
                  Digital Millennium Copyright Act (17 U.S.C. § 512) (“
                  <strong>DMCA</strong>”), the written notice (the “
                  <strong>DMCA Notice</strong>”) must include substantially the
                  following:
                </p>
                <br />
                <ul className={classes.list}>
                  <li>Your physical or electronic signature.</li>
                  <li>
                    Identification of the copyrighted work you believe to have
                    been infringed or, if the claim involves multiple works on
                    the Website, a representative list of such works.
                  </li>
                  <li>
                    Identification of the material you believe to be infringing
                    in a sufficiently precise manner to allow us to locate that
                    material.
                  </li>
                  <li>
                    Adequate information by which we can contact you (including
                    your name, postal address, telephone number, and, if
                    available, email address).
                  </li>
                  <li>
                    A statement that you have a good faith belief that use of
                    the copyrighted material is not authorized by the copyright
                    owner, its agent, or the law.
                  </li>
                  <li>
                    A statement that the information in the written notice is
                    accurate.
                  </li>
                  <li>
                    A statement, under penalty of perjury, that you are
                    authorized to act on behalf of the copyright owner.
                  </li>
                </ul>
                <br />
                <p>
                  Our designated copyright agent to receive DMCA Notices is:
                </p>
                <br />
                <div className={classes.addressContainer}>
                  <p>Joseph R. Waala, Esq.</p>
                  <p>Relativity Law Group</p>
                  <p>424 Madison Ave .Suite 300</p>
                  <p>New York, NY 10017+ 1 585-993-2507</p>
                  <p>JRWaala@RelativityLaw.com</p>
                </div>
                <br />
                <p>
                  If you fail to comply with all of the requirements of Section
                  512(c)(3) of the DMCA, your DMCA Notice may not be effective.
                </p>
                <br />
                <p>
                  Please be aware that if you knowingly materially misrepresent
                  that material or activity on the Website is infringing your
                  copyright, you may be held liable for damages (including costs
                  and attorneys’ fees) under Section 512(f) of the DMCA.
                </p>
                <br />
                <p>
                  <u>Counter Notification Procedures</u>
                </p>
                <br />
                <p>
                  If you believe that material you posted on the Website was
                  removed or access to it was disabled by mistake or
                  misidentification, you may file a counter notification with us
                  (a “<strong>Counter Notice</strong>”) by submitting written
                  notification to our copyright agent designated above. Pursuant
                  to the DMCA, the Counter Notice must include substantially the
                  following:
                </p>
                <br />
                <ul className={classes.list}>
                  <li>Your physical or electronic signature.</li>
                  <li>
                    An identification of the material that has been removed or
                    to which access has been disabled and the location at which
                    the material appeared before it was removed or access
                    disabled.
                  </li>
                  <li>
                    Adequate information by which we can contact you (including
                    your name, postal address, telephone number, and, if
                    available, email address).
                  </li>
                  <li>
                    A statement under penalty of perjury by you that you have a
                    good faith belief that the material identified above was
                    removed or disabled as a result of a mistake or
                    misidentification of the material to be removed or disabled.
                  </li>
                  <li>
                    A statement that you will consent to the jurisdiction of the
                    Federal District Court for the judicial district in which
                    your address is located (or if you reside outside the United
                    States for any judicial district in which the Website may be
                    found) and that you will accept service from the person (or
                    an agent of that person) who provided the Website with the
                    complaint at issue.
                  </li>
                </ul>
                <br />
                <p>
                  The DMCA allows us to restore the removed content if the party
                  filing the original DMCA Notice does not file a court action
                  against you within ten business days of receiving the copy of
                  your Counter Notice.
                </p>
                <p>
                  Please be aware that if you knowingly materially misrepresent
                  that material or activity on the Website was removed or
                  disabled by mistake or misidentification, you may be held
                  liable for damages (including costs and attorneys’ fees) under
                  Section 512(f) of the DMCA.
                </p>
                <br />
                <p>
                  <u>Repeat Infringers</u>
                </p>
                <br />
                <p>
                  It is our policy in appropriate circumstances to disable
                  and/or terminate the accounts of users who are repeat
                  infringers.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Reliance on Information Posted</u>
                  </strong>
                </p>
                <br />
                <p>
                  The information presented on or through the Website is made
                  available solely for general information purposes. We do not
                  warrant the accuracy, completeness, or usefulness of this
                  information. Any reliance you place on such information is
                  strictly at your own risk. We disclaim all liability and
                  responsibility arising from any reliance placed on such
                  materials by you or any other visitor to the Website, or by
                  anyone who may be informed of any of its contents.
                </p>
                <br />
                <p>
                  This Website may include content provided by third parties,
                  including materials provided by other users, bloggers, and
                  third-party licensors, syndicators, aggregators, and/or
                  reporting services. All statements and/or opinions expressed
                  in these materials, and all articles and responses to
                  questions and other content, other than the content provided
                  by the Company, are solely the opinions and the responsibility
                  of the person or entity providing those materials. These
                  materials do not necessarily reflect the opinion of the
                  Company. We are not responsible, or liable to you or any third
                  party, for the content or accuracy of any materials provided
                  by any third parties.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Changes to the Website</u>
                  </strong>
                </p>
                <br />
                <p>
                  We may update the content on this Website from time to time,
                  but its content is not necessarily complete or up-to-date. Any
                  of the material on the Website may be out of date at any given
                  time, and we are under no obligation to update such material.{" "}
                </p>
                <br />
                <p>
                  <strong>
                    <u>Information About You and Your Visits to the Website</u>
                  </strong>
                </p>
                <br />
                <p>
                  All information we collect on this Website is subject to our
                  Privacy Policy. By using the Website, you consent to all
                  actions taken by us with respect to your information in
                  compliance with the Privacy Policy.{" "}
                </p>
                <br />
                <p>
                  <strong>
                    <u>
                      Online Purchases, Applications, and Other Terms and
                      Conditions
                    </u>
                  </strong>
                </p>
                <br />
                <p>
                  All purchases or applications made through our site or other
                  transactions for the sale of goods or services formed through
                  the Website, or resulting from visits made by you, are
                  governed by our <a href="/terms-of-sale">Terms of Sale</a>,
                  which are hereby incorporated into these Terms of Use.
                </p>
                <br />
                <p>
                  Additional terms and conditions may also apply to specific
                  portions, services, or features of the Website. All such
                  additional terms and conditions are hereby incorporated by
                  this reference into these Terms of Use.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Linking to the Website and Social Media Features</u>
                  </strong>
                </p>
                <br />
                <p>
                  You may link to our homepage, provided you do so in a way that
                  is fair and legal and does not damage our reputation or take
                  advantage of it, but you must not establish a link in such a
                  way as to suggest any form of association, approval, or
                  endorsement on our part without our express consent.{" "}
                </p>
                <br />
                <p>
                  This Website may provide certain social media features that
                  enable you to:
                </p>
                <br />
                <ul className={classes.list}>
                  <li>
                    Link from your own or certain third-party websites to
                    certain content on this Website.
                  </li>
                  <li>
                    Send emails or other communications with certain content, or
                    links to certain content, on this Website.
                  </li>
                  <li>
                    Cause limited portions of content on this Website to be
                    displayed or appear to be displayed on your own or certain
                    third-party websites.
                  </li>
                </ul>
                <br />
                <p>
                  You may use these features solely as they are provided by us,
                  and solely with respect to the content they are displayed
                  with, and otherwise in accordance with any additional terms
                  and conditions we provide with respect to such features.
                  Subject to the foregoing, you must not:
                </p>
                <br />
                <ul className={classes.list}>
                  <li>
                    Establish a link from any website that is not owned by you.
                  </li>
                  <li>
                    Cause the Website or portions of it to be displayed on, or
                    appear to be displayed by, any other site, for example,
                    framing, deep linking, or in-line linking.
                  </li>
                  <li>
                    Link to any part of the Website other than the homepage.
                  </li>
                  <li>
                    Otherwise take any action with respect to the materials on
                    this Website that is inconsistent with any other provision
                    of these Terms of Use.
                  </li>
                </ul>
                <br />
                <p>
                  The website from which you are linking, or on which you make
                  certain content accessible, must comply in all respects with
                  the Content Standards set out in these Terms of Use.
                </p>
                <br />
                <p>
                  You agree to cooperate with us in causing any unauthorized
                  framing or linking immediately to stop. We reserve the right
                  to withdraw linking permission without notice.
                </p>
                <br />
                <p>
                  We may disable all or any social media features and any links
                  at any time without notice in our discretion.{" "}
                </p>
                <br />
                <p>
                  <strong>
                    <u>Links and Third-Party Data from the Website</u>
                  </strong>
                </p>
                <br />
                <p>
                  If the Website contains links to other sites and resources or
                  data provided by third parties, these links and data are
                  provided for your convenience only. This includes links
                  contained in advertisements, including banner advertisements
                  and sponsored links. This also includes third-party data
                  sources that may be accessed through the Website’s Waitlist
                  Feature (defined below), including vaccination status as may
                  be requested from third-party sources through the Website. We
                  have no control over the contents of those sites, data, or
                  resources, and accept no responsibility for them or for any
                  loss or damage that may arise from your use of them. If you
                  decide to access any of the third-party websites linked to
                  this Website, you do so entirely at your own risk and subject
                  to the terms and conditions of use for such websites.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Geographic Restrictions</u>
                  </strong>
                </p>
                <br />
                <p>
                  The owner of the Website is based in the State of Delaware in
                  the United States. We make no claims that the Website or any
                  of its content is accessible or appropriate outside of the
                  United States. Access to the Website may not be legal by
                  certain persons or in certain countries. If you access the
                  Website from outside the United States, you do so on your own
                  initiative and are responsible for compliance with local laws.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Waitlist Management Feature</u>
                  </strong>
                </p>
                <br />
                <p>
                  The Website provides a functionality that allows venues such
                  as restaurants and other stores or venues with physical
                  locations to manage a waitlist for entry into the venue’s
                  location (“<strong>Waitlist Feature</strong>”). You may only
                  use the Waitlist Feature to collect pre-defined data points
                  about potential and actual visitors of your venue (“
                  <strong>Visitors</strong>”) and to notify those Visitors of
                  their position within the waitlist and when they may enter the
                  venue.{" "}
                  <strong>
                    <u>
                      You may not under any circumstances use the Waitlist
                      Feature to solicit or market to Visitors or otherwise
                      include any content that we have not approved of in
                      advance.
                    </u>
                  </strong>{" "}
                  You are solely responsible and will defend, indemnify, and
                  hold us harmless for any violation of the foregoing
                  prohibition.
                </p>
                <br />
                <p>
                  You will not use the Waitlist Feature for any purposes beyond
                  those explicitly stated in these Terms of Use. Without
                  limiting that, you will not, directly or indirectly, or permit
                  anyone else to:{" "}
                </p>
                <br />
                <ul className={classes.list}>
                  <li>
                    use the Website to market or advertise to, or otherwise
                    communicate with Visitors or anyone else beyond the purposes
                    specifically stated in these Terms of Use;{" "}
                  </li>
                  <li>
                    use the Website or information collected through the Website
                    to contact or communicate with any Visitor without receiving
                    all consent required by applicable law, or otherwise use the
                    Website or information collected through the Website except
                    in strict compliance with applicable law, rules,
                    regulations, and guidance; or{" "}
                  </li>
                </ul>
                <br />
                <p>You acknowledge and agree that: </p>
                <br />
                <ul className={classes.list}>
                  <li>
                    we do not guarantee or warrant that any wait time that may
                    be provided is correct or accurate;{" "}
                  </li>
                  <li>
                    you are solely responsible for the substance of all
                    communications with Visitors and obtaining all consent
                    necessary or recommended before sending such communications
                    and notifications;{" "}
                  </li>
                  <li>
                    you are solely responsible for ensuring that your use of the
                    Waitlist Feature and communications with Visitors comply
                    with all applicable laws, rules, regulations, and guidance,
                    including, without limitation, the Telephone Consumer
                    Protection Act and all related regulations and guidance; and{" "}
                  </li>
                  <li>
                    your communications with Visitors are solely at your own
                    risk and that you have sole responsibility for those
                    communications.
                  </li>
                </ul>
                <br />
                <p>
                  For clarity, all other use restrictions, disclaimers,
                  limitations, and other terms set forth in these Terms of Use
                  also apply to the Waitlist Feature in addition to the terms
                  stated in this Section.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Disclaimer of Warranties</u>
                  </strong>
                </p>
                <br />
                <p>
                  You acknowledge and agree that we cannot and do not guarantee
                  or warrant that files available for downloading from the
                  internet or the Website will be free of viruses or other
                  destructive code. You are responsible for implementing
                  sufficient procedures and checkpoints to satisfy your
                  requirements for anti-virus protection and accuracy of data
                  input and output, and for maintaining a means external to the
                  Website for any reconstruction of any lost data. TO THE
                  FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
                  LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
                  ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL
                  THAT MAY INFECT YOUR COMPUTER EQUIPMENT, PROGRAMS, DATA, OR
                  OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR
                  ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
                  DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
                  LINKED TO OR FROM IT.
                </p>
                <br />
                <p>
                  YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR
                  ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE
                  WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
                  THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS
                  AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
                  EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON
                  ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
                  REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
                  RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
                  WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY
                  NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS
                  THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS
                  OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE,
                  ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
                  THAT OUR SITE OR THE SERVERS THAT MAKES IT AVAILABLE ARE FREE
                  OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR
                  ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
                  OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.{" "}
                </p>
                <br />
                <p>
                  TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY
                  DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                  IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                  ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND
                  FITNESS FOR PARTICULAR PURPOSE.
                </p>
                <br />
                <p>
                  THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
                  EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Limitation on Liability</u>
                  </strong>
                </p>
                <br />
                <p>
                  TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE
                  COMPANY, ITS AFFILIATES, LICENSORS, AND SERVICE PROVIDERS, AND
                  ITS AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
                  CONTRACTORS, AGENTS, LICENSORS, SUPPLIERS, SUCCESSORS, AND
                  ASSIGNS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
                  THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
                  INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY
                  CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY
                  DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
                  PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
                  INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
                  REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
                  SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
                  WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                  CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.{" "}
                </p>
                <br />
                <p>
                  THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
                  EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Indemnification</u>
                  </strong>
                </p>
                <br />
                <p>
                  YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS THE COMPANY,
                  ITS AFFILIATES, LICENSORS, AND SERVICE PROVIDERS, AND ITS AND
                  THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS,
                  AGENTS, LICENSORS, SUPPLIERS, SUCCESSORS, AND ASSIGNS FROM AND
                  AGAINST ANY CLAIMS, LIABILITIES, DAMAGES, JUDGMENTS, AWARDS,
                  LOSSES, COSTS, EXPENSES, OR FEES (INCLUDING REASONABLE
                  ATTORNEYS’ FEES) ARISING OUT OF OR RELATING TO YOUR VIOLATION
                  OF THESE TERMS OF USE OR YOUR USE OF THE WEBSITE, INCLUDING,
                  BUT NOT LIMITED TO, YOUR USER CONTRIBUTIONS, ANY USE OF THE
                  WEBSITE’S CONTENT, SERVICES, AND PRODUCTS OTHER THAN AS
                  EXPRESSLY AUTHORIZED IN THESE TERMS OF USE, OR YOUR USE OF ANY
                  INFORMATION OBTAINED FROM THE WEBSITE.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Governing Law and Jurisdiction</u>
                  </strong>
                </p>
                <br />
                <p>
                  All matters relating to the Website and these Terms of Use,
                  and any dispute or claim arising therefrom or related thereto
                  (in each case, including non-contractual disputes or claims),
                  shall be governed by and construed in accordance with the
                  internal laws of the State of Delaware without giving effect
                  to any choice or conflict of law provision or rule (whether of
                  the State of Delaware or any other jurisdiction).
                </p>
                <br />
                <p>
                  Any legal suit, action, or proceeding arising out of, or
                  related to, these Terms of Use or the Website shall be
                  instituted exclusively in the federal courts of the United
                  States or the courts of the State of Delaware, although we
                  reserve the right to bring any suit, action, or proceeding
                  against you for breach of these Terms of Use in your state or
                  country of residence or any other relevant country. You waive
                  any and all objections to the exercise of jurisdiction over
                  you by such courts and to venue in such courts.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Arbitration</u>
                  </strong>
                </p>
                <br />
                <p>
                  At Company’s sole discretion, we may require You to submit any
                  disputes arising from these Terms of Use or use of the
                  Website, including disputes arising from or concerning their
                  interpretation, violation, invalidity, non-performance, or
                  termination, to final and binding arbitration under the Rules
                  of Arbitration of the American Arbitration Association
                  applying Delaware law.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Limitation on Time to File Claims</u>
                  </strong>
                </p>
                <br />
                <p>
                  ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                  RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE
                  COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                  ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
                  PERMANENTLY BARRED.
                </p>
                <br />
                <p>
                  <strong>
                    <u>Waiver and Severability</u>
                  </strong>
                </p>
                <br />
                <p>
                  No waiver by the Company of any term or condition set out in
                  these Terms of Use shall be deemed a further or continuing
                  waiver of such term or condition or a waiver of any other term
                  or condition, and any failure of the Company to assert a right
                  or provision under these Terms of Use shall not constitute a
                  waiver of such right or provision.
                </p>
                <br />
                <p>
                  If any provision of these Terms of Use is held by a court or
                  other tribunal of competent jurisdiction to be invalid,
                  illegal, or unenforceable for any reason, such provision shall
                  be eliminated or limited to the minimum extent such that the
                  remaining provisions of the Terms of Use will continue in full
                  force and effect.{" "}
                </p>
                <br />
                <p>
                  <strong>
                    <u>Entire Agreement</u>
                  </strong>
                </p>
                <br />
                <p>
                  These Terms of Use, our Privacy Policy, and our Terms of
                  Service constitute the sole and entire agreement between you
                  and the Company relating to the Website and supersede all
                  prior and contemporaneous understandings, agreements,
                  representations, and warranties, both written and oral,
                  relating to the Website.{" "}
                </p>
                <br />
                <p>
                  <strong>
                    <u>Your Comments and Concerns</u>
                  </strong>
                </p>
                <br />
                <p>
                  This website is operated by Ociety, Inc, 16192 Coastal Hwy.,
                  Lewes, DE 19958.
                </p>
                <br />
                <p>
                  All notices of copyright infringement claims should be sent to
                  the copyright agent designated in our Copyright Policy, above.
                </p>
                <br />
                <p>
                  All other feedback, comments, requests for technical support,
                  and other communications regarding the Website should be
                  directed to: Ociety, Inc., 205 Hudson Street, New York, NY
                  10013, United States
                </p>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </div>
  );
};

export default TermsOfUse;
