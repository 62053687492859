import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "title"
  | "notion"
  | "buttonCreate"
  | "buttonCreateIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginBottom: theme.spacing(-5),
        background:
          "url('/images/backgrounds/venues-list-empty-search-bg.png') no-repeat center",
        backgroundSize: "contain",
      },
      title: {
        fontWeight: 700,
        marginBottom: theme.spacing(4.5),
      },
      notion: {
        lineHeight: "25px",
        maxWidth: 815,
        marginBottom: theme.spacing(6),
      },
      buttonCreate: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(5),
      },
      buttonCreateIcon: {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(1),
      },
    })
);
