import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "dayName"
  | "dayNameWrapper"
  | "button"
  | "buttonPaper"
  | "timePicker"
  | "timePickerInput"
  | "timePickerWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        padding: theme.spacing(1.5, 0, 0.6),
        "&:not(:last-of-type)": {
          borderBottom: "2px solid #EBECF0",
        },
      },
      dayNameWrapper: {
        minHeight: "50px",
        paddingRight: theme.spacing(2),
        boxSizing: "border-box",
        paddingTop: "5px",
      },
      dayName: {
        fontWeight: 700,
      },
      button: {
        borderRadius: 4,
        display: "block",
        padding: 0,
        marginLeft: "auto",
      },
      buttonPaper: {
        width: 50,
        height: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      timePicker: {
        marginLeft: 12,
        // TODO: find better solution
        "& .MuiInput-underline": {
          "&:before, &:after": {
            display: "none",
          },
        },
      },
      timePickerInput: {
        padding: 1,
        cursor: "pointer",
        fontWeight: 600,
      },
      timePickerWrapper: {
        padding: theme.spacing(1, 2),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    })
);
