import { PromotionLimitType, PromotionType } from "../../../enums";
import { IDayHours } from "./IDayHours";
import { IUpdatePromotionFormRequest } from "./IUpdatePromotionFormRequest";

export default class UpdatePromotionFormRequest
  implements IUpdatePromotionFormRequest
{
  public name = "";
  public description = "";
  public id = 0;
  public start = "";
  public end = "";
  public promotionPhotosIds: number[] = [];
  public promotionVideosIds: number[] = [];
  public promotionCategoryId = 0;
  public promotionSubCategoryId = 0;
  public type = PromotionType.FOOD;
  public daysHours: IDayHours[] = [];
  public limitType = PromotionLimitType.SINGLE;
  public limitPercent = 0;
  public limitMaxAmount = 0;
  public limitPrice = 0;
  public limitItemsName = "";
  public quantity = 0;
  public notifyHowManyLeft = false;
  public notifyHowManyLeftCount = 0;
  public maximumUsagesPerCustomer = 0;
  public restrictMinimumAge = "0";
  public promotionCode = "";
  public isQuantityUnlimited = true;
  public ageGroupId = "";
  public mainPhotoId = 0;
  public dietaryRestrictions = "";

  set photosIds(ids: number[]) {
    this.promotionPhotosIds = ids;
  }

  set videosIds(ids: number[]) {
    this.promotionVideosIds = ids;
  }

  set categoryId(id: string) {
    this.promotionCategoryId = Number(id);
  }

  set subCategoryId(id: string) {
    this.promotionSubCategoryId = Number(id);
  }
}
