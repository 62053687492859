import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TimePicker } from "@material-ui/pickers";
import clsx from "clsx";
import React, { FC } from "react";

import OcietyIcon from "../../../../../../../../../../../common/components/OcietyIcon";
import OcietySwitch from "../../../../../../../../../../../common/components/OcietySwitch";
import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import { getMomentFromTimeString } from "../../../../../../../../../../../utils/dateTimeUtils";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionDayHoursRow: FC<Props> = (props: Props) => {
  const { id, day, isActive, start, end, toggleDay, editTime, rootClass } =
    props;
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={1}
      justify="space-between"
      key={id}
      alignItems="flex-start"
      className={clsx(classes.root, rootClass)}>
      <Grid item container xs={5} sm={6} md={5} lg={5} justify="space-between">
        <Grid
          item
          container
          alignItems="center"
          className={classes.dayNameWrapper}
          justify={"space-between"}>
          <Grid item xs={12} sm={4} md={8} lg={8}>
            <Typography variant={"subtitle2"}>{day}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            lg={4}
            className={classes.switchWrapper}>
            <OcietySwitch
              value={isActive}
              checked={isActive}
              onChange={() => toggleDay(id)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={7} sm={6} md={7} lg={7}>
        <Grid
          container
          spacing={2}
          key={id + "-clock"}
          justify="space-between"
          alignItems="center">
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper
              className={clsx(
                classes.timePickerWrapper,
                !isActive && classes.timePickerWrapperDisabled
              )}
              elevation={0}>
              <OcietyIcon
                name={isActive ? "clockActive" : "clock"}
                height={"26px"}
                width={"26px"}
                viewBox={"0 0 26 26"}
              />
              <TimePicker
                className={classes.timePicker}
                disabled={!isActive}
                label={dictionary.promotions.createPromotionForm.startLabel}
                inputProps={{
                  className: classes.timePickerInput,
                  shrink: true,
                }}
                value={getMomentFromTimeString(start)}
                onChange={(newTime) => {
                  if (newTime) {
                    return editTime(id, "start", newTime?.format("HH:mm:ss"));
                  }
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper
              className={clsx(
                classes.timePickerWrapper,
                !isActive && classes.timePickerWrapperDisabled
              )}>
              <OcietyIcon
                name={isActive ? "clockActive" : "clock"}
                height={"26px"}
                width={"26px"}
                viewBox={"0 0 26 26"}
              />
              <TimePicker
                className={classes.timePicker}
                disabled={!isActive}
                label={dictionary.promotions.createPromotionForm.endLabel}
                inputProps={{
                  className: classes.timePickerInput,
                  shrink: true,
                }}
                value={getMomentFromTimeString(end)}
                onChange={(newTime) => {
                  if (newTime) {
                    return editTime(id, "end", newTime?.format("HH:mm:ss"));
                  }
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PromotionDayHoursRow;
