import { Color } from "@material-ui/lab";
import React, { FC, useCallback, useState } from "react";

import Alert from "./index";

interface Props {
  children?: React.ReactElement;
}

export type ShowAlertActionType = (
  message: string,
  severity: Color,
  title?: string,
  time?: number
) => void;

type HideAlertActionType = (alertId: number) => void;

interface AlertContextInterface {
  alerts: JSX.Element[];
  showAlert: ShowAlertActionType;
  hideAlert: HideAlertActionType;
}

export const AlertContext = React.createContext<AlertContextInterface>({
  alerts: [],
  showAlert: () => undefined,
  hideAlert: () => undefined,
});

const AlertContextProvider: FC<Props> = ({ children }) => {
  const [alerts, setAlerts] = useState<JSX.Element[]>([]);

  const showAlert: ShowAlertActionType = useCallback(
    (message: string, severity: Color, title?: string, time?: number) => {
      const alertId = new Date().getTime();

      setAlerts((prevAlerts) => {
        const newAlerts = [...prevAlerts];

        // To prevent duplications
        if (!prevAlerts.some((alert) => alert.props.children === message)) {
          newAlerts.push(
            <Alert
              severity={severity}
              key={alertId}
              alertId={alertId}
              title={title}
              time={time}>
              {message}
            </Alert>
          );
        }

        return newAlerts;
      });
    },
    []
  );

  const hideAlert: HideAlertActionType = useCallback((alertId: number) => {
    setAlerts((prevAlerts) =>
      prevAlerts.filter((alert) => alertId !== alert.props.alertId)
    );
  }, []);

  return (
    <AlertContext.Provider value={{ alerts, showAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
