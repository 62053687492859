import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "dialogContent"
  | "root"
  | "dayNameWrapper"
  | "dayName"
  | "hoursCenter"
  | "disabledDayName"
  | "hoursContainer"
  | "lastDay"
  | "timePickerWrapper"
  | "timePicker"
  | "unActiveHours"
  | "timeLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        padding: theme.spacing(0, 2, 0),
        "&:not(:last-of-type)": {
          borderBottom: "2px solid #EDEFF6",
        },
      },
      dialogContent: {
        maxWidth: 500,
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(1, 0, 1, 0),
        },
        padding: theme.spacing(3, 5, 3, 5),
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, 1.5),
        },
      },
      dayNameWrapper: {
        minHeight: 60,
        padding: theme.spacing(1, 0, 1, 0),
        boxSizing: "border-box",
        borderBottom: `2px solid ${colors.grey.divider}`,
        [theme.breakpoints.down("sm")]: {
          paddingRight: theme.spacing(0),
        },
      },
      lastDay: {
        borderBottom: "none",
      },
      dayName: {
        fontWeight: 700,
      },
      disabledDayName: {
        fontWeight: 200,
      },
      hoursCenter: {
        textAlign: "center",
      },
      hoursContainer: {
        border: `2px solid ${colors.grey.divider}`,
        backgroundColor: colors.grey.bgUltraLight,
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(1.5, 0),
        },
      },
      unActiveHours: {
        display: "none",
      },
      timePickerWrapper: {
        padding: theme.spacing(0, 1),
        margin: theme.spacing(0, 0, 0, 1),
        maxWidth: 130,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0, 0, 0, 0.5),
        },
      },
      timePicker: {
        boxSizing: "border-box",
        marginLeft: 12,
      },
      timeLabel: {
        marginTop: 8,
      },
    })
);
