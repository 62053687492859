import { Dispatch } from "redux";

import { authorize, verifyMFA } from "../../common/api";
import { AuthCredentials, VerifyMFACodeRequest } from "../../common/models";
import { handleLoginError } from "../../common/utils/authUtils";
import dictionary from "../../i18n/en_US/dictionary";
import {
  AuthActionTypes,
  CLEAR_MFA_TOKEN,
  ClearMFATokenAction,
  LOGIN_CLEAR_ERROR,
  LOGIN_FULFILLED,
  LOGIN_PENDING,
  LOGIN_REJECTED,
  LoginClearErrorAction,
  LoginFailAction,
  LoginPendingAction,
  LoginResponse,
  LoginSuccessAction,
  LOGOUT,
  LogoutAction,
  MFAResponse,
  SET_MFA_TOKEN,
  SetMFATokenAction,
  TOGGLE_REMEMBER_ME,
  VERIFY_MFA_CODE_PENDING,
  VerifyMFACodePendingAction,
} from "./types";

const loginPendingAction = (): LoginPendingAction => ({
  type: LOGIN_PENDING,
});

const loginSuccessAction = ({
  roles,
  permissions,
}: LoginResponse): LoginSuccessAction => ({
  type: LOGIN_FULFILLED,
  roles,
  permissions,
});

const loginFailAction = (error: string): LoginFailAction => ({
  type: LOGIN_REJECTED,
  error,
});

export const login =
  (authCredentials: AuthCredentials) =>
  async (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(loginPendingAction());

    try {
      const authResponse: LoginResponse | MFAResponse = await authorize(
        authCredentials
      );

      if ("twoFactor" in authResponse && authResponse.twoFactor) {
        dispatch(setMFAToken(authResponse));
      } else if ("roles" in authResponse) {
        dispatch(loginSuccessAction(authResponse));
      } else {
        dispatch(loginFailAction(dictionary.auth.unknownError));
      }
    } catch (e) {
      dispatch(loginFailAction(handleLoginError(e)));
    }
  };

export const loginClearError = (): LoginClearErrorAction => ({
  type: LOGIN_CLEAR_ERROR,
});

const verifyMFACodePendingAction = (): VerifyMFACodePendingAction => ({
  type: VERIFY_MFA_CODE_PENDING,
});

export const verifyMFACode =
  (
    verifyMFACodeRequest: VerifyMFACodeRequest,
    errorCallback?: (error: string) => void
  ) =>
  async (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(verifyMFACodePendingAction());

    try {
      const authResponse: LoginResponse = await verifyMFA(verifyMFACodeRequest);

      dispatch(loginSuccessAction(authResponse));
    } catch (e) {
      const error = handleLoginError(e);

      if (errorCallback) {
        errorCallback(error);
      }

      dispatch(loginFailAction(error));
    }
  };

export const logout = (): LogoutAction => ({
  type: LOGOUT,
});

export const setMFAToken = (mfaResponse: MFAResponse): SetMFATokenAction => {
  const { type, token } = mfaResponse;

  return {
    type: SET_MFA_TOKEN,
    mfaToken: token,
    mfaType: type,
  };
};

export const clearMFAToken = (): ClearMFATokenAction => ({
  type: CLEAR_MFA_TOKEN,
});

export const toggleRememberMe =
  () => async (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch({
      type: TOGGLE_REMEMBER_ME,
    });
  };
