import { FormControl, Grid, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent, FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../common/components/ButtonBlack";
import ButtonCancel from "../../../../common/components/ButtonCancel";
import ConfirmDialog from "../../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../../common/components/ConfirmDialog/Props";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useForm } from "../../../../common/hooks";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { getRouteWithSlash, Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  APPROVE_VENUE_CLAIM,
  ApproveVenueClaimRequest,
  REJECT_VENUE_CLAIM,
  RejectVenueClaimRequest,
} from "./api";
import { defaultForm, Field, schema } from "./form";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueClaimPendingInformationForAdmin: FC<Props> = (props) => {
  const { isWaitlistAdmin } = props;
  const [denyRequestConfirmModal, setDenyRequestConfirmModal] = useState(false);

  const history = useHistory();
  const { form, setAndValidate } = useForm(schema, defaultForm);
  const { showAlert } = useContext(AlertContext);
  const classes = useStyles();
  const [{ loading: loadingApprove }, approveClaim] = useRestApi<
    any,
    ApproveVenueClaimRequest
  >(APPROVE_VENUE_CLAIM(props.requestId), "POST");
  const [{ loading: loadingReject }, rejectClaim] = useRestApi<
    any,
    RejectVenueClaimRequest
  >(REJECT_VENUE_CLAIM(props.requestId), "POST");

  const handleChangeFormField = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAndValidate(e.target.name as Field, e.target.value);
  };

  const redirectToTheList = () => {
    history.push(getRouteWithSlash(Route.VenuesClaimRequestsPending));
  };

  const handlePressAcceptClaimVenueButton = () => {
    approveClaim({ comment: form.values.comment, isWaitlistAdmin })
      .then(() => {
        showAlert(dictionary.venueClaimPending.approveSuccess, "success");
        redirectToTheList();
      })
      .catch(() => {
        showAlert(dictionary.venueClaimPending.approveError, "error");
      });
  };

  const handlePressRejectClaimButton = () => {
    rejectClaim({ comment: form.values.comment })
      .then(() => {
        showAlert(dictionary.venueClaimPending.rejectSuccess, "success");
        redirectToTheList();
      })
      .catch(() => {
        showAlert(dictionary.venueClaimPending.rejectError, "error");
      });
  };

  return (
    <div className={classes.root}>
      {(loadingApprove || loadingReject) && <LoadingIndicator withMask />}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography variant={"h3"} className={classes.title}>
          {dictionary.venueClaimPending.informationForAdmin.title}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography variant={"body2"} className={classes.helperText}>
          {dictionary.venueClaimPending.informationForAdmin.commentHelper}
        </Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            multiline={true}
            rows={4}
            label={dictionary.venuePending.informationForAdmin.commentLabel}
            placeholder={
              dictionary.venuePending.informationForAdmin.commentPlaceholder
            }
            name="comment"
            error={!!form.errors.comment}
            helperText={form.errors.comment}
            onChange={handleChangeFormField}
            type="text"
            value={form.values.comment || ""}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <div className={classes.actionsToolbar}>
        <ButtonCancel
          className={classes.deleteButton}
          onClick={() => setDenyRequestConfirmModal(true)}>
          {dictionary.venueClaimPending.rejectButton}
        </ButtonCancel>
        <ButtonBlack
          disabled={!form.isValid}
          onClick={handlePressAcceptClaimVenueButton}>
          {dictionary.venueClaimPending.approveButton}
        </ButtonBlack>
      </div>
      <ConfirmDialog
        open={denyRequestConfirmModal}
        onClose={() => setDenyRequestConfirmModal(false)}
        onConfirm={handlePressRejectClaimButton}
        message={
          "Are you sure you would like to deny request? This cannot be undone."
        }
        type={ConfirmModalType.YES_DELETE}
        yesTypeAdditionalText={"deny request"}
      />
    </div>
  );
};

export default VenueClaimPendingInformationForAdmin;
