import { DialogActionsClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiDialogActions: Partial<Record<DialogActionsClassKey, CSSProperties>> =
  {
    root: {
      paddingLeft: 90,
      paddingRight: 90,
      paddingBottom: 40,
      paddingTop: 24,
    },
  };

export default MuiDialogActions;
