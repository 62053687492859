export const GET_VENUE_REVIEWS_TREND = "reviews/trend";

export interface VenueReviewsTrend {
  month: string;
  userReviewsQty: number;
  criticReviewsQty: number;
}

export interface GetVenueReviewsTrendResponse {
  stats: VenueReviewsTrend[];
}
