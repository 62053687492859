import { VenueReviewsTrend } from "./api";

export const chartDataMock: VenueReviewsTrend[] = [
  {
    month: "Jan",
    userReviewsQty: 1200,
    criticReviewsQty: 654,
  },
  {
    month: "Feb",
    userReviewsQty: 874,
    criticReviewsQty: 1024,
  },
  {
    month: "Mar",
    userReviewsQty: 993,
    criticReviewsQty: 888,
  },
  {
    month: "Apr",
    userReviewsQty: 600,
    criticReviewsQty: 675,
  },
  {
    month: "May",
    userReviewsQty: 567,
    criticReviewsQty: 432,
  },
  {
    month: "Jun",
    userReviewsQty: 1045,
    criticReviewsQty: 400,
  },
];
