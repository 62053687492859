import React, { FC, useEffect } from "react";

import { useRestApi } from "../../../../common/hooks/useRestApi";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import DashboardWidgetSmall from "../../../Dashboard/components/DashboardWidgetSmall";
import { ScoreTotal } from "../../../Dashboard/models";
import { GET_ACTIVE_PROMOTIONS_TOTAL } from "./api";

const TotalActivePromotions: FC = () => {
  const [{ data, loading }, getActivePromotionsQty] = useRestApi<ScoreTotal>(
    GET_ACTIVE_PROMOTIONS_TOTAL,
    "GET"
  );

  useEffect(() => {
    getActivePromotionsQty().catch((e) => console.error(e));
  }, [getActivePromotionsQty]);
  return (
    <DashboardWidgetSmall
      orientation={"vertical"}
      loading={loading}
      icon={"promotions"}
      linkHash={"#active"}
      description={dictionary.dashboard.totalActivePromotions}
      link={Route.Promotions}
      score={data?.score || 0}
    />
  );
};
export default TotalActivePromotions;
