import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames = "root" | "loginLink" | "loginLinkText" | "loginNotion";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        margin: theme.spacing(4, 0),
      },
      loginNotion: {
        fontWeight: 600,
        textAlign: "center",
      },
      loginLink: {
        display: "inline",
        color: colors.red.primary,
      },
      loginLinkText: {
        fontWeight: 700,
        color: colors.red.primary,
      },
    })
);
