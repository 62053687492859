import { Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import PageTitle from "../../../common/components/PageTitle";
import { useGlobalStyles } from "../../../common/styles";
import dictionary from "../../../i18n/en_US/dictionary";
import AllPromotionsList from "./components/AllPromotionsList";
import { useStyles } from "./styles";

const OcietyAdminPromotions: FC = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  return (
    <div>
      <div className={clsx(globalClasses.flexRow_Center, classes.titleWrapper)}>
        <PageTitle>{dictionary.promotions.titlePage}</PageTitle>
      </div>

      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} lg={12} md={12} xl={12}>
          <AllPromotionsList />
        </Grid>
      </Grid>
    </div>
  );
};
export default OcietyAdminPromotions;
