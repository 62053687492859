import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import OcietyIcon from "../../../../../../../../common/components/OcietyIcon";
import OcietySwitch from "../../../../../../../../common/components/OcietySwitch";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { setFormValue } from "../../../../../../../../store/forms/actions";
import { AppState } from "../../../../../../../../store/rootReducer";
// eslint-disable-next-line max-len
import { getMomentFromTimeString } from "../../../../../../../../utils/dateTimeUtils";
// eslint-disable-next-line max-len
import { WorkingDaysForm } from "../../../../form/WorkingDaysForm";
import { defaultTimeProps } from "../../utils";
import Props from "./Props";
import { useStyles } from "./styles";

const WorkingHoursDayRow: FC<Props> = (props: Props) => {
  const { id, day, hours, isOpen } = props;
  const form = useSelector<AppState, WorkingDaysForm>(
    (state) => state.forms.workingDays
  );
  const dispatch = useDispatch();
  const classes = useStyles();

  const toggleOpen = (itemId?: string) => () => {
    const newState = form.values.workingDays?.map((workday) => {
      if (workday.id === itemId) {
        if (workday.hours.length === 0 && !workday.isOpen) {
          return {
            ...workday,
            isOpen: true,
            hours: [...workday.hours, defaultTimeProps()],
          };
        }

        return { ...workday, isOpen: !workday.isOpen };
      }

      return workday;
    });

    dispatch(setFormValue("workingDays", "workingDays", newState));
  };

  const editHours = (
    dayId: string,
    timeIndex: string,
    prop: string,
    newTime: MaterialUiPickersDate
  ) => {
    const newState = form.values.workingDays?.map((workday) => {
      if (workday.id === dayId) {
        const newHours = workday.hours.map((time) => {
          if (time.id === timeIndex) {
            return {
              ...time,
              [prop]: newTime?.toDate().toLocaleTimeString(),
            };
          }

          return time;
        });

        return {
          ...workday,
          hours: newHours,
        };
      }
      return workday;
    });

    dispatch(setFormValue("workingDays", "workingDays", newState));
  };

  const addHours = (dayId: string) => () => {
    const newState = form.values.workingDays?.map((workday) => {
      if (workday.id === dayId) {
        return {
          ...workday,
          hours: [...workday.hours, defaultTimeProps()],
          isOpen: true,
        };
      }

      return workday;
    });

    dispatch(setFormValue("workingDays", "workingDays", newState));
  };

  const removeHours = (dayId: string, timeIndex: string) => () => {
    const newState = form.values.workingDays?.map((workday) => {
      if (workday.id === dayId) {
        const newHours = workday.hours.filter((time) => time.id !== timeIndex);

        if (newHours.length) {
          return {
            ...workday,
            hours: newHours,
          };
        }

        return {
          ...workday,
          hours: newHours,
          isOpen: false,
        };
      }

      return workday;
    });

    dispatch(setFormValue("workingDays", "workingDays", newState));
  };

  return (
    <Grid
      container
      spacing={1}
      justify="space-between"
      key={id}
      alignItems="flex-start"
      className={classes.root}>
      <Grid
        item
        container
        sm={12}
        xs={12}
        md={4}
        lg={4}
        justify="space-between">
        <Grid
          item
          container
          alignItems="center"
          className={classes.dayNameWrapper}
          justify={"space-between"}>
          <Grid item xs={11} sm={11} md={8} lg={8}>
            <Typography
              className={classes.dayName}
              variant={isOpen ? "subtitle1" : "subtitle2"}>
              {day}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={4} lg={4}>
            <OcietySwitch
              value={isOpen}
              checked={isOpen}
              onChange={toggleOpen(id)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} md={7} lg={7}>
        {hours.map((pair) => (
          <Grid
            container
            spacing={2}
            key={pair.id}
            justify="space-between"
            alignItems="center">
            <Grid item xs={12} sm={5} md={5} lg={5}>
              <Paper className={classes.timePickerWrapper}>
                <OcietyIcon
                  name={isOpen ? "clockActive" : "clock"}
                  height={"26px"}
                  width={"26px"}
                  viewBox={"0 0 26 26"}
                />
                <TimePicker
                  className={classes.timePicker}
                  disabled={!isOpen}
                  label={dictionary.venues.venueForm.openLabel}
                  inputProps={{
                    className: classes.timePickerInput,
                    shrink: true,
                  }}
                  value={getMomentFromTimeString(pair.from)}
                  onChange={(newTime) => {
                    return editHours(id, pair.id, "from", newTime);
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5}>
              <Paper className={classes.timePickerWrapper}>
                <OcietyIcon
                  name={isOpen ? "clockActive" : "clock"}
                  height={"26px"}
                  width={"26px"}
                  viewBox={"0 0 26 26"}
                />
                <TimePicker
                  className={classes.timePicker}
                  disabled={!isOpen}
                  label={dictionary.venues.venueForm.closeLabel}
                  inputProps={{
                    className: classes.timePickerInput,
                    shrink: true,
                  }}
                  value={getMomentFromTimeString(pair.to)}
                  onChange={(newTime) => editHours(id, pair.id, "to", newTime)}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <IconButton
                className={classes.button}
                onClick={removeHours(id, pair.id)}
                size="small"
                disabled={hours.length < 2}>
                <Paper className={classes.buttonPaper}>
                  <OcietyIcon
                    name={hours.length < 2 ? "deleteDisabled" : "delete"}
                  />
                </Paper>
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sm={2} md={1} lg={1}>
        <IconButton
          className={classes.button}
          size="small"
          onClick={addHours(id)}
          disabled={!isOpen || hours.length >= 5}>
          <Paper className={classes.buttonPaper}>
            <OcietyIcon
              name={!isOpen || hours.length >= 5 ? "addDisabled" : "add"}
            />
          </Paper>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default WorkingHoursDayRow;
