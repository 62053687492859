import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { useGlobalStyles } from "../../styles";
import BackButton from "../BackButton";
import Props from "./Props";
import { useStyles } from "./styles";

const PageTitle: FC<Props> = (props: Props) => {
  const { children, wrapperClassName, backURL } = props;

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <div className={clsx(globalClasses.flexRow_Center, wrapperClassName)}>
      {backURL && <BackButton to={backURL} />}
      <Typography className={classes.titleText} variant={"h2"}>
        {children}
      </Typography>
    </div>
  );
};

export default PageTitle;
