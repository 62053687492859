import {
  FormControl,
  Grid,
  Hidden,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { GET_VENUE_TYPES } from "../../../../common/api";
import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ButtonCancel from "../../../../common/components/ButtonCancel";
import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import ConfirmDialog from "../../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../../common/components/ConfirmDialog/Props";
import StateAutocompleteSearch from "../../../../common/components/forms/StateAutocompleteSearch";
import MultipleSelect from "../../../../common/components/MultipleSelect";
import Option from "../../../../common/components/MultipleSelect/Option";
import { useForm } from "../../../../common/hooks";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { VenueType, VenueTypes } from "../../../../common/models";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import VenuePendingInformationForAdmin from "../VenuePendingInformationForAdmin";
import {
  ApproveVenueRequest,
  RejectVenueRequest,
  VENUE_CREATION_REQUEST_APPROVE,
  VENUE_CREATION_REQUEST_REJECT,
} from "./api";
import {
  venueAddRequestForm,
  VenueAddRequestFormField,
  venueAddRequestFormSchema,
} from "./form";
import Props from "./Props";
import { useStyles } from "./styles";

const VenuePendingVenueForm: FC<Props> = (props: Props) => {
  const history = useHistory();
  const { showAlert } = useContext(AlertContext);
  const [confirmationDenyPopup, setConfirmationDenyPopup] = useState(false);
  const [{ data: venueTypes }, setVenueTypes] = useRestApi<VenueTypes>(
    GET_VENUE_TYPES,
    "GET"
  );

  const [{ loading: loadingVenueApprove }, approveVenue] = useRestApi<
    any,
    ApproveVenueRequest
  >(VENUE_CREATION_REQUEST_APPROVE, "POST");

  const [{ loading: loadingVenueReject }, rejectVenue] = useRestApi<
    { comment: string },
    RejectVenueRequest
  >(VENUE_CREATION_REQUEST_REJECT(props.requestId), "POST");

  const { form, setFormValues, setAndValidate, validateForm } = useForm(
    venueAddRequestFormSchema,
    venueAddRequestForm
  );
  const classes = useStyles();

  useEffect(() => {
    setVenueTypes().catch((e) => console.error(e));
  }, [setVenueTypes]);

  useEffect(() => {
    setFormValues({
      phoneNumber: props.venuePhone,
      bar: props.barTypesIds || "",
      restaurant: props.restaurantTypesIds || "",
      name: props.venueName,
      email: props.venueEmail,
      state: props.venueState,
      city: props.venueCity,
      country: props.venueCountry,
      address: props.venueAddress,
      latitude: "",
      longitude: "",
      comment: "",
      website: props.venueWebsite,
    });
  }, [props, setFormValues]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAndValidate(
      event.target.name as VenueAddRequestFormField,
      event.target.value
    );
  };

  const onSelectedTypes = (type: "bar" | "restaurant", values: string[]) => {
    setAndValidate(type, values.join(","));
  };

  const handleChangeState = useCallback(
    (state?: string) => {
      setAndValidate("state", state || "");
    },
    [setAndValidate]
  );

  const handleChangeCountry = useCallback(
    (country?: string) => {
      setAndValidate("country", country || "");
    },
    [setAndValidate]
  );

  const handleOpenConfirmationDenyPopup = () => {
    setConfirmationDenyPopup(true);
  };

  const handlePressRejectButton = () => {
    rejectVenue({ comment: form.values.comment })
      .then((e) => {
        showAlert(dictionary.venuePending.venueForm.rejectSuccess, "success");
        history.push("/" + Route.VenuesPending);
      })
      .catch((e) => {
        showAlert(dictionary.venuePending.venueForm.rejectError, "error");
      });
  };

  const handlePressCreateVenueButton = () => {
    if (!validateForm()) {
      return;
    }

    approveVenue({
      requestId: props.requestId,
      venueAddress: form.values.address,
      venueBarTypesIds: form.values.bar || undefined,
      venueCity: form.values.city,
      venueCountry: form.values.country,
      venueEmail: form.values.email,
      venueLatitude: form.values.latitude,
      venueLongitude: form.values.longitude,
      venueName: form.values.name,
      venuePhone: form.values.phoneNumber,
      venueRestaurantTypesIds: form.values.restaurant || undefined,
      venueState: form.values.state,
      venueWebsite: form.values.website,
    })
      .then(() => {
        showAlert(dictionary.venuePending.venueForm.approveSuccess, "success");
        history.push("/" + Route.VenuesPending);
      })
      .catch(() => {
        showAlert(dictionary.venuePending.venueForm.approveError, "error");
      });
  };

  return (
    <div>
      <Typography variant={"h3"}>Venue information</Typography>
      <Grid container spacing={4} className={classes.formContainer}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              required
              onChange={handleChange}
              label={dictionary.venuesPending.addForm.name}
              name="name"
              error={!!form.errors.name}
              helperText={form.errors.name}
              type="text"
              value={form.values.name || ""}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Hidden smDown>
          <Grid item lg={4} md={4} sm={6} xs={12} />
        </Hidden>
        <Grid item lg={8} md={8} sm={6} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              required
              label={dictionary.venuesPending.addForm.address}
              name="address"
              error={!!form.errors.address}
              helperText={form.errors.address}
              onChange={handleChange}
              type="text"
              value={form.values.address || ""}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Hidden smDown>
          <Grid item lg={4} md={4} />
        </Hidden>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <FormControl className={classes.formControl}>
            <StateAutocompleteSearch
              state={form.values.state}
              country={form.values.country}
              error={form.errors.state}
              onChange={handleChangeState}
              label={dictionary.venuesPending.addForm.state}
            />
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              required
              label={dictionary.venuesPending.addForm.city}
              name="city"
              error={!!form.errors.city}
              helperText={form.errors.city}
              onChange={handleChange}
              type="text"
              value={form.values.city || ""}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Hidden smDown>
          <Grid item lg={4} md={4} />
        </Hidden>
        {/*<Grid item lg={4} md={4} sm={4} xs={12}>
          <FormControl className={classes.formControl}>
            <CountryAutocompleteSearch
              country={form.values.country}
              error={form.errors.country}
              onChange={handleChangeCountry}
              label={dictionary.venuesPending.addForm.country}
            />
          </FormControl>
        </Grid>*/}
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              required
              label={dictionary.venuesPending.addForm.latitude}
              name="latitude"
              error={!!form.errors.latitude}
              helperText={form.errors.latitude}
              onChange={handleChange}
              type="text"
              value={form.values.latitude || ""}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              required
              label={dictionary.venuesPending.addForm.longitude}
              name="longitude"
              error={!!form.errors.longitude}
              helperText={form.errors.longitude}
              onChange={handleChange}
              type="text"
              value={form.values.longitude || ""}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Hidden smDown>
          <Grid item lg={4} md={4} />
        </Hidden>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              required
              label={dictionary.venuesPending.addForm.email}
              disabled
              name="email"
              error={!!form.errors.email}
              helperText={form.errors.email}
              onChange={handleChange}
              type="text"
              value={form.values.email || ""}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              required
              label={dictionary.venuesPending.addForm.phone}
              disabled
              name="phoneNumber"
              error={!!form.errors.phoneNumber}
              helperText={form.errors.phoneNumber}
              onChange={handleChange}
              type="text"
              value={form.values.phoneNumber || ""}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <FormControl variant={"outlined"} required fullWidth>
                <MultipleSelect
                  error={form.errors.bar}
                  onSelected={(values) => {
                    onSelectedTypes("bar", values);
                  }}
                  options={
                    !venueTypes
                      ? []
                      : venueTypes.bar.map(
                          (type: VenueType) =>
                            new Option(String(type.id), type.subtype)
                        )
                  }
                  selected={form.values.bar ? form.values.bar.split(",") : []}
                  type="bar"
                  label={dictionary.venues.venueForm.labelBarTypes}
                  helperText={form.errors.bar}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <FormControl variant={"outlined"} required fullWidth>
                <MultipleSelect
                  error={form.errors.restaurant}
                  onSelected={(values) => {
                    onSelectedTypes("restaurant", values);
                  }}
                  options={
                    !venueTypes
                      ? []
                      : venueTypes.restaurant.map(
                          (type: VenueType) =>
                            new Option(String(type.id), type.subtype)
                        )
                  }
                  selected={
                    form.values.restaurant
                      ? form.values.restaurant.split(",")
                      : []
                  }
                  type="restaraunt"
                  label={dictionary.venues.venueForm.labelRestaurantTypes}
                  helperText={form.errors.restaurant}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <VenuePendingInformationForAdmin
          classes={classes}
          form={form}
          onChange={handleChange}
        />
      </Grid>
      <div className={classes.actionsToolbar}>
        <ButtonSuccess
          disabled={!form.isValid || loadingVenueApprove}
          onClick={handlePressCreateVenueButton}>
          {dictionary.venuePending.venueForm.approveButton(loadingVenueApprove)}
        </ButtonSuccess>
        <ButtonCancel
          className={classes.deleteButton}
          disabled={loadingVenueReject}
          onClick={handleOpenConfirmationDenyPopup}>
          {dictionary.venuePending.venueForm.rejectButton}
        </ButtonCancel>
      </div>
      <ConfirmDialog
        open={confirmationDenyPopup}
        onClose={() => setConfirmationDenyPopup(false)}
        onConfirm={handlePressRejectButton}
        subtitle={
          "Are you sure you would like to reject venue? All data will be lost"
        }
        type={ConfirmModalType.CONFIRM}
        message={" \n  "}
      />
    </div>
  );
};

export default VenuePendingVenueForm;
