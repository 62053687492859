import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames =
  | "root"
  | "slideWrapper"
  | "slideImage"
  | "slideVideo"
  | "removeButton"
  | "arrow"
  | "arrowLeft"
  | "btn"
  | "arrowWrapper"
  | "warningIconContainer"
  | "warningIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
      },
      slideWrapper: {
        position: "relative",
        margin: theme.spacing(0.5),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 160,
        overflow: "hidden",
      },
      slideImage: {
        height: "100%",
        maxHeight: "100%",
        width: "100%",
        display: "inline-block",
      },
      slideVideo: {
        width: "100%",
        height: "auto",
      },
      removeButton: {
        zIndex: 1,
        position: "absolute",
        height: 32,
        width: 32,
        backgroundColor: "rgba(0,0,0,.46)",
        borderRadius: 4,
        top: 7,
        right: 7,
        padding: 5,

        "&:hover": {
          backgroundColor: "rgba(0,0,0,.56)",
        },
      },
      arrow: {
        display: "flex",
        marginRight: 10,
        width: 37,
        height: 37,
        backgroundColor: "#E5E7EF",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        cursor: "pointer",
      },
      arrowLeft: {
        "-webkit-transform": "scaleX(-1)",
      },
      btn: {
        padding: 0,
      },
      arrowWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: 8,
      },
      warningIconContainer: {
        zIndex: 1,
        position: "absolute",
        height: 40,
        width: 40,
        borderRadius: 4,
        top: 114,
        right: 7,
        backgroundColor: "rgba(255, 170, 80, 1)",
      },
      warningIcon: {
        "& > path": {
          fill: "white",
        },
        width: 24,
        marginBottom: 9,
        marginRight: 9,
      },
    })
);
