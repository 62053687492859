import moment from "moment";

import { Form } from "../../../../../../common/models";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { ValidationRules } from "../../../../../../utils/validation";
import { PromotionLimitType, PromotionType } from "../../../enums";
export type PromotionMainFormField =
  | "type"
  | "categoryId"
  | "subCategoryId"
  | "start"
  | "end"
  | "description"
  | "limitType"
  | "limitPercent"
  | "limitMaxAmount"
  | "limitItemsName"
  | "limitPrice"
  | "dietaryRestrictions";

export type PromotionMainFormValues = {
  [key in PromotionMainFormField]: string;
};

export type PromotionMainFormErrors = {
  [key in PromotionMainFormField]: string;
};

export interface IPromotionMainForm extends Form {
  values: PromotionMainFormValues;
  errors: PromotionMainFormErrors;
  touched: PromotionMainFormField[];
}

export const PromotionMainForm: IPromotionMainForm = {
  values: {
    type: PromotionType.FOOD,
    categoryId: "",
    subCategoryId: "",
    start: moment().format("YYYY-MM-DD"),
    end: moment().add(1, "days").format("YYYY-MM-DD"),
    description: "",
    limitType: PromotionLimitType.SINGLE,
    limitPercent: "0",
    limitMaxAmount: "0",
    limitPrice: "0",
    limitItemsName: "",
    dietaryRestrictions: "",
  },
  errors: {
    type: "",
    categoryId: "",
    subCategoryId: "",
    start: "",
    end: "",
    description: "",
    limitType: "",
    limitPercent: "",
    limitMaxAmount: "",
    limitPrice: "",
    limitItemsName: "",
    dietaryRestrictions: "",
  },
  touched: [],
  isValid: true,
};

export type PromotionMainFormSchemaType = Record<
  PromotionMainFormField,
  ValidationRules
>;

export const limitItemsNameBaseRules: ValidationRules = {
  fieldName: dictionary.promotions.createPromotionForm.productName,
  required: true,
  length: {
    min: 2,
    max: 256,
  },
};

export const limitPriceBaseRules: ValidationRules = {
  fieldName: dictionary.promotions.createPromotionForm.productPrice,
  type: "number+",
  required: true,
};

export const PromotionMainFormSchema: PromotionMainFormSchemaType = {
  type: {
    required: true,
  },
  categoryId: {
    type: "number",
  },
  subCategoryId: {
    type: "number",
  },
  start: {},
  end: {},
  description: {
    required: false,
  },
  limitType: {},
  limitPercent: {
    fieldName: dictionary.promotions.createPromotionForm.percent,
    type: "percentNumber",
    required: true,
  },
  limitMaxAmount: {
    fieldName: dictionary.promotions.createPromotionForm.maxAmount,
    type: "number",
  },
  limitPrice: limitPriceBaseRules,
  limitItemsName: limitItemsNameBaseRules,
  dietaryRestrictions: {},
};
