import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { Route } from "../../../config/router";
import dictionary from "../../../i18n/en_US/dictionary";
import { NavLink } from "../../models";
import LinksBox from "../LinksBox";
import Props from "./Props";
import { useStyles } from "./styles";

const footerLinks: NavLink[] = [
  // {
  //   path: Route.AboutUs,
  //   label: dictionary.footer.aboutUs,           waiting for new implementations
  // },
  {
    path: Route.TermsOfUse,
    label: dictionary.footer.termsOfUse,
  },
  {
    path: Route.TermsOfSale,
    label: dictionary.footer.termsOfSale,
  },
  {
    path: Route.Privacy,
    label: dictionary.footer.privacy,
  },
  {
    path: Route.ContactUs,
    label: dictionary.footer.contactUs,
  },
];

export const FOOTER_HEIGHT = "58px";
export const FOOTER_HEIGHT_MOBILE = "123px";

const Footer: FC<Props> = (props: Props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <footer {...rest} className={clsx(classes.root, className)}>
      <LinksBox
        className={classes.links}
        links={footerLinks}
        linkTextClassName={classes.linkText}
      />
      <Typography className={classes.copyright} variant="body1">
        {dictionary.footer.copyright}
      </Typography>
    </footer>
  );
};

export default Footer;
