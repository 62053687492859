export enum Route {
  ContactUs = "contact-us",
  BillingInformation = "billing-information",
  Dashboard = "dashboard",
  ForgotPassword = "forgot-password",
  Help = "help",
  Home = "",
  Login = "login",
  AboutUs = "about-us",
  Reporting = "reporting",
  Reviews = "reviews",
  AllReviews = "reviews/all-reviews",
  Recommendations = "recommendations",
  Settings = "settings",
  Subscriptions = "subscriptions",
  TermsAndConditions = "terms-and-conditions",
  TermsOfUse = "terms-of-use",
  TermsOfSale = "terms-of-sale",
  Privacy = "privacy-policy",
  Plan = "plan",
  Venues = "businesses",
  Promotions = "promotions",
  Messages = "messages",
  Venue = "venue",
  VenueSettings = "venue-settings",
  VenueSettingsAdmin = "venue-settings/:id",
  Profile = "profile",
  ProfileOld = "profile-old",
  Rbac = "rbac",
  VenuesPending = "venues-pending",
  VenuesChangesPending = "venues-changes-pending",
  VenuePending = "venue-pending/:id",
  VenueClaimPending = "venue-claim-pending/:id",
  VenuesClaimRequestsPending = "venue-claim-pending",
  Users = "users",
  PromotionsUsages = "promotions/:id/usages",
  VenueStaff = "venue-team",
  ManagerPromotions = "manager-promotions",
  UserPhotos = "media-requests",
  ReportedMedia = "reported-media",
}

export const getRouteWithSlash = (route: Route) => "/" + route;
