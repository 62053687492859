import { Checkbox, Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import { AxiosRequestConfig } from "axios";
import clsx from "clsx";
import React, { FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../../../common/components/ButtonBlack";
import ButtonPrimary from "../../../../../../common/components/ButtonPrimary";
import ImageFullScreen from "../../../../../../common/components/ImageFullScreen";
import ImgMediaCard from "../../../../../../common/components/ImgMediaCard";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../utils/dateTimeUtils";
import { REPORTED_MEDIA_ACCEPT } from "../../../../api";
import { AcceptReportRequest } from "../../../../models";
import { useStyles } from "../../styles";
import Props from "./Props";

const ReportedMediaTableRow: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [isFullScreenImg, setIsFullScreenImg] = useState(false);

  const { showAlert } = useContext(AlertContext);

  const {
    reportedMediaItem,
    handleOpenDeclineConfirmDialog,
    isChecked,
    setCheckedAll,
    selected,
    fetchReportedMedia,
  } = props;
  const {
    fileUrlForUser,
    venueName,
    address,
    id,
    photoId,
    createdAt,
    email,
    comment,
    question,
  } = reportedMediaItem;

  const [, acceptReport] = useRestApi<
    any,
    AcceptReportRequest,
    AxiosRequestConfig
  >(REPORTED_MEDIA_ACCEPT, "DELETE", {
    data: { photoIds: [photoId] },
  });

  const handleClickDeclineAction = () => {
    handleOpenDeclineConfirmDialog();
    setCheckedAll([id]);
  };

  const handleClickAcceptAction = () => {
    acceptReport()
      .then(() => {
        showAlert(
          dictionary.reportedMedia.acceptSuccessPopup,
          "success",
          dictionary.app.success
        );
        fetchReportedMedia();
      })
      .catch(() =>
        showAlert(
          dictionary.reportedMedia.acceptErrorPopup,
          "error",
          dictionary.app.oopsError
        )
      )
      .finally(() => setCheckedAll([]));
  };

  const handleCheckReport = () => {
    isChecked
      ? setCheckedAll((prevState: number[]) =>
          prevState.filter((state) => state !== id)
        )
      : setCheckedAll((prevState: number[]) => [...prevState, id]);
  };

  return (
    <Grid
      container
      lg={12}
      xs={12}
      component={Paper}
      className={clsx(classes.root, classes.gridContainer, classes.rootRow)}>
      <Grid className={clsx(classes.rowItem, classes.rowItem5)}>
        <Checkbox
          color="primary"
          checked={isChecked}
          onChange={handleCheckReport}
        />
      </Grid>
      <Grid
        className={clsx(classes.rowItem, classes.rowItem8)}
        onClick={() => setIsFullScreenImg(true)}>
        <ImgMediaCard height={69} width={129} image={fileUrlForUser} />
      </Grid>
      <Grid item className={clsx(classes.rowItem, classes.rowItem20)}>
        <div>
          <Typography variant={"body1"} className={classes.text}>
            {venueName}
          </Typography>
          <Typography variant={"body2"} className={classes.textAddress}>
            {address}
          </Typography>
        </div>
      </Grid>
      <Grid item className={clsx(classes.rowItem, classes.rowItem10)}>
        <Typography
          variant={"body1"}
          className={clsx(classes.text, classes.dateTextColor)}>
          {getFormattedDate(createdAt)}
        </Typography>
      </Grid>
      <Grid item className={clsx(classes.rowItem, classes.rowItem19)}>
        <Typography variant={"body1"} className={classes.text}>
          {email}
        </Typography>
      </Grid>
      <Grid item className={clsx(classes.rowItem, classes.rowItem19)}>
        <div className={classes.reportTextWrapper}>
          {question.map((el) => (
            <Typography key={el.id} variant={"body1"} className={classes.text}>
              {el.question}
            </Typography>
          ))}
          <Tooltip title={comment} placement={"bottom"}>
            <Typography variant={"body2"} className={classes.commentTxt}>
              {comment}
            </Typography>
          </Tooltip>
        </div>
      </Grid>
      <Grid item className={clsx(classes.rowItem, classes.rowItem19)}>
        <div className={classes.actionsWrapper}>
          <ButtonBlack
            disabled={!!selected}
            onClick={handleClickAcceptAction}
            className={clsx(classes.acceptButton, classes.approveButton)}>
            {dictionary.reportedMedia.actions.accept}
          </ButtonBlack>
          <ButtonPrimary
            disabled={!!selected}
            onClick={handleClickDeclineAction}
            color="primary"
            className={classes.acceptButton}>
            {dictionary.reportedMedia.actions.decline}
          </ButtonPrimary>
        </div>
      </Grid>
      <ImageFullScreen
        src={fileUrlForUser}
        isOpen={isFullScreenImg}
        close={() => setIsFullScreenImg(false)}
      />
    </Grid>
  );
};
export default ReportedMediaTableRow;
