import { TableCell, Typography } from "@material-ui/core";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const TH: FC<Props> = (props: Props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <TableCell>
      <Typography variant={"body2"} className={classes.text}>
        {children}
      </Typography>
    </TableCell>
  );
};

export default TH;
