import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import config from "../views/CoreSettings/ducks/reducer";
import profile from "../views/Profile/ducks/reducer";
import venueAdminProfile from "../views/VenueAdminProfile/ducks/reducer";
import venues from "../views/Venues/ducks/reducer";
import venue from "../views/VenueSettings/ducks/reducer";
import auth from "./auth/reducer";
import cart from "./cart/reducer";
import currentVenue from "./currentVenue/reducer";
import forgotPassword from "./forgotPassword/reducer";
import forms from "./forms/reducer";
import messaging from "./messaging/reducer";
import user from "./user/reducer";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["forms"],
};

const rootReducer = combineReducers({
  auth,
  forms,
  user,
  forgotPassword,
  venues,
  venue,
  currentVenue,
  venueAdminProfile,
  profile,
  config,
  cart,
  messaging,
});

export type AppState = ReturnType<typeof rootReducer>;

export default persistReducer(rootPersistConfig, rootReducer);
