// eslint-disable-next-line max-len
import {
  CoreSettingsForm,
  CoreSettingsFormSchema,
} from "../../views/CoreSettings/form";
import { IProfileForm } from "../../views/Profile/components/ProfileForm/form/IProfileForm";
import IProfileFormSchema from "../../views/Profile/components/ProfileForm/form/IProfileFormSchema";
import { VenueAdminMainForm } from "../../views/VenueAdminProfile/components/VenueAdminMainForm/form/VenueAdminMainForm";
import { IVenueAdminMainFormSchema } from "../../views/VenueAdminProfile/components/VenueAdminMainForm/form/VenueAdminMainFormSchema";
import {
  AssignVenueForm,
  AssignVenueFormSchema,
} from "../../views/Venues/components/AssignVenueDialog/form";
import {
  ClaimVenueForm,
  ClaimVenueFormSchema,
  ClaimVenueVerifyForm,
  ClaimVenueVerifyFormSchema,
} from "../../views/Venues/components/ClaimVenueDialog/form";
import { VenueForm } from "../../views/VenueSettings/components/VenueCommonData/form/VenueForm";
import { VenueFormSchema } from "../../views/VenueSettings/components/VenueCommonData/form/VenueFormSchema";
import { WorkingDaysForm } from "../../views/VenueSettings/components/VenueCommonData/form/WorkingDaysForm";

export type FormKeys = keyof FormsState;

export interface Form {
  errors: {
    [key: string]: string | undefined;
  };
  isValid: boolean;
  touched: string[];
  values: {
    [key: string]: any;
  };
}

export interface LoginForm extends Form {
  errors: {
    email?: string;
    password?: string;
  };
  values: {
    email?: string;
    password?: string;
  };
}

export interface MFAForm extends Form {
  errors: {
    code?: string;
  };
  values: {
    code?: string;
  };
}

export interface ForgotPasswordForm {
  errors: {
    email?: string;
  };
  isValid: boolean;
  touched: string[];
  values: {
    email?: string;
  };
}
export interface ChangePasswordForm {
  errors: {
    password?: string;
    confirmNewPassword?: string;
    code?: string;
  };
  isValid: boolean;
  touched: string[];
  values: {
    password?: string;
    confirmNewPassword?: string;
    code?: string;
  };
}

export type FormErrors = {
  [key in FormFieldKey]?: string;
};

export type FormTypes =
  | LoginForm
  | MFAForm
  | ClaimVenueForm
  | ClaimVenueVerifyForm
  | AssignVenueForm
  | CoreSettingsForm
  | ForgotPasswordForm
  | ChangePasswordForm
  | VenueForm
  | WorkingDaysForm;

export interface FormsState {
  login: LoginForm;
  mfa: MFAForm;
  forgotPassword: ForgotPasswordForm;
  changePassword: ChangePasswordForm;
  claimVenue: ClaimVenueForm;
  claimVenueVerify: ClaimVenueVerifyForm;
  assignVenue: AssignVenueForm;
  coreSettings: CoreSettingsForm;
  venue: VenueForm;
  venueAdminProfile: VenueAdminMainForm;
  profile: IProfileForm;
  workingDays: WorkingDaysForm;
}

export const CLEAR_FORM = "CLEAR_FORM";
export const CLEAR_FORM_ERROR = "CLEAR_FORM_ERROR";
export const CLEAR_FORM_ERRORS = "CLEAR_FORM_ERRORS";
export const DELETE_FORM_ERROR = "DELETE_FORM_ERROR";
export const DELETE_FORM_VALUE = "DELETE_FORM_VALUE";
export const REMOVE_TOUCHED_FIELD = "REMOVE_TOUCHED_FIELD";
export const SET_FORM = "SET_FORM";
export const SET_FORM_ERROR = "SET_FORM_ERROR";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";
export const SET_FORM_VALID = "SET_FORM_VALID";
export const SET_FORM_VALUE = "SET_FORM_VALUE";
export const SET_TOUCHED_FIELD = "SET_TOUCHED_FIELD";

interface FormBaseActionType {
  formKey: FormKeys;
}

export interface LoginFormSchema {
  email: {
    presence: { allowEmpty: boolean; message: string };
    email: boolean;
    length: {
      maximum: number;
    };
  };
  password: {
    presence: { allowEmpty: boolean; message: string };
    length: {
      maximum: number;
    };
  };
}

export interface ForgotPasswordFormSchema {
  email: {
    email: true;
    presence: { allowEmpty: boolean; message: string };
    length: {
      maximum: number;
    };
  };
}

export interface ChangePasswordFormSchema {
  password: {
    presence: { allowEmpty: boolean; message: string };
    length: {
      maximum: number;
    };
  };

  confirmNewPassword: {
    equality: Record<string, any>;
    presence: { allowEmpty: boolean; message: string };
    length: {
      maximum: number;
    };
  };

  code: {
    presence: { allowEmpty: boolean; message: string };
    format: Record<string, any>;
    length: {
      maximum: number;
      minimum: number;
    };
  };
}

export interface MFAFormSchema {
  code: {
    presence: { allowEmpty: boolean; message: string };
    length: {
      maximum: number;
    };
  };
}

export type FormSchemaTypes =
  | LoginFormSchema
  | MFAFormSchema
  | ClaimVenueFormSchema
  | ClaimVenueVerifyFormSchema
  | ForgotPasswordFormSchema
  | ChangePasswordFormSchema
  | AssignVenueFormSchema
  | CoreSettingsFormSchema
  | VenueFormSchema
  | IVenueAdminMainFormSchema
  | IProfileFormSchema;

export type FormFieldKey =
  | keyof LoginFormSchema
  | keyof MFAFormSchema
  | keyof ClaimVenueFormSchema
  | keyof ClaimVenueVerifyFormSchema
  | keyof ForgotPasswordFormSchema
  | keyof ChangePasswordFormSchema
  | keyof AssignVenueFormSchema
  | keyof CoreSettingsFormSchema;
// | keyof VenueDetailsFormSchema;

export interface ClearFormActionType extends FormBaseActionType {
  type: typeof CLEAR_FORM;
}

export interface ClearFormErrorsActionType extends FormBaseActionType {
  type: typeof CLEAR_FORM_ERRORS;
}

export interface DeleteFormErrorActionType extends FormBaseActionType {
  type: typeof DELETE_FORM_ERROR;
  formFieldKey: FormFieldKey;
}

export interface DeleteFormValueActionType extends FormBaseActionType {
  type: typeof DELETE_FORM_VALUE;
  formFieldKey: FormFieldKey;
}

export interface RemoveTouchedFieldActionType extends FormBaseActionType {
  type: typeof REMOVE_TOUCHED_FIELD;
  formFieldKey: FormFieldKey;
}

export interface SetFormActionType extends FormBaseActionType {
  type: typeof SET_FORM;
  form: FormTypes;
}

export interface SetFormErrorActionType extends FormBaseActionType {
  type: typeof SET_FORM_ERROR;
  formFieldKey: FormFieldKey;
  error: string;
  value?: any;
}

export interface ClearFormErrorActionType extends FormBaseActionType {
  type: typeof CLEAR_FORM_ERROR;
  formFieldKey: FormFieldKey;
  value?: any;
}

export interface SetFormErrorsActionType extends FormBaseActionType {
  type: typeof SET_FORM_ERRORS;
  errors: FormErrors;
}

export interface SetFormValidActionType extends FormBaseActionType {
  type: typeof SET_FORM_VALID;
}

export interface SetFormValueActionType extends FormBaseActionType {
  type: typeof SET_FORM_VALUE;
  formFieldKey: FormFieldKey;
  value: any;
}

export interface SetTouchedFieldActionType extends FormBaseActionType {
  type: typeof SET_TOUCHED_FIELD;
  formFieldKey: FormFieldKey;
}

export type FormActionTypes =
  | ClearFormActionType
  | ClearFormErrorActionType
  | ClearFormErrorsActionType
  | DeleteFormErrorActionType
  | DeleteFormValueActionType
  | RemoveTouchedFieldActionType
  | SetFormActionType
  | SetFormErrorActionType
  | SetFormErrorsActionType
  | SetFormValidActionType
  | SetFormValueActionType
  | SetTouchedFieldActionType;
