import React, { FC, useCallback, useContext, useEffect, useRef } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";

import { AlertContext } from "./common/components/Alert/AlertContextProvider";
import PrivateRoute from "./common/components/PrivateRoute";
import RouteWithLayout from "./common/components/RouteWithLayout";
import { useIsDiscountAdmin } from "./common/hooks/useIsDiscountAdmin";
import { useIsManager } from "./common/hooks/useIsManager";
import { useIsSuperAdmin } from "./common/hooks/useIsSuperAdmin";
import { useMessaging } from "./common/hooks/useMessaging";
import { useWebsocket } from "./common/hooks/useWebsocket";
import { isPackagesDisabled, RBACK_ENABLED } from "./config";
import { subscribeResponse } from "./config/api";
import { getRouteWithSlash, Route } from "./config/router";
import MainLayout from "./layouts/Main";
import MinimalLayout from "./layouts/Minimal";
import BillingInformation from "./views/BillingInformation";
import ContactUs from "./views/ContactUs";
import CoreSettings from "./views/CoreSettings";
import Dashboard from "./views/Dashboard";
import DashboardSuperAdmin from "./views/DashboardSuperAdmin";
import ForgotPassword from "./views/ForgotPassword";
import Login from "./views/Login";
import ManagerPromotions from "./views/ManagerPromotions/Promotions";
import Messages from "./views/Messages";
import NotFound from "./views/NotFound";
import Plan from "./views/Plan";
import Profile from "./views/Profile";
import OcietyAdminPromotions from "./views/Promotions/OcietyAdminPromotions";
import PromotionsUsages from "./views/Promotions/PromotionsUsages";
import VenueAdminPromotions from "./views/Promotions/VenueAdminPromotions";
import RBACAdmins from "./views/RBAC/admins";
import RBAC from "./views/RBAC/main";
import RBACRolePermissions from "./views/RBAC/rolePermissions";
import RBACRoles from "./views/RBAC/roles";
import ReportedPhotos from "./views/ReportedPhotos";
import Reporting from "./views/Reporting";
import Reviews from "./views/Reviews";
import AllReviews from "./views/Reviews/components/AllReviews";
import PrivacyPolicy from "./views/StaticPages/PrivacyPolicy";
import TermsAndConditions from "./views/StaticPages/TermsAndConditions";
import TermsOfSale from "./views/StaticPages/TermsOfSale";
import TermsOfUse from "./views/StaticPages/TermsOfUse";
import Subscriptions from "./views/Subscriptions";
import UserPhotos from "./views/UserPhotos";
import VenueAdminProfile from "./views/VenueAdminProfile";
import VenueClaimPending from "./views/VenueClaimPending";
import VenueClaimRequestsPending from "./views/VenueClaimRequestsPending";
import VenuePending from "./views/VenuePending";
import Venues from "./views/Venues";
import VenuesChangesPending from "./views/VenuesChangesPending";
import VenueSettings from "./views/VenueSettings";
import VenuesPending from "./views/VenuesPending";
import VenueStaff from "./views/VenueStaff/main";

const Routes: FC = () => {
  useWebsocket();
  useMessaging();

  const { showAlert } = useContext(AlertContext);
  const isSubscribed = useRef(false);
  const superAdmin = useIsSuperAdmin();
  const discountAdmin = useIsDiscountAdmin();
  const manager = useIsManager();
  const history = useHistory();

  useEffect(() => {
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_CHAT_WEBSITE_ID;
    (function () {
      const d = document;
      const s: any = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.id = "crisp";
      s.async = 1;
      if (!discountAdmin) {
        d.getElementsByTagName("head")[0].appendChild(s);
      } else {
        const linkElements = d.getElementsByTagName("link");
        for (let i = linkElements.length - 1; i >= 0; --i) {
          if (linkElements[i].href.includes("crisp")) {
            linkElements[i].remove();
          }
        }
        const chartElement = d.getElementById("crisp-chatbox");
        if (chartElement) {
          chartElement?.parentNode?.removeChild(chartElement);
        }
      }
    })();
  }, [discountAdmin]);

  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      (function (w, d, s, l, i) {
        // @ts-ignore
        w[l] = window.dataLayer || [];
        // @ts-ignore
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        // @ts-ignore
        j.async = true;
        // @ts-ignore
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        // @ts-ignore
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", process.env.REACT_APP_GTM_ID);
    }
  }, []);

  const showErrorMessage = useCallback(
    (message: string) => {
      showAlert(message, "error");
    },
    [showAlert]
  );

  // Moved from useEffect, because need to subscribe before app's first render
  if (!isSubscribed.current) {
    subscribeResponse((message: string) => {
      showErrorMessage(message);
    });

    isSubscribed.current = true;
  }

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={
          discountAdmin
            ? getRouteWithSlash(Route.ManagerPromotions)
            : getRouteWithSlash(Route.Dashboard)
        }
      />
      {(superAdmin || manager) && (
        <PrivateRoute
          component={OcietyAdminPromotions}
          exact
          layout={MainLayout}
          path="/promotions"
        />
      )}
      {!superAdmin && !manager && (
        <PrivateRoute
          component={VenueAdminPromotions}
          exact
          layout={MainLayout}
          path="/promotions"
        />
      )}
      {!isPackagesDisabled && !superAdmin && !manager && (
        <PrivateRoute
          component={BillingInformation}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.BillingInformation)}
        />
      )}
      {!discountAdmin && (
        <PrivateRoute
          component={PromotionsUsages}
          exact
          layout={MainLayout}
          path="/promotions/:id/usages"
        />
      )}
      <RouteWithLayout
        component={Venues}
        exact
        layout={MinimalLayout}
        path="/businesses/:uuid?"
      />
      <RouteWithLayout
        component={ContactUs}
        exact
        layout={MinimalLayout}
        path="/contact-us"
      />
      <RouteWithLayout
        component={TermsAndConditions}
        exact
        layout={MinimalLayout}
        path="/terms-and-conditions"
      />
      <RouteWithLayout
        component={() => {
          window.location.href = "https://help.ociety.com/";
          return null;
        }}
        exact
        layout={MinimalLayout}
        path="/help"
      />
      {!discountAdmin && (
        <PrivateRoute
          component={Profile}
          exact
          layout={MainLayout}
          path="/profile"
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          // component={VenueAdminPromotions}
          component={OcietyAdminPromotions}
          exact
          layout={MainLayout}
          path="/promotions"
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={VenuesPending}
          exact
          layout={MainLayout}
          path="/venues-pending"
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={VenuePending}
          exact
          layout={MainLayout}
          path="/venue-pending/:id"
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={VenuesChangesPending}
          exact
          layout={MainLayout}
          path="/venues-changes-pending"
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={UserPhotos}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.UserPhotos)}
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={ReportedPhotos}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.ReportedMedia)}
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={VenueClaimRequestsPending}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.VenuesClaimRequestsPending)}
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={VenueClaimPending}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.VenueClaimPending)}
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={Subscriptions}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.Subscriptions)}
        />
      )}
      <PrivateRoute
        component={VenueAdminProfile}
        exact
        layout={MainLayout}
        path="/profile-old"
      />
      {(superAdmin || manager) && RBACK_ENABLED && (
        <PrivateRoute component={RBAC} exact layout={MainLayout} path="/rbac" />
      )}
      {(superAdmin || manager) && RBACK_ENABLED && (
        <PrivateRoute
          component={RBACRoles}
          exact
          layout={MainLayout}
          path="/rbac/roles"
        />
      )}
      {!discountAdmin && (
        <PrivateRoute
          component={VenueStaff}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.VenueStaff)}
        />
      )}
      {!discountAdmin && (
        <PrivateRoute
          component={superAdmin || manager ? DashboardSuperAdmin : Dashboard}
          exact
          layout={MainLayout}
          path="/dashboard"
        />
      )}
      {!superAdmin && !discountAdmin && !manager && (
        <PrivateRoute
          component={Messages}
          exact
          layout={MainLayout}
          path="/messages"
        />
      )}
      {!superAdmin && !discountAdmin && !manager && (
        <PrivateRoute
          component={Reporting}
          exact
          layout={MainLayout}
          path="/reporting"
        />
      )}
      {/*{!superAdmin && !discountAdmin && !manager &&(
        <PrivateRoute
          component={Recommendations}
          exact
          layout={MainLayout}
          path="/recommendations"
        />
      )}*/}
      {!superAdmin && !discountAdmin && !manager && (
        <PrivateRoute
          component={Reviews}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.Reviews)}
        />
      )}
      {!superAdmin && !discountAdmin && !manager && (
        <PrivateRoute
          component={AllReviews}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.AllReviews)}
        />
      )}
      {!discountAdmin && (
        <PrivateRoute
          component={VenueSettings}
          exact
          layout={MainLayout}
          path={
            superAdmin || manager
              ? getRouteWithSlash(Route.VenueSettingsAdmin)
              : getRouteWithSlash(Route.VenueSettings)
          }
        />
      )}
      {!superAdmin && !discountAdmin && !manager && (
        <PrivateRoute component={Plan} exact layout={MainLayout} path="/plan" />
      )}
      {superAdmin && !discountAdmin && RBACK_ENABLED && manager && (
        <PrivateRoute
          component={RBACAdmins}
          exact
          layout={MainLayout}
          path="/rbac/admins"
        />
      )}
      {superAdmin && !discountAdmin && manager && RBACK_ENABLED && (
        <PrivateRoute
          component={RBACRolePermissions}
          exact
          layout={MainLayout}
          path="/rbac/role/:number"
        />
      )}
      {discountAdmin && (
        <PrivateRoute
          component={ManagerPromotions}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.ManagerPromotions)}
        />
      )}
      <RouteWithLayout
        component={TermsOfSale}
        exact
        path="/terms-of-sale"
        layout={MinimalLayout}
      />
      <RouteWithLayout
        component={TermsOfUse}
        exact
        path="/terms-of-use"
        layout={MinimalLayout}
      />
      <RouteWithLayout
        component={PrivacyPolicy}
        exact
        layout={MinimalLayout}
        path="/privacy-policy"
      />
      <RouteWithLayout
        component={TermsAndConditions}
        exact
        layout={MinimalLayout}
        path="/terms-and-conditions"
      />
      <RouteWithLayout
        component={TermsOfSale}
        exact
        path="/terms-of-sale"
        layout={MinimalLayout}
      />
      <RouteWithLayout
        component={TermsOfUse}
        exact
        path="/terms-of-use"
        layout={MinimalLayout}
      />
      <RouteWithLayout
        component={PrivacyPolicy}
        exact
        layout={MinimalLayout}
        path="/privacy-policy"
      />
      <RouteWithLayout
        className={"no-header"}
        component={Login}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        className={"no-header"}
        component={ForgotPassword}
        exact
        layout={MinimalLayout}
        path="/forgot-password"
      />
      {superAdmin && !discountAdmin && !manager && (
        <PrivateRoute
          component={CoreSettings}
          exact
          layout={MainLayout}
          path="/settings"
        />
      )}
      <RouteWithLayout
        component={NotFound}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      {history.location.pathname === "/venues" ? (
        <Redirect to="/businesses" />
      ) : (
        <Redirect to="/not-found" />
      )}
    </Switch>
  );
};

export default Routes;
