import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import Avatar from "../../../../../../../../common/components/Avatar";
import { formatUserTypeToString } from "../../../../../../../../utils/userUtils";
import Props from "./Props";
import { useStyles } from "./styles";

const ReviewUserBlock: FC<Props> = (props: Props) => {
  const { avatar, firstName, userType } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar imagePath={avatar} />
      <div className={classes.rightSection}>
        <Typography className={classes.userName}>{firstName}</Typography>
        <Typography className={classes.userType}>
          {formatUserTypeToString(userType)}
        </Typography>
      </div>
    </div>
  );
};

export default ReviewUserBlock;
