import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames = "button";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      button: {
        backgroundColor: colors.white.primary,
        color: theme.palette.text.primary,
        border: "1.5px solid #EBECF0",
        borderRadius: 4,
        minHeight: 48,
        minWidth: 116,
        textTransform: "none",
        "&:hover": {
          backgroundColor: colors.white.primary,
        },
        "&:$disabled": {
          opacity: ".7",
        },
        fontWeight: "bold",
      },
    })
);
