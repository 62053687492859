import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "error"
  | "uploadedFilesTitle"
  | "uploadedFilesTitleWrapper"
  | "infoIcon"
  | "uploaderContainer";
export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        margin: theme.spacing(0, 0),
      },
      uploaderContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      error: {
        backgroundColor: "rgba(255,218,218,0.35)",
      },
      uploadedFilesTitle: {
        marginBottom: 10,
      },
      infoIcon: { marginBottom: 10, marginLeft: 10 },
      uploadedFilesTitleWrapper: {
        display: "flex",
        alignItems: "center",
        zIndex: 1,
        position: "relative",
        marginTop: 32,
        width: "50%",
      },
    })
);
