import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { DEFAULT_AVATAR_SIZE } from "../../../../../../../../common/components/Avatar/styles";
import { OcietyTheme } from "../../../../../../../../common/models";
import { colors } from "../../../../../../../../theme/colors";

export type ClassNames = "root" | "rightSection" | "userType" | "userName";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        borderBottom: "1px solid #F3F5F7",
        display: "flex",
        maxWidth: "100%",
        alignItems: "center",
        flexDirection: "row",
        paddingBottom: theme.spacing(3),
      },
      rightSection: {
        maxWidth: `calc(100% - ${DEFAULT_AVATAR_SIZE}px)`,
        display: "flex",
        flexDirection: "column",
      },
      userName: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(24),
        marginBottom: 10,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      userType: {
        fontWeight: 700,
        color: colors.grey.light,
        fontSize: theme.typography.pxToRem(16),
      },
    })
);
