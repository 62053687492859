import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import { Player } from "video-react";

import { AlertContext } from "../../../../../../../../../../../common/components/Alert/AlertContextProvider";
import Dropzone from "../../../../../../../../../../../common/components/forms/Dropzone";
import OcietyTooltip from "../../../../../../../../../../../common/components/OcietyTooltip";
import PackageInformationPopup from "../../../../../../../../../../../common/components/PackageInformationPopup";
import { useIsDiscountAdmin } from "../../../../../../../../../../../common/hooks/useIsDiscountAdmin";
import { useVenueHasPackage } from "../../../../../../../../../../../common/hooks/useVenueHasPacakage";
import { ReactComponent as InfoTooltip } from "../../../../../../../../../../../common/svg/icons/info-tooltip.svg";
import { ReactComponent as VideoDropZoneIcon } from "../../../../../../../../../../../common/svg/icons/videoDropZoneIcon.svg";
import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../../../../../../../../../../store/currentVenue/reducer";
import { PackageUID } from "../../../../../../../../../../../store/currentVenue/types";
import { AppState } from "../../../../../../../../../../../store/rootReducer";
import VenueMediaCarousel from "../../../../../../../../../../VenueSettings/components/VenueMediaContent/components/VenueMediaCarousel";
import VenueMediaUploader from "../../../../../../../../../../VenueSettings/components/VenueMediaContent/components/VenueMediaUploader";
import PhotoUpload from "../../../../../../../../../../VenueSettings/components/VenueMediaContent/components/VenueMediaUploader/components/PhotoUpload";
import VideoUpload from "../../../../../../../../../../VenueSettings/components/VenueMediaContent/components/VenueMediaUploader/components/VideoUpload";
import { PromotionFormDialogContext } from "../../../../../../PromotionFormDialogContextProvider";
import { uploadPromotionPhoto, uploadPromotionVideo } from "./api";
import Props from "./Props";
import { useStyles } from "./styles";

const FileUploaderPromotion: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    error,
    onVideoUpload,
    onPhotoUpload,
    onPhotoDelete,
    photosWithIds,
    videosWithIds,
    onVideoDelete,
    onMainPhotoChange,
  } = props;
  const { showAlert } = useContext(AlertContext);
  const { promotionId } = useContext(PromotionFormDialogContext);
  const [isOpen, setIsOpen] = useState(false);

  const discountAdmin = useIsDiscountAdmin();
  const hasPackage = useVenueHasPackage(PackageUID.PROFILE_UPD);

  let additionalClass;
  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (appState) => appState.currentVenue
  );

  if (error) {
    additionalClass = classes.error;
  }

  const venueId: number = currentVenue ? currentVenue.id : 0;

  const handleUploadVideo = useCallback(
    (
      photo: any,
      progressUpdate: (value: number) => void,
      successCallback: () => void
    ) => {
      uploadPromotionVideo(photo, String(venueId), progressUpdate)
        .then((response) => response.data)
        .then((venueVideo: { discountVideoId: number; location: string }) => {
          onVideoUpload({
            id: venueVideo.discountVideoId,
            url: venueVideo.location,
          });

          showAlert(
            dictionary.venues.mediaContent.successPhotoUpload,
            "success"
          );
          successCallback();
        })
        .catch(() =>
          showAlert(dictionary.venues.mediaContent.errorPhotoUpload, "error")
        );
    },
    [showAlert, onVideoUpload, venueId]
  );

  const handleUploadPhoto = useCallback(
    (
      photo: any,
      progressUpdate: (value: number) => void,
      successCallback: () => void
    ) => {
      uploadPromotionPhoto(photo, venueId, promotionId, progressUpdate)
        .then((response) => response.data)
        .then((venuePhoto: { discountPhotoId: number; location: string }) => {
          onPhotoUpload({
            id: venuePhoto.discountPhotoId,
            url: venuePhoto.location,
          });
          if (!hasPackage && !discountAdmin) {
            setIsOpen(true);
          }

          showAlert(
            dictionary.venues.mediaContent.successPhotoUpload,
            "success"
          );
          successCallback();
        })
        .catch((error) => {
          showAlert(
            error.response
              ? error.response.data
              : dictionary.venues.mediaContent.errorPhotoUpload,
            "error"
          );
        });
    },
    [discountAdmin, venueId, promotionId, onPhotoUpload, showAlert, hasPackage]
  );

  const onPhotoDrop = useCallback(
    (
      files: any[],
      progressUpdate: (value: number) => void,
      successCallback: () => void
    ) => {
      if (files.length) {
        const file = files[0];
        if (file.type.match(/image/g)) {
          handleUploadPhoto(file, progressUpdate, successCallback);
        } else {
          showAlert("Incorrect file type", "error");
        }
      }

      if (!hasPackage) {
        setIsOpen(true);
      }
    },
    [handleUploadPhoto, showAlert, hasPackage]
  );

  const onVideoDrop = useCallback(
    (
      files: any[],
      progressUpdate: (value: number) => void,
      successCallback: () => void
    ) => {
      if (files.length) {
        const file = files[0];
        if (file.type.match(/video/g)) {
          handleUploadVideo(file, progressUpdate, successCallback);
        } else {
          showAlert("Incorrect file type", "error");
        }
      }
    },
    [handleUploadVideo, showAlert]
  );

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  return (
    <div className={clsx(classes.root, additionalClass)}>
      {photosWithIds.length || videosWithIds.length ? (
        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.uploaderContainer}>
          <OcietyTooltip
            title={dictionary.venues.mediaContent.photoUploadHint}
            placement="bottom-start"
            disableHoverListener={hasPackage}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Dropzone isLock={!hasPackage} onDrop={onPhotoDrop} />
            </Grid>
          </OcietyTooltip>
          <OcietyTooltip
            title={dictionary.venues.mediaContent.videoUploadHint}
            placement="bottom-end"
            disableHoverListener={hasPackage}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Dropzone
                isLock={!hasPackage}
                onDrop={onVideoDrop}
                description={dictionary.venues.mediaContent.maxVideoSize}
                dropZoneIcon={<VideoDropZoneIcon />}
              />
            </Grid>
          </OcietyTooltip>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <PhotoUpload
              onUploadPhoto={handleUploadPhoto}
              isLock={!hasPackage}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <VideoUpload
              onUploadVideo={handleUploadVideo}
              isLock={!hasPackage}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.uploadedFilesTitleWrapper}>
              <Typography variant="h3" className={classes.uploadedFilesTitle}>
                {dictionary.venues.mediaContent.uploadedFiles}
              </Typography>
              <OcietyTooltip
                placement="right"
                title={
                  hasPackage
                    ? dictionary.venues.mediaContent.maxPhotoQuantity
                    : dictionary.venues.mediaContent.photoUploadHint
                }>
                <InfoTooltip className={classes.infoIcon} />
              </OcietyTooltip>
            </div>
            <VenueMediaCarousel
              promotionPhotos={photosWithIds}
              promotionVideos={videosWithIds}
              onDeletePhoto={onPhotoDelete}
              onDeleteVideo={onVideoDelete}
              onMainPhotoChange={onMainPhotoChange}
            />
          </Grid>
          <PackageInformationPopup
            open={isOpen}
            onClose={handleClosePopup}
            content={dictionary.venues.mediaContent.informationPopupContent}
            title={dictionary.venues.mediaContent.informationPopup}
          />
        </Grid>
      ) : (
        <VenueMediaUploader
          tag={"interior"}
          uploaderTitle={
            dictionary.promotions.createPromotionForm.uploadPromotionMedia
          }
          onUploadPromotionPhoto={handleUploadPhoto}
          onUploadPromotionVideo={handleUploadVideo}
          label={
            dictionary.promotions.createPromotionForm
              .promotionWithoutUploadingHint
          }
        />
      )}
    </div>
  );
};
export default FileUploaderPromotion;
