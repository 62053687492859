import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import { OcietyTheme } from "../../models";
import ButtonCancel from "../ButtonCancel";
import ButtonDelete from "../ButtonDelete";
import ButtonPrimary from "../ButtonPrimary";
import Props, { ConfirmModalType } from "./Props";
import { useStyles } from "./styles";

const ConfirmDialog: FC<Props> = (props: Props) => {
  const {
    open,
    onClose,
    onConfirm,
    type,
    yesTypeAdditionalText,
    title,
    subtitle,
    message,
  } = props;

  const isMobile = useMediaQuery((theme: OcietyTheme) =>
    theme.breakpoints.down("sm")
  );

  const classes = useStyles();
  const additionalText = yesTypeAdditionalText ? yesTypeAdditionalText : "";

  const renderConfirmButton = () => {
    switch (type) {
      case ConfirmModalType.DELETE: {
        return (
          <ButtonDelete
            className={classes.actionButton}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          />
        );
      }
      case ConfirmModalType.YES_DELETE: {
        return (
          <ButtonPrimary
            className={classes.actionButton}
            onClick={() => {
              onConfirm();
              onClose();
            }}>
            {dictionary.confirmDialog.yesDeleteButton(additionalText)}
          </ButtonPrimary>
        );
      }
      case ConfirmModalType.CONFIRM: {
        return (
          <ButtonPrimary
            className={classes.actionButton}
            onClick={() => {
              onConfirm();
              onClose();
            }}>
            {dictionary.confirmDialog.confirmButton}
          </ButtonPrimary>
        );
      }
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.titleWrapper} disableTypography>
        <Typography className={classes.title}>
          {title || dictionary.confirmDialog.title}
        </Typography>
        {!isMobile && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.subtitle}>{subtitle || null}</Typography>
        <Typography className={classes.message}>{message}</Typography>
      </DialogContent>
      {!isMobile ? (
        <DialogActions className={classes.actions}>
          <ButtonCancel onClick={onClose}>
            {dictionary.confirmDialog.cancelButton}
          </ButtonCancel>
          {renderConfirmButton()}
        </DialogActions>
      ) : (
        <DialogActions className={classes.actions}>
          <Grid container>
            <Grid item xs={12}>
              {renderConfirmButton()}
            </Grid>
            <Grid item xs={12} className={classes.actionButtonContainer}>
              <ButtonCancel onClick={onClose} className={classes.actionButton}>
                {dictionary.confirmDialog.cancelButton}
              </ButtonCancel>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
