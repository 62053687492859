import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../common/models";
import { colors } from "../../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "content"
  // | "icon"
  | "nameContainer"
  // | "avatar"
  | "actions"
  // | "address"
  | "tableRow"
  // | "title"
  // | "textField"
  // | "photoContainer"
  | "heading"
  | "th"
  | "td"
  | "td33"
  | "td40"
  | "thead"
  | "tbody"
  | "venueName"
  | "signInButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(5),
      },
      tbody: {
        boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
      },
      thead: {
        background: "transparent",
      },
      th: {
        border: "none",
      },
      td: {
        position: "relative",
        border: "none",
        "&:after": {
          content: "''",
          display: "block",
          position: "absolute",
          left: 0,
          bottom: 0,
          width: "100%",
          height: theme.spacing(1),
          background: theme.palette.background.default,
          boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
        },
        "&:first-of-type, &:last-of-type": {
          borderRadius: 4,
        },
      },
      td33: {
        width: "33%",
      },
      td40: {
        width: "40%",
      },
      content: {
        padding: 0,
        marginBottom: theme.spacing(4),
      },
      nameContainer: {
        display: "flex",
        alignItems: "center",
      },
      // avatar: {
      //   height: 60,
      //   width: 60,
      // },
      actions: {
        justifyContent: "flex-end",
      },
      tableRow: {
        padding: theme.spacing(1, 1, 2),
      },
      // icon: {
      //   padding: theme.spacing(1),
      // },
      // title: {
      //   marginTop: theme.spacing(3),
      // },
      // textField: {
      //   marginTop: theme.spacing(2),
      // },
      signInButton: {
        margin: theme.spacing(2, 0),
      },
      venueName: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
      // address: {
      //   color: colors.grey.additional,
      //   fontSize: theme.typography.pxToRem(16),
      //   fontWeight: 600,
      // },
      // photoContainer: {
      //   display: "flex",
      //   justifyContent: "center",
      // },
      heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
      },
    })
);
