import {
  requiredNumberValidationRulesDefault,
  requiredTextValidationRulesDefault,
} from "../../../../../config/validation";

export interface VenueFormSchema {
  name: Record<string, unknown>;
  description: Record<string, unknown>;
  address: Record<string, unknown>;
  city: Record<string, unknown>;
  country: Record<string, unknown>;
  state: Record<string, unknown>;
  latitude: Record<string, unknown>;
  longitude: Record<string, unknown>;
  website: Record<string, unknown>;
  price: Record<string, unknown>;
  bar: Record<string, unknown>;
  restaurant: Record<string, unknown>;
}

export const venueFormSchema: VenueFormSchema = {
  name: {
    ...requiredTextValidationRulesDefault,
    length: {
      maximum: 128,
    },
  },
  description: {
    length: {
      maximum: 2500,
    },
  },
  address: {
    ...requiredTextValidationRulesDefault,
    length: {
      maximum: 128,
    },
  },
  city: { ...requiredTextValidationRulesDefault },
  country: { ...requiredTextValidationRulesDefault },
  state: { ...requiredTextValidationRulesDefault },
  latitude: {
    ...requiredNumberValidationRulesDefault,
  },
  longitude: {
    ...requiredNumberValidationRulesDefault,
  },
  price: {
    ...requiredNumberValidationRulesDefault,
  },
  bar: {},
  restaurant: {},
  website: {},
};
