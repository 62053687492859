import { Typography } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import React, { FC, useCallback, useContext, useEffect } from "react";

import { AlertContext } from "../../../../../common/components/Alert/AlertContextProvider";
import Dropzone from "../../../../../common/components/forms/Dropzone";
import LoadingIndicator from "../../../../../common/components/LoadingIndicator";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { ClaimFlowSteps } from "../form";
import { useStyles } from "../styles";
import Props from "./Props";

const AttachmentStep: FC<Props> = (props: Props) => {
  const { loading, attachment, setAttachment, currentStep } = props;
  const { showAlert } = useContext(AlertContext);

  const classes = useStyles();

  const onDrop = useCallback(
    (files: any) => {
      setAttachment(files);
    },
    [setAttachment]
  );

  useEffect(() => {
    if (attachment.length) {
      showAlert(
        dictionary.venues.claimVenueDialog.dialogAttachmentSuccessMessage,
        "success"
      );
    }
  }, [attachment, showAlert]);

  return (
    <>
      <DialogContent dividers className={classes.root}>
        <form>
          <Typography className={classes.confirmOwnershipLabel} variant={"h5"}>
            {(currentStep === ClaimFlowSteps.ATTACHMENT_FILE_NO_CODE &&
              dictionary.venues.claimVenueDialog.confirmOwnershipLabel) ||
              (currentStep === ClaimFlowSteps.ATTACHMENT_FILE_NO_MATCH &&
                dictionary.venues.claimVenueDialog.dialogAttachmentNoMatch)}
          </Typography>
          <Typography className={classes.attachmentCriteriaText}>
            {dictionary.venues.claimVenueDialog.dialogTitleAttachmentInfo}
          </Typography>
          <ul className={classes.attachmentListCriteria}>
            <li>
              <Typography className={classes.attachmentCriteriaText}>
                {
                  dictionary.venues.claimVenueDialog
                    .dialogTitleAttachmentInfoFirst
                }
              </Typography>
            </li>
            <li>
              <Typography className={classes.attachmentCriteriaText}>
                {
                  dictionary.venues.claimVenueDialog
                    .dialogTitleAttachmentInfoSecond
                }
              </Typography>
            </li>
            <li>
              <Typography className={classes.attachmentCriteriaText}>
                {
                  dictionary.venues.claimVenueDialog
                    .dialogTitleAttachmentInfoThird
                }
              </Typography>
            </li>
          </ul>

          <Dropzone
            isLock={false}
            classes={{
              root: classes.dropzoneRoot,
            }}
            description={
              dictionary.venues.claimVenueDialog.attachmentDropZoneInfo
            }
            onDrop={onDrop}
            accept=".jpg,.jpeg,.png,.pdf"
          />

          {!!attachment.length && (
            <Typography className={classes.attachmentsList}>
              {dictionary.venues.list.createVenueValidationAttachmentsList(
                attachment.map((attachment: any) => attachment.name)
              )}
            </Typography>
          )}
        </form>
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
    </>
  );
};

export default AttachmentStep;
