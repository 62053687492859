import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames = "root" | "star" | "ratingRow" | "ratingLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(4),
        minHeight: 50,
      },
      star: {
        margin: theme.spacing(0, 3 / 8),
      },
      ratingRow: {
        margin: theme.spacing(1, 0, 0, 0),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      ratingLabel: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
      },
    })
);
