import clsx from "clsx";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const BlockWrapper: FC<Props> = (props: Props) => {
  const { children, isActive } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, isActive && classes.wrapperActive)}>
      {children}
    </div>
  );
};
export default BlockWrapper;
