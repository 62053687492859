import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import OcietyNavLink from "../../../../common/components/OcietyNavLink";
import TextMessage from "../../../../common/components/TextMessage";
// eslint-disable-next-line max-len
import { useSubmitFormOnEnter } from "../../../../common/hooks/useSubmitFormOnEnter";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  login as logIn,
  loginClearError,
  toggleRememberMe,
} from "../../../../store/auth/actions";
import { setAndValidate, validateForm } from "../../../../store/forms/actions";
import { FormFieldKey } from "../../../../store/forms/types";
import { AppState } from "../../../../store/rootReducer";
import { schema } from "./form";
import Props from "./Props";

const LoginForm: FC<Props> = (props: Props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { forms, auth }: AppState = useSelector((state: AppState) => state);
  const form = forms.login;

  useEffect(() => {
    // To clear errors on component mounting
    dispatch(loginClearError());

    return () => {
      // To clear errors on component unmounting
      dispatch(loginClearError());
    };
  }, [dispatch]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;
    dispatch(loginClearError());
    dispatch(setAndValidate("login", key as FormFieldKey, value, schema));
  };

  const handleSignIn = async () => {
    const { email, password } = form.values;

    if (!email || !password) {
      dispatch(validateForm("login", schema));
      return;
    }

    dispatch(
      logIn({
        username: email.trim(),
        password,
        rememberMe: auth.rememberMe,
      })
    );
  };

  useSubmitFormOnEnter(handleSignIn);

  return (
    <>
      {auth.error && (
        <Box className={classes.errorMessageWrapper}>
          <Box className={classes.errorMessageContent}>
            <TextMessage className={classes.errorMessage} type="error">
              {auth.error}
            </TextMessage>
          </Box>
        </Box>
      )}
      <div>
        <TextField
          className={classes.textField}
          required
          error={!!form.errors.email}
          fullWidth
          helperText={form.errors.email}
          label={dictionary.auth.emailFieldLabel}
          name="email"
          onChange={handleChange}
          type="text"
          value={form.values.email || ""}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          required
          error={!!form.errors.password}
          fullWidth
          helperText={form.errors.password}
          label={dictionary.auth.passwordFieldLabel}
          name="password"
          onChange={handleChange}
          type="password"
          value={form.values.password || ""}
          variant="outlined"
        />
      </div>
      <div className={classes.actionsWrapper}>
        <div className={classes.actionsSecondaryContainer}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  checked: classes.checkboxChecked,
                }}
                color="default"
                checked={auth.rememberMe}
                onChange={() => dispatch(toggleRememberMe())}
                name="rememberMe"
              />
            }
            label={
              <Typography className={classes.checkboxLabel}>
                {dictionary.auth.rememberMe}
              </Typography>
            }
          />
          <OcietyNavLink
            className={classes.forgotPasswordLink}
            linkTextClassName={classes.forgotPasswordLinkText}
            to={Route.ForgotPassword}>
            {dictionary.auth.forgotPasswordLink}
          </OcietyNavLink>
        </div>
        <ButtonPrimary
          className={classes.signInButton}
          disabled={!form.isValid || !!auth.error}
          fullWidth
          onClick={handleSignIn}>
          {dictionary.auth.signInButtonText}
        </ButtonPrimary>
        <div className={classes.registerActionWrapper}>
          <Typography className={classes.registerLabel}>
            {dictionary.auth.registerQuestion}
          </Typography>
          <OcietyNavLink
            to={Route.Venues}
            className={classes.registerLink}
            linkTextClassName={classes.registerLinkText}>
            {dictionary.auth.registerLink}
          </OcietyNavLink>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
