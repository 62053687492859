export const GET_CLAIM_REQUEST = (requestId: number) =>
  `venue/venue-claim-pending/${requestId}`;

export interface VenueClaimRequestData {
  comment?: string;
  company: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  phoneNumber: string;
  title: string;
  venueName: string;
  venueId: number;
  isWaitlistAdmin?: boolean;
  documentPath?: any;
}
