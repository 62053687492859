import React, { FC, useContext, useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartContainer from "../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../common/components/ChartTooltip";
import { VenueReportingContext } from "../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../common/hooks/useRequestByPeriod";
import dictionary from "../../../../i18n/en_US/dictionary";
import { chartAdditionalColor, colors } from "../../../../theme/colors";
import {
  LineDefaultProps,
  XAxisDefaultProps,
  YAxisDefaultProps,
} from "../../../Reporting/defaultProps";
import {
  GET_VENUE_REVIEWS_TREND,
  GetVenueReviewsTrendResponse,
  VenueReviewsTrend,
} from "./api";
import { chartDataMock } from "./mock";

const ReviewsTrend: FC = () => {
  const [chartData, setChartData] = useState(chartDataMock);
  const { filters } = useContext(VenueReportingContext);
  const { response, loading } =
    useRequestByPeriod<GetVenueReviewsTrendResponse>(
      GET_VENUE_REVIEWS_TREND,
      filters
    );

  const prepareChartData = (stats: VenueReviewsTrend[]) => {
    setChartData(
      stats.map((reviewData) => ({
        month: reviewData.month.slice(0, 3),
        userReviewsQty: reviewData.userReviewsQty,
        criticReviewsQty: reviewData.criticReviewsQty,
      }))
    );
  };

  useEffect(() => {
    if (response) {
      prepareChartData(response.stats);
    }
  }, [response]);

  const isChartEmpty = !chartData.some(
    (data) => (data.criticReviewsQty || data.userReviewsQty) !== 0
  );

  return (
    <ChartContainer
      chartType={"line-with-y-axis"}
      loading={loading}
      isChartEmpty={isChartEmpty}
      title={dictionary.reviews.reviewsTrendChartTitle}>
      <LineChart data={chartData}>
        <CartesianGrid
          strokeDasharray="3 3"
          strokeOpacity={0.6}
          color={colors.grey.light}
          vertical={false}
        />
        <Line
          {...LineDefaultProps}
          dataKey={"userReviewsQty"}
          unit={dictionary.reviews.usersReviewsTrendUnit}
          stroke={chartAdditionalColor}
        />
        <Line
          {...LineDefaultProps}
          dataKey={"criticReviewsQty"}
          unit={dictionary.reviews.criticsReviewsTrendUnit}
        />
        <XAxis {...XAxisDefaultProps} dataKey="month" />
        <YAxis {...YAxisDefaultProps} />
        <Tooltip content={<ChartTooltip />} />
      </LineChart>
    </ChartContainer>
  );
};

export default ReviewsTrend;
