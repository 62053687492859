import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames = "root" | "actionsRow" | "messageIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {},
    actionsRow: {
      display: "flex",
      alignItems: "center",
    },
    messageIcon: {
      marginRight: 10,
    },
  })
);
