import { Form } from "../../../../common/models";
import { ValidationRules } from "../../../../utils/validation";

export type ChangePasswordFormField = "currentPass" | "newPass" | "confirmPass";
export type ChangePasswordFormValues = {
  [key in ChangePasswordFormField]: string;
};
export type ChangePasswordFormErrors = {
  [key in ChangePasswordFormField]: string;
};

export interface ChangePasswordForm extends Form {
  values: ChangePasswordFormValues;
  errors: ChangePasswordFormErrors;
  isValid: boolean;
  touched: ChangePasswordFormField[];
}

export type ChangePasswordFormSchema = Record<
  ChangePasswordFormField,
  ValidationRules
>;

export const changePasswordForm: ChangePasswordForm = {
  values: {
    currentPass: "",
    newPass: "",
    confirmPass: "",
  },
  errors: {
    currentPass: "",
    newPass: "",
    confirmPass: "",
  },
  touched: [],
  isValid: true,
};

export const schema: ChangePasswordFormSchema = {
  currentPass: {
    required: true,
  },
  newPass: {
    required: true,
    type: "password",
    length: {
      min: 8,
    },
  },
  confirmPass: {
    required: true,
    equal: "newPass",
  },
};
