import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames = "root" | "title" | "chart" | "wrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      chart: {
        height: "100% !important",
        width: "100% !important",
        marginLeft: -15,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      root: {
        height: "100%",
        position: "relative",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(5, 2),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(3, 1),
        },
      },
      title: {
        margin: theme.spacing(0, 0, 0, 5),
      },
      wrapper: {
        height: "100%",
      },
    })
);
