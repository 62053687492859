import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import VenueReportingContextProvider from "../../common/components/VenueReportingContextProvider";
import PageTitleWithToolbar from "./components/PageTitleWithToolbar";
import RecentReviews from "./components/RecentReviews";
import ReviewsScoring from "./components/ReviewsScoring";
import ReviewsTrend from "./components/ReviewsTrend";

const Reviews: FC = () => {
  return (
    <VenueReportingContextProvider>
      <div>
        <PageTitleWithToolbar />
        <div>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <ReviewsTrend />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <ReviewsScoring />
            </Grid>
            {/*<Grid item lg={6} md={6} sm={12} xs={12}>
              <ReviewsImprovements />                         // disabled business improvements
            </Grid>*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <RecentReviews />
            </Grid>
          </Grid>
        </div>
      </div>
    </VenueReportingContextProvider>
  );
};

export default Reviews;
