import { DialogContent } from "@material-ui/core";
import moment from "moment";
import React, { FC, useContext, useEffect } from "react";

import { AgeGroup } from "../../../../../../../common/components/AgeGroupSelect/api";
import LoadingIndicator from "../../../../../../../common/components/LoadingIndicator";
import StepsIndicator from "../../../../../../../common/components/StepsIndicator";
import { usePrevious } from "../../../../../../../common/hooks";
import { useIsDiscountAdmin } from "../../../../../../../common/hooks/useIsDiscountAdmin";
import { useRestApi } from "../../../../../../../common/hooks/useRestApi";
import { FileUrlWithId } from "../../../../../../../common/models";
import { GET_MANAGER_PROMOTION } from "../../../../../../ManagerPromotions/Promotions/api";
import { PromotionLimitType, PromotionType } from "../../../../enums";
import { IPromotion } from "../../models/IPromotion";
import { PromotionFormDialogContext } from "../../PromotionFormDialogContextProvider";
import AdvancedInfoPromotionStep from "./components/AdvancedInfoPromotionStep";
import BasicInfoPromotionStep from "./components/BasicInfoPromotionStep";
import MainInfoPromotionStep from "./components/MainInfoPromotionStep";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionFormDialogContent: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    promotionId: propsPromotionId,
    setDietaryRestrictions,
    dietaryRestrictions,
    ageGroupId,
    setAgeGroupId,
  } = props;
  const {
    currentStep,
    basicForm,
    mainForm,
    advancedForm,
    promotionId,
    setPromotionId,
    loading,
    setLoading,
    setDaysHours,
    resetForm,
  } = useContext(PromotionFormDialogContext);
  const prevPromotionId: number | null = usePrevious(promotionId);
  const discountAdmin = useIsDiscountAdmin();
  const [, getPromotionData] = useRestApi<IPromotion>(
    "promotions/" + (propsPromotionId ? propsPromotionId : 0),
    "GET"
  );
  const [, getManagerPromotionData] = useRestApi<IPromotion>(
    GET_MANAGER_PROMOTION(propsPromotionId ? propsPromotionId : 0),
    "GET"
  );

  const { setFormValues: basicSetFormValues } = basicForm;
  const { setFormValues: mainSetFormValues, setFormValue: mainSetFormValue } =
    mainForm;
  const { setFormValues: advancedFormSetFormValues } = advancedForm;

  useEffect(() => {
    if (propsPromotionId) {
      setPromotionId(propsPromotionId);
    }
  }, [
    resetForm,
    prevPromotionId,
    setPromotionId,
    promotionId,
    propsPromotionId,
  ]);

  useEffect(() => {
    if (propsPromotionId) {
      setLoading(true);
      const getPromotion = discountAdmin
        ? getManagerPromotionData()
        : getPromotionData();
      getPromotion
        .then((promotion: IPromotion | undefined) => {
          if (promotion) {
            mainSetFormValues({
              type: (promotion.type as PromotionType) || PromotionType.FOOD,
              categoryId: promotion.categoryId
                ? String(promotion.categoryId)
                : "",
              subCategoryId: promotion.subCategoryId
                ? String(promotion.subCategoryId)
                : "",
              start: promotion.start
                ? moment(promotion.start).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
              end: promotion.end
                ? moment(promotion.end).format("YYYY-MM-DD")
                : moment().add(1, "days").format("YYYY-MM-DD"),
              description: promotion.description || "",
              limitType: promotion.itemsLimit || PromotionLimitType.SINGLE,
              limitPercent: promotion.percent ? String(promotion.percent) : "0",
              limitMaxAmount: promotion.maxAmount
                ? String(promotion.maxAmount)
                : "0",
              limitPrice: promotion.sellingPrice
                ? String(promotion.sellingPrice)
                : "0",
              limitItemsName: promotion.itemsName
                ? String(promotion.itemsName)
                : "",
              dietaryRestrictions: promotion.dietaryRestrictions
                ? promotion.dietaryRestrictions.map((item) => item.id).join()
                : "",
            });
            setDietaryRestrictions(promotion.dietaryRestrictions);
            advancedFormSetFormValues({
              quantity: promotion.discountQuantity
                ? String(promotion.discountQuantity)
                : "0",
              notifyHowManyLeft: promotion.notifyHowManyLeft ? "1" : "0",
              notifyHowManyLeftCount: promotion.notifyHowManyLeftCount
                ? String(promotion.notifyHowManyLeftCount)
                : "0",
              maximumUsagesPerCustomer: promotion.maximumUsagesPerCustomer
                ? String(promotion.maximumUsagesPerCustomer)
                : "0",
              restrictMinimumAge: promotion.restrictMinimumAge
                ? String(promotion.restrictMinimumAge)
                : "0",
              promotionCode: promotion.promoCode
                ? String(promotion.promoCode)
                : "",
              isQuantityUnlimited: promotion.isQuantityUnlimited ? "1" : "0",
              ageGroupId: promotion.ageGroup
                ? promotion.ageGroup.map((item: AgeGroup) => item.id).join()
                : "",
            });
            setAgeGroupId(
              promotion.ageGroup
                ? promotion.ageGroup.map((item: AgeGroup) => item.id)
                : []
            );
            setDaysHours(promotion.daysHours);
            basicSetFormValues({
              id: String(promotion.id) || "",
              promotionPhotos: JSON.stringify(
                promotion.photos.map(
                  ({ id, fileUrl, isMainPhoto }): FileUrlWithId => ({
                    id,
                    url: fileUrl,
                    isMainPhoto,
                  })
                )
              ),
              promotionVideos: JSON.stringify(
                promotion.videos.map(
                  ({ id, fileUrl }): FileUrlWithId => ({ id, url: fileUrl })
                )
              ),
              mainPhotoId: promotion.mainPhoto
                ? JSON.stringify(promotion.mainPhoto.id)
                : "",
            });
          }
        })
        .catch((e) => {
          console.log("e -->>> ", e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    discountAdmin,
    mainSetFormValue,
    setDaysHours,
    setAgeGroupId,
    propsPromotionId,
    setLoading,
    getPromotionData,
    getManagerPromotionData,
    basicSetFormValues,
    mainSetFormValues,
    advancedFormSetFormValues,
    setDietaryRestrictions,
  ]);

  return (
    <DialogContent className={classes.dialogContent}>
      {loading && <LoadingIndicator withMask />}
      <StepsIndicator currentStep={currentStep} stepsNames={["", "", ""]} />
      {currentStep === 1 ? (
        <MainInfoPromotionStep
          dietaryRestrictions={dietaryRestrictions}
          setDietaryRestrictions={setDietaryRestrictions}
        />
      ) : currentStep === 2 ? (
        <AdvancedInfoPromotionStep
          ageGroupId={ageGroupId}
          setAgeGroupId={setAgeGroupId}
        />
      ) : (
        <BasicInfoPromotionStep />
      )}
    </DialogContent>
  );
};
export default PromotionFormDialogContent;
