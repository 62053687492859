import io, { Socket } from "socket.io-client";

import { Message } from "../views/Messages/models";
import { MessagingEvents } from "./events";

type EventCallback = (...args: any[]) => void;

class WebsocketClient {
  private client: Socket | null = null;
  private pendingListeners: Map<MessagingEvents, EventCallback> = new Map();

  public get isConnected() {
    return Boolean(this.client);
  }

  public subscribeForChatMessages = (
    onGetNewMessage: (newChatMessage: Message) => void
  ) => {
    if (this.client) {
      this.client
        .off(MessagingEvents.NEW_MESSAGE_FROM_USER)
        .on(MessagingEvents.NEW_MESSAGE_FROM_USER, onGetNewMessage);
    } else {
      this.pendingListeners.set(
        MessagingEvents.NEW_MESSAGE_FROM_USER,
        onGetNewMessage
      );
    }
  };

  public unsubscribeFromChatMessages = () => {
    this.client?.off(MessagingEvents.NEW_MESSAGE_FROM_USER);
  };

  public connect = (venueId: number) => {
    this.client = io(process.env.REACT_APP_WEBSOCKET_URL as string, {
      auth: {
        venueId,
      },
      withCredentials: true,
      transports: ["polling", "websocket"],
    });

    if (this.client && this.pendingListeners.size) {
      this.pendingListeners.forEach(
        (eventCallback: EventCallback, eventName: MessagingEvents) => {
          this.client?.off(eventName).on(eventName, eventCallback);
          this.pendingListeners.delete(eventName);
        }
      );
    }
  };

  public disconnect = () => {
    if (this.client) {
      this.client.offAny();
      this.client.disconnect();
      this.client = null;
    }

    this.pendingListeners = new Map();
  };
}

const client = new WebsocketClient();

export default client;
