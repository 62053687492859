import {
  CLEAR_FORM,
  CLEAR_FORM_ERROR,
  CLEAR_FORM_ERRORS,
  DELETE_FORM_ERROR,
  // DELETE_FORM_VALUE,
  FormActionTypes,
  // FormErrors,
  FormFieldKey,
  FormsState,
  SET_FORM,
  // FormTypes,
  // REMOVE_TOUCHED_FIELD,
  SET_FORM_ERROR,
  SET_FORM_ERRORS,
  SET_FORM_VALID,
  SET_FORM_VALUE,
  // SET_TOUCHED_FIELD,
} from "./types";

export type FormValues = {
  [key in FormFieldKey]: any;
};

const defaultFormState = {
  errors: {},
  isValid: true,
  touched: [],
  values: {},
};

const defaultState: FormsState = {
  login: { ...defaultFormState },
  mfa: { ...defaultFormState },
  claimVenue: { ...defaultFormState },
  claimVenueVerify: { ...defaultFormState },
  forgotPassword: { ...defaultFormState },
  changePassword: { ...defaultFormState },
  assignVenue: { ...defaultFormState },
  coreSettings: { ...defaultFormState },
  venue: { ...defaultFormState },
  venueAdminProfile: { ...defaultFormState },
  profile: { ...defaultFormState },
  workingDays: { ...defaultFormState },
};

export default (
  state: FormsState = defaultState,
  action: FormActionTypes
): FormsState => {
  const formKey = action.formKey;

  switch (action.type) {
    case CLEAR_FORM:
      return {
        ...state,
        [formKey]: defaultState[formKey],
      };
    case CLEAR_FORM_ERROR:
      const newState = {
        ...state,
      };

      if (action.value) {
        // @ts-ignore
        newState[formKey].values[action.formFieldKey] = action.value;
      }

      // @ts-ignore
      delete newState[formKey].errors[action.formFieldKey];
      newState[formKey].isValid = !!Object.keys(newState[formKey].errors)
        .length;

      return newState;
    case CLEAR_FORM_ERRORS:
      return {
        ...state,
        [formKey]: {
          ...state[formKey],
          errors: defaultState[formKey].errors,
        },
      };
    case DELETE_FORM_ERROR:
      // So bad line... TODO: change it =)
      // @ts-ignore
      // eslint-disable-next-line max-len
      // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
      const { [action.formFieldKey]: omittedError, ...newErrors } =
        state[formKey].errors;

      return {
        ...state,
        [formKey]: {
          ...state[formKey],
          errors: newErrors,
        },
      };
    case SET_FORM_ERROR:
      return {
        ...state,
        [formKey]: {
          ...state[formKey],
          errors: {
            ...state[formKey].errors,
            [action.formFieldKey]: action.error,
          },
          isValid: false,
        },
      };
    case SET_FORM_ERRORS:
      return {
        ...state,
        [formKey]: {
          ...state[formKey],
          errors: action.errors,
          isValid: false,
        },
      };
    case SET_FORM_VALID:
      return {
        ...state,
        [formKey]: {
          ...state[formKey],
          isValid: !Object.entries(state[formKey].errors).length,
        },
      };
    case SET_FORM_VALUE:
      return {
        ...state,
        [formKey]: {
          ...state[formKey],
          values: {
            ...state[formKey].values,
            [action.formFieldKey]: action.value,
          },
        },
      };
    case SET_FORM:
      return {
        ...state,
        [formKey]: action.form,
      };
    /*
    case DELETE_FORM_VALUE:
      const { [payload]: omittedValue, ...newValues } = state.values;

      return {
        ...state,
        values: newValues,
      };

    case REMOVE_TOUCHED_FIELD:
      return {
        ...state,
        touched: state.touched.filter((field) => field !== payload),
      };

    case SET_TOUCHED_FIELD:
      const exist = state.touched.includes(payload);

      if (exist) {
        return state;
      }

      return {
        ...state,
        touched: [...state.touched, payload],
      };*/

    default:
      return state;
  }
};
