import { CheckboxClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

import { colors } from "../colors";

const MuiCheckbox: Partial<Record<CheckboxClassKey, CSSProperties>> = {
  root: {
    "&$checked": {
      color: colors.green.primary,
    },
  },
};

export default MuiCheckbox;
