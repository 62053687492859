import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../common/models";
import { colors } from "../../../../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "formControlWithTooltip"
  | "formControlAge"
  | "formControlAgeLabel"
  | "sectionTitleMargin1"
  | "sectionTitleMargin2"
  | "marginTopField"
  | "infoIcon"
  | "uncheckedIcon"
  | "checkedIcon"
  | "radioWrapper"
  | "radioLabel"
  | "notificationLabelWrapper"
  | "notificationLabel"
  | "ageGroupLabels";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: "auto",
        margin: theme.spacing(1, 0),
      },
      formControlWithTooltip: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      formControlAge: {
        display: "flex",
        flexDirection: "row",
      },
      formControlAgeLabel: {
        marginRight: 70,
      },
      sectionTitleMargin1: {
        marginBottom: 10,
      },
      sectionTitleMargin2: {
        marginBottom: 20,
      },
      marginTopField: {
        marginTop: 22,
      },
      infoIcon: {
        marginLeft: 8,
      },
      uncheckedIcon: {
        width: 20,
        height: 20,
        borderRadius: "50%",
        border: `1px solid ${colors.grey.disabled}`,
      },
      checkedIcon: {
        width: 20,
        height: 20,
        borderRadius: "50%",
        boxShadow: "0px 1px 3px rgba(254, 84, 84, 0.3)",
        border: `5px solid ${colors.red.primary}`,
      },
      radioWrapper: {
        margin: 0,
      },
      radioLabel: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
      notificationLabelWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      notificationLabel: {
        marginRight: 16,
      },
      ageGroupLabels: {
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 600,
      },
    })
);
