import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "iconWrapper"
  | "stepWrapper"
  | "stepWrapperActive"
  | "stepNumber"
  | "stepName"
  | "textWrapper";

const inactiveColor = colors.grey.medium;
const activeColor = colors.red.primary;

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
      },
      iconWrapper: {
        display: "inline-block",
        float: "left",
        padding: 10,
        marginTop: 10,
      },
      stepWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
        borderTop: `5px solid ${inactiveColor}`,
        position: "relative",
        flex: "1",
        "&:not(:last-of-type):after": {
          content: "'●'",
          color: inactiveColor,
          position: "absolute",
          height: "100%",
          top: -12,
          left: "calc(100% + 5px)",
        },
      },
      textWrapper: {
        display: "flex",
        flexDirection: "column",
      },
      stepWrapperActive: {
        borderTopColor: activeColor,
        "&:not(:last-of-type):after": {
          color: activeColor,
        },
      },
      stepNumber: {
        marginTop: 10,
        fontWeight: 600,
      },
      stepName: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        color: colors.grey.primary,
      },
    })
);
