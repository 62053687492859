import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
} from "react";

import {
  AgeGroup,
  GET_AGE_GROUP_OPTIONS,
} from "../../../../../../../../../common/components/AgeGroupSelect/api";
import Option from "../../../../../../../../../common/components/MultipleSelect/Option";
import OcietyBlockWrapperForRadioButton from "../../../../../../../../../common/components/OcietyBlockWrapperForRadioButton";
import OcietySwitch from "../../../../../../../../../common/components/OcietySwitch";
import OcietyTooltip from "../../../../../../../../../common/components/OcietyTooltip";
import { useRestApi } from "../../../../../../../../../common/hooks/useRestApi";
import { ReactComponent as InfoIcon } from "../../../../../../../../../common/svg/icons/info.svg";
import dictionary from "../../../../../../../../../i18n/en_US/dictionary";
import { colors } from "../../../../../../../../../theme/colors";
import { PromotionAdvancedFormField } from "../../../../forms/advanced";
import { PromotionFormDialogContext } from "../../../../PromotionFormDialogContextProvider";
import Props from "./Props";
import { useStyles } from "./styles";

const AdvancedInfoPromotionStep: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { ageGroupId, setAgeGroupId } = props;
  const { advancedForm, mainForm } = useContext(PromotionFormDialogContext);
  const { form, setAndValidate, setSchemaRules, setFormValue } = advancedForm;
  const [{ data: ageGroupsData }, getAgeGroups] = useRestApi<Array<AgeGroup>>(
    GET_AGE_GROUP_OPTIONS,
    "GET"
  );

  const ageGroupOptions = ageGroupsData
    ? ageGroupsData
        .filter((option) =>
          mainForm.form.values.categoryId === "29"
            ? option.name !== "18-20"
            : option
        )
        .map(
          (ageGroup: AgeGroup) => new Option(String(ageGroup.id), ageGroup.name)
        )
    : [];

  useEffect(() => {
    getAgeGroups().catch((e) => console.error(e));
  }, [getAgeGroups]);

  useEffect(() => {
    if (form.values.isQuantityUnlimited === "1") {
      setSchemaRules({ quantity: {} });
      if (form.values.quantity) {
        setAndValidate("quantity", "1"); // set default value
      }
    } else {
      setSchemaRules({
        quantity: {
          type: "number+",
          required: true,
        },
      });
    }
  }, [
    setSchemaRules,
    setAndValidate,
    form.values.isQuantityUnlimited,
    form.values.quantity,
  ]);

  const isQuantityUnlimited = () => {
    return form.values.isQuantityUnlimited === "1";
  };
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const fieldName = event.target.name as PromotionAdvancedFormField;
    let fieldValue = event.target.value;

    if (
      [
        "notifyHowManyLeftCount",
        "quantity",
        "maximumUsagesPerCustomer",
      ].includes(fieldName)
    ) {
      fieldValue = fieldValue.replace(/\D/g, "");
    }
    setAndValidate(fieldName, fieldValue);
  };

  const isDisableNotifyLeft = () => {
    return form.values.notifyHowManyLeft === "0";
  };
  const handleSwitchChange = (changed: boolean) => {
    setAndValidate("notifyHowManyLeft", changed ? "1" : "0");
  };

  const handleChangeLimit = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAndValidate(
        "isQuantityUnlimited",
        (event.target as HTMLInputElement).value || "0"
      );
    },
    [setAndValidate]
  );

  const clearValueFieldOnClick = (e: any) => {
    if (e.target.value === "0") e.target.value = "";
  };

  const handleChangeAgeGroup = (event: any) => {
    const checkedAgeGroup = Number(event.target.value);
    let ageGroups;
    if (ageGroupId.includes(checkedAgeGroup)) {
      ageGroups = ageGroupId.filter((age) => age !== checkedAgeGroup);
      setAgeGroupId(ageGroups);
    } else {
      ageGroups = [...ageGroupId, checkedAgeGroup];
      setAgeGroupId(ageGroups);
    }

    setFormValue("ageGroupId", ageGroups?.map((item) => item).join());
  };

  const handleChangeAllAgeGroups = () => {
    if (ageGroupId.length) {
      setAgeGroupId([]);
      setFormValue("ageGroupId", "");
    } else {
      setAgeGroupId(ageGroupOptions?.map((item) => Number(item.value)));
      setFormValue(
        "ageGroupId",
        ageGroupOptions?.map((item) => item.value).join()
      );
    }
  };

  return (
    <>
      <Grid container spacing={4} className={classes.root}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h3">
            {dictionary.promotions.quantityLimitationTitle}
          </Typography>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={form.values.isQuantityUnlimited}
            onChange={handleChangeLimit}
            className={classes.marginTopField}
            defaultValue={"0"}>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <OcietyBlockWrapperForRadioButton
                  isActive={!isQuantityUnlimited()}
                  label={dictionary.promotions.createPromotionForm.limitedLabel}
                  value={"0"}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <OcietyBlockWrapperForRadioButton
                  isActive={isQuantityUnlimited()}
                  label={
                    dictionary.promotions.createPromotionForm.unlimitedLabel
                  }
                  value={"1"}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography
            variant="h3"
            style={{
              color: isQuantityUnlimited()
                ? colors.grey.secondary
                : colors.grey.primary,
            }}>
            {dictionary.promotions.maximumUsagesAcrossAllTitle}
          </Typography>
          <FormControl fullWidth>
            <TextField
              id="filled-quantity"
              disabled={isQuantityUnlimited()}
              label={dictionary.promotions.typeQuantityLabel}
              variant="outlined"
              name={"quantity"}
              className={classes.marginTopField}
              onChange={handleChange}
              onClick={clearValueFieldOnClick}
              helperText={form.errors.quantity}
              error={!!form.errors.quantity}
              value={form.values.quantity}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography variant="h3">
            {dictionary.promotions.maximumUsagesPerCustomerTitle}
          </Typography>
          <FormControl fullWidth>
            <TextField
              id="filled-maximumUsagesPerCustomer"
              variant="outlined"
              className={classes.marginTopField}
              label={dictionary.promotions.typeQuantityLabel}
              name={"maximumUsagesPerCustomer"}
              onChange={handleChange}
              onClick={clearValueFieldOnClick}
              helperText={form.errors.maximumUsagesPerCustomer}
              error={!!form.errors.maximumUsagesPerCustomer}
              value={form.values.maximumUsagesPerCustomer}
            />
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={classes.notificationLabelWrapper}>
            <Typography
              variant="h3"
              className={classes.notificationLabel}
              style={{
                color: isQuantityUnlimited()
                  ? colors.grey.secondary
                  : colors.grey.primary,
              }}>
              {dictionary.promotions.notificationTitle}
            </Typography>
            <OcietySwitch
              disabled={isQuantityUnlimited()}
              value={form.values.notifyHowManyLeft === "1"}
              checked={form.values.notifyHowManyLeft === "1"}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => {
                handleSwitchChange(checked);
              }}
            />
          </div>

          <FormControl fullWidth style={{ paddingTop: 9 }}>
            <TextField
              disabled={isQuantityUnlimited() || isDisableNotifyLeft()}
              id="filled-number"
              name="notifyHowManyLeftCount"
              variant="outlined"
              label={dictionary.promotions.typeQuantityLabel}
              onChange={handleChange}
              onClick={clearValueFieldOnClick}
              className={classes.marginTopField}
              helperText={form.errors.notifyHowManyLeftCount}
              error={!!form.errors.notifyHowManyLeftCount}
              value={form.values.notifyHowManyLeftCount}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </FormControl>
        </Grid>
        <Grid item lg={10} md={10} sm={12} xs={12}>
          <div className={classes.notificationLabelWrapper}>
            <Typography variant={"h3"}>
              {dictionary.promotions.createPromotionForm.selectAgeGroup}
            </Typography>
            <OcietyTooltip
              title={
                dictionary.promotions.createPromotionForm.selectAgeGroupInfo
              }
              placement="bottom-start">
              <InfoIcon className={classes.infoIcon} />
            </OcietyTooltip>
          </div>
          <FormControlLabel
            label={dictionary.promotions.createPromotionForm.allAgeGroupLabel}
            classes={{ label: classes.ageGroupLabels }}
            control={
              <Checkbox
                color={"primary"}
                defaultChecked
                checked={ageGroupId.length === ageGroupOptions.length}
                indeterminate={
                  ageGroupId.length < ageGroupOptions.length &&
                  ageGroupId.length !== 0
                }
                onChange={handleChangeAllAgeGroups}
              />
            }
          />
          <FormGroup className={classes.formControlAge}>
            {ageGroupOptions.map((ageGroup) => (
              <FormControlLabel
                key={ageGroup.value}
                onChange={handleChangeAgeGroup}
                control={
                  <Checkbox
                    checked={ageGroupId.includes(Number(ageGroup.value))}
                    color={"primary"}
                  />
                }
                label={ageGroup.label}
                value={ageGroup.value}
                classes={{ label: classes.ageGroupLabels }}
                className={classes.formControlAgeLabel}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default AdvancedInfoPromotionStep;
