import { DialogActions } from "@material-ui/core";
import React, { FC, useContext } from "react";
import { useSelector } from "react-redux";

import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../../../../common/components/ButtonBlack";
import ButtonCancel from "../../../../../../../common/components/ButtonCancel";
import { useIsDiscountAdmin } from "../../../../../../../common/hooks/useIsDiscountAdmin";
import { FileUrlWithId } from "../../../../../../../common/models";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../../../../../../store/currentVenue/reducer";
import { AppState } from "../../../../../../../store/rootReducer";
import {
  createManagerPromotion,
  updateManagerPromotion,
} from "../../../../../../ManagerPromotions/Promotions/api";
import { PromotionLimitType, PromotionType } from "../../../../enums";
import { useStyles } from "../../../../styles";
import { createPromotion, updatePromotion } from "../../api";
import CreatePromotionFormRequest from "../../models/CreatePromotionFormRequest";
import UpdatePromotionFormRequest from "../../models/UpdatePromotionFormRequest";
import { PromotionFormDialogContext } from "../../PromotionFormDialogContextProvider";
import Props from "./Props";

const PromotionFormDialogActions: FC<Props> = (props: Props) => {
  const {
    closeDialog,
    refresh,
    dietaryRestrictions,
    ageGroupId,
    setDietaryRestrictions,
    setAgeGroupId,
  } = props;
  const { showAlert } = useContext(AlertContext);
  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (appState) => appState.currentVenue
  );

  const venueId: number = currentVenue ? currentVenue.id : 0;
  const classes = useStyles();
  const discountAdmin = useIsDiscountAdmin();

  const {
    currentStep,
    isNextDisabled,
    setCurrentStep,
    basicForm,
    mainForm,
    advancedForm,
    promotionId,
    daysHours,
    setPromotionId,
    resetForm,
  } = useContext(PromotionFormDialogContext);

  const getSecondaryButtonLabel = () =>
    currentStep === 1
      ? dictionary.promotions.createPromotionForm.cancelButtonLabel
      : dictionary.promotions.createPromotionForm.backButtonLabel;

  const getPrimaryButtonLabel = () => {
    if (currentStep === 1) {
      return "Next";
    } else if (currentStep === 2) {
      if (promotionId) {
        return "Next";
      } else {
        return "Create & Publish";
      }
    } else return "Finish";
  };

  const closeAndReset = () => {
    setAgeGroupId([]);
    setDietaryRestrictions([]);
    closeDialog();
    resetForm();
  };
  const handlePressSecondaryButton = () => {
    if (currentStep === 1) {
      return closeAndReset();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  // filesWithIdsJSON - it is JSON with array of FileUrlWithId objects
  const getPromotionFilesIds = (filesWithIdsJSON: string) => {
    return filesWithIdsJSON.length
      ? JSON.parse(filesWithIdsJSON).map(
          (fileUrlWithId: FileUrlWithId) => fileUrlWithId.id
        )
      : [];
  };

  const sendUpdateRequest = (): void => {
    const request = new UpdatePromotionFormRequest();
    const advancedFormValues = advancedForm.form.values;
    request.id = promotionId;
    request.type = mainForm.form.values.type as PromotionType;
    request.description = mainForm.form.values.description;
    request.start = mainForm.form.values.start;
    request.end = mainForm.form.values.end;
    request.videosIds = getPromotionFilesIds(
      basicForm.form.values.promotionVideos
    );
    request.photosIds = getPromotionFilesIds(
      basicForm.form.values.promotionPhotos
    );
    request.categoryId = mainForm.form.values.categoryId;
    request.subCategoryId = mainForm.form.values.subCategoryId;
    request.daysHours = daysHours;
    request.limitType = mainForm.form.values.limitType as PromotionLimitType;
    request.limitMaxAmount = Number(mainForm.form.values.limitMaxAmount);
    request.limitPercent = Number(mainForm.form.values.limitPercent);
    request.limitPrice = Number(mainForm.form.values.limitPrice);
    request.limitItemsName = mainForm.form.values.limitItemsName;
    request.dietaryRestrictions = mainForm.form.values.dietaryRestrictions;
    request.quantity = Number(advancedFormValues.quantity);
    request.notifyHowManyLeft = advancedFormValues.notifyHowManyLeft === "1";
    request.notifyHowManyLeftCount = Number(
      advancedFormValues.notifyHowManyLeftCount
    );
    request.maximumUsagesPerCustomer = Number(
      advancedFormValues.maximumUsagesPerCustomer
    );
    request.restrictMinimumAge = advancedFormValues.restrictMinimumAge;
    request.promotionCode = advancedFormValues.promotionCode;
    request.isQuantityUnlimited =
      advancedFormValues.isQuantityUnlimited === "1";
    request.ageGroupId = advancedFormValues.ageGroupId;
    request.mainPhotoId = Number(basicForm.form.values.mainPhotoId);

    const update = discountAdmin
      ? updateManagerPromotion(
          request,
          promotionId,
          venueId,
          dietaryRestrictions,
          ageGroupId
        )
      : updatePromotion(
          request,
          promotionId,
          venueId,
          dietaryRestrictions,
          ageGroupId
        );

    update
      .then(() => {
        showAlert(
          dictionary.promotions.createPromotionForm.msgSuccessUpdated,
          "success"
        );

        closeAndReset();
        if (refresh) {
          refresh();
        }
      })
      .catch((error) => {
        if (error.data === "Insult word") {
          showAlert(dictionary.notifications.insultingMessageError, "error");
        } else
          showAlert(
            dictionary.promotions.createPromotionForm.msgErrorUpdated,
            "error"
          );
      });
  };

  const sendRequest = () => {
    const request = new CreatePromotionFormRequest();
    const advancedFormValues = advancedForm.form.values;
    request.description = mainForm.form.values.description;
    request.start = mainForm.form.values.start;
    request.end = mainForm.form.values.end;
    request.type = mainForm.form.values.type as PromotionType;
    request.photosIds = getPromotionFilesIds(
      basicForm.form.values.promotionPhotos
    );
    request.videosIds = getPromotionFilesIds(
      basicForm.form.values.promotionVideos
    );
    request.promotionCategoryId = mainForm.form.values.categoryId;
    request.promotionSubCategoryId = mainForm.form.values.subCategoryId;
    request.daysHours = daysHours;
    request.limitType = mainForm.form.values.limitType as PromotionLimitType;
    request.limitMaxAmount = Number(mainForm.form.values.limitMaxAmount);
    request.limitPercent = Number(mainForm.form.values.limitPercent);
    request.limitPrice = Number(mainForm.form.values.limitPrice);
    request.limitItemsName = mainForm.form.values.limitItemsName;
    request.dietaryRestrictions = mainForm.form.values.dietaryRestrictions;
    request.quantity = Number(advancedFormValues.quantity);
    request.notifyHowManyLeft = advancedFormValues.notifyHowManyLeft === "1";
    request.notifyHowManyLeftCount = Number(
      advancedFormValues.notifyHowManyLeftCount
    );
    request.maximumUsagesPerCustomer = Number(
      advancedFormValues.maximumUsagesPerCustomer
    );
    request.ageGroupId = advancedFormValues.ageGroupId;
    request.promotionCode = advancedFormValues.promotionCode;
    request.isQuantityUnlimited =
      advancedFormValues.isQuantityUnlimited === "1";
    request.restrictMinimumAge = advancedFormValues.restrictMinimumAge;
    request.mainPhotoId = Number(basicForm.form.values.mainPhotoId);

    const create = discountAdmin
      ? createManagerPromotion(
          request,
          venueId,
          dietaryRestrictions,
          ageGroupId
        )
      : createPromotion(request, venueId, dietaryRestrictions, ageGroupId);
    create
      .then((response) => {
        showAlert(
          dictionary.promotions.createPromotionForm.msgSuccessCreated,
          "success"
        );
        setPromotionId(response.data);
      })
      .catch((e) => {
        if (e.data === "Insult word") {
          showAlert(dictionary.notifications.insultingMessageError, "error");
        } else
          showAlert(
            dictionary.promotions.createPromotionForm.msgErrorCreation,
            "error"
          );
      });
  };

  const handlePressPrimaryButton = () => {
    if (currentStep === 2 && advancedForm.validateForm()) {
      setCurrentStep(currentStep + 1);
      if (promotionId) {
        setCurrentStep(currentStep + 1);
      } else {
        sendRequest();
      }
    }

    if (currentStep === 3 && basicForm.validateForm()) {
      sendUpdateRequest();
    }

    if (currentStep === 1 && mainForm.validateForm()) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <DialogActions
      classes={{
        root: classes.dialogActions,
      }}>
      <ButtonCancel onClick={handlePressSecondaryButton}>
        {getSecondaryButtonLabel()}
      </ButtonCancel>
      <ButtonBlack onClick={handlePressPrimaryButton} disabled={isNextDisabled}>
        {getPrimaryButtonLabel()}
      </ButtonBlack>
    </DialogActions>
  );
};

export default PromotionFormDialogActions;
