import clsx from "clsx";
import React, { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";

import ButtonBlack from "../../common/components/ButtonBlack";
import ButtonPrimary from "../../common/components/ButtonPrimary";
import Footer from "../../common/components/Footer";
import Logo from "../../common/components/Logo";
import OcietyNavLink from "../../common/components/OcietyNavLink";
import { useGlobalStyles } from "../../common/styles";
import { getRouteWithSlash, Route } from "../../config/router";
import dictionary from "../../i18n/en_US/dictionary";
import { isLoggedIn } from "../../utils/userUtils";
import Props from "./Props";
import { useStyles } from "./styles";

const Minimal: FC<Props> = (props: Props) => {
  const { children, className } = props;
  const globalClasses = useGlobalStyles();
  const loggedIn = useMemo(isLoggedIn, []);
  const classes = useStyles();
  const history = useHistory();

  const onContactUsClick = () => {
    history.push(Route.ContactUs);
  };

  const onLoginClick = () => {
    history.push(Route.Login);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <header className={classes.header}>
        {history.location.pathname !== "/businesses" ? (
          <OcietyNavLink
            to={Route.Home}
            noEffects
            linkTextClassName={globalClasses.flexRowStartCenter}>
            <Logo withText />
          </OcietyNavLink>
        ) : (
          <a href={"https://ociety.com/"}>
            <Logo withText />
          </a>
        )}
        <div>
          {location.pathname !== getRouteWithSlash(Route.ContactUs) && (
            <ButtonPrimary
              color={"secondary"}
              variant={"outlined"}
              className={classes.contactLink}
              onClick={onContactUsClick}>
              {dictionary.contactUs.pageTitle}
            </ButtonPrimary>
          )}
          {!loggedIn && (
            <ButtonBlack className={classes.loginButton} onClick={onLoginClick}>
              {dictionary.topBar.loginLink}
            </ButtonBlack>
          )}
        </div>
      </header>
      <main className={classes.content}>{children}</main>
      <Footer />
    </div>
  );
};

export default Minimal;
