import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "label"
  | "btnWrapper"
  | "dropZone"
  | "btnWrapperWithFile"
  | "whiteSpace"
  | "title";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      },
      label: {
        textAlign: "center",
        color: colors.grey.additional,
        marginTop: 8,
      },
      btnWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: 400,
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
          maxWidth: 300,
        },
      },
      btnWrapperWithFile: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginTop: 22,
      },
      dropZone: {
        backgroundImage: "url('/images/backgrounds/first-drop-zone-bg.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor: "white",
        backgroundPosition: "50% 35%",
        border: "none",
        marginBottom: theme.spacing(3),
      },
      whiteSpace: {
        width: 10,
      },
      title: {
        textAlign: "center",
      },
    })
);
