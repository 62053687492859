import { Avatar, Button } from "@material-ui/core";
import React, { FC, useEffect, useRef, useState } from "react";

import dictionary from "../../../../../../i18n/en_US/dictionary";
import { useStyles } from "../../../../../VenueSettings/components/VenueMediaContent/components/VenueMediaUploader/components/VideoUpload/styles";
import { uploadAvatar } from "./api";
import Props from "./Props";

const AvatarUpload: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const photoInput = useRef<HTMLInputElement>(null);

  const [disable, setDisable] = useState(false);
  const [avatarLink, setAvatarLink] = useState(
    props.avatarLink
      ? props.avatarLink
      : "/default-avatar-profile-icon-grey.jpg"
  );
  const openImageSelector = () => {
    // @ts-ignore
    photoInput.current.click();
  };

  useEffect(() => {
    if (props.avatarLink) {
      setAvatarLink(props.avatarLink);
    }
  }, [props.avatarLink]);
  const handleFileChange = (event: any) => {
    setDisable(true);
    uploadAvatar(event.target.files[0])
      .then((response) => response.data)
      .then((avatarUrl: any) => {
        // @ts-ignore
        photoInput.current.value = null; // Clear input after successfully uploading
        setAvatarLink(avatarUrl);
        setDisable(false);
      });
  };
  return (
    <div className={classes.root}>
      <div>
        <Avatar style={{ width: 200, height: 200 }} src={avatarLink} />
      </div>
      <input
        ref={photoInput}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleFileChange}
        id="photo-selector-input"
        type="file"
      />
      <Button
        style={{ width: 200, height: 60 }}
        size={"small"}
        disabled={disable}
        component="span"
        onClick={openImageSelector}>
        {dictionary.venueAdmin.profile.uploadPhoto}
      </Button>
    </div>
  );
};
export default AvatarUpload;
