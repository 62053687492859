export const colors = {
  black: {
    primary: "#282828",
  },
  blue: {
    additional: "#273950",
    primary: "#6979F8",
    dark: "#5967D3",
    secondary: "#2886E4",
    light: "#E7E9FE",
    primary2: "#6E8CF9",
  },
  red: {
    primary: "#FE5454",
    light: "rgba(255, 129, 129, 0.2)",
    secondary: "#FF9696",
    dark: "#D74747",
    lightPink: "#FFF0F0",
    lightPinkDarker: "#ffcdcd",
  },
  green: {
    primary: "#6FCF97",
    secondary: "#66BD5F",
    additional: "#51B87E",
    dark: "#529a4c",
    veryDark: "#58A151",
    light: "#67C19F",
    lightSecondary: "#B4D8CA",
  },
  grey: {
    divider: "#E3E7EC",
    primary: "#464854",
    secondary: "#A5B2C5",
    stroke: "#F5F5F9",
    secondaryLight: "#9EA5B5",
    light: "#CFD9ED",
    lighten: "#F4F6F8",
    medium: "#E5E7EF",
    additional: "#596880",
    helpText: "#909EAF",
    text: "#73859B",
    bgLight: "#EDEFF6",
    bgUltraLight: "#F9FAFC",
    disabled: "#CCD0DE",
  },
  dark: {
    main: "#273950",
    secondary: "#3D3E48",
  },
  orange: {
    main: "#FFB546",
  },
  yellow: {
    primary: "#FFCF5C",
    dark: "#e5b91f",
    light: "#FFF8E7",
  },
  white: {
    primary: "#FFFFFF",
  },
};

export const improvementsChartColors = [
  colors.green.light,
  colors.green.lightSecondary,
  "#549F52",
  "#79C177",
  "#48967A",
];

export const chartPrimaryColor = colors.green.light;
export const chartAdditionalColor = colors.green.lightSecondary;
export const chartSecondAdditionalColor = colors.green.secondary;
export const chartSecondaryColor = colors.grey.medium;
