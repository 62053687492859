import { Grid, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent, FC, useContext, useEffect, useMemo } from "react";

import OcietyNavLink from "../../../../../../common/components/OcietyNavLink";
import PhoneNumberInput from "../../../../../../common/components/PhoneNumberInput";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import { User } from "../../../../../../common/models";
import { Route } from "../../../../../../config/router";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { isLoggedIn } from "../../../../../../utils/userUtils";
import { CreateVenueDialogContext } from "../../CreateVenueDialogContextProvider";
import { CreateVenueAdminInfoFormField } from "../../forms/admin";
import { useStyles } from "./styles";

const CreateVenueDialogAdminInfoStep: FC = () => {
  const { adminForm } = useContext(CreateVenueDialogContext);
  const { form, setAndValidate, setFormValues } = adminForm;
  const classes = useStyles();
  const loggedIn = useMemo(isLoggedIn, []);
  const [, getUserData] = useRestApi<User>("profile/info", "GET");

  const handleChangeFormField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAndValidate(
      event.target.name as CreateVenueAdminInfoFormField,
      event.target.value
    );
  };

  useEffect(() => {
    if (loggedIn) {
      getUserData()
        .then((userData) => {
          if (userData) {
            setFormValues({
              firstName: userData.firstName || "",
              lastName: userData.lastName || "",
              title: userData.title || "",
              email: userData.email || "",
              company: userData.company || "",
              phoneNumber: userData.phone || "",
              comment: "",
            });
          }
        })
        .catch((e) => console.log("e -->>> ", e));
    }
  }, [loggedIn, getUserData, setFormValues]);

  return (
    <>
      <Grid container spacing={4} className={classes.root}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.firstNameFieldLabel}
            name="firstName"
            error={!!form.errors.firstName}
            helperText={form.errors.firstName}
            onChange={handleChangeFormField}
            type="text"
            value={form.values.firstName}
            variant="outlined"
            required
            disabled={loggedIn}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.lastNameFieldLabel}
            name="lastName"
            error={!!form.errors.lastName}
            helperText={form.errors.lastName}
            onChange={handleChangeFormField}
            type="text"
            value={form.values.lastName}
            variant="outlined"
            required
            disabled={loggedIn}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.companyFieldLabel}
            name="company"
            error={!!form.errors.company}
            helperText={form.errors.company}
            onChange={handleChangeFormField}
            type="text"
            value={form.values.company}
            variant="outlined"
            required
            disabled={loggedIn}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.titleFieldLabel}
            name="title"
            error={!!form.errors.title}
            helperText={form.errors.title}
            onChange={handleChangeFormField}
            type="text"
            value={form.values.title}
            variant="outlined"
            required
            disabled={loggedIn}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.emailFieldLabel}
            name="email"
            error={!!form.errors.email}
            helperText={form.errors.email}
            onChange={handleChangeFormField}
            type="text"
            value={form.values.email}
            variant="outlined"
            required
            disabled={loggedIn}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <PhoneNumberInput
            value={form.values.phoneNumber}
            onChange={handleChangeFormField}
            error={form.errors.phoneNumber}
            name="phoneNumber"
            label={dictionary.venues.claimVenueDialog.phoneNumberFieldLabel}
            isInputDisabled={loggedIn}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.commentFieldLabel}
            name="comment"
            multiline
            rows={3}
            rowsMax={5}
            error={!!form.errors.comment}
            helperText={form.errors.comment}
            onChange={handleChangeFormField}
            type="textarea"
            value={form.values.comment}
            variant="outlined"
          />
        </Grid>
      </Grid>
      {!loggedIn && (
        <Typography className={classes.loginNotion}>
          {dictionary.venues.list.createVenueLoginQuestion}
          <OcietyNavLink
            className={classes.loginLink}
            linkTextClassName={classes.loginLinkText}
            to={Route.Login}
            state={{
              from: "/businesses",
              createVenuePopupOpen: true,
            }}>
            {dictionary.venues.list.createVenueLoginLink}
          </OcietyNavLink>
        </Typography>
      )}
    </>
  );
};

export default CreateVenueDialogAdminInfoStep;
