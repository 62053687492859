import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, ReactElement } from "react";
import { NavLink, NavLinkProps, useHistory } from "react-router-dom";

import { Route } from "../../../config/router";
import { useStyles } from "./styles";

// @ts-ignore
interface Props extends NavLinkProps {
  to: Route | Array<Route | string>;
  linkTextClassName?: string;
  noEffects?: boolean;
  icon?: ReactElement;
  state?: Record<string, any>;
}

const OcietyNavLink: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory<Record<string, string>>();
  const {
    className,
    children,
    icon,
    linkTextClassName,
    noEffects,
    state,
    to,
    ...rest
  } = props;

  let toHash = undefined;
  if (Array.isArray(to)) {
    to[0].replace(":id", to[1]);

    if (to[2]) {
      toHash = to[2];
    }
  }

  return (
    <NavLink
      className={clsx(
        classes.link,
        icon && classes.linkWithIcon,
        className,
        noEffects && classes.noEffects
      )}
      to={{
        pathname: "/" + (Array.isArray(to) ? to[0].replace(":id", to[1]) : to),
        hash: toHash,
        state: {
          ...history.location.state,
          ...state,
        },
      }}
      {...rest}>
      {icon}
      {typeof children === "string" ? (
        <Typography
          component={"span"}
          className={linkTextClassName}
          variant="body1">
          {children}
        </Typography>
      ) : (
        <Typography component={"div"} className={linkTextClassName}>
          {children}
        </Typography>
      )}
    </NavLink>
  );
};

export default OcietyNavLink;
