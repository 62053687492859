import { PromotionLimitType, PromotionType } from "../../../enums";
import { ICreatePromotionFormRequest } from "./ICreatePromotionFormRequest";
import { IDayHours } from "./IDayHours";

export default class CreatePromotionFormRequest
  implements ICreatePromotionFormRequest
{
  public name = "";
  public description = "";
  public start = "";
  public end = "";
  public promotionPhotosIds: number[] = [];
  public promotionVideosIds: number[] = [];
  public promotionCategoryId = "";
  public promotionSubCategoryId = "";
  public type = PromotionType.FOOD;
  public daysHours: IDayHours[] = [];
  public limitType = PromotionLimitType.SINGLE;
  public limitPercent = 0;
  public limitMaxAmount = 0;
  public limitPrice = 0;
  public limitItemsName = "";
  public quantity = 0;
  public notifyHowManyLeft = false;
  public notifyHowManyLeftCount = 0;
  public maximumUsagesPerCustomer = 0;
  public restrictMinimumAge = "0";
  public promotionCode = "";
  public isQuantityUnlimited = true;
  public ageGroupId = "";
  public mainPhotoId = 0;
  public dietaryRestrictions = "";

  set photosIds(ids: number[]) {
    this.promotionPhotosIds = ids;
  }

  set videosIds(ids: number[]) {
    this.promotionVideosIds = ids;
  }
}
