import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "rowItem"
  | "rowItemFirst"
  | "rowItemLast"
  | "rowItem1"
  | "rowItem2"
  | "gridContainer"
  | "rowItemCenter"
  | "headerRowItemText"
  | "iconWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      gridContainer: {
        [theme.breakpoints.down("lg")]: {
          minWidth: 1200,
        },
      },
      root: {
        marginTop: theme.spacing(3),
        backgroundColor: "white",
        padding: 30,
        maxWidth: "100%",
        boxSizing: "border-box",
        overflowX: "auto",
        [theme.breakpoints.down("sm")]: {
          maxWidth: 500,
        },
        [theme.breakpoints.up("sm")]: {
          maxWidth: "100%",
        },
      },
      headerRowItemText: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
      },
      iconWrapper: {
        display: "flex",
        alignItems: "center",
        marginLeft: 6,
      },
      rowItem: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 1),
      },
      rowItemFirst: {
        paddingLeft: theme.spacing(5),
        maxWidth: "14%",
      },
      rowItemCenter: {
        display: "flex",
        justifyContent: "center",
      },
      rowItem1: {
        flexGrow: 0,
        maxWidth: "6.666666%",
        flexBasis: "6.666666%",
      },
      rowItem2: {
        flexGrow: 0,
        maxWidth: "13.333333%",
        flexBasis: "13.333333%",
      },
      rowItemLast: {
        padding: theme.spacing(1, 5, 1, 1),
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down("md")]: {
          justifyContent: "flex-start",
        },
      },
    })
);
