import clsx from "clsx";
import React, { FC, useContext } from "react";

import PageTitle from "../../../../common/components/PageTitle";
import PeriodSelect from "../../../../common/components/PeriodSelect";
import { VenueReportingContext } from "../../../../common/components/VenueReportingContextProvider";
import {
  AnalyticsPeriod,
  AnalyticsPeriodSelectOption,
} from "../../../../common/models";
import { useGlobalStyles } from "../../../../common/styles";
import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const periodOptions: AnalyticsPeriodSelectOption[] = [
  {
    value: AnalyticsPeriod.LastThreeMoths,
    label: "Last 3 months",
  },
  {
    value: AnalyticsPeriod.LastSixMoths,
    label: "Last 6 months",
  },
  {
    value: AnalyticsPeriod.LastYear,
    label: "Last year",
  },
];

const PageTitleWithToolbar: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { period, setPeriod } = useContext(VenueReportingContext);
  const { backURL } = props;
  return (
    <div className={clsx(globalClasses.flexRowBetweenCenter, classes.root)}>
      <PageTitle backURL={backURL}>{dictionary.reviews.pageTitle}</PageTitle>
      <div className={classes.actionsWrapper}>
        <PeriodSelect
          setPeriod={setPeriod}
          period={period}
          className={classes.periodSelect}
          periodOptions={periodOptions}
        />
      </div>
    </div>
  );
};

export default PageTitleWithToolbar;
