import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Route } from "../../../../../config/router";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { updateCurrentVenue } from "../../../../../store/currentVenue/actions";
import { formatDateForMessages } from "../../../../../utils/dateTimeUtils";
import { useCurrentVenueId } from "../../../../hooks/useCurrentVenueId";
import { useRestApi } from "../../../../hooks/useRestApi";
import { AdminNotification, AdminNotificationType } from "../../../../models";
import icons from "../../../../svg/icons/notifications/icons";
import { AlertContext } from "../../../Alert/AlertContextProvider";
import { MARK_ADMIN_NOTIFICATIONS_AS_VIEWED } from "../../api";
import Props from "./Props";
import { ClassNames, useStyles } from "./styles";

const NotificationsDropdownItem: FC<Props> = (props: Props) => {
  const { notification } = props;
  const { date, isNew, message, title, type, image, id, venueId, venueName } =
    notification;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const history = useHistory();
  const currentVenueId = useCurrentVenueId();
  const dispatch = useDispatch();
  const isSameVenue = currentVenueId === venueId;
  const [, markAsViewed] = useRestApi<AdminNotification[]>(
    MARK_ADMIN_NOTIFICATIONS_AS_VIEWED(id),
    "PUT"
  );

  const goToTheTargetPage = useCallback(() => {
    let path = "";

    switch (type) {
      case AdminNotificationType.VENUE_POS_CONNECT:
        path = Route.Promotions;
        break;
      case AdminNotificationType.VENUE_POS_DISCONNECT:
        path = Route.Promotions;
        break;
      case AdminNotificationType.VENUE_MENU_DELETE:
        path = Route.Promotions;
        break;
      case AdminNotificationType.PROMOTION:
        path = Route.Promotions;
        break;
      case AdminNotificationType.RECOMMENDATION:
        path = Route.Recommendations;
        break;
      case AdminNotificationType.VENUE_REVIEW:
        path = Route.Reviews;
        break;
    }

    history.push(path);
  }, [history, type]);

  const changeCurrentVenue = useCallback(() => {
    dispatch(updateCurrentVenue(venueId));
    showAlert(dictionary.notifications.venueChanged(venueName), "info");
  }, [dispatch, showAlert, venueId, venueName]);

  const handleClickNotification = useCallback(() => {
    if (isNew) {
      markAsViewed().catch((e) => console.error(e));
    }

    if (!isSameVenue) {
      changeCurrentVenue();
    }

    goToTheTargetPage();
  }, [changeCurrentVenue, goToTheTargetPage, isNew, isSameVenue, markAsViewed]);

  const getNotificationIcon = () => {
    const Icon = icons[type];

    return (
      <div
        className={clsx(
          classes.notificationIconContainer,
          classes[(type + "_ICON") as ClassNames]
        )}>
        <Icon />
      </div>
    );
  };

  const getDateAndVenueString = () => {
    const dateTime = formatDateForMessages(date);

    return isSameVenue ? dateTime : `${dateTime} (${venueName})`;
  };

  return (
    <div
      className={clsx(classes.root, isNew && classes.rootNew)}
      onClick={handleClickNotification}>
      <div className={classes.leftContainer}>
        {image && (
          <div className={classes.imageContainer}>
            <img className={classes.image} src={image} alt="" />
          </div>
        )}
        {getNotificationIcon()}
      </div>
      <div className={classes.rightContainer}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.message}>{message}</Typography>
        <Typography className={classes.dateTime}>
          {getDateAndVenueString()}
        </Typography>
      </div>
    </div>
  );
};

export default NotificationsDropdownItem;
