import {
  CardActions,
  Table,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import TBody from "../../../../../../common/components/TBody";
import TH from "../../../../../../common/components/TH";
import { VenueReportingContext } from "../../../../../../common/components/VenueReportingContextProvider";
import { usePagination } from "../../../../../../common/hooks";
import { useCurrentVenueId } from "../../../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import { Review } from "../../../../../../common/models";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import AllReviewsTableRow from "../AllReviewsTableRow";
import { GET_VENUE_REVIEWS } from "./api";
import Props from "./Props";
import { useStyles } from "./styles";

const AllReviewsTable: FC<Props> = (props: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const currentVenueId = useCurrentVenueId();
  const classes = useStyles();
  const { period } = useContext(VenueReportingContext);
  const [pagination, dispatchPagination, paginationTypes] =
    usePagination(false);
  const { foodRating, serviceRating, userType, searchKey } = props;
  const [{ data, loading }, getReviews] = useRestApi<Review[]>(
    GET_VENUE_REVIEWS(currentVenueId, period),
    "GET"
  );

  const filteredReviews = useMemo(() => {
    const reviews = data || [];

    return reviews.filter((review) => {
      if (
        searchKey &&
        !review.username.toLowerCase().includes(searchKey.trim().toLowerCase())
      ) {
        return false;
      }

      if (foodRating && foodRating !== review.food) {
        return false;
      }

      if (serviceRating && serviceRating !== review.service) {
        return false;
      }

      if (userType && userType !== review.userType) {
        return false;
      }

      return true;
    });
  }, [data, searchKey, foodRating, serviceRating, userType]);

  useEffect(() => {
    getReviews().catch((e) => console.error(e));
  }, [getReviews]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    currentPage: number
  ) => {
    dispatchPagination({ action: paginationTypes.SET_PAGE, currentPage });
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <div className={classes.root}>
      {loading && <LoadingIndicator withMask />}
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TH>{dictionary.reviews.allReviewsTablePhotoColumnName}</TH>
              <TH>{dictionary.reviews.allReviewsTableUserColumnName}</TH>
              <TH>{dictionary.reviews.allReviewsTableUserTypeColumnName}</TH>
              <TH>{dictionary.reviews.allReviewsTableCreatedDateColumnName}</TH>
              <TH>{dictionary.reviews.allReviewsTableUpdatedDateColumnName}</TH>
              <TH>{dictionary.reviews.allReviewsTableFoodRatingColumnName}</TH>
              <TH>
                {dictionary.reviews.allReviewsTableServiceRatingColumnName}
              </TH>
              <TH>{dictionary.reviews.allReviewsTableActionColumnName}</TH>
            </TableRow>
          </TableHead>
          <TBody>
            {filteredReviews
              .slice(
                pagination.currentPage * rowsPerPage,
                pagination.currentPage * rowsPerPage + rowsPerPage
              )
              .map((review) => (
                <AllReviewsTableRow review={review} key={review.id} />
              ))}
          </TBody>
        </Table>
      </div>
      <CardActions>
        <TablePagination
          component="div"
          count={filteredReviews.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={pagination.currentPage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </CardActions>
    </div>
  );
};

export default AllReviewsTable;
