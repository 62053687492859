import { Divider, Typography } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import dictionary from "../../../../i18n/en_US/dictionary";
import { getMfaProfile, setMfaDefault } from "./api";
import CallMethodDialog from "./components/CallMethodDialog";
import EmailMethodDialog from "./components/EmailMethodDialog";
import MFACallControl from "./components/MFACallControl";
import MFAEmailControl from "./components/MFAEmailControl";
import MFASMSControl from "./components/MFASMSControl";
import OTPMethodDialog from "./components/OTPMethodDialog";
import SMSMethodDialog from "./components/SMSMethodDialog";
import IMfaData, { TwoFactoryAuthTypeEnum } from "./models/IMfaData";
import Props from "./Props";
import { useStyles } from "./styles";

const MFA: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [mfaData, setMfaData] = useState<IMfaData | null>(null);
  const { showAlert } = useContext(AlertContext);
  const [checkedType, setCheckedType] =
    useState<TwoFactoryAuthTypeEnum | null>(null);
  const [loading, setLoading] = useState(false);
  const [isCallMethodDialogOpen, setIsCallMethodDialogOpen] = useState(false);
  const [isEmailMethodDialogOpen, setIsEmailMethodDialogOpen] = useState(false);
  const [isSMSMethodDialogOpen, setIsSMSMethodDialogOpen] = useState(false);
  const [isOTPMethodDialogOpen, setIsOTPMethodDialogOpen] = useState(false);

  useEffect(() => {
    // TODO replace via one method
    getMfaProfile()
      .then((mfaData: IMfaData) => {
        if (mfaData) {
          setMfaData(mfaData);
          setCheckedType(mfaData.mfaTwoFactoryAuthTypeDefault);
        }
      })
      .catch(() => {
        showAlert(dictionary.profile.mfa.errorFetchData, "error");
      });
  }, [showAlert]);

  const refreshMfaData = () => {
    getMfaProfile()
      .then((mfaData: IMfaData) => {
        if (mfaData) {
          setMfaData(mfaData);
          setCheckedType(mfaData.mfaTwoFactoryAuthTypeDefault);
        }
      })
      .catch(() => {
        showAlert(dictionary.profile.mfa.errorFetchData, "error");
      });
  };

  const onChangeChecked = async (type: TwoFactoryAuthTypeEnum) => {
    try {
      setCheckedType(type);
      const result = await setMfaDefault(type);

      if (!result) {
        showAlert(
          dictionary.venueAdmin.profile.mfa.changeDefaultMethodError,
          "error"
        );
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(
        dictionary.venueAdmin.profile.mfa.changeDefaultMethodError,
        "error"
      );
    }
  };

  return (
    <div className={classes.root}>
      {loading && <LoadingIndicator withMask />}
      <Typography variant={"h3"}>{dictionary.profile.mfa.title}</Typography>
      <div className={classes.wrapperControls}>
        <MFASMSControl
          isActive={!!mfaData?.mfaSmsEnable}
          isSelected={TwoFactoryAuthTypeEnum.SMS === checkedType}
          phone={mfaData?.mfaSmsPhone}
          setLoading={setLoading}
          refreshData={refreshMfaData}
          onClickEnable={setIsSMSMethodDialogOpen}
          onSelectClick={() => {
            onChangeChecked(TwoFactoryAuthTypeEnum.SMS);
          }}
        />
        <Divider />
        <MFACallControl
          isActive={!!mfaData?.mfaCallEnable}
          isSelected={TwoFactoryAuthTypeEnum.CALL === checkedType}
          phone={mfaData?.mfaCallPhone}
          setLoading={setLoading}
          refreshData={refreshMfaData}
          onClickEnable={setIsCallMethodDialogOpen}
          onSelectClick={() => {
            onChangeChecked(TwoFactoryAuthTypeEnum.CALL);
          }}
        />
        <Divider />
        <MFAEmailControl
          isActive={!!mfaData?.mfaMailEnable}
          isSelected={TwoFactoryAuthTypeEnum.EMAIL === checkedType}
          email={mfaData?.mfaMailEmail}
          setLoading={setLoading}
          refreshData={refreshMfaData}
          onClickEnable={setIsEmailMethodDialogOpen}
          onSelectClick={() => {
            onChangeChecked(TwoFactoryAuthTypeEnum.EMAIL);
          }}
        />
        {/*

        Hid MFA by Authentication app on Profile page due to it is not implemented yet

        <Divider />
        <MFAOTPControl
          isActive={!!mfaData?.mfaTotpEnable}
          isSelected={TwoFactoryAuthTypeEnum.TOTP === checkedType}
          description={mfaData?.mfaTotpNote}
          setLoading={setLoading}
          refreshData={refreshMfaData}
          onClickEnable={setIsOTPMethodDialogOpen}
          onSelectClick={() => {
            onChangeChecked(TwoFactoryAuthTypeEnum.TOTP);
          }}
       /> */}
      </div>

      <SMSMethodDialog
        isOpen={isSMSMethodDialogOpen}
        setOpen={setIsSMSMethodDialogOpen}
        onSuccessEnabled={() => {
          refreshMfaData();
        }}
      />
      <CallMethodDialog
        isOpen={isCallMethodDialogOpen}
        setOpen={setIsCallMethodDialogOpen}
        onSuccessEnabled={() => {
          refreshMfaData();
        }}
      />
      <OTPMethodDialog
        isOpen={isOTPMethodDialogOpen}
        setOpen={setIsOTPMethodDialogOpen}
        onSuccessEnabled={() => {
          refreshMfaData();
        }}
      />
      <EmailMethodDialog
        isOpen={isEmailMethodDialogOpen}
        setOpen={setIsEmailMethodDialogOpen}
        onSuccessEnabled={() => {
          refreshMfaData();
        }}
      />
    </div>
  );
};
export default MFA;
