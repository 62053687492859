import { Typography } from "@material-ui/core";
import React, { FC, useCallback, useContext } from "react";

import { AlertContext } from "../../../../../../../../common/components/Alert/AlertContextProvider";
import Dropzone from "../../../../../../../../common/components/forms/Dropzone";
import { ReactComponent as AttachmentIcon } from "../../../../../../../../common/svg/icons/attachment.svg";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { CreateVenueDialogContext } from "../../../../CreateVenueDialogContextProvider";
import { useStyles } from "./styles";

const AttachmentsBlock: FC = () => {
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const { setAttachments, attachments } = useContext(CreateVenueDialogContext);

  const onDrop = useCallback(
    (files: any[]) => {
      setAttachments(files);
      showAlert(
        dictionary.venues.list.createVenuePhotosAttached(files.length),
        "success"
      );
    },
    [setAttachments, showAlert]
  );

  return (
    <div>
      <Typography variant={"h3"} component={"span"} className={classes.title}>
        {dictionary.venues.list.createVenueValidationStepAttachmentsTitle}
      </Typography>
      <Dropzone
        isLock={false}
        label={dictionary.venues.list.createVenueValidationAttachmentsLabel}
        dropZoneIcon={<AttachmentIcon />}
        description={
          dictionary.venues.list.createVenueValidationAttachmentsDescription
        }
        classes={{
          root: classes.dropzoneRoot,
        }}
        onDrop={onDrop}
        multiple
      />
      {!!attachments.length && (
        <Typography className={classes.attachmentsList}>
          {dictionary.venues.list.createVenueValidationAttachmentsList(
            attachments.map((attachment: any) => attachment.name).join(", ")
          )}
        </Typography>
      )}
    </div>
  );
};

export default AttachmentsBlock;
