import React, { FC, ReactElement } from "react";

import { ReactComponent as StarIconEmpty } from "../../../../common/svg/icons/star-empty.svg";
import { ReactComponent as StarIconFilled } from "../../../../common/svg/icons/star-filled.svg";
import Props from "./Props";
import { useStyles } from "./styles";

const RatingStars: FC<Props> = (props: Props) => {
  const { numberOfStars } = props;
  const classes = useStyles();
  const stars: ReactElement[] = [];
  const filledStarsQty = numberOfStars || 0;

  for (let i = 0; i < 5; i++) {
    stars.push(
      i < filledStarsQty ? (
        <StarIconFilled className={classes.star} />
      ) : (
        <StarIconEmpty className={classes.star} />
      )
    );
  }

  return <div>{stars}</div>;
};

export default RatingStars;
