import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "selectWrapper"
  | "select"
  | "selectLabel"
  | "notActiveMessageRoot"
  | "notActiveMessageText"
  | "notActiveMessageTextLink"
  | "warningIcon"
  | "oneVenueName"
  | "oneVenueTitle";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        alignItems: "center",
      },
      notActiveMessageRoot: {
        display: "flex",
        marginLeft: theme.spacing(3),
        backgroundColor: colors.grey.bgUltraLight,
        padding: theme.spacing(1.5),
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("lg")]: {
          maxWidth: "46%",
          marginLeft: theme.spacing(3),
        },
      },
      notActiveMessageText: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
      },
      notActiveMessageTextLink: {
        marginLeft: theme.spacing(1.5),
      },
      warningIcon: {
        minWidth: 30,
        "& > path": {
          fill: colors.grey.secondary,
        },
      },
      selectWrapper: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: theme.typography.pxToRem(16),
        boxSizing: "border-box",
        backgroundColor: theme.palette.primary.light,
        "& > fieldset": {
          border: "none",
        },
        [theme.breakpoints.up("sm")]: {
          minWidth: 292,
        },
      },
      select: {
        paddingTop: 15.5,
        paddingBottom: 15.5,
      },
      selectLabel: {
        visibility: "hidden",
      },
      oneVenueTitle: {
        color: "#BAC4D1",

        fontFamily: "Nunito Sans",
        fontSize: theme.typography.pxToRem(20),
      },
      oneVenueName: {
        color: "#464854",
        fontWeight: "bold",
      },
    })
);
