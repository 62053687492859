import clsx from "clsx";
import React, { ChangeEvent, FC } from "react";

import SearchInput from "../../../../common/components/SearchInput";
import { useDebounce } from "../../../../common/hooks";
import dictionary from "../../../../i18n/en_US/dictionary";
import { Props } from "./Props";
import { useStyles } from "./styles";

const VenuesToolbar: FC<Props> = (props: Props) => {
  const { handleSearch, className } = props;

  const classes = useStyles();

  const debouncedSearch = useDebounce(handleSearch, 500);

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder={dictionary.venues.list.searchInputPlaceholder}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => debouncedSearch(event.target.value)}
        />
      </div>
    </div>
  );
};

export default VenuesToolbar;
