import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useState } from "react";

import ConfirmDialog from "../../../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../../../common/components/ConfirmDialog/Props";
import { useRestApi } from "../../../../../common/hooks/useRestApi";
import { ReactComponent as DeleteIcon } from "../../../../../common/svg/icons/delete-2.svg";
import { ReactComponent as EditIcon } from "../../../../../common/svg/icons/edit.svg";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { DELETE_MANAGER } from "../../api";
import IManager from "../../models/IManager";
import EditUserDialog from "../EditUserDialog";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueStaffManager: FC<Props> = (props: Props) => {
  const { manager, currentManager, onSuccess, toggleEditUserModal } = props;
  const [selectedPromotionId, setSelectedPromotionId] =
    useState<number | null>(null);
  const [_, deleteManager] = useRestApi(DELETE_MANAGER(manager.id), "DELETE");

  const classes = useStyles();

  const editManager = async (manager: IManager) => {
    toggleEditUserModal(manager);
  };

  const handleDeleteManager = async () => {
    deleteManager().then(() => {
      onSuccess && onSuccess();
    });
  };

  const getRoles = (manager: IManager) => {
    const roles = [];
    if (manager.isWaitlistAdmin) {
      roles.push(dictionary.venueStaff.managers.roles.waitlistManager);
    }
    if (manager.isDiscountAdmin) {
      roles.push(dictionary.venueStaff.managers.roles.discountManager);
    }
    return roles.join(", ");
  };

  return (
    <>
      {(!currentManager ||
        (!!currentManager && currentManager.id != manager.id)) && (
        <Grid container xs={12} component={Paper} className={classes.manager}>
          <Grid item xs={3} className={classes.rowItemName}>
            <Typography
              variant={"body2"}
              className={clsx(classes.rowItemText, classes.text)}>
              {manager.firstName}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.rowItem}>
            <Typography variant={"body2"} className={classes.text}>
              {manager.email}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            className={clsx(classes.rowItem, classes.rowItemCenter)}>
            <Typography variant={"body2"} className={classes.text}>
              {getRoles(manager)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItemSmallWidth
            )}>
            <Grid item xs={6}>
              <IconButton onClick={() => editManager(manager)} name={"edit"}>
                <EditIcon />
              </IconButton>
            </Grid>

            <Grid item xs={6}>
              <IconButton
                onClick={() => setSelectedPromotionId(manager.id)}
                name={"delete"}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!!currentManager && manager.id === currentManager.id && (
        <EditUserDialog
          key={manager.id}
          onSuccess={onSuccess}
          toggleOpen={(manager) => toggleEditUserModal(manager)}
          isOpen={!!currentManager}
          manager={currentManager}
        />
      )}
      <ConfirmDialog
        open={!!selectedPromotionId}
        onClose={() => setSelectedPromotionId(null)}
        onConfirm={handleDeleteManager}
        subtitle={dictionary.promotions.onDeleteMessage}
        type={ConfirmModalType.YES_DELETE}
        message=""
      />
    </>
  );
};
export default VenueStaffManager;
