import {
  Box,
  CardActions,
  Paper,
  TablePagination,
  Typography,
} from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import CreateNewVenueButton from "../../common/components/CreateNewVenueButton";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import { AppState } from "../../store/rootReducer";
import CreateVenueDialog from "./components/CreateVenueDialog";
import VenuesSearchEmpty from "./components/VenuesSearchEmpty";
import VenuesTable from "./components/VenuesTable";
import VenuesToolbar from "./components/VenuesToolbar";
import { getAllVenues } from "./ducks/actions";
import Props from "./Props";
import { useStyles } from "./styles";

const Venues: FC<Props> = () => {
  const history = useHistory<{ createVenuePopupOpen?: boolean } | undefined>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { list, venuesCount, loading } = useSelector(
    (state: AppState) => state.venues
  );
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const [search, setSearch] = useState<string>();
  const [page, setPage] = useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const skip = !search ? page * rowsPerPage : searchPage * rowsPerPage;

  const openCreateVenueDialog = useCallback(() => {
    setCreateDialogOpen(true);
  }, []);

  const closeCreateVenueDialog = useCallback(() => {
    setCreateDialogOpen(false);
  }, []);

  useEffect(() => {
    if (history.location.state?.createVenuePopupOpen) {
      const state = { ...history.location.state };
      delete state.createVenuePopupOpen;

      setCreateDialogOpen(true);
      history.push(history.location.pathname, state);
    }
  }, [history]);

  useEffect(() => {
    dispatch(getAllVenues(skip, rowsPerPage, search));
  }, [dispatch, skip, rowsPerPage, search]);

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSearch = (searchPhrase: string) => {
    setSearch(searchPhrase);

    if (!searchPhrase) {
      setSearchPage(0);
    }
  };

  return (
    <div className={classes.root}>
      <PageTitle>{dictionary.venues.list.pageTitle}</PageTitle>
      <Paper className={classes.contentWrapper}>
        {loading && <LoadingIndicator withMask />}
        <div className={classes.topSection}>
          <Box>
            <Typography variant={"body1"} className={classes.pageNotion}>
              {dictionary.venues.list.searchAndCreationNotion}
            </Typography>
            <div className={classes.searchAndCreateWrapper}>
              <VenuesToolbar
                handleSearch={handleSearch}
                className={classes.searchField}
              />
              <CreateNewVenueButton
                openCreateVenuePopup={openCreateVenueDialog}
                className={classes.createNewVenueButton}
              />
            </div>
          </Box>
          <Box className={classes.trademarkContainer}>
            <Typography variant={"body1"}>
              {dictionary.venues.trademarkText}
            </Typography>
            <div className={classes.trademarkWrapper}>
              <img
                alt={"restaurantdata.jpg"}
                title={"restaurantdata.com"}
                src={"/images/restaurantdata.jpg"}
                className={classes.trademark}
              />
            </div>
          </Box>
        </div>
        {search && list.length === 0 ? (
          <VenuesSearchEmpty openCreateVenuePopup={openCreateVenueDialog} />
        ) : (
          <VenuesTable venues={list} isSearching={!!search} />
        )}
        <CardActions>
          <TablePagination
            component="div"
            count={venuesCount}
            onChangePage={(event, page) => {
              !search ? setPage(page) : setSearchPage(page);
            }}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={!search ? page : searchPage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </CardActions>
      </Paper>
      <CreateVenueDialog
        closeDialog={closeCreateVenueDialog}
        open={createDialogOpen}
      />
    </div>
  );
};

export default Venues;
