import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames = "root" | "leftBtn" | "rightBtn";
export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: 20,
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
      },
      leftBtn: {},
      rightBtn: {
        borderLeft: "1px solid #E1E2E3",
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
      },
    })
);
