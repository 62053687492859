import { Form } from "../../../../../common/models";
import { ValidationRules } from "../../../../../utils/validation";

export type CreateVenueVenueInfoFormField =
  | "bar"
  | "restaurant"
  | "name"
  | "city"
  | "state"
  | "country"
  | "email"
  | "phoneNumber"
  | "address";

export type CreateVenueVenueInfoFormValues = {
  [key in CreateVenueVenueInfoFormField]: string;
};

export type CreateVenueVenueInfoFormErrors = {
  [key in CreateVenueVenueInfoFormField]: string;
};

export interface CreateVenueVenueInfoForm extends Form {
  values: CreateVenueVenueInfoFormValues;
  errors: CreateVenueVenueInfoFormErrors;
  touched: CreateVenueVenueInfoFormField[];
}

export type CreateVenueVenueInfoFormSchema = Record<
  CreateVenueVenueInfoFormField,
  ValidationRules
>;

export const createVenueVenueInfoForm: CreateVenueVenueInfoForm = {
  values: {
    name: "",
    city: "",
    email: "",
    state: "",
    country: "",
    phoneNumber: "",
    address: "",
    bar: "",
    restaurant: "",
  },
  errors: {
    name: "",
    city: "",
    email: "",
    state: "",
    country: "",
    phoneNumber: "",
    address: "",
    bar: "",
    restaurant: "",
  },
  touched: [],
  isValid: true,
};

export const createVenueVenueInfoFormSchema: CreateVenueVenueInfoFormSchema = {
  city: {
    required: true,
    length: {
      max: 256,
    },
  },
  name: {
    required: true,
    length: {
      max: 1000,
    },
  },
  email: {
    required: true,
    type: "email",
    length: {
      max: 256,
    },
  },
  state: {
    required: true,
    length: {
      max: 256,
    },
  },
  country: {
    required: true,
    length: {
      max: 256,
    },
  },
  phoneNumber: {
    required: true,
    type: "phone",
    length: {
      max: 256,
    },
  },
  address: {
    required: true,
    length: {
      max: 256,
    },
  },
  bar: {
    length: {
      max: 1000,
    },
  },
  restaurant: {
    length: {
      max: 1000,
    },
  },
};
