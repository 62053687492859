import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import CreateNewVenueButton from "../../../../common/components/CreateNewVenueButton";
import { ReactComponent as EmptySearchIcon } from "../../../../common/svg/icons/search-empty.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const VenuesSearchEmpty: FC<Props> = (props) => {
  const classes = useStyles();
  const { openCreateVenuePopup } = props;

  return (
    <div className={classes.root}>
      <EmptySearchIcon />
      <Typography variant={"h1"} component={"p"} className={classes.title}>
        {dictionary.venues.list.searchEmptyTitle}
      </Typography>
      <Typography variant={"body2"} className={classes.notion}>
        {dictionary.venues.list.searchEmptyNotion}
      </Typography>
      <CreateNewVenueButton openCreateVenuePopup={openCreateVenuePopup} />
    </div>
  );
};

export default VenuesSearchEmpty;
