import { Paper, Typography } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";

import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { ReviewsResponse } from "../../../../common/models";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { GET_RECENT_REVIEWS } from "./api";
import RecentReviewsSlider from "./components/RecentReviewsSlider";
import { useStyles } from "./styles";

const RecentReviews: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const venueId = useCurrentVenueId();
  const [{ data, loading }, getRecentReviews] = useRestApi<ReviewsResponse>(
    GET_RECENT_REVIEWS(venueId),
    "GET"
  );

  useEffect(() => {
    getRecentReviews().catch((e) => console.error(e));
  }, [getRecentReviews]);

  const handlePressAllReviewsButton = () => {
    history.push(Route.AllReviews);
  };

  return (
    <Paper>
      <div className={classes.root}>
        {loading && <LoadingIndicator withMask />}
        <Typography variant={"h3"}>{dictionary.reviews.recentTitle}</Typography>
        <div className={classes.actionsToolbar}>
          <ButtonPrimary onClick={handlePressAllReviewsButton}>
            {dictionary.reviews.seeAllReviewsButton}
          </ButtonPrimary>
        </div>
        {data?.reviews?.length ? (
          <RecentReviewsSlider reviews={data?.reviews} isBar={data?.isBar} />
        ) : (
          <div className={classes.emptyContainer}>
            <Typography variant={"subtitle2"}>
              {dictionary.reviews.recentEmpty}
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default RecentReviews;
