import { InputAdornment, TextField } from "@material-ui/core";
import React, { ChangeEvent, FC } from "react";

import OcietyIcon from "../../../../../../../common/components/OcietyIcon";
import { useDebounce } from "../../../../../../../common/hooks";
import Props from "./Props";
import { useStyles } from "./styles";

const SearchPromotion: FC<Props> = (props: Props) => {
  const { onChange } = props;
  const classes = useStyles();
  const debouncedSearch = useDebounce(onChange, 500);
  return (
    <TextField
      required
      fullWidth
      id="filled-phone-number"
      name="currentPass"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <OcietyIcon
              name={"greySearchButton"}
              width={16}
              height={16}
              className={classes.icon}
              viewBox="0 0 16 16"
            />
          </InputAdornment>
        ),
      }}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        debouncedSearch(event.target.value);
      }}
      placeholder={"Search by name"}
      type="text"
      variant="outlined"
    />
  );
};
export default SearchPromotion;
