import { Button, ButtonProps } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import { ReactComponent as DeleteIcon } from "../../svg/icons/delete.svg";
import { useStyles } from "./styles";

const ButtonDelete: FC<ButtonProps> = (props) => {
  const { className, children, ...rest } = props;

  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.button, className)}
      size="large"
      type="submit"
      variant="outlined"
      {...rest}>
      <DeleteIcon className={classes.icon} />
      {children || dictionary.confirmDialog.deleteButton}
    </Button>
  );
};

export default ButtonDelete;
