import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "select";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        minWidth: 246,
        [theme.breakpoints.up("md")]: {
          "&:not(:first-of-type)": {
            marginLeft: 20,
          },
        },
      },
      select: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(14 / 8, 20 / 8),
      },
    })
);
