import "video-react/dist/video-react.css";

import { IconButton } from "@material-ui/core";
import React, { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
// @ts-ignore
import { Player } from "video-react";

import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import ConfirmDialog from "../../../../../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../../../../../common/components/ConfirmDialog/Props";
import OcietyTooltip from "../../../../../../../common/components/OcietyTooltip";
import { useCurrentVenueId } from "../../../../../../../common/hooks/useCurrentVenueId";
import { useIsManager } from "../../../../../../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../../../../../../common/hooks/useIsSuperAdmin";
import { useRestApi } from "../../../../../../../common/hooks/useRestApi";
import { ReactComponent as RemoveIcon } from "../../../../../../../common/svg/icons/rubbish-bin.svg";
import { ReactComponent as WarningIcon } from "../../../../../../../common/svg/icons/warning-circle.svg";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { DELETE_PROMOTION_VIDEO } from "../../../../../../Promotions/VenueAdminPromotions/components/PromotionFormDialog/components/PromotionFormDialogContent/components/BasicInfoPromotionStep/components/FileUploaderPromotion/api";
import { deleteVenueVideo } from "../../../../../ducks/actions";
import { useStyles } from "../styles";
import { DELETE_VENUE_VIDEO } from "./api";
import Props from "./Props";

const VenueMediaCarouselVideoSlide: FC<Props> = (props: Props) => {
  const { id, url, active, onDeleteVideoPromotion, type } = props;
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const dispatch = useDispatch();
  const venueId = useCurrentVenueId();
  const [, deleteVideoVenue] = useRestApi(
    DELETE_VENUE_VIDEO(venueId, id),
    "DELETE"
  );
  const [, deleteVideoPromotion] = useRestApi<any, any>(
    DELETE_PROMOTION_VIDEO(venueId, id),
    "DELETE"
  );
  const superAdmin = useIsSuperAdmin();
  const manager = useIsManager();

  const classes = useStyles();

  const handleDeleteVideo = () => {
    if (!venueId) {
      return showAlert(
        dictionary.venues.venueForm.deleteVideoNoVenueIdError,
        "error"
      );
    }
    onDeleteVideoPromotion
      ? deleteVideoPromotion()
          .then(() => {
            onDeleteVideoPromotion(id);
            showAlert(
              dictionary.venues.venueForm.deleteVideoSuccess,
              "success"
            );
          })
          .catch(() => {
            showAlert(dictionary.venues.venueForm.deleteVideoError, "error");
          })
      : deleteVideoVenue()
          .then(() => {
            dispatch(deleteVenueVideo(id));
            showAlert(
              dictionary.venues.venueForm.deleteVideoSuccess,
              "success"
            );
          })
          .catch(() => {
            showAlert(dictionary.venues.venueForm.deleteVideoError, "error");
          });
  };

  return (
    <div key={id}>
      {isConfirmDialogOpen && (
        <ConfirmDialog
          open={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          onConfirm={handleDeleteVideo}
          type={ConfirmModalType.DELETE}
          message={dictionary.venues.venueForm.deleteVideoConfirmationMessage}
        />
      )}
      <div className={classes.slideWrapper}>
        <IconButton
          className={classes.removeButton}
          onClick={() => setIsConfirmDialogOpen(true)}>
          <RemoveIcon />
        </IconButton>
        <Player playsInline src={url} />
        {!active && type === "venue" && (
          <div className={classes.warningIconContainer}>
            <OcietyTooltip
              title={
                superAdmin || manager
                  ? dictionary.venues.venueForm.pendingTooltipSuperAdmin
                  : dictionary.venues.venueForm.pendingTooltipVenueAdmin
              }>
              <WarningIcon />
            </OcietyTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default VenueMediaCarouselVideoSlide;
