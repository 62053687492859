import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import RatingStars from "../../../../../RatingStars";
import Props from "./Props";
import { useStyles } from "./styles";

const ReviewRatingBlock: FC<Props> = (props: Props) => {
  const { food, service, isBar } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {food && (
        <div className={classes.ratingRow}>
          <Typography className={classes.ratingLabel}>
            {isBar
              ? dictionary.reviews.foodDrinksLabel
              : dictionary.reviews.foodLabel}
          </Typography>
          <div>
            <RatingStars numberOfStars={food} />
          </div>
        </div>
      )}
      {service && (
        <div className={classes.ratingRow}>
          <Typography className={classes.ratingLabel}>
            {dictionary.reviews.serviceLabel}
          </Typography>
          <div>
            <RatingStars numberOfStars={service} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewRatingBlock;
