import { http } from "../../config/api";
import UrlUtils from "../../utils/UrlUtils";

export const VENUE_PHOTOS_APPROVE = "/venue/approve-photo/0";
export const VENUE_PHOTOS_DECLINE = "/venue/delete-photo/0";

const VENUE_PHOTOS_ALL = (limit: number, skip?: number, search?: string) => {
  const url = `venue/venue-photos/0`;

  const queryParams: {
    limit?: number;
    skip?: number;
    search?: string;
  } = {};

  if (limit) {
    queryParams.limit = limit;
  }

  if (skip) {
    queryParams.skip = skip;
  }

  if (search) {
    queryParams.search = search;
  }

  // @ts-ignore
  const query = "?" + UrlUtils.buildQuery(queryParams);

  return url + query;
};

export const getVenuePendingPhotosAll = async (
  limit: number,
  skip?: number,
  search?: string
): Promise<any> =>
  http
    .get(VENUE_PHOTOS_ALL(limit, skip, search))
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
