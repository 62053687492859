import { Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";

import PageTitle from "../../common/components/PageTitle";
import PeriodSelect from "../../common/components/PeriodSelect";
import { AnalyticsPeriod } from "../../common/models";
import { useGlobalStyles } from "../../common/styles";
import dictionary from "../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../store/currentVenue/reducer";
import { AppState } from "../../store/rootReducer";
import MostPopularPromotion from "./components/MostPopularPromotion";
import PopularDaysWidget from "./components/PopularDaysWidget";
import PromotionsFeedbackScore from "./components/PromotionsFeedbackScore";
import TotalPromotionsActive from "./components/TotalPromotionsActive";
import TotalPromotionsUsed from "./components/TotalPromotionsUsed";
import TotalRecommendations from "./components/TotalRecommendations";
import TotalReviews from "./components/TotalReviews";
import { useStyles } from "./styles";

const Dashboard: FC = () => {
  const [period, setPeriod] = useState(AnalyticsPeriod.LastThreeMoths);
  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (appState) => appState.currentVenue
  );
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const currentVenueId = currentVenue ? currentVenue.id : 0;

  const handlePeriodChange = (currentPeriod: AnalyticsPeriod) => {
    setPeriod(currentPeriod);
  };

  return (
    <div>
      <div
        className={clsx(
          globalClasses.flexRowBetweenCenter,
          classes.titleWrapper
        )}>
        <PageTitle>{dictionary.dashboard.pageTitle}</PageTitle>
        <PeriodSelect
          className={classes.periodSelect}
          setPeriod={handlePeriodChange}
          period={period}
        />
      </div>
      <Grid container spacing={3} style={{ maxWidth: "none" }}>
        <Grid item xs={12} lg={3} md={6} xl={3}>
          <TotalPromotionsUsed venueId={currentVenueId} period={period} />
        </Grid>
        <Grid item xs={12} lg={3} md={6} xl={3}>
          <TotalRecommendations venueId={currentVenueId} period={period} />
        </Grid>
        <Grid item xs={12} lg={3} md={6} xl={3}>
          <TotalReviews venueId={currentVenueId} period={period} />
        </Grid>
        <Grid item xs={12} lg={3} md={6} xl={3}>
          <TotalPromotionsActive venueId={currentVenueId} />
        </Grid>
        <Grid item xs={12} lg={6} md={12}>
          <PopularDaysWidget period={period} venueId={currentVenueId} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PromotionsFeedbackScore period={period} />
          <MostPopularPromotion period={period} venueId={currentVenueId} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
