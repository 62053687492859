import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames = "root";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
    },
  })
);
