import { CardContent, Grid, Paper } from "@material-ui/core";
import React, { FC } from "react";

import Props from "../../Venues/Props";
import { useStyles } from "../styles";

const PrivacyPolicy: FC<Props> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <CardContent className={classes.cardContainer}>
          <Grid container justify="center" spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <h1 className={classes.title}>Ociety’s Privacy Notice</h1>
                <p className={classes.updated}>Last updated 2/2/2022</p>
                <br />
                <br />
                <p>Effective Date: 11/22/2021</p>
                <br />
                <p>
                  Ociety, Inc. (“Ociety,” “our,” “we,” or “us”) respects your
                  privacy. This Privacy Notice applies to personal information
                  that we collect in connection with our mobile application (the
                  “app”) and websites (collectively, the “services”). “Personal
                  Information” means information that identifies you or that can
                  be reasonably associated/ linked to you.
                </p>
                <br />
                <ul className={classes.tableOfContent}>
                  <li>
                    <a href="#info-collects">
                      Types of Information Ociety Collects
                    </a>
                  </li>
                  <li>
                    <a href="#how-collects-info">
                      How Ociety Collects Information
                    </a>
                  </li>
                  <li>
                    <a href="#how-uses-info">How Ociety Uses Information</a>
                  </li>
                  <li>
                    <a href="#interest-based-ad">Interest-Based Advertising</a>
                  </li>
                  <li>
                    <a href="#disclosures-info">Disclosures of Information</a>
                  </li>
                  <li>
                    <a href="#security-personal-info">
                      Security of Personal Information
                    </a>
                  </li>
                  <li>
                    <a href="#privacy-choice">Your Privacy Choices</a>
                  </li>
                  <li>
                    <a href="#children-age">Children Under the Age of 18</a>
                  </li>
                  <li>
                    <a href="#california-privacy">California Privacy Rights</a>
                  </li>
                  <li>
                    <a href="#personal-information-transfers">
                      International Transfers of Personal Information
                    </a>
                  </li>
                  <li>
                    <a href="#privacy-notice">Privacy Notice Updates</a>
                  </li>
                  <li>
                    <a href="#contact-us">Contact Us</a>
                  </li>
                </ul>
                <br />
                <h2 id="info-collects">
                  1. Types of Information Ociety Collects
                </h2>
                <br />
                <br />
                <p>
                  The information collected about you depends on how you
                  interact with the Services. Collection and processing of your
                  information are based on different contexts: our performance
                  of a contract, our obligations under law, our legitimate
                  interest in conducting our business, protection of a natural
                  person’s vital interests and/or upon your consent. Ociety may
                  collect the information below, including Personal Information,
                  when you interact with the Services.
                </p>
                <br />
                <h4>
                  Depending on how you interact with the Services, information
                  about you may be collected directly by Ociety or by venues
                  through the Services. This privacy notice only applies to how
                  we handle information we collect and maintain about you. We do
                  not control, and are not responsible for, what venues may or
                  may not do with your information.{" "}
                </h4>
                <br />
                <ul style={{ marginLeft: "40px" }}>
                  <li>
                    <strong>Sign-Up Information:</strong> We collect information
                    when you sign up for the services, such as your phone number
                    and certain information regarding your computer or mobile
                    device (see “Device Information” below). If you sign up via
                    another service or account (e.g., Google or Facebook), then
                    we may also collect your name, User-ID, email address, and
                    photo link).
                  </li>
                  <li>
                    <strong>Profile Information:</strong> We collect information
                    you choose to add to your profile, such as city, country,
                    age range, and gender. We may also collect information from
                    Facebook and Google regarding your Facebook and Google
                    profiles when you log in via those accounts, such as name
                    and email.
                  </li>
                  <li>
                    <strong>Venue Information:</strong> We collect contact
                    information that is associated with a particular venue,
                    including email addresses.
                  </li>
                  <li>
                    <strong>Venue Waitlist Information:</strong> We collect
                    information related to you and your party’s wait for a seat
                    with or entrance into a venue, including occasion (e.g.,
                    birthday, anniversary), age range, relationship to venue
                    (e.g., loyalty status), dietary preferences (e.g.,
                    vegetarian, vegan, etc.), allergies, and location. If the
                    venue requests, we may ask you to provide vaccination status
                    and/or verification information, such as Quick Response (QR)
                    code.
                  </li>
                  <li>
                    <strong>Device Information:</strong> We collect information
                    related to the device you use to access the Services and to
                    your Internet connection, including device type, advertising
                    IDs and device identifiers, IP address, mobile network
                    information, operating system and version, browser type,
                    referring URLs, and location information (see “Location
                    Information” below).
                  </li>
                  <li>
                    <strong>Location Information:</strong> If you grant access,
                    we may collect your precise location information through the
                    mobile device you use to access the App when the App is
                    running, including in the background.
                  </li>
                  <li>
                    <strong>Activity Information:</strong> We collect
                    information about your activity on the Services, including
                    searches and views, clicks, promotion usage, interactions
                    with advertisements, and any other information you submit to
                    us through the Services.
                  </li>
                  <li>
                    <strong>User Preferences/Interests:</strong> We may collect
                    information about your preferences or interests, such as
                    language preferences and venue preferences (restaurants,
                    cuisine, bars, spending limit, crowds, atmosphere.)
                  </li>
                  <li>
                    <strong>Payment Information:</strong> If you sign up for
                    paid services directly with us, our third party payment
                    processor may collect information about your payment card,
                    such as card number, expiration date, and card security
                    code. Currently, our payment processors are Braintree and
                    Stripe. Braintree uses and processes your complete payment
                    information in accordance with Braintree’s privacy policy
                    available at {""}
                    <a
                      href="https://www.braintreepayments.com/legal/braintree-privacy-policy"
                      className={classes.wrapLongText}>
                      https://www.braintreepayments.com/legal/braintree-privacy-policy
                    </a>
                    . Stripe uses and processes your complete payment
                    information in accordance with Stripe’s privacy policy
                    available at {""}
                    <a href="https://stripe.com/privacy">
                      https://stripe.com/privacy
                    </a>
                    .
                  </li>
                </ul>
                <br />
                <h2 id="how-collects-info">
                  2. How Ociety Collects Information
                </h2>
                <br />
                <p>We collect this information:</p>
                <br />
                <ul style={{ marginLeft: "40px" }}>
                  <li>
                    <strong>Directly from You:</strong> we collect information
                    that you provide to us when you sign up for and use the
                    Services, provide waitlist and/or reservation information,
                    review a venue, indicate preferences, answer questions on
                    the “Train My AI” page on the App, or correspond with us,
                    such as through email, web forms, or the chat function on
                    the Services. Waitlist and/or reservation information may
                    also be collected from you by venues using the Services.
                  </li>
                  <li>
                    <strong>From Your Device:</strong> we may collect
                    information from the devices you use when you download the
                    App and when you access the Services. This information may
                    be collected through automated means, including through
                    cookies, pixel tags, web beacons, scripts, local shared
                    objects (such as HTML5 and Flash), or other similar
                    technologies (“Tracking Technologies”).
                  </li>
                  <li>
                    <strong>From Third Parties:</strong> we may receive
                    information about you or about your use of the Services from
                    third parties, such as our service providers, data analytics
                    providers, advertisers/advertising networks, and the app
                    stores through which you download the App. We may also
                    receive information about you through third-party
                    integrations, such as when you sign up for the Services
                    through your Google, Facebook, or Apple account or otherwise
                    give permission through privacy settings for those services
                    to disclose information to us.
                  </li>
                </ul>
                <br />
                <h2 id="how-uses-info">3. How Ociety Uses Information</h2>
                <br />
                <p>
                  We may use your information, including Personal Information,
                  to:
                </p>
                <br />
                <ul style={{ marginLeft: "40px" }}>
                  <li>
                    Operate, maintain, and improve the Services and our
                    business.
                  </li>
                  <li>
                    Communicate with you about the Services on our own behalf or
                    on behalf of venues, including through SMS and/or text
                    messages, telephone calls, push notifications, and emails.
                  </li>
                  <li>Process payments. </li>
                  <li>
                    Provide you with offers and advertisements from
                    participating venues that may be of interest to you.
                  </li>
                  <li>
                    Understand your preferences and make the Services more
                    relevant to you.
                  </li>
                  <li>
                    Verify your location in order for you to use a promotion.
                  </li>
                  <li>
                    Provide you with interest-based advertisements (see{" "}
                    <a href="#interest-based-ad">Interest-Based Advertising</a>
                    ).
                  </li>
                  <li>
                    Measure performance of and analyze usage of the Services.
                  </li>
                  <li>Protect our rights, our users, and others.</li>
                  <li>
                    Provide you with the Services and fulfill any other purpose
                    for which you provide it.
                  </li>
                  <li>
                    To carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us,
                    including for billing and collection.
                  </li>
                </ul>
                <br />
                <p>
                  We may use information about you for other purposes with your
                  consent or where the information is aggregated or
                  de-identified such that the information cannot reasonably be
                  linked to you. Although we describe above our primary purposes
                  in collecting your information, in many situations we have
                  more than one purpose. For example, if you complete an online
                  purchase or other transaction we may collect your information
                  to perform our contract with you, but we also have a
                  legitimate interest in maintaining your information after your
                  transaction is complete so that we can quickly and easily
                  respond to any questions about your transaction. As a result,
                  our collection and processing of your information is based on
                  different contexts upon your consent, our need to perform a
                  contract, our obligations under law, and/or our legitimate
                  interest in conducting our business.
                </p>
                <br />
                <h2 id="interest-based-ad">4. Interest-Based Advertising</h2>
                <br />
                <br />
                <p>
                  On our Services, we or our service providers may collect
                  information about your activities within the Services for use
                  in providing you with advertising about products and services
                  tailored to your individual interests. To opt out of certain
                  interest-based advertising, see{" "}
                  <a href="#privacy-choice">Your Privacy Choices</a>
                </p>

                <br />
                <h2 id="disclosures-info">5. Disclosures of Information</h2>
                <br />
                <br />
                <p>
                  We may disclose information, including Personal Information:
                </p>
                <br />
                <ul style={{ marginLeft: "40px" }}>
                  <li>
                    At your direction or to enable you to intentionally interact
                    with a venue or other third party.
                  </li>
                  <li>
                    To vendors who provide services on our behalf and other
                    third parties we use to support our business and, where
                    required by applicable law, who are bound by contractual
                    obligations with respect to disclosure and use of personal
                    information. These vendors are not authorized by us to use
                    or disclose the information, except as necessary to perform
                    services on our behalf or to comply with legal requirements.
                  </li>
                  <li>
                    To digital advertising and data analytics providers that
                    provide services to us or features on our App or websites,
                    including Google (see &ldquo;How Google uses data when you
                    use our partners&apos; sites or apps&rdquo;, (located at{" "}
                    <a
                      href="https://www.google.com/policies/privacy/partners/"
                      className={classes.wrapLongText}>
                      www.google.com/policies/privacy/partners/
                    </a>
                    , or any other URL Google may provide from time to time)).
                  </li>
                  <li>
                    To venues that you browse or visit in connection with our
                    Services, but only in aggregated form unless you choose to
                    disclose non-aggregated information to such venues
                    (including waitlist information referenced in Section 1).
                  </li>
                  <li>
                    To our parents, subsidiaries, and affiliates, which may use
                    the information for the purposes described in this Privacy
                    Notice.
                  </li>
                  <li>
                    To law enforcement, regulators, and courts to comply with
                    any court order, law, or legal process, including to respond
                    to any government or regulatory request.
                  </li>
                  <li>
                    If we believe it is necessary to protect our rights, our
                    users, or others, or to investigate suspected or actual
                    fraud or illegal activity.
                  </li>
                  <li>
                    To enforce or apply our{" "}
                    <a href="/terms-of-use">Terms of Use</a> or service terms
                    and other agreements, including for billing and collection
                    purposes.
                  </li>
                  <li>
                    In the event of a merger, divestiture, restructuring,
                    reorganization, dissolution, bankruptcy, or sale or transfer
                    of all or substantially all of our business or assets.
                  </li>
                </ul>
                <br />
                <p>
                  We may disclose information about you for other purposes with
                  your consent or where the information is aggregated or
                  de-identified such that the information cannot reasonably be
                  linked to you.
                </p>
                <br />
                <h2 id="security-personal-info">
                  6. Security of Personal Information
                </h2>
                <br />
                <br />
                <p>
                  We have implemented an information security program that
                  contains measures designed to secure your Personal Information
                  in our possession or control from unauthorized access, use,
                  alteration, and disclosure. The safety and security of your
                  information also depends on you. Where we have given you (or
                  where you have chosen) a password for access to certain parts
                  of our Services, you are responsible for keeping this password
                  confidential. We ask you not to share your password with
                  anyone. We urge you to be careful about giving out information
                  in public areas of the Services like review boards. The
                  information you share in public areas may be viewed by any
                  user of the Services.
                </p>
                <br />
                <h2 id="privacy-choice">7. Your Privacy Choices</h2>
                <br />
                <br />
                <ul style={{ marginLeft: "40px" }}>
                  <li>
                    <strong>Interest-Based Advertising:</strong> You may opt-out
                    of interest-based advertisements by sending us an email at{" "}
                    <a href="mailto:privacy@ociety.com">privacy@ociety.com</a>{" "}
                    and requesting that we do not provide you with
                    interest-based advertising or opting out of interest-based
                    advertising directly through the App. Please note that
                    opting out does not prevent the display of all
                    advertisements to you. For more information see{" "}
                    <a href="#interest-based-ad">Interest-Based Advertising</a>.
                  </li>
                  <li>
                    <strong>Manage Collection of Location Information:</strong>{" "}
                    The App will request access to collect your mobile device’s
                    precise location information. You may prevent or limit
                    access to location information through your device settings,
                    but please note that temporary access is required when using
                    a promotion.
                  </li>
                  <li>
                    <strong>Opt Out of Marketing Emails:</strong> If you no
                    longer wish to receive marketing emails from Ociety, you may
                    click the “Unsubscribe” link in an email at any time.
                  </li>
                  <li>
                    <strong>
                      Retaining, Deleting, and Accessing Your Personal
                      Information:
                    </strong>
                    Typically, we retain your personal information for the
                    period necessary to provide you with access to the Services
                    and to otherwise fulfill the purposes outlined in this
                    policy, unless a longer retention period is required or
                    permitted by law. In addition, we may keep your personal
                    information as needed to comply with our legal obligations,
                    resolve disputes, and/or enforce our agreements. The precise
                    periods for which we keep your personal information vary
                    depending on the nature of the information and why we need
                    it.
                  </li>
                </ul>
                <br />
                <p>
                  You can review personal information we have collected about
                  you by logging into the Services and visiting your account
                  profile page. You may also send us an email at{" "}
                  <a href="mailto:privacy@ociety.com">privacy@ociety.com</a> to
                  request to review or delete any personal information that you
                  have provided to us. We will respond to your request as
                  required by applicable law. You may also delete personal
                  information we have collected about you by deleting your
                  profile through the App or resetting your profile. However,
                  resetting your profile only deletes information provided after
                  your profile is set up (e.g., App usage information associated
                  with your profile is deleted, however, information you
                  provided to set up the profile is not deleted during reset).
                  Also, we are only able to delete information that we have
                  about you. If you provide venue waitlist information (see
                  Section 1) or otherwise choose to disclose information to a
                  venue through the Services, the venue may also have the
                  information that you provided. If the venue keeps such
                  information outside of the App, we cannot delete or report to
                  you about such information. You must contact the applicable
                  venue about any such information the venue may have about you.
                </p>
                <br />
                <p>
                  Deleting your personal information may also require deletion
                  of your user account. If you delete your reviews or other
                  public facing contributions from the Services (“User
                  Contributions”), copies of your User Contributions may remain
                  viewable in cached and archived pages, or might have been
                  copied or stored by other users of the Services. Proper access
                  and use of information provided on the Services, including
                  User Contributions, is governed by our Terms of Use.{" "}
                </p>
                <br />
                <h2 id="children-age">8. Children Under the Age of 18 </h2>
                <br />
                <p>
                  Our Services are not intended for users under the age of 18.
                  Nor are our Services designed or intended to collect
                  information from persons under the age of 18. We do not
                  knowingly collect or maintain any information from children
                  under the age of 18. If you have information that you believe
                  indicates that we have processed information pertaining to a
                  person less than 18 years old, please contact us at{" "}
                  <a href="mailto:privacy@ociety.com">privacy@ociety.com</a>.
                </p>
                <br />
                <h2 id="california-privacy">9. California Privacy Rights</h2>
                <br />
                <ul style={{ marginLeft: "40px" }}>
                  <li>
                    <strong>
                      Request Information About Direct Marketing Disclosures:
                    </strong>
                    California residents may have the right to request
                    information about disclosures of Personal Information to
                    third parties for their direct marketing purposes. To make
                    such a request, please email us at{" "}
                    <a href="mailto:privacy@ociety.com">privacy@ociety.com</a>.
                  </li>
                  <li>
                    <strong>Request Removal of Your Posts:</strong> If you are a
                    California resident under 18 and a registered user of our
                    Services, you may request and obtain the removal of any
                    content or information you have publicly posted to the
                    Services. To make such a request, please email us at{" "}
                    <a href="mailto:privacy@ociety.com">privacy@ociety.com</a>.
                    Note that a request to obtain such removal does not ensure
                    complete or comprehensive removal of the content or
                    information you have posted.
                  </li>
                  <li>
                    <strong>Do Not Track Disclosure:</strong> Do Not Track
                    (“DNT”) is a privacy preference that you can set in your web
                    browsers, which allows you to opt out of tracking by
                    websites and online services. We do not currently recognize
                    or respond to DNT signals. For more information about DNT,
                    please see{" "}
                    <a href="www.eff.org/issues/do-not-track">
                      www.eff.org/issues/do-not-track
                    </a>
                    .
                  </li>
                </ul>
                <br />
                <h2 id="privacy-notice">10. Privacy Notice Updates</h2>
                <br />
                <br />
                <p>
                  It is our policy to post any changes we make to our privacy
                  policy on this page. We may update this Privacy Notice at any
                  time. If we make any material changes, we will notify you by
                  email if there is an email address associated with your
                  account or by posting a notice on the Services prior to or on
                  the effective date of the changes.
                </p>
                <br />
                <h2 id="contact-us">11. Contact Us</h2>
                <br />
                <br />
                <p>
                  You may contact us at{" "}
                  <a href="mailto:privacy@ociety.com">privacy@ociety.com</a>.
                </p>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
