import { FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, { FC, useEffect } from "react";

import { useAsyncState } from "../../../../../../common/hooks/useAsyncState";
import { PromotionCategory } from "../../../../../../common/models";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { useSelectStyles, useStyles } from "../../../../styles";
import {
  GET_PROMOTION_FILTERED_CATEGORIES,
  GetFilteredCategoriesResponse,
} from "./api";
import Props from "./Props";

const PromotionCategoriesFilter: FC<Props> = ({
  currentCategoryId,
  setCurrentCategoryId,
}) => {
  const [{ response }, setCategories] =
    useAsyncState<GetFilteredCategoriesResponse>(
      GET_PROMOTION_FILTERED_CATEGORIES
    );
  const classes = useStyles();
  const selectClasses = useSelectStyles();

  useEffect(() => {
    setCategories().then();
  }, [setCategories]);

  const handleChangeCategoryFilter = (e: any) => {
    setCurrentCategoryId(e.target.value);
  };

  useEffect(() => {
    setCurrentCategoryId(
      response?.categories[response?.categories.length - 1].id
    );
  }, [response, setCurrentCategoryId]);

  if (!response?.categories || currentCategoryId === undefined) {
    return null;
  }

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.filterSelect, "select-no-legend")}>
      <Select
        classes={selectClasses}
        id="popular_promotions_category"
        value={currentCategoryId}
        label={dictionary.reporting.promotionCategorySelectLabel}
        onChange={handleChangeCategoryFilter}>
        <MenuItem value="" disabled>
          <em>{dictionary.reporting.promotionCategorySelectLabel}</em>
        </MenuItem>
        {response.categories.map((promotionCategory: PromotionCategory) => (
          <MenuItem key={promotionCategory.id} value={promotionCategory.id}>
            {promotionCategory.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PromotionCategoriesFilter;
