import { http } from "../../../config/api";
import UrlUtils from "../../../utils/UrlUtils";
import IPromotionDietaryRestriction from "../../Promotions/VenueAdminPromotions/components/PromotionFormDialog/components/PromotionFormDialogContent/components/MainInfoPromotionStep/models/IPromotionDietaryRestriction";
import CreatePromotionFormRequest from "../../Promotions/VenueAdminPromotions/components/PromotionFormDialog/models/CreatePromotionFormRequest";
import UpdatePromotionFormRequest from "../../Promotions/VenueAdminPromotions/components/PromotionFormDialog/models/UpdatePromotionFormRequest";

export const DELETE_MANAGER_PROMOTION = (
  venueId: number,
  promotionId: number
) => `manager-promotions/${venueId}/${promotionId}`;
export const DUPLICATE_MANAGER_PROMOTION = (venueId: number) =>
  `/manager-promotions/duplicate/${venueId}`;
export const CREATE_MANAGER_PROMOTION = (venueId: number) =>
  `manager-promotions/${venueId}`;
export const GET_MANAGER_PROMOTION = (promotionId: number) =>
  `manager-promotions/${promotionId}`;
export const UPDATE_MANAGER_PROMOTION = (
  venueId: number,
  promotionId: number
) => `manager-promotions/${venueId}/${promotionId}`;
export const GET_MANAGER_PROMOTIONS = (venueId: number, filter?: string) => {
  const url = `manager-promotions/${venueId}/all`;
  let query = "";

  if (filter) {
    query = "?" + UrlUtils.buildQuery({ filter });
  }

  return url + query;
};

export const createManagerPromotion = async (
  createPromotionFormRequest: CreatePromotionFormRequest,
  venueId: number,
  dietaryRestrictions?: IPromotionDietaryRestriction[],
  ageGroupId?: number[]
): Promise<any> =>
  http
    .post(
      CREATE_MANAGER_PROMOTION(venueId),
      Object.assign(createPromotionFormRequest, {
        dietaryRestrictions,
        ageGroupId,
      })
    )
    .then((response) => ({
      ...response,
    }))
    .catch((error) => {
      throw error.response;
    });

export const updateManagerPromotion = async (
  updatePromotionFormRequest: UpdatePromotionFormRequest,
  promotionId: number,
  venueId: number,
  dietaryRestrictions?: IPromotionDietaryRestriction[],
  ageGroupId?: number[]
): Promise<any> =>
  http
    .put(
      UPDATE_MANAGER_PROMOTION(venueId, promotionId),
      Object.assign(updatePromotionFormRequest, {
        dietaryRestrictions,
        ageGroupId,
      })
    )
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
