import { capitalize, Typography } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import {
  Cell,
  Legend,
  LegendPayload,
  LegendValueFormatter,
  Pie,
  PieChart,
  Sector,
  Tooltip,
  TooltipPayload,
} from "recharts";

import ChartContainer from "../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../common/components/ChartTooltip";
import { useStyles as useTooltipStyles } from "../../../../common/components/ChartTooltip/styles";
import { VenueReportingContext } from "../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../common/hooks/useRequestByPeriod";
import { MobileAppUserType } from "../../../../common/models";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  chartAdditionalColor,
  chartPrimaryColor,
  colors,
} from "../../../../theme/colors";
import { LegendImprovementsProps } from "../../../Reporting/defaultProps";
import {
  GET_VENUE_REVIEWS_SCORING,
  GetVenueReviewsScoringResponse,
} from "./api";
import { chartDataMock } from "./mock";
import { tooltipWrapperStyle, useStyles } from "./styles";

const ReviewsScoring: FC = () => {
  const [chartData, setChartData] = useState(chartDataMock);
  const { filters } = useContext(VenueReportingContext);
  const { response, loading } =
    useRequestByPeriod<GetVenueReviewsScoringResponse>(
      GET_VENUE_REVIEWS_SCORING,
      filters
    );
  const [activeShapeIndex, setActiveShapeIndex] = useState(0);
  const tooltipClasses = useTooltipStyles();
  const classes = useStyles();

  const getBarFillColor = (index: number) => {
    const valuesArray = chartData.map(({ userType }) => userType);
    const currentUserType = valuesArray[index];

    return currentUserType === MobileAppUserType.CRITIC
      ? chartPrimaryColor
      : chartAdditionalColor;
  };

  const renderTooltipContent = (payload: ReadonlyArray<TooltipPayload>) => {
    const data = payload[0].payload;

    return (
      <div style={{ width: "auto" }}>
        <Typography className={tooltipClasses.label}>{`${
          data.reviewsPercent
        }% ${capitalize(
          data.userType === MobileAppUserType.USER
            ? dictionary.reviews.scoringUserUnit
            : dictionary.reviews.scoringCriticUnit
        )}`}</Typography>
      </div>
    );
  };

  useEffect(() => {
    if (response) {
      setChartData(response.stats);
    }
  }, [response]);

  const legendFormatter: LegendValueFormatter = (
    value: LegendPayload["value"]
  ) => {
    return value === MobileAppUserType.USER
      ? dictionary.reviews.scoringUserUnit
      : dictionary.reviews.scoringCriticUnit;
  };

  const renderActiveShape = (props: any) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;

    return (
      <g>
        <svg
          x={cx - 7}
          y={cy - 40}
          dy={0}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.553 7.27191C15.9585 6.87688 16.1016 6.29709 15.9266 5.75909C15.7519 5.22088 15.2954 4.83597 14.7352 4.75456L11.2762 4.25197C11.1006 4.22634 10.9489 4.11613 10.8704 3.95713L9.32347 0.8225C9.073 0.315156 8.56585 0 7.99997 0C7.4341 0 6.92694 0.315156 6.67647 0.8225C6.67647 0.822531 5.12957 3.95709 5.12957 3.95709C5.05107 4.11616 4.89935 4.22638 4.72404 4.25194L1.26485 4.75453C0.704598 4.83597 0.248067 5.22088 0.0734731 5.75881C-0.101621 6.29709 0.0414731 6.87691 0.446817 7.27181L2.94988 9.71194C3.07685 9.83566 3.13479 10.0139 3.10488 10.1885L2.51388 13.6339C2.41838 14.1917 2.64335 14.7448 3.101 15.0772C3.55875 15.4098 4.15438 15.4529 4.6555 15.1897L7.74932 13.5631C7.90625 13.4806 8.09366 13.4806 8.25044 13.563L11.3445 15.1897C11.8456 15.4529 12.4413 15.4098 12.899 15.0772C13.3566 14.7448 13.5816 14.1917 13.4861 13.6339L12.8951 10.1886C12.8652 10.0139 12.9231 9.83566 13.0502 9.71184L15.553 7.27191Z"
            fill="#FFCF5C"
          />
        </svg>
        <text
          x={cx}
          y={cy}
          dy={8}
          fontSize={28}
          fontWeight={700}
          fontFamily={"Nunito Sans"}
          textAnchor="middle"
          fill="#273950">
          {payload.averageRating}
        </text>
        <text
          x={cx}
          y={cy}
          dy={30}
          textAnchor="middle"
          fill={colors.grey.primary}
          fontSize={13}
          fontWeight={400}
          fontFamily={"Nunito Sans"}>
          average
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={
            payload.averageRating > 0 && payload.reviewsPercent !== 100
              ? startAngle + 0.3
              : startAngle
          }
          endAngle={
            payload.averageRating > 0 && payload.reviewsPercent !== 100
              ? endAngle - 0.3
              : endAngle
          }
          innerRadius={70}
          outerRadius={outerRadius + 6}
          fill={fill}
        />
      </g>
    );
  };

  const handleMouseEnter = (_: any, index: number) => {
    setActiveShapeIndex(index);
  };

  const isChartEmpty = !chartData.some(
    (data) => (data.reviewsPercent || data.averageRating) !== 0
  );

  return (
    <ChartContainer
      chartType={"pie"}
      loading={loading}
      isChartEmpty={isChartEmpty}
      title={dictionary.reviews.reviewsScoringChartTitle}>
      <PieChart className={classes.root}>
        <Pie
          data={chartData}
          dataKey="reviewsPercent"
          activeIndex={activeShapeIndex}
          activeShape={renderActiveShape}
          nameKey="userType"
          innerRadius={60}
          outerRadius={90}
          onMouseEnter={handleMouseEnter}
          fill="#8884d8">
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getBarFillColor(index)} />
          ))}
        </Pie>
        <Tooltip
          wrapperStyle={tooltipWrapperStyle}
          content={<ChartTooltip renderCustomContent={renderTooltipContent} />}
        />
        <Legend
          layout={"vertical"}
          align={"right"}
          verticalAlign={"middle"}
          formatter={legendFormatter}
          wrapperStyle={LegendImprovementsProps.wrapperStyle}
        />
      </PieChart>
    </ChartContainer>
  );
};

export default ReviewsScoring;
