import { IconButton } from "@material-ui/core";
import React, { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";

import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import ConfirmDialog from "../../../../../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../../../../../common/components/ConfirmDialog/Props";
import OcietyTooltip from "../../../../../../../common/components/OcietyTooltip";
import PrimaryPhotoRadio from "../../../../../../../common/components/PrimaryPhotoRadio";
import { useCurrentVenueId } from "../../../../../../../common/hooks/useCurrentVenueId";
import { useIsManager } from "../../../../../../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../../../../../../common/hooks/useIsSuperAdmin";
import { useRestApi } from "../../../../../../../common/hooks/useRestApi";
import { ReactComponent as RemoveIcon } from "../../../../../../../common/svg/icons/rubbish-bin.svg";
import { ReactComponent as WarningIcon } from "../../../../../../../common/svg/icons/warning-circle.svg";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { DELETE_PROMOTION_PHOTO } from "../../../../../../Promotions/VenueAdminPromotions/components/PromotionFormDialog/components/PromotionFormDialogContent/components/BasicInfoPromotionStep/components/FileUploaderPromotion/api";
import { changeMainPhotoVenue, getVenue } from "../../../../../ducks/actions";
import { useStyles } from "../styles";
import { CHANGE_MAIN_PHOTO, DELETE_VENUE_PHOTO } from "./api";
import Props from "./Props";

const VenueMediaCarouselPhotoSlide: FC<Props> = (props: Props) => {
  const {
    id,
    url,
    active,
    isMainPhoto,
    onChangeMainPhotoPromotion,
    onDeletePhotoPromotion,
    type,
  } = props;
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const dispatch = useDispatch();
  const venueId = useCurrentVenueId();
  const [, deletePhotoVenue] = useRestApi(
    DELETE_VENUE_PHOTO(venueId, id),
    "DELETE"
  );
  const [, deletePhotoPromotion] = useRestApi<any, any>(
    DELETE_PROMOTION_PHOTO(venueId, id),
    "DELETE"
  );
  const [, changeMainPhoto] = useRestApi<any, any>(
    CHANGE_MAIN_PHOTO(venueId, id),
    "PATCH"
  );
  const superAdmin = useIsSuperAdmin();
  const manager = useIsManager();

  const classes = useStyles();

  const handleDeletePhoto = () => {
    if (!venueId) {
      return showAlert(
        dictionary.venues.venueForm.deletePhotoNoVenueIdError,
        "error"
      );
    }
    onDeletePhotoPromotion
      ? deletePhotoPromotion()
          .then(() => {
            onDeletePhotoPromotion(id);
            showAlert(
              dictionary.venues.venueForm.deletePhotoSuccess,
              "success"
            );
          })
          .catch(() => {
            showAlert(dictionary.venues.venueForm.deletePhotoError, "error");
          })
      : deletePhotoVenue()
          .then(() => {
            dispatch(getVenue(venueId));
            showAlert(
              dictionary.venues.venueForm.deletePhotoSuccess,
              "success"
            );
          })
          .catch(() => {
            showAlert(dictionary.venues.venueForm.deletePhotoError, "error");
          });
  };
  const handleMainPhotoChange = () => {
    if (!venueId) {
      return showAlert(
        dictionary.venues.venueForm.changeMainPhotoNoVenueIdError,
        "error"
      );
    }
    onChangeMainPhotoPromotion
      ? onChangeMainPhotoPromotion(id)
      : changeMainPhoto()
          .then(() => {
            dispatch(changeMainPhotoVenue(id));
            showAlert(
              dictionary.venues.venueForm.changeMainPhotoSuccess,
              "success"
            );
          })
          .catch(() => {
            showAlert(
              dictionary.venues.venueForm.changeMainPhotoError,
              "error"
            );
          });
  };

  return (
    <div key={id}>
      {isConfirmDialogOpen && (
        <ConfirmDialog
          open={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          onConfirm={handleDeletePhoto}
          type={ConfirmModalType.DELETE}
          message={dictionary.venues.venueForm.deletePhotoConfirmationMessage}
        />
      )}
      <div className={classes.slideWrapper}>
        <IconButton
          className={classes.removeButton}
          onClick={() => setIsConfirmDialogOpen(true)}>
          <RemoveIcon />
        </IconButton>
        <PrimaryPhotoRadio
          handleMainPhotoChange={handleMainPhotoChange}
          isMainPhoto={!!isMainPhoto}
        />
        {!active && type === "venue" && (
          <div className={classes.warningIconContainer}>
            <OcietyTooltip
              title={
                superAdmin || manager
                  ? dictionary.venues.venueForm.pendingTooltipSuperAdmin
                  : dictionary.venues.venueForm.pendingTooltipVenueAdmin
              }>
              <WarningIcon className={classes.warningIcon} />
            </OcietyTooltip>
          </div>
        )}
        <img className={classes.slideImage} src={url} alt={"venue"} />
      </div>
    </div>
  );
};

export default VenueMediaCarouselPhotoSlide;
