export enum PackageUID {
  BASIC = "BASIC",
  CUSTOMER = "CUSTOMER",
  PROMOTIONS = "PROMOTIONS",
  RECOMMENDATIONS = "RECOMMENDATIONS",
  PROFILE_UPD = "PROFILE_UPD",
  FULL_ACCESS = "FULL_ACCESS",
}

export const CURRENT_VENUE_SET_CURRENT_VENUE =
  "CURRENT_VENUE.SET_CURRENT_VENUE";
export const CURRENT_VENUE_GET_OWN_VENUES_PENDING =
  "CURRENT_VENUE.GET_OWN_VENUES.PENDING";
export const CURRENT_VENUE_GET_OWN_VENUES_FULFILLED =
  "CURRENT_VENUE.GET_OWN_VENUES.FULFILLED";
export const CURRENT_VENUE_GET_OWN_VENUES_REJECTED =
  "CURRENT_VENUE.GET_OWN_VENUES.REJECTED";

export interface GetOwnVenuesResponse {
  venues: OwnVenue[];
}

export interface OwnVenue {
  name: string;
  id: number;
  isActive: boolean;
  packagesUid: PackageUID[];
}

export interface SetCurrentVenueAction {
  type: typeof CURRENT_VENUE_SET_CURRENT_VENUE;
  venue?: OwnVenue;
}

export interface GetOwnVenuesSuccessAction {
  type: typeof CURRENT_VENUE_GET_OWN_VENUES_FULFILLED;
  venues: OwnVenue[];
}

export interface GetOwnVenuesFailAction {
  type: typeof CURRENT_VENUE_GET_OWN_VENUES_REJECTED;
  error: string;
}

export interface GetOwnVenuesPendingAction {
  type: typeof CURRENT_VENUE_GET_OWN_VENUES_PENDING;
}

export type CurrentVenueActionTypes =
  | SetCurrentVenueAction
  | GetOwnVenuesSuccessAction
  | GetOwnVenuesFailAction
  | GetOwnVenuesPendingAction;
