import {
  Button,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

// eslint-disable-next-line max-len
import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import ButtonSuccess from "../../../../../../common/components/ButtonSuccess";
// eslint-disable-next-line max-len
import CountryAutocompleteSearch from "../../../../../../common/components/forms/CountryAutocompleteSearch";
// eslint-disable-next-line max-len
import StateAutocompleteSearch from "../../../../../../common/components/forms/StateAutocompleteSearch";
// eslint-disable-next-line max-len
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import MultipleSelect from "../../../../../../common/components/MultipleSelect";
import Option from "../../../../../../common/components/MultipleSelect/Option";
import OcietyIcon from "../../../../../../common/components/OcietyIcon";
// eslint-disable-next-line max-len
import OcietyTooltip from "../../../../../../common/components/OcietyTooltip";
import { priceDisplayValues } from "../../../../../../common/const";
import { useIsManager } from "../../../../../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../../../../../common/hooks/useIsSuperAdmin";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import {
  VenuePendingChanges,
  VenueType,
} from "../../../../../../common/models";
import { ReactComponent as WarningIcon } from "../../../../../../common/svg/icons/warning-circle.svg";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../../store/currentVenue/types";
import {
  setAndValidate,
  setFormError,
  validateForm,
} from "../../../../../../store/forms/actions";
import { FormFieldKey } from "../../../../../../store/forms/types";
import { AppState } from "../../../../../../store/rootReducer";
import {
  validateLatitude,
  validateLongitude,
  validateWebsite,
} from "../../../../../../utils/validation";
import { saveVenue } from "../../../../ducks/actions";
import { getVenueTypes } from "../../api";
import VenueFormHelper from "../../form/helpers/VenueFormHelper";
import { venueFormSchema as schema } from "../../form/VenueFormSchema";
import Props from "../../Props";
import Holidays from "../Holidays/components/HolidaysDialog";
import WorkingHoursDialog from "../WorkingHours/components/WorkingHoursDialog";
import { useStyles } from "./styles";

const VenueCommonDataForm: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { holidays, setHolidays } = props;
  const dispatch = useDispatch();
  const [workingHoursDialogOpen, setWorkingHoursDialogOpen] = useState(false);
  const [holidaysDialogOpen, setHolidaysDialogOpen] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const { forms } = useSelector((state: AppState) => state);
  const form = forms.venue;
  const superAdmin = useIsSuperAdmin();
  const manager = useIsManager();
  const hasPackage = useVenueHasPackage(PackageUID.PROFILE_UPD);
  const focusOnError = useRef<HTMLDivElement>(null);

  const { venue, loading } = useSelector((state: AppState) => state.venue);
  const defaultTypesState: { bar: VenueType[]; restaurant: VenueType[] } = {
    bar: [],
    restaurant: [],
  };
  const [types, setTypes] = useState(defaultTypesState);

  const openWorkingHoursDialog = () => {
    setWorkingHoursDialogOpen(true);
  };

  const openHolidaysDialog = () => {
    setHolidaysDialogOpen(true);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const fieldName: FormFieldKey = event.target.name;
    const fieldValue: FormFieldKey = event.target.value;

    dispatch(setAndValidate("venue", fieldName, fieldValue, schema));

    // because there is no build-in validation for coordinates in redux forms
    if (fieldName === "latitude") {
      validateLatitude(fieldValue, (error: string) => {
        dispatch(setFormError("venue", "latitude", "Latitude " + error));
      });
    } else if (fieldName === "longitude") {
      validateLongitude(fieldValue, (error: string) => {
        dispatch(setFormError("venue", "longitude", "Longitude " + error));
      });
    }
    // not to get an error when this field is empty and it's checked for being a url despite the fact it is optional
    if (fieldName === "website") {
      validateWebsite(fieldValue, (error: string) => {
        dispatch(setFormError("venue", "website", "Website " + error));
      });
    }
  };

  const handleChangeFieldValue = useCallback(
    (fieldName: string, value: string | number) => {
      dispatch(
        setAndValidate("venue", fieldName as FormFieldKey, value, schema)
      );
    },
    [dispatch]
  );

  const validateTypes = () =>
    Boolean(form.values.bar || form.values.restaurant);

  const checkFieldErrorIndex = (field: string) => {
    const errorsFieldsArr = Object.keys(form.errors);
    return field === errorsFieldsArr[0];
  };

  const handleSave = () => {
    if (!form.isValid) {
      focusOnError?.current?.focus();
    }

    if (!dispatch(validateForm("venue", schema))) {
      return;
    }

    if (!validateTypes()) {
      return showAlert(
        dictionary.venues.venueForm.typesValidationError,
        "error"
      );
    }

    if (venue) {
      dispatch(saveVenue(VenueFormHelper.saveMap(venue.id, form), showAlert));
    }
  };

  const hasSubtype = (subtype: string): boolean => !!subtype.length;
  const isSubtypesRequired = (): boolean => {
    if (form.values.bar && form.values.restaurant) {
      return (
        !hasSubtype(form.values.bar) && !hasSubtype(form.values.restaurant)
      );
    }

    return false;
  };

  const handleChangeCountry = (country?: string) => {
    handleChangeFieldValue("country", country || "");
  };

  const handleChangeState = useCallback(
    (state?: string) => {
      handleChangeFieldValue("state", state || "");
    },
    [handleChangeFieldValue]
  );

  const withTooltip = (
    fieldElement: ReactElement,
    fieldName: keyof VenuePendingChanges
  ) => (
    <FormControl
      className={clsx(
        classes.formControl,
        !!venue?.pendingChanges?.[fieldName] && classes.formControlWithTooltip
      )}>
      {fieldElement}
      {!!venue?.pendingChanges?.[fieldName] && (
        <OcietyTooltip
          title={
            superAdmin || manager
              ? dictionary.venues.venueForm.pendingTooltipSuperAdmin
              : dictionary.venues.venueForm.pendingTooltipVenueAdmin
          }>
          <WarningIcon />
        </OcietyTooltip>
      )}
    </FormControl>
  );

  useEffect(() => {
    getVenueTypes().then((resp) => {
      if (resp && resp.bar && resp.restaurant) {
        setTypes(resp);
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant={"h3"}>
        {dictionary.venues.venueForm.commonInfoTitle}
      </Typography>
      {loading && <LoadingIndicator withMask />}
      <Grid container spacing={4}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          {withTooltip(
            <TextField
              fullWidth
              required
              label={dictionary.venues.venueForm.labelName}
              name="name"
              error={!!form.errors.name}
              helperText={form.errors.name}
              onChange={handleChange}
              type="text"
              value={form.values.name || ""}
              inputRef={
                form.errors.name && checkFieldErrorIndex("name")
                  ? focusOnError
                  : null
              }
              variant="outlined"
            />,
            "name"
          )}
        </Grid>
        <Hidden smDown>
          <Grid item lg={4} md={4} sm={6} xs={12} />
        </Hidden>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              multiline={true}
              rows={4}
              label={dictionary.venues.venueForm.labelDescription}
              name="description"
              error={!!form.errors.description}
              helperText={form.errors.description}
              onChange={handleChange}
              type="text"
              value={form.values.description || ""}
              inputRef={
                form.errors.description && checkFieldErrorIndex("description")
                  ? focusOnError
                  : null
              }
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item lg={8} md={8} sm={6} xs={12}>
          {withTooltip(
            <TextField
              fullWidth
              required
              label={dictionary.venues.venueForm.labelAddress}
              name="address"
              error={!!form.errors.address}
              helperText={form.errors.address}
              onChange={handleChange}
              type="text"
              value={form.values.address || ""}
              inputRef={
                form.errors.address && checkFieldErrorIndex("address")
                  ? focusOnError
                  : null
              }
              variant="outlined"
            />,
            "address"
          )}
        </Grid>
        <Grid item lg={8} md={8} sm={6} xs={12}>
          {withTooltip(
            <TextField
              fullWidth
              label={dictionary.venues.venueForm.labelAddress2}
              name="address2"
              error={!!form.errors.address2}
              helperText={form.errors.address2}
              onChange={handleChange}
              type="text"
              value={form.values.address2 || ""}
              variant="outlined"
            />,
            "address2"
          )}
        </Grid>
        <Hidden smDown>
          <Grid item lg={4} md={4} />
        </Hidden>
        {/*<Grid item lg={4} md={4} sm={4} xs={12}>
          {withTooltip(
            <CountryAutocompleteSearch
              fullWidth
              country={form.values.country}
              error={form.errors.country}
              onChange={handleChangeCountry}
              label={dictionary.venues.venueForm.labelCountry}
              ref={
                form.errors.country && checkFieldErrorIndex("country")
                  ? focusOnError
                  : null
              }
            />,
            "country"
          )}
        </Grid>*/}
        <Grid item lg={4} md={4} sm={4} xs={12}>
          {withTooltip(
            <StateAutocompleteSearch
              fullWidth
              state={form.values.state}
              country={form.values.country}
              error={form.errors.state}
              onChange={handleChangeState}
              label={dictionary.venues.venueForm.labelState}
              ref={
                form.errors.state && checkFieldErrorIndex("state")
                  ? focusOnError
                  : null
              }
            />,
            "state"
          )}
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          {withTooltip(
            <TextField
              fullWidth
              required
              label={dictionary.venues.venueForm.labelCity}
              name="city"
              error={!!form.errors.city}
              helperText={form.errors.city}
              onChange={handleChange}
              type="text"
              value={form.values.city || ""}
              inputRef={
                form.errors.city && checkFieldErrorIndex("city")
                  ? focusOnError
                  : null
              }
              variant="outlined"
            />,
            "city"
          )}
        </Grid>
        <Hidden smDown>
          <Grid item lg={4} md={4} />
        </Hidden>
        {(superAdmin || manager) && (
          <>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  required
                  label={dictionary.venuesPending.addForm.latitude}
                  name="latitude"
                  error={!!form.errors.latitude}
                  helperText={form.errors.latitude}
                  onChange={handleChange}
                  type="text"
                  value={form.values.latitude || ""}
                  inputRef={
                    form.errors.latitude && checkFieldErrorIndex("latitude")
                      ? focusOnError
                      : null
                  }
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  required
                  label={dictionary.venuesPending.addForm.longitude}
                  name="longitude"
                  error={!!form.errors.longitude}
                  helperText={form.errors.longitude}
                  onChange={handleChange}
                  type="text"
                  value={form.values.longitude || ""}
                  inputRef={
                    form.errors.longitude && checkFieldErrorIndex("longitude")
                      ? focusOnError
                      : null
                  }
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Hidden smDown>
              <Grid item lg={4} md={4} />
            </Hidden>
          </>
        )}
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <FormControl className={classes.formControl}>
            <Tooltip
              disableHoverListener={hasPackage || superAdmin}
              disableFocusListener={hasPackage || superAdmin}
              title={dictionary.plan.toAccessFieldPurchasePackageTooltip}
              placement="top">
              {withTooltip(
                <TextField
                  fullWidth
                  disabled={!(hasPackage || superAdmin)}
                  label={dictionary.venuesPending.addForm.website}
                  name="website"
                  error={!!form.errors.website}
                  helperText={form.errors.website}
                  onChange={handleChange}
                  type="text"
                  value={form.values.website || ""}
                  inputRef={
                    form.errors.website && checkFieldErrorIndex("website")
                      ? focusOnError
                      : null
                  }
                  variant="outlined"
                />,
                "website"
              )}
            </Tooltip>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <FormControl
            className={classes.formControl}
            variant={"outlined"}
            required>
            <InputLabel id="price_range_select_label">
              {dictionary.venues.venueForm.labelPrice}
            </InputLabel>
            <Select
              id="price_range_select"
              value={form.values.price || ""}
              labelId={"price_range_select_label"}
              label={dictionary.venues.venueForm.labelPrice}
              onChange={(e: any) => {
                handleChangeFieldValue("price", e.target.value);
              }}>
              <MenuItem value="" disabled selected>
                <em>{dictionary.venues.venueForm.labelPrice}</em>
              </MenuItem>
              {priceDisplayValues.map(({ value, display }) => (
                <MenuItem key={value} value={value}>
                  {display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} xl={4} sm={6} xs={12}>
              <Button
                fullWidth
                className={classes.buttonBlack}
                onClick={openWorkingHoursDialog}>
                <OcietyIcon
                  className={classes.buttonBlackIcon}
                  name={"clock"}
                  width={"22px"}
                  height={"22px"}
                  viewBox={"0 0 30 30"}
                />
                {dictionary.venues.venueForm.workingHoursChangeButton}
                <OcietyIcon
                  className={classes.smallArrowIcon}
                  name={"arrowRightSmall"}
                  width={"12px"}
                  height={"12px"}
                  viewBox={"0 0 12 12"}
                />
              </Button>
            </Grid>
            <Grid item lg={4} md={4} xl={4} sm={6} xs={12}>
              <Button
                fullWidth
                className={classes.buttonBlack}
                onClick={openHolidaysDialog}>
                <OcietyIcon
                  className={classes.buttonBlackIcon}
                  name={"calendar"}
                  width={"24px"}
                  height={"24px"}
                  viewBox={"0 0 18 18"}
                />
                {dictionary.venues.venueForm.holidaysChangeButton}
                <OcietyIcon
                  className={classes.smallArrowIcon}
                  name={"arrowRightSmall"}
                  width={"12px"}
                  height={"12px"}
                  viewBox={"0 0 12 12"}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <FormControl
                className={classes.formControl}
                variant={"outlined"}
                required>
                <MultipleSelect
                  error={form.errors.bar}
                  required={isSubtypesRequired()}
                  onSelected={(values) => {
                    handleChangeFieldValue("bar", values.join(","));
                  }}
                  options={types.bar.map(
                    (type: VenueType) =>
                      new Option(String(type.id), type.subtype)
                  )}
                  selected={form.values.bar ? form.values.bar.split(",") : []}
                  type="bar"
                  label={dictionary.venues.venueForm.labelBarTypes}
                  helperText={form.errors.bar}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <FormControl
                className={classes.formControl}
                variant={"outlined"}
                required>
                <MultipleSelect
                  error={form.errors.restaurant}
                  required={isSubtypesRequired()}
                  onSelected={(values) => {
                    handleChangeFieldValue("restaurant", values.join(","));
                  }}
                  options={types.restaurant.map(
                    (type: VenueType) =>
                      new Option(String(type.id), type.subtype)
                  )}
                  selected={
                    form.values.restaurant
                      ? form.values.restaurant.split(",")
                      : []
                  }
                  type="restaraunt"
                  label={dictionary.venues.venueForm.labelRestaurantTypes}
                  helperText={form.errors.restaurant}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.actionsWrapper}>
        <ButtonSuccess onClick={handleSave}>
          {dictionary.venues.venueForm.btnSave}
        </ButtonSuccess>
      </div>
      <WorkingHoursDialog
        open={workingHoursDialogOpen}
        onClose={() => {
          setWorkingHoursDialogOpen(false);
        }}
      />
      <Holidays
        open={holidaysDialogOpen}
        onClose={() => {
          setHolidaysDialogOpen(false);
        }}
        setHolidays={setHolidays}
        holidays={holidays}
      />
    </div>
  );
};

export default VenueCommonDataForm;
