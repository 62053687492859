import {
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Radio,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import ActionsDropdown from "../../../../../../common/components/ActionsDropdown";
import { ActionItemProps } from "../../../../../../common/components/ActionsDropdown/Props";
import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import OcietyIcon from "../../../../../../common/components/OcietyIcon";
import OcietySplitButton from "../../../../../../common/components/OcietySplitButton";
import { Color } from "../../../../../../common/components/OcietySplitButton/enums";
import IOptionBtn from "../../../../../../common/components/OcietySplitButton/IOptionBtn";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import { Route } from "../../../../../../config/router";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { UPDATE_VENUE_MAIN_PROMOTION } from "../../../../../Venues/api";
import { DiscountStatus } from "../../../models/DiscountStatus";
import PromotionActiveDaysAndHours from "../PromotionActiveDaysAndHours";
import PromotionImage from "../PromotionImage";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionsGridRow: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    promotion,
    onChangeStatus,
    onEditClick,
    onDeleteClick,
    onDuplicateClick,
    isSuperAdminGrid,
    refresh,
  } = props;
  const history = useHistory();
  const { showAlert } = useContext(AlertContext);
  const [isActiveHoursOpen, setIsActiveHoursOpen] = useState(false);

  const [{ loading }, updateMainPromotion] = useRestApi<any>(
    UPDATE_VENUE_MAIN_PROMOTION(promotion.venueId, promotion.id),
    "PATCH"
  );
  const actions: ActionItemProps[] = [
    {
      iconName: "listAction",
      label: "Promo codes",
      onClick: () => {
        history.push(
          Route.PromotionsUsages.replace(":id", promotion.id.toString())
        );
      },
    },
    {
      iconName: "duplicateAction",
      label: "Duplicate",
      onClick: () => {
        onDuplicateClick(promotion.id);
      },
    },
  ];

  if (promotion.status !== DiscountStatus.EXPIRED) {
    actions.unshift({
      iconName: "editAction",
      label: "Edit",
      onClick: () => onEditClick(promotion.id),
    });
  }

  if (!promotion.views && !promotion.usages) {
    actions.push({
      iconName: "removeAction",
      label: "Remove",
      onClick: () => onDeleteClick(promotion.id),
    });
  }

  const options: IOptionBtn[] = [
    {
      label: "Pending",
      value: DiscountStatus.PENDING,
      color: Color.YELLOW,
    },
    {
      label: "Enabled",
      value: DiscountStatus.ENABLED,
      color: Color.GREEN,
    },
    {
      label: "Paused",
      value: DiscountStatus.PAUSE,
      color: Color.BLACK,
    },
    {
      label: "Expired",
      value: DiscountStatus.EXPIRED,
      color: Color.BLUE,
    },
    {
      label: "Cancelled",
      value: DiscountStatus.CANCELLED,
      color: Color.RED,
    },
  ];

  const getOptions = () => {
    if (promotion.status === DiscountStatus.EXPIRED) {
      return options.filter((o) => o.value === DiscountStatus.EXPIRED);
    }

    return options;
  };

  const onChangeValue = (status: DiscountStatus): void => {
    onChangeStatus(promotion.id, status, promotion.venueId);
  };

  const printDate = (date: Date): string => {
    return moment(date).format("MM/DD/yyyy");
  };

  const printTime = (time: string) => {
    const hour = time.slice(0, 2);
    const isDay = parseInt(hour) > 12;
    return time.slice(0, 5) + dictionary.promotions.formatTime(isDay);
  };

  const onMainPromotionChange = async () => {
    try {
      if (await updateMainPromotion()) {
        refresh();
      }
    } catch (e) {
      showAlert(dictionary.promotions.changeMainPromotonError, "error");
    }
  };

  const activeHours = () => {
    const todayDate = moment().format("dddd");
    const activeTodayHours = promotion.daysHours.find(
      (activeDay) => activeDay.day === todayDate
    );
    return activeTodayHours?.isActive
      ? `${printTime(activeTodayHours.start)}-${printTime(
          activeTodayHours.end
        )}`
      : "";
  };

  const handleOpenActiveHoursModal = () => {
    setIsActiveHoursOpen((prev) => !prev);
  };

  return (
    <Grid container xs={12} component={Paper} className={classes.root}>
      {loading && <LoadingIndicator withMask />}
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemFirst,
          classes.rowItem2
        )}>
        <PromotionImage
          categoryName={promotion.mainCategoryName}
          image={promotion.img}
        />
      </Grid>
      <Grid item className={clsx(classes.rowItemName, classes.rowItem2)}>
        <Typography
          variant={"body2"}
          className={clsx(classes.rowItemText, classes.nameText)}>
          {promotion.name}
        </Typography>
      </Grid>
      {!isSuperAdminGrid && (
        <Grid
          item
          className={clsx(
            classes.rowItem,
            classes.rowItemPrimary,
            classes.rowItemCenter,
            classes.rowItem1
          )}>
          <Typography variant={"body2"} className={classes.text}>
            <FormControlLabel
              disabled={promotion.status !== DiscountStatus.ENABLED}
              checked={promotion.isMainPromotion}
              onChange={onMainPromotionChange}
              value={promotion.isMainPromotion}
              key={promotion.id}
              className={classes.primaryText}
              control={<Radio />}
              label=""
            />
          </Typography>
        </Grid>
      )}
      {isSuperAdminGrid && (
        <Grid item className={clsx(classes.rowItemName, classes.rowItem1)}>
          <Typography variant={"body2"} className={classes.rowItemName}>
            {promotion.venueName}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem1
        )}>
        <Typography variant={"body2"} className={classes.text}>
          {promotion.views}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem1
        )}>
        <Typography variant={"body2"} className={classes.text}>
          {promotion.usages}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem2
        )}>
        <div className={classes.activeHoursWrapper}>
          <Typography variant={"body2"} className={classes.text}>
            {activeHours()}
          </Typography>
          <Link
            variant="body2"
            component="button"
            underline="always"
            onClick={handleOpenActiveHoursModal}
            className={classes.link}>
            Active Days and Hours
          </Link>
        </div>
        <PromotionActiveDaysAndHours
          open={isActiveHoursOpen}
          onClose={handleOpenActiveHoursModal}
          activeDaysAndHours={promotion.daysHours}
          printTime={printTime}
        />
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem1
        )}>
        <Typography variant={"body2"} className={classes.text}>
          {printDate(promotion.start)}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem2
        )}>
        <Typography variant={"body2"} className={classes.text}>
          {printDate(promotion.end)}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemStatus,
          classes.rowItemCenter,
          classes.rowItem2
        )}>
        <Typography variant={"body2"}>
          <OcietySplitButton
            options={getOptions()}
            onChangeValue={onChangeValue}
            value={promotion.status}
            isExpired={printDate(promotion.end) < printDate(new Date())}
          />
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem1
        )}>
        {isSuperAdminGrid && (
          <OcietyIcon
            onClick={() => alert("Not implemented")}
            name={"mailBlue"}
            width="22"
            height="22"
            viewBox="0 0 22 22"
          />
        )}
        {!isSuperAdminGrid && <ActionsDropdown items={actions} />}
      </Grid>
    </Grid>
  );
};
export default PromotionsGridRow;
