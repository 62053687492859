import { Grid } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect, useRef } from "react";

import { FileUrlWithId } from "../../../../../../../../../common/models";
import { PromotionFormDialogContext } from "../../../../PromotionFormDialogContextProvider";
import FileUploaderPromotion from "./components/FileUploaderPromotion";
import { useStyles } from "./styles";

const BasicInfoPromotionStep: FC = () => {
  const classes = useStyles();

  const focusOnError = useRef<HTMLDivElement>(null);
  const {
    basicForm,
    photosWithIds,
    setPhotosWithIds,
    videosWithIds,
    setVideosWithIds,
  } = useContext(PromotionFormDialogContext);
  const { form, setAndValidate } = basicForm;

  useEffect(() => {
    if (!basicForm.form.isValid) {
      focusOnError?.current?.focus();
    }
  }, [basicForm.form.isValid]);

  useEffect(() => {
    const photos = basicForm.form.values.promotionPhotos.length
      ? JSON.parse(basicForm.form.values.promotionPhotos)
      : ([] as FileUrlWithId[]);
    setPhotosWithIds(photos);
  }, [
    setPhotosWithIds,
    basicForm.form.values.promotionPhotos,
    basicForm.form.values.mainPhotoId,
  ]);

  useEffect(() => {
    setVideosWithIds(
      basicForm.form.values.promotionVideos.length
        ? JSON.parse(basicForm.form.values.promotionVideos)
        : ([] as FileUrlWithId[])
    );
  }, [setVideosWithIds, basicForm.form.values.promotionVideos]);

  const pushVideoToString = (
    stringifiedArray: string,
    newItem: FileUrlWithId
  ) => {
    const itemsArray: FileUrlWithId[] = stringifiedArray.length
      ? JSON.parse(stringifiedArray)
      : [];
    itemsArray.push(newItem);
    return JSON.stringify(itemsArray);
  };

  const pushPhotoToString = (
    stringifiedArray: string,
    newItem: FileUrlWithId,
    mainPhotoId: number
  ) => {
    const itemsArray: FileUrlWithId[] = stringifiedArray.length
      ? JSON.parse(stringifiedArray)
      : [];
    if (!mainPhotoId) {
      itemsArray.push(Object.assign(newItem, { isMainPhoto: true }));
    } else {
      itemsArray.push(Object.assign(newItem, { isMainPhoto: false }));
    }
    return JSON.stringify(itemsArray);
  };

  const removePhotoFromString = (
    stringifiedArray: string,
    itemToRemoveID: number,
    newMainPhotoId: number | null
  ) => {
    let itemsArray: FileUrlWithId[] = stringifiedArray.length
      ? JSON.parse(stringifiedArray)
      : [];
    itemsArray = itemsArray.filter(({ id }) => id !== itemToRemoveID);

    if (newMainPhotoId) {
      itemsArray = itemsArray.map((item) => {
        item.isMainPhoto = item.id === newMainPhotoId;
        return item;
      });
    }
    return JSON.stringify(itemsArray);
  };

  const removeVideoFromString = (
    stringifiedArray: string,
    itemToRemoveID: number
  ) => {
    let itemsArray: FileUrlWithId[] = stringifiedArray.length
      ? JSON.parse(stringifiedArray)
      : [];
    itemsArray = itemsArray.filter(({ id }) => id !== itemToRemoveID);
    return JSON.stringify(itemsArray);
  };

  const updateMainPhotoIdInString = (
    stringifiedData: string,
    mainPhotoId: number
  ) => {
    return JSON.stringify(mainPhotoId);
  };

  const updateItemInString = (stringifiedArray: string, itemId: number) => {
    const itemsArray: FileUrlWithId[] = stringifiedArray.length
      ? JSON.parse(stringifiedArray)
      : [];

    return JSON.stringify(
      itemsArray.map((item) => {
        item.isMainPhoto = item.id === itemId;
        return item;
      })
    );
  };

  const handleOnPhotoDelete = (photoId: number) => {
    const photos = JSON.parse(basicForm.form.values.promotionPhotos);
    const currentMainPhotoId = Number(basicForm.form.values.mainPhotoId);
    if (photos.length > 1 && photoId === currentMainPhotoId) {
      const newMainPhoto = photos.find(
        (item: { id: number }) => item.id !== photoId
      );
      if (newMainPhoto) {
        setMainPhotoId(newMainPhoto.id);
      }
      removePhoto(photoId, newMainPhoto.id);
    } else {
      removePhoto(photoId, null);
    }
  };

  const removePhoto = useCallback(
    (id: number, newMainPhotoId: number | null) => {
      setAndValidate(
        "promotionPhotos",
        removePhotoFromString(
          basicForm.form.values.promotionPhotos,
          id,
          newMainPhotoId
        )
      );
    },
    [basicForm.form.values.promotionPhotos, setAndValidate]
  );

  const handleOnVideoDelete = useCallback(
    (id: number) => {
      return setAndValidate(
        "promotionVideos",
        removeVideoFromString(basicForm.form.values.promotionVideos, id)
      );
    },
    [basicForm.form.values.promotionVideos, setAndValidate]
  );

  const handleOnPhotoUpload = (photoWithId: FileUrlWithId) => {
    const mainPhotoId = basicForm.form.values.mainPhotoId
      ? Number(basicForm.form.values.mainPhotoId)
      : basicForm.form.values.mainPhotoId;
    addPhoto(photoWithId, mainPhotoId);
    if (!mainPhotoId) {
      setMainPhotoId(photoWithId.id);
    }
  };

  const addPhoto = useCallback(
    (photoWithId: FileUrlWithId, mainPhotoId) => {
      setAndValidate(
        "promotionPhotos",
        pushPhotoToString(
          basicForm.form.values.promotionPhotos,
          photoWithId,
          mainPhotoId
        )
      );
    },
    [basicForm.form.values.promotionPhotos, setAndValidate]
  );

  const handleOnMainPhotoChange = (photoId: number) => {
    setMainPhotoId(photoId);
    setPhotoIsMain(photoId);
  };
  const setMainPhotoId = useCallback(
    (photoId: number) => {
      setAndValidate(
        "mainPhotoId",
        updateMainPhotoIdInString(basicForm.form.values.mainPhotoId, photoId)
      );
    },
    [basicForm.form.values.mainPhotoId, setAndValidate]
  );
  const setPhotoIsMain = useCallback(
    (photoId: number) => {
      setAndValidate(
        "promotionPhotos",
        updateItemInString(basicForm.form.values.promotionPhotos, photoId)
      );
    },
    [basicForm.form.values.promotionPhotos, setAndValidate]
  );

  const handleOnVideoUpload = useCallback(
    (videoWithId: FileUrlWithId) => {
      setAndValidate(
        "promotionVideos",
        pushVideoToString(basicForm.form.values.promotionVideos, videoWithId)
      );
    },
    [basicForm.form.values.promotionVideos, setAndValidate]
  );

  return (
    <>
      <Grid container spacing={4} className={classes.root}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <FileUploaderPromotion
            photosWithIds={photosWithIds}
            videosWithIds={videosWithIds}
            error={!!form.errors.promotionPhotos}
            onPhotoUpload={handleOnPhotoUpload}
            onPhotoDelete={handleOnPhotoDelete}
            onVideoUpload={handleOnVideoUpload}
            onVideoDelete={handleOnVideoDelete}
            onMainPhotoChange={handleOnMainPhotoChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BasicInfoPromotionStep;
