import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { Autocomplete } from "@material-ui/lab";
import React, { ChangeEvent, FC, useRef, useState } from "react";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import TextMessage from "../../../../common/components/TextMessage";
import { VenueAdmin } from "../../../../common/models";
import dictionary from "../../../../i18n/en_US/dictionary";
import { assignVenueToUser } from "../../api";
import { Props } from "./Props";
import { useStyles } from "./styles";

const AssignVenueToUserDialog: FC<Props> = (props: Props) => {
  const { userOptions, venueId, open, setOpen } = props;
  const classes = useStyles();
  const [username, setUsername] = useState<string | undefined>("");
  const [isWaitlistAdmin, setIsWaitlistAdmin] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const timeoutId = useRef<NodeJS.Timeout>();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorMsg("");
    setUsername(event.target.value as string | undefined);
  };

  const handleClose = () => {
    setOpen(false);
    setErrorMsg("");
    setSuccessMsg("");

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  };

  const handleUserAutocompleteChange = (
    event: ChangeEvent<Record<string, any>>,
    admin: VenueAdmin | null
  ) => {
    setErrorMsg("");
    setUsername(admin?.email || "");
  };

  const handleAssignVenue = () => {
    if (!username) {
      return;
    }

    setLoading(true);

    // TODO: Change true to real isWaitlistAdmin value
    assignVenueToUser(venueId, username, true)
      .then(() => {
        setErrorMsg("");
        setSuccessMsg(dictionary.venues.assignVenueToUserForm.successMessage);
        timeoutId.current = setTimeout(() => {
          handleClose();
        }, 1500);
      })
      .catch((error) => {
        if (!error) {
          return;
        }

        const msg = error.data.details
          ? error.data.details[0].message
          : error.data;
        setErrorMsg(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="alert-dialog-title">
        {dictionary.venues.assignVenueToUserForm.title}
      </DialogTitle>
      <DialogContent style={{ minWidth: "400px" }}>
        <FormControl fullWidth variant="outlined">
          <Autocomplete
            id="country-autocomplete"
            options={userOptions}
            getOptionLabel={(admin: VenueAdmin) => admin.email}
            inputValue={username || ""}
            onChange={handleUserAutocompleteChange}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                fullWidth
                label={
                  dictionary.venues.assignVenueToUserForm.selectUserPlaceholder
                }
                name="country"
                onChange={handleChange}
                type="text"
                variant="outlined"
              />
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isWaitlistAdmin}
                onChange={(event) => setIsWaitlistAdmin(event.target.checked)}
                name="isWaitlistAdmin"
                color="primary"
              />
            }
            label={dictionary.venues.assignVenueToUserForm.isWaitlistAdmin}
          />
        </FormControl>
        {!!errorMsg && (
          <TextMessage className={classes.message} type="error">
            {errorMsg}
          </TextMessage>
        )}
        {!!successMsg && (
          <TextMessage className={classes.message} type="success">
            {successMsg}
          </TextMessage>
        )}
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={handleClose}>
          {dictionary.venues.assignVenueToUserForm.cancelButton}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={!!errorMsg || !username}
          onClick={handleAssignVenue}>
          {dictionary.venues.assignVenueToUserForm.assignButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(AssignVenueToUserDialog);
