import { Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";

import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import OcietyIcon from "../../../../common/components/OcietyIcon";
import OcietyNavLink from "../../../../common/components/OcietyNavLink";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { useGlobalStyles } from "../../../../common/styles";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  GET_VENUE_PROMOTIONS_MOST_POPULAR_PROMOTION,
  MostPopularPromotionResponse,
} from "../../models";
import Props from "./Props";
import { useStyles } from "./styles";

const MostPopularPromotion: FC<Props> = (props: Props) => {
  const { period, venueId } = props;
  const [{ data, loading }, setAsyncState] =
    useRestApi<MostPopularPromotionResponse>(
      GET_VENUE_PROMOTIONS_MOST_POPULAR_PROMOTION(venueId, period),
      "GET"
    );
  const discountName = data?.promotion?.name;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();

  const handleClickCreatePromotion = () => {
    history.push(Route.Promotions);
  };

  useEffect(() => {
    if (!!venueId) {
      setAsyncState().catch((e) => console.error(e));
    }
  }, [setAsyncState, venueId]);

  if (!discountName) {
    return (
      <Paper>
        <div className={clsx(classes.root, classes.rootEmpty)}>
          {loading && <LoadingIndicator withMask />}
          <div
            className={clsx(
              classes.content,
              globalClasses.flexRowBetweenCenter
            )}>
            <div className={classes.contentTextWrapper}>
              <Typography className={classes.title} variant={"h3"}>
                {dictionary.dashboard.popularPromotionTitle}
              </Typography>
              <Typography className={classes.emptyPromotionText}>
                {dictionary.dashboard.popularPromotionEmpty}
              </Typography>
            </div>
            <ButtonPrimary onClick={handleClickCreatePromotion}>
              {dictionary.dashboard.createPromotionText}
            </ButtonPrimary>
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <OcietyNavLink to={Route.Promotions} noEffects>
      <div className={classes.root}>
        {loading && <LoadingIndicator withMask />}
        <OcietyIcon
          className={classes.icon}
          name={"arrowRightWithBackground"}
          viewBox={"0 0 40 32"}
        />
        <div className={classes.content}>
          <Typography className={classes.title} variant={"h3"}>
            {dictionary.dashboard.popularPromotionTitle}
          </Typography>
          <Typography className={classes.promotionName}>
            {discountName}
          </Typography>
        </div>
      </div>
    </OcietyNavLink>
  );
};

export default MostPopularPromotion;
