import { useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React, { FC } from "react";

import ArrowButton from "../../../../../../common/components/ArrowButton";
import Props from "./Props";
import { useStyles } from "./styles";

const PaginationBtnActions: FC<Props> = (props: Props) => {
  const { count, page, rowsPerPage, onChangePage } = props;
  const classes = useStyles();
  const theme = useTheme();

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page + 1);
  };

  if (rowsPerPage >= count) {
    return null;
  }

  if (count === 0) {
    return null;
  }
  return (
    <div className={classes.root}>
      <ArrowButton
        className={classes.leftBtn}
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
        style={{
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
        }}>
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </ArrowButton>
      <ArrowButton
        className={classes.rightBtn}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="next page">
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </ArrowButton>
    </div>
  );
};
export default PaginationBtnActions;
