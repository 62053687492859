import {
  AppBar,
  Divider,
  Hidden,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useState } from "react";
import { withRouter } from "react-router-dom";

import MiniCart from "../../../views/Cart/components/MiniCart";
import MiniCartButton from "../../../views/Cart/components/MiniCartButton";
import PromotionsTopbar from "../../../views/ManagerPromotions/Topbar/PromotionsTopbar";
import { useIsDiscountAdmin } from "../../hooks/useIsDiscountAdmin";
import { useIsManager } from "../../hooks/useIsManager";
import { useIsSuperAdmin } from "../../hooks/useIsSuperAdmin";
import AccountDropdown from "../AccountDropdown";
import HelpDropdown from "../HelpDropdown";
import NotificationsDropdown from "../NotificationsDropdown";
import OcietyIcon from "../OcietyIcon";
import VenueSelect from "../VenueSelect";
import Props from "./Props";
import { useStyles } from "./styles";

export const HEADER_HEIGHT = "92px";

const Topbar: FC<Props> = (props: Props) => {
  const isSuperAdmin = useIsSuperAdmin();
  const isManager = useIsManager();
  const { toggleSidebar, staticContext, ...rest } = props;
  const [isOpenCart, setIsOpenCart] = useState(false);
  const classes = useStyles();

  const discountAdmin = useIsDiscountAdmin();

  const toggleCart = () => {
    setIsOpenCart((prev) => !prev);
  };

  const handleCloseCart = () => {
    setIsOpenCart(false);
  };

  return !discountAdmin ? (
    <AppBar
      {...rest}
      className={clsx(classes.root)}
      color="inherit"
      position="relative">
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            onClick={toggleSidebar}
            className={classes.menuBurger}
            id="menu_burger_button">
            <OcietyIcon name={"burgerMenu"} />
          </IconButton>
        </Hidden>
        {!isSuperAdmin && !isManager && (
          <Hidden mdDown>
            <VenueSelect />
          </Hidden>
        )}
        <div className={classes.actionsWrapper}>
          {!isSuperAdmin && !isManager && (
            <>
              <MiniCartButton toggleCart={toggleCart} />
              <MiniCart isOpen={isOpenCart} onClose={handleCloseCart} />
              <HelpDropdown />
            </>
          )}
          {!isManager && <NotificationsDropdown />}
        </div>
        <Divider orientation="vertical" flexItem />
        <AccountDropdown />
      </Toolbar>
    </AppBar>
  ) : (
    <PromotionsTopbar />
  );
};

export default withRouter(Topbar);
