import { http } from "../../../../../config/api";
import UrlUtils from "../../../../../utils/UrlUtils";
import { IPromotion } from "../../../common/models/IPromotion";
import IPromotionsAllResponse from "../../../VenueAdminPromotions/components/PromotionsList/models/IPromotionsAllResponse";
import IPromotionUsages from "./models/IPromotionUsages";
const USED_PROMOTIONS_ALL = (
  pomotionId: number,
  limit?: number,
  skip?: number
) => {
  const url = `promotions/${pomotionId}/usages`;

  let query = "";
  const queryParams: {
    limit?: number;
    skip?: number;
  } = {};

  if (limit) {
    queryParams.limit = limit;
  }

  if (skip) {
    queryParams.skip = skip;
  }

  // @ts-ignore
  query = "?" + UrlUtils.buildQuery(queryParams);

  return url + query;
};

export default interface IPromotionUsagesResponse {
  usedDiscounts: IPromotionUsages[];
  totalCount: number;
}

export const getUsedPromotionsByPromotionId = async (
  promotionId: number,
  limit?: number,
  skip?: number
): Promise<IPromotionUsagesResponse> =>
  http
    .get(USED_PROMOTIONS_ALL(promotionId, limit, skip))
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
