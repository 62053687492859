import React, { FC, useContext, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartContainer from "../../../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../../../common/components/ChartTooltip";
// eslint-disable-next-line max-len
import { VenueReportingContext } from "../../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import { AnalyticsPeriod } from "../../../../../../common/models";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PromotionsReporting } from "../../../../../../permissions/Reporting/PromotionsReporting";
import { PackageUID } from "../../../../../../store/currentVenue/types";
// eslint-disable-next-line max-len
import {
  chartAdditionalColor,
  chartPrimaryColor,
  chartSecondaryColor,
  colors,
} from "../../../../../../theme/colors";
import { havePermission } from "../../../../../../utils/userUtils";
import {
  BarDefaultProps,
  LegendDefaultProps,
  XAxisDefaultProps,
  YAxisDefaultProps,
} from "../../../../defaultProps";
import { useStyles } from "../../../../styles";
import {
  GET_PROMOTION_USAGES_BY_DEVICE,
  GetPromotionUsagesByDeviceResponse,
  PromotionUsagesDeviceData,
} from "./api";
import { chartDataMock } from "./mock";

const PromotionUsagesByDevice: FC = () => {
  const { period } = useContext(VenueReportingContext);
  const [chartData, setChartData] = useState(chartDataMock);
  const { filters, handlePackageName } = useContext(VenueReportingContext);
  const hasPackage = useVenueHasPackage(PackageUID.PROMOTIONS);
  const haveAccess = havePermission(
    PromotionsReporting.PROMOTION_USAGES_BY_DEVICE
  );
  const { response, loading } =
    useRequestByPeriod<GetPromotionUsagesByDeviceResponse>(
      GET_PROMOTION_USAGES_BY_DEVICE,
      filters,
      hasPackage
    );

  const classes = useStyles();
  const parseChartData = (stats: PromotionUsagesDeviceData[]) => {
    setChartData(
      stats.map((viewsData) => ({
        Android: viewsData.androidPercent,
        AndroidWithPercent: viewsData.androidPercent + " %",
        Iphone: viewsData.iphonePercent,
        IphoneWithPercent: viewsData.iphonePercent + " %",
        month: viewsData.month.slice(0, 3),
      }))
    );
  };

  useEffect(() => {
    if (response) {
      parseChartData(response.deviceStats);
    }
    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [response, hasPackage]);

  const isChartEmpty = !chartData.some(
    (data) => (data.Iphone || data.Android) !== 0
  );

  return (
    <ChartContainer
      loading={loading}
      locked={!hasPackage}
      isChartEmpty={isChartEmpty}
      title={dictionary.reporting.promotionUsagesByDeviceTitle}
      chartType={"bar-with-y-axis-and-legend-bottom"}
      packageName={handlePackageName(PackageUID.PROMOTIONS)}>
      <BarChart data={chartData}>
        <CartesianGrid
          strokeDasharray="3 3"
          strokeOpacity={0.6}
          color={colors.grey.light}
          vertical={false}
        />
        <Legend
          {...LegendDefaultProps}
          wrapperStyle={{
            ...LegendDefaultProps.wrapperStyle,
            textTransform: "none",
          }}
        />
        <Bar
          {...BarDefaultProps}
          dataKey={"Iphone"}
          name={"iPhone"}
          fill={chartPrimaryColor}
          unit={dictionary.reporting.venueViewsByDeviceIphoneUnit}>
          {period < AnalyticsPeriod.LastSixMoths && (
            <LabelList
              dataKey="IphoneWithPercent"
              position="top"
              className={classes.barLabel}
            />
          )}
        </Bar>
        <Bar
          {...BarDefaultProps}
          dataKey={"Android"}
          fill={chartSecondaryColor}
          unit={dictionary.reporting.venueViewsByDeviceAndroidUnit}>
          {period < AnalyticsPeriod.LastSixMoths && (
            <LabelList
              dataKey="AndroidWithPercent"
              position="top"
              className={classes.barLabel}
            />
          )}
        </Bar>
        <YAxis {...YAxisDefaultProps} />
        <XAxis {...XAxisDefaultProps} dataKey="month" />
        <Tooltip
          content={<ChartTooltip />}
          cursor={{
            stroke: chartAdditionalColor,
            strokeWidth: 2,
            opacity: 0.3,
          }}
        />
      </BarChart>
    </ChartContainer>
  );
};

export default PromotionUsagesByDevice;
