import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import { validate, ValidationRules } from "../utils/validation";
import IPromotionDietaryRestriction from "../views/Promotions/VenueAdminPromotions/components/PromotionFormDialog/components/PromotionFormDialogContent/components/MainInfoPromotionStep/models/IPromotionDietaryRestriction";
import { Form } from "./models";

export interface UseFormTypes<S, F> {
  form: F;
  clearErrors: (clearKeys: Array<keyof S>) => void;
  setAndValidate: (key: keyof S, value: string) => void;
  validateForm: () => boolean;
  setFormValue: (key: keyof S, value: string) => void;
  setFormValues: (values: { [key in keyof S]: string | any }) => void;
  setSchemaRules: (newRules: Partial<Record<keyof S, ValidationRules>>) => void;
}

export const usePagination = (isSearching: boolean) => {
  const types = useMemo(
    () =>
      Object.freeze({
        SET_PAGE: "SET_PAGE",
        IS_SEARCHING_TRUE: "IS_SEARCHING_TRUE",
        IS_SEARCHING_FALSE: "IS_SEARCHING_FALSE",
        RESET: "RESET",
      }),
    []
  );

  const [pagination, dispatchPagination] = useReducer(
    (state: any, action: any) => {
      switch (action.type) {
        case types.SET_PAGE:
          if (state.isSearching) {
            return state;
          }
          return {
            currentPage: action.currentPage,
            prevPage: state.currentPage,
          };
        case types.IS_SEARCHING_TRUE:
          return {
            currentPage: 0,
            prevPage: state.currentPage,
            isSearching: true,
          };
        case types.IS_SEARCHING_FALSE:
          return {
            currentPage: state.prevPage,
            prevPage: state.prevPage,
            isSearching: false,
          };
        case types.RESET:
          return {
            currentPage: 0,
            prevPage: null,
            isSearching: false,
          };
        default:
          throw new Error();
      }
    },
    {
      currentPage: 0,
      prevPage: 0,
      isSearching: false,
    }
  );

  useEffect(() => {
    if (isSearching) {
      dispatchPagination({ type: types.IS_SEARCHING_TRUE });
    } else {
      dispatchPagination({ type: types.IS_SEARCHING_FALSE });
    }
  }, [isSearching, types]);

  return [pagination, dispatchPagination, types];
};

/**
 * Get previous state/prop value
 *
 * @param value any
 * @returns {*}
 */
export const usePrevious = (value: any): any => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

/**
 * Hook for forms state store and validation
 *
 * @param primarySchema
 * @param defaultForm
 */
export function useForm<
  S extends Record<string, Record<string, any>>,
  F extends Form
>(primarySchema: S, defaultForm: F): UseFormTypes<S, F> {
  const [form, setForm] = useState(defaultForm);
  const schema = useRef(primarySchema);

  const setValue = useCallback(
    (prevForm: F, key: keyof S, value: string): F => {
      return {
        ...prevForm,
        values: {
          ...prevForm.values,
          [key]: value,
        },
      };
    },
    []
  );

  const setValues = useCallback(
    (prevForm: F, values: { [key in keyof S]: string }): F => {
      return {
        ...prevForm,
        values: {
          ...prevForm.values,
          ...values,
        },
      };
    },
    []
  );

  const validateField = useCallback(
    (key: keyof S, value: string, newForm: F): F => {
      const error: string = validate(key, value, schema.current, newForm);

      newForm = {
        ...newForm,
        isValid: false,
        errors: {
          ...newForm.errors,
          [key]: error,
        },
      };

      newForm.isValid = !Object.values(newForm.errors).some(
        (err: string) => !!err
      );

      return newForm;
    },
    []
  );

  const clearErrors = useCallback(
    (clearErrorKeys: Array<keyof S>) => {
      const newErrors = Object.keys(form.errors)
        .filter((err) => {
          !clearErrorKeys.includes(err);
        })
        .reduce((objErrors, key: string) => {
          return {
            ...objErrors,
            [key]: newForm.errors[key],
          };
        }, {});

      const newForm: F = {
        ...form,
        errors: newErrors,
      };

      setForm(newForm);
    },
    [form]
  );

  const validateForm = useCallback((): boolean => {
    const newForm = { ...form };
    const values = form.values;

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        newForm.errors[key] = validate(
          key,
          values[key],
          schema.current,
          newForm
        );
      }
    }

    newForm.isValid = !Object.values(newForm.errors).some(
      (err: string) => !!err
    );

    setForm(newForm);

    return newForm.isValid;
  }, [schema, form]);

  const setAndValidate = useCallback(
    (key: keyof S, value: string) => {
      setForm((prevForm) =>
        validateField(key, value, setValue(prevForm, key, value))
      );
    },
    [setValue, validateField]
  );

  const setFormValue = useCallback(
    (key: keyof S, value: string): void => {
      setForm((prevForm) => setValue(prevForm, key, value));
    },
    [setValue]
  );

  const setFormValues = useCallback(
    (values: { [key in keyof S]: string }) => {
      setForm((prevForm) => setValues(prevForm, values));
    },
    [setValues]
  );

  const setSchemaRules = useCallback(
    (newRules: Partial<Record<keyof S, ValidationRules>>) => {
      const prevRules = schema.current;
      // @ts-ignore
      schema.current = { ...prevRules, ...newRules };
    },
    [schema]
  );
  return {
    form,
    clearErrors,
    setAndValidate,
    validateForm,
    setFormValue,
    setFormValues,
    setSchemaRules,
  };
}

export const useDebounce = (
  callback: (...args: any[]) => void,
  wait: number
) => {
  const argsRef = useRef();
  const timeout = useRef();

  function cleanup() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  useEffect(() => cleanup, []);
  // @ts-ignore
  return (...args: any[]) => {
    // @ts-ignore
    argsRef.current = args;

    cleanup();

    // @ts-ignore
    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        // @ts-ignore
        callback(...argsRef.current);
      }
    }, wait);
  };
};
