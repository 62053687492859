export interface VenueClaimPending {
  id: number;
  venueName: string;
  firstName: string;
  lastName: string;
  date: Date;
  venueId: number;
}

export const GET_VENUES_CLAIM_PENDING = "/venue/venues-claim-pending";
