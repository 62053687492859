import { Form } from "../../../../../../common/models";
import { ValidationRules } from "../../../../../../utils/validation";
import { PromotionType } from "../../../enums";

export type PromotionBasicFormField =
  | "id"
  | "promotionPhotos"
  | "promotionVideos"
  | "mainPhotoId";

export type PromotionBasicFormValues = {
  [key in PromotionBasicFormField]: string;
};

export type PromotionBasicFormErrors = {
  [key in PromotionBasicFormField]: string;
};

export interface IPromotionBasicForm extends Form {
  values: PromotionBasicFormValues;
  errors: PromotionBasicFormErrors;
  touched: PromotionBasicFormField[];
}

export const PromotionBasicForm: IPromotionBasicForm = {
  values: {
    id: "",
    promotionPhotos: "",
    promotionVideos: "",
    mainPhotoId: "",
  },
  errors: {
    id: "",
    promotionPhotos: "",
    promotionVideos: "",
    mainPhotoId: "",
  },
  touched: [],
  isValid: true,
};

export type PromotionBasicFormSchemaType = Record<
  PromotionBasicFormField,
  ValidationRules
>;

export const PromotionBasicFormSchema: PromotionBasicFormSchemaType = {
  id: {},
  promotionPhotos: {},
  promotionVideos: {},
  mainPhotoId: {},
};
