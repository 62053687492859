import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../../theme/colors";
import { OcietyTheme } from "../../../models";

export type ClassNames = "root" | "closeButton" | "title";
export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        margin: 0,
        padding: "28px 43px 28px 32px",
        fontSize: theme.typography.pxToRem(20),
        color: colors.dark.main,
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
      },
      title: {
        fontWeight: 600,
      },
      closeButton: {
        padding: 0,
      },
    })
);
