import { FormControl, Grid, TextField } from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
} from "react";

import { GET_VENUE_TYPES } from "../../../../../../common/api";
import CountryAutocompleteSearch from "../../../../../../common/components/forms/CountryAutocompleteSearch";
import StateAutocompleteSearch from "../../../../../../common/components/forms/StateAutocompleteSearch";
import MultipleSelect from "../../../../../../common/components/MultipleSelect";
import Option from "../../../../../../common/components/MultipleSelect/Option";
import PhoneNumberInput from "../../../../../../common/components/PhoneNumberInput";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import { VenueType, VenueTypes } from "../../../../../../common/models";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { CreateVenueDialogContext } from "../../CreateVenueDialogContextProvider";
import { CreateVenueVenueInfoFormField } from "../../forms/venue";
import { useStyles } from "./styles";

const CreateVenueDialogVenueInfoStep: FC = () => {
  const { venueForm } = useContext(CreateVenueDialogContext);
  const [{ data: venueTypes }, setVenueTypes] = useRestApi<VenueTypes>(
    GET_VENUE_TYPES,
    "GET"
  );
  const { form, setAndValidate } = venueForm;
  const classes = useStyles();

  const handleChangeFormField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAndValidate(
      event.target.name as CreateVenueVenueInfoFormField,
      event.target.value
    );
  };

  const onSelectedTypes = (type: "bar" | "restaurant", values: string[]) => {
    setAndValidate(type, values.join(","));
  };

  const handleChangeState = useCallback(
    (state?: string) => {
      setAndValidate("country", "USA");
      setAndValidate("state", state || "");
    },
    [setAndValidate]
  );

  /*const handleChangeCountry = useCallback(
    (country?: string) => {
      setAndValidate("country", country || "");
    },
    [setAndValidate]
  );*/

  useEffect(() => {
    setVenueTypes().catch((e) => console.error(e));
  }, [setVenueTypes]);

  return (
    <>
      <Grid container spacing={4} className={classes.root}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.venues.list.creatVenueNameFieldLabel}
            name="name"
            error={!!form.errors.name}
            helperText={form.errors.name}
            onChange={handleChangeFormField}
            type="text"
            value={form.values.name}
            variant="outlined"
            required
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        {/*<Grid item lg={6} md={6} sm={12} xs={12}>
          <CountryAutocompleteSearch
            country={form.values.country}
            error={form.errors.country}
            onChange={handleChangeCountry}
            label={dictionary.venues.list.creatVenueCountryFieldLabel}
          />
        </Grid>*/}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <StateAutocompleteSearch
            state={form.values.state}
            country={form.values.country}
            error={form.errors.state}
            onChange={handleChangeState}
            label={dictionary.venues.list.creatVenueStateFieldLabel}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.venues.list.creatVenueCityFieldLabel}
            name="city"
            error={!!form.errors.city}
            helperText={form.errors.city}
            onChange={handleChangeFormField}
            type="text"
            value={form.values.city}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.venues.list.creatVenueAddressFieldLabel}
            name="address"
            error={!!form.errors.address}
            helperText={form.errors.address}
            onChange={handleChangeFormField}
            type="text"
            value={form.values.address}
            variant="outlined"
            required
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.venues.list.creatVenueEmailFieldLabel}
            name="email"
            error={!!form.errors.email}
            helperText={form.errors.email}
            onChange={handleChangeFormField}
            type="text"
            value={form.values.email}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <PhoneNumberInput
            value={form.values.phoneNumber}
            onChange={handleChangeFormField}
            name="phoneNumber"
            error={form.errors.phoneNumber}
            label={dictionary.venues.list.creatVenueNamePhoneNumberFieldLabel}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <FormControl variant={"outlined"} required fullWidth>
            <MultipleSelect
              error={form.errors.bar}
              onSelected={(values) => {
                onSelectedTypes("bar", values);
              }}
              options={
                !venueTypes
                  ? []
                  : venueTypes.bar.map(
                      (type: VenueType) =>
                        new Option(String(type.id), type.subtype)
                    )
              }
              selected={form.values.bar ? form.values.bar.split(",") : []}
              type="bar"
              label={dictionary.venues.venueForm.labelBarTypes}
              helperText={form.errors.bar}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <FormControl variant={"outlined"} required fullWidth>
            <MultipleSelect
              error={form.errors.restaurant}
              onSelected={(values) => {
                onSelectedTypes("restaurant", values);
              }}
              options={
                !venueTypes
                  ? []
                  : venueTypes.restaurant.map(
                      (type: VenueType) =>
                        new Option(String(type.id), type.subtype)
                    )
              }
              selected={
                form.values.restaurant ? form.values.restaurant.split(",") : []
              }
              type="restaraunt"
              label={dictionary.venues.venueForm.labelRestaurantTypes}
              helperText={form.errors.restaurant}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateVenueDialogVenueInfoStep;
