import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames =
  | "root"
  | "user"
  | "pushPromotions"
  | "row"
  | "paginationWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      user: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
        color: "#273950",
      },
      pushPromotions: {
        color: "#7C8BC2",
        fontWeight: "bold",
      },
      row: {
        margin: 10,
        "&:last-of-type td ": {
          paddingBottom: 10,
        },
        "&:last-of-type td:after": {
          display: "none",
        },
      },
      paginationWrapper: {
        "& > div": {
          justifyContent: "flex-end",
        },
      },
    })
);
