import { FormControl, TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React, { FC } from "react";

import {
  ratingDisplayValues,
  userTypeDisplayValues,
} from "../../../../../../common/const";
import { MobileAppUserType } from "../../../../../../common/models";
import { ReactComponent as SearchIcon } from "../../../../../../common/svg/icons/search.svg";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const AllReviewsFilters: FC<Props> = (props: Props) => {
  const {
    searchKey,
    onChangeSearchKey,
    userType,
    foodRating,
    serviceRating,
    onChangeFoodRating,
    onChangeServiceRating,
    onChangeUserType,
  } = props;

  const classes = useStyles();

  const handleChangeFoodRating = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeFoodRating(Number(event.target.value));
  };

  const handleChangeServiceRating = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeServiceRating(Number(event.target.value));
  };

  const handleChangeUserType = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUserType(
      event.target.value === "null"
        ? null
        : (event.target.value as MobileAppUserType)
    );
  };

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.searchInputContainer}>
          <TextField
            className={classes.searchInput}
            id="all_reviews_search_input"
            value={searchKey}
            placeholder={dictionary.reviews.allReviewsSearchInputPlaceholder}
            name="searchKey"
            onChange={onChangeSearchKey}
            type="text"
            variant="outlined"
          />
          <SearchIcon className={classes.searchIcon} />
        </div>
      </div>
      <div className={classes.additionalFilters}>
        <FormControl className={classes.filterSelectFormControl}>
          <TextField
            label={userType ? dictionary.reviews.userTypeSelectLabel : ""}
            select
            onChange={handleChangeUserType}
            value={userType || "null"}
            InputProps={{
              className: classes.inputField,
            }}
            SelectProps={{
              classes: { outlined: classes.selectRoot },
            }}
            variant="outlined">
            <MenuItem value="null">
              {dictionary.reviews.userTypeSelectLabel}
            </MenuItem>
            {userTypeDisplayValues.map(({ value, display }) => (
              <MenuItem key={value} value={value}>
                {display}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl className={classes.filterSelectFormControl}>
          <TextField
            label={foodRating ? dictionary.reviews.foodRatingSelectLabel : ""}
            select
            onChange={handleChangeFoodRating}
            value={foodRating}
            InputProps={{
              className: classes.inputField,
            }}
            SelectProps={{
              classes: { outlined: classes.selectRoot },
            }}
            variant="outlined">
            <MenuItem value="0">
              {dictionary.reviews.foodRatingSelectLabel}
            </MenuItem>
            {ratingDisplayValues.map(({ value, display }) => (
              <MenuItem key={value} value={value}>
                {display}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl className={classes.filterSelectFormControl}>
          <TextField
            label={
              serviceRating ? dictionary.reviews.serviceRatingSelectLabel : ""
            }
            select
            onChange={handleChangeServiceRating}
            value={serviceRating}
            InputProps={{
              className: classes.inputField,
            }}
            SelectProps={{
              classes: { outlined: classes.selectRoot },
            }}
            variant="outlined">
            <MenuItem value="0">
              {dictionary.reviews.serviceRatingSelectLabel}
            </MenuItem>
            {ratingDisplayValues.map(({ value, display }) => (
              <MenuItem key={value} value={value}>
                {display}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>
    </div>
  );
};

export default AllReviewsFilters;
