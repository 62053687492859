import { Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";

import { AlertContext } from "../../../common/components/Alert/AlertContextProvider";
import ConfirmDialog from "../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../common/components/ConfirmDialog/Props";
import PageTitle from "../../../common/components/PageTitle";
import VenueReportingContextProvider from "../../../common/components/VenueReportingContextProvider";
import { useGlobalStyles } from "../../../common/styles";
import dictionary from "../../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../../store/currentVenue/reducer";
import { AppState } from "../../../store/rootReducer";
import { deletePromotion, duplicatePromotion } from "./api";
import MostPopularPromotionsChart from "./components/MostPopularPromotionsChart";
import PromotionFormDialog from "./components/PromotionFormDialog";
import { PromotionFormDialogContext } from "./components/PromotionFormDialog/PromotionFormDialogContextProvider";
import PromotionsList from "./components/PromotionsList";
import PromotionUsagesViewsChart from "./components/PromotionUsagesViewsChart";
import VenueAdminFilters from "./components/VenueAdminFilters";
import { useStyles } from "./styles";

const VenueAdminPromotions: FC = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { showAlert } = useContext(AlertContext);
  const { setPromotionId } = useContext(PromotionFormDialogContext);
  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (appState) => appState.currentVenue
  );
  const venueId: number = currentVenue ? currentVenue.id : 0;
  const [refreshToken, setRefreshToken] = useState(0);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [editPromotionId, setEditPromotionId] = useState<number | null>(null);
  const [deletePromotionId, setDeletePromotionId] =
    useState<number | null>(null);
  const [duplicatePromotionId, setDuplicatePromotionId] =
    useState<number | null>(null);

  // Create promotion mode
  const openFormDialog = useCallback(() => {
    setPromotionId(0);
    setEditPromotionId(null);
    setFormDialogOpen(true);
  }, [setPromotionId]);

  // Edit promotion mode
  const openEditFormDialog = useCallback((promotionId: number) => {
    setFormDialogOpen(true);
    setEditPromotionId(promotionId);
  }, []);

  const openDeleteDialog = useCallback((promotionId: number) => {
    setDeletePromotionId(promotionId);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setDeletePromotionId(null);
  }, []);

  const openDuplicateDialog = useCallback((promotionId: number) => {
    setDuplicatePromotionId(promotionId);
  }, []);

  const closeDuplicateDialog = useCallback(() => {
    setDuplicatePromotionId(null);
  }, []);

  const closeFormDialog = useCallback(() => {
    setFormDialogOpen(false);
  }, []);

  const refreshGrid = useCallback(() => {
    setRefreshToken(+new Date());
  }, []);

  const confirmDeletePromotion = async () => {
    if (!deletePromotionId) {
      return;
    }

    try {
      const result = await deletePromotion(venueId, deletePromotionId);

      if (result) {
        showAlert(dictionary.promotions.deletePromotionSuccess, "success");
        refreshGrid();
      } else {
        showAlert(dictionary.promotions.deletePromotonError, "error");
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.promotions.deletePromotonError, "error");
    }
  };

  const confirmDuplicatePromotion = async () => {
    if (!duplicatePromotionId) {
      return;
    }

    try {
      const result = await duplicatePromotion(duplicatePromotionId);

      if (result) {
        showAlert(
          dictionary.promotions.duplicatePromotion.createdPromotionDuplicate,
          "success"
        );

        if (result.data.id) {
          openEditFormDialog(result.data.id);
        }
        refreshGrid();
      } else {
        showAlert(
          dictionary.promotions.duplicatePromotion.errorPromotionDuplicate,
          "error"
        );
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert("Error", "error");
    }
  };
  return (
    <VenueReportingContextProvider>
      <div>
        <div
          className={clsx(globalClasses.flexRow_Center, classes.titleWrapper)}>
          <PageTitle>{dictionary.promotions.titlePage}</PageTitle>
          <VenueAdminFilters className={classes.periodSelect} />
        </div>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <PromotionUsagesViewsChart />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <MostPopularPromotionsChart />
          </Grid>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <PromotionsList
              refreshToken={refreshToken}
              openEditDialog={openEditFormDialog}
              openDeleteDialog={openDeleteDialog}
              openCreateDialog={openFormDialog}
              openDuplicateDialog={openDuplicateDialog}
              refresh={refreshGrid}
              venueId={venueId}
            />
          </Grid>
        </Grid>
        <PromotionFormDialog
          refresh={refreshGrid}
          promotionId={editPromotionId}
          closeDialog={closeFormDialog}
          open={formDialogOpen}
        />
        <ConfirmDialog
          open={!!deletePromotionId}
          onClose={closeDeleteDialog}
          onConfirm={confirmDeletePromotion}
          subtitle={"Are you sure you would like to delete this? "}
          type={ConfirmModalType.YES_DELETE}
          message={" \n This cannot be undone."}
        />

        <ConfirmDialog
          open={!!duplicatePromotionId}
          onClose={closeDuplicateDialog}
          onConfirm={confirmDuplicatePromotion}
          subtitle={"Are you sure you would like to duplicate this promotion? "}
          type={ConfirmModalType.CONFIRM}
          message={" \n  "}
        />
      </div>
    </VenueReportingContextProvider>
  );
};

export default VenueAdminPromotions;
