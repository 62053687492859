import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ButtonDelete from "../../../../common/components/ButtonDelete";
import ConfirmDialog from "../../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../../common/components/ConfirmDialog/Props";
import dictionary from "../../../../i18n/en_US/dictionary";
import { logout } from "../../../../store/auth/actions";
import { deleteProfile } from "./api";

const DeleteProfile = () => {
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const { showAlert } = useContext(AlertContext);
  const dispatch = useDispatch();
  const confirmDeletePromotion = async () => {
    try {
      const result = await deleteProfile();

      if (!result) {
        showAlert(
          "An error occurred during delete profile. " +
            " Please try again later",
          "error"
        );
      }

      if (result) {
        dispatch(logout());
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert("Error", "error");
    }
  };
  return (
    <div style={{ marginTop: 30, marginBottom: 50, width: "50%" }}>
      <Typography variant={"h3"}>{"Delete your profile"}</Typography>
      <Grid container spacing={1} style={{ marginTop: 20 }}>
        <Grid item xs={12}>
          <Alert severity="warning">
            {"This cannot be undone. Please choose carefully."}
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <ButtonDelete
            onClick={() => {
              setDeleteConfirm(true);
            }}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        open={deleteConfirm}
        onClose={() => {
          setDeleteConfirm(false);
        }}
        onConfirm={confirmDeletePromotion}
        subtitle=" "
        type={ConfirmModalType.YES_DELETE}
        yesTypeAdditionalText={
          dictionary.profile.confirmationDeleteProfileButtonLabel
        }
        message={dictionary.profile.popupMessageOnDeleteProfile}
      />
    </div>
  );
};
export default DeleteProfile;
