import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { ChangeEvent, FC, useContext, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

// eslint-disable-next-line max-len
import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useForm } from "../../../../common/hooks";
import { useRestApi } from "../../../../common/hooks/useRestApi";
// eslint-disable-next-line max-len
import { useSubmitFormOnEnter } from "../../../../common/hooks/useSubmitFormOnEnter";
import { useGlobalStyles } from "../../../../common/styles";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  ContactUsLetterRequestData,
  ContactUsLetterResponse,
  SEND_CONTACT_US_LETTER,
} from "../../models";
import { contactUsForm, ContactUsFormField, schema } from "./form";
import { useStyles } from "./styles";

const ContactUsForm: FC = () => {
  const { showAlert } = useContext(AlertContext);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [{ loading }, sendEmail] = useRestApi<
    ContactUsLetterResponse,
    ContactUsLetterRequestData
  >(SEND_CONTACT_US_LETTER, "POST");
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { form, setAndValidate, validateForm } = useForm(schema, contactUsForm);
  const [recaptchaValidated, setRecaptchaValidated] = useState(false);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;

    setAndValidate(key as ContactUsFormField, value);
  };

  const handleSubmitForm = () => {
    if (!validateForm() || !recaptchaValidated) {
      return;
    }

    const { message, subject, phoneNumber, companyName, email, yourName } =
      form.values;

    sendEmail({
      // undefined because empty string not allowed on BE validation
      companyName: companyName || undefined,
      subject,
      phoneNumber,
      name: yourName,
      email,
      // undefined because empty string not allowed on BE validation
      message: message || undefined,
    })
      .then((res) => {
        res &&
          showAlert(dictionary.contactUs.sendEmailSuccessMessage, "success");
      })
      .catch((error) => {
        if (error.response.data === "Insult word") {
          showAlert(dictionary.notifications.insultingMessageError, "error");
        } else {
          showAlert(dictionary.contactUs.sendEmailErrorMessage, "error");
        }
      });

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setRecaptchaValidated(false);
    }
  };

  useSubmitFormOnEnter(handleSubmitForm);

  const verifyRecaptcha = (recaptchaToken: string | null) => {
    setRecaptchaValidated(Boolean(recaptchaToken));
  };

  return (
    <Paper className={classes.root}>
      <Typography variant={"h3"}>
        {dictionary.contactUs.sendMessageTitle}
      </Typography>
      <div className={classes.form}>
        {loading && <LoadingIndicator withMask />}
        <Grid container spacing={4} style={{ maxWidth: "none" }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              required
              error={!!form.errors.yourName}
              fullWidth
              helperText={form.errors.yourName}
              label={dictionary.contactUs.yourNameFieldLabel}
              name="yourName"
              onChange={handleChange}
              type="text"
              value={form.values.yourName || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              required
              error={!!form.errors.email}
              fullWidth
              helperText={form.errors.email}
              label={dictionary.contactUs.emailFieldLabel}
              name="email"
              onChange={handleChange}
              type="text"
              value={form.values.email || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              error={!!form.errors.companyName}
              fullWidth
              helperText={form.errors.companyName}
              label={dictionary.contactUs.companyNameFieldLabel}
              name="companyName"
              onChange={handleChange}
              type="text"
              value={form.values.companyName || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              required
              error={!!form.errors.phoneNumber}
              fullWidth
              helperText={form.errors.phoneNumber}
              label={dictionary.contactUs.phoneNumberFieldLabel}
              name="phoneNumber"
              onChange={handleChange}
              type="text"
              value={form.values.phoneNumber || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              required
              error={!!form.errors.subject}
              fullWidth
              helperText={form.errors.subject}
              label={dictionary.contactUs.subjectFieldLabel}
              name="subject"
              onChange={handleChange}
              type="text"
              value={form.values.subject || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              error={!!form.errors.message}
              fullWidth
              multiline
              rows={3}
              helperText={form.errors.message}
              label={dictionary.contactUs.messageFieldLabel}
              name="message"
              onChange={handleChange}
              type="text"
              value={form.values.message || ""}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div
          className={clsx(
            globalClasses.flexRowEndCenter,
            classes.actionsToolbar
          )}>
          <div className={classes.recaptchaContainer}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
              onChange={verifyRecaptcha}
            />
          </div>
          <ButtonPrimary
            onClick={handleSubmitForm}
            disabled={!recaptchaValidated || !form.isValid}>
            {dictionary.contactUs.submitFormButton}
          </ButtonPrimary>
        </div>
      </div>
    </Paper>
  );
};

export default ContactUsForm;
