import { Divider, Grid, Paper } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../../common/components/LoadingIndicator";
import PageTitle from "../../common/components/PageTitle";
import { useRestApi } from "../../common/hooks/useRestApi";
import { VenueAddRequestDetails } from "../../common/models";
import { Route } from "../../config/router";
import dictionary from "../../i18n/en_US/dictionary";
import VenuePendingAttachments from "./components/VenuePendingAttachments";
import VenuePendingUserData from "./components/VenuePendingUserData";
import VenuePendingVenueForm from "./components/VenuePendingVenueForm";
import Props from "./Props";
import { useStyles } from "./styles";

const VenuePending: FC<Props> = (props: Props) => {
  const requestId = parseInt(props.match.params.id);
  const [{ data, loading }, getVenueAddRequest] =
    useRestApi<VenueAddRequestDetails>(
      `venue/venue-pending/${requestId}`,
      "GET"
    );

  const classes = useStyles();

  useEffect(() => {
    getVenueAddRequest().catch((e) => console.error(e));
  }, [getVenueAddRequest]);

  return (
    <div className={classes.root}>
      <PageTitle backURL={Route.VenuesPending}>
        {dictionary.venuePending.pageTitle}
      </PageTitle>
      <Paper className={classes.content}>
        {loading && <LoadingIndicator withMask />}
        {data && (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <VenuePendingUserData
                comment={data.comment}
                userCompany={data.userCompany}
                userEmail={data.userEmail}
                userFirstName={data.userFirstName}
                userLastName={data.userLastName}
                userPhoneNumber={data.userPhoneNumber}
                userTitle={data.userTitle}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {data.comment ? (
                <Divider className={classes.divider} />
              ) : (
                <div className={classes.divider} />
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <VenuePendingAttachments attachments={data.attachments} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <VenuePendingVenueForm
                requestId={data.id}
                venueName={data.venueName}
                venueCity={data.venueCity}
                venueCountry={data.venueCountry}
                venueAddress={data.venueAddress}
                venueEmail={data.venueEmail}
                venueState={data.venueState}
                venuePhone={data.venuePhone}
                venueWebsite={data.venueWebsite}
                barTypesIds={data.barTypesIds}
                restaurantTypesIds={data.restaurantTypesIds}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </div>
  );
};

export default withRouter(VenuePending);
