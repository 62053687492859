export const APP_NAME = "Ociety";

export const VALID_COUNTRY_CODES = process.env.VALID_PHONE_COUNTRIES || "US,AT";

export const HELP_EMAIL = "help@ociety.com";

export const CURRENCY_SYMBOL = "$";

export const isPackagesDisabled =
  process.env.REACT_APP_PACKAGES_DISABLED === "true";

export const RBACK_ENABLED = false;
