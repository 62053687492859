export const GET_VENUE_PROMOTION_USAGE_POPULAR_HOURS =
  "reporting/promotion-usage-popular-hours";

export interface PromotionUsagePopularHoursData {
  hour: number;
  usages: number;
}

export interface GetPromotionUsagePopularHoursResponse {
  stats: PromotionUsagePopularHoursData[];
}
