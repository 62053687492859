import { Paper, Typography } from "@material-ui/core";
import React, { ChangeEvent, FC, useState } from "react";

import VenueReportingContextProvider from "../../../../common/components/VenueReportingContextProvider";
import { MobileAppUserType } from "../../../../common/models";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import PageTitleWithToolbar from "../PageTitleWithToolbar";
import AllReviewsFilters from "./components/AllReviewsFilters";
import AllReviewsTable from "./components/AllReviewsTable";
import { useStyles } from "./styles";

const AllReviews: FC = () => {
  const [searchKey, setSearchKey] = useState("");
  const [userType, setUserType] = useState<MobileAppUserType | null>(null);
  const [foodRating, setFoodRating] = useState(0);
  const [serviceRating, setServiceRating] = useState(0);
  const classes = useStyles();

  const handleChangeSearchKey = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchKey(event.target.value);
  };

  const handleChangeFoodRating = (rating: number) => {
    setFoodRating(rating);
  };

  const handleChangeServiceRating = (rating: number) => {
    setServiceRating(rating);
  };

  const handleChangeUserType = (type: MobileAppUserType | null) => {
    setUserType(type);
  };

  return (
    <VenueReportingContextProvider>
      <div className={classes.root}>
        <PageTitleWithToolbar backURL={Route.Reviews} />
        <Paper className={classes.content}>
          <Typography variant={"h3"}>
            {dictionary.reviews.allReviewsSectionTitle}
          </Typography>
          <AllReviewsFilters
            searchKey={searchKey}
            foodRating={foodRating}
            serviceRating={serviceRating}
            userType={userType}
            onChangeUserType={handleChangeUserType}
            onChangeServiceRating={handleChangeServiceRating}
            onChangeFoodRating={handleChangeFoodRating}
            onChangeSearchKey={handleChangeSearchKey}
          />
          <div className={classes.tableContainer}>
            <AllReviewsTable
              userType={userType}
              serviceRating={serviceRating}
              foodRating={foodRating}
              searchKey={searchKey}
            />
          </div>
        </Paper>
      </div>
    </VenueReportingContextProvider>
  );
};

export default AllReviews;
