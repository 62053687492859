import { List } from "@material-ui/core";
import React, { FC, useEffect, useRef } from "react";

import MessagesListItem from "../MessagesListItem";
import Props from "./Props";
import { useStyles } from "./styles";

const MessagesList: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { messages } = props;
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <List className={classes.root}>
      {messages.map((message) => (
        <MessagesListItem message={message} key={message.id} />
      ))}
      <div ref={messagesEndRef} />
    </List>
  );
};

export default MessagesList;
