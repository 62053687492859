import React, { FC } from "react";
import Slider, { Settings } from "react-slick";

import RecentReviewsSlide from "../RecentReviewsSlide";
import Props from "./Props";

const RecentReviewsSlider: FC<Props> = (props) => {
  const { reviews, isBar } = props;
  const settings: Settings = {
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 500,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {reviews.map((review) => (
          <RecentReviewsSlide key={review.id} review={review} isBar={isBar} />
        ))}
      </Slider>
    </div>
  );
};

export default RecentReviewsSlider;
