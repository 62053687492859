import { http } from "../config/api";
import { LoginResponse, MFAResponse } from "../store/auth/types";
import { GetOwnVenuesResponse } from "../store/currentVenue/types";
import {
  ChangePasswordResponse,
  ForgotPasswordResponse,
} from "../store/forgotPassword/types";
import {
  AuthCredentials,
  ChangePassword,
  ForgotPassword,
  VerifyMFACodeRequest,
} from "./models";

const LOGIN = "auth/login";
const MFA_VERIFY = "auth/verify";
const FORGOT_PASSWORD = "auth/forgot-password";
const CHANGE_PASSWORD = "auth/change-password";
const PUSH_NOTIFICATIONS_SUBSCRIPTION_SAVE = "webpush/subscribe";
const GET_OWN_VENUES = "venue/own-venues";
export const GET_VENUE_TYPES = "venue/types";

export const authorize = async (
  authCredentials: AuthCredentials
): Promise<LoginResponse | MFAResponse> =>
  http
    .post(LOGIN, authCredentials)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const forgotPasswordRequest = async (
  request: ForgotPassword
): Promise<ForgotPasswordResponse> =>
  http
    .post(FORGOT_PASSWORD, request)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const getOwnVenuesRequest = async (): Promise<GetOwnVenuesResponse> =>
  http
    .get(GET_OWN_VENUES)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const changePasswordRequest = async (
  request: ChangePassword
): Promise<ChangePasswordResponse> =>
  http
    .post(CHANGE_PASSWORD, request)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const verifyMFA = async (
  verifyMFACodeRequest: VerifyMFACodeRequest
): Promise<LoginResponse> =>
  http
    .post(MFA_VERIFY, verifyMFACodeRequest)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const savePushNotificationsSubscription = async (
  subscription: PushSubscription
): Promise<{ success: boolean }> =>
  http
    .post(PUSH_NOTIFICATIONS_SUBSCRIPTION_SAVE, subscription)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
