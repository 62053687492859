import {
  Box,
  Button,
  Chip,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import React, {
  ChangeEvent,
  FC,
  KeyboardEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ConfirmDialog from "../../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../../common/components/ConfirmDialog/Props";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useDebounce, usePrevious } from "../../../../common/hooks";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { ReactComponent as AddIcon } from "../../../../common/svg/icons/add-icon.svg";
import { ReactComponent as CloseChipIcon } from "../../../../common/svg/icons/close-chip-icon.svg";
import { ReactComponent as DisabledAddIcon } from "../../../../common/svg/icons/disabled-add-icon.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../store/rootReducer";
import { useStyles as useBaseStyles } from "../../styles";
import { SaveVenueTagsRequest, VENUE_TAGS } from "./api";
import { useStyles } from "./styles";

const VenueTags: FC = () => {
  const baseClasses = useBaseStyles();
  const venueId = useCurrentVenueId();
  const { showAlert } = useContext(AlertContext);
  const classes = useStyles();

  const [tagsInput, setTagsInput] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [focusedInput, setFocusedInput] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const onFocus = () => setFocusedInput(true);
  const onBlur = () => setFocusedInput(false);
  const prevTags = usePrevious(tags);
  const [_, saveVenueTags] = useRestApi<any, SaveVenueTagsRequest>(
    VENUE_TAGS,
    "PUT"
  );
  const { venue, loading } = useSelector((state: AppState) => state.venue);

  useEffect(() => {
    if (venue?.tags) {
      setTags(venue.tags);
    }
  }, [venue?.tags]);

  const handleChangeTags = (e: ChangeEvent<any>) => {
    const { value } = e.target;

    setTagsInput(value);
  };

  const handleSaveAllTags = async (allTags: string[]) => {
    await saveVenueTags({ tags: allTags, id: venueId }).catch((e) => {
      if (e.response.data === "Insult word") {
        setErrorMsg(dictionary.notifications.insultingMessageError);
        showAlert(dictionary.notifications.insultingMessageError, "error");
      }
    });
  };

  const addTags = () => {
    if (!tags.includes(tagsInput)) {
      setTags((prevState) => [...prevState, tagsInput]);
    }
  };

  const debouncedSaveAllTags = useDebounce(() => handleSaveAllTags(tags), 2000);

  const handleClickEnter: KeyboardEventHandler<HTMLDivElement> = (e: any) => {
    if ((e.key === "Enter" || e.key === " ") && tagsInput === "") {
      e.preventDefault();
    }

    if ((e.key === "Enter" || e.key === " ") && tagsInput !== "") {
      e.preventDefault();
      addTags();
      setTagsInput("");
      onBlur();
      inputRef.current?.blur();
    }
  };

  const handleDeleteTag = (tagToDelete: string) => {
    const newList = tags.filter((tag) => tag !== tagToDelete);
    setTags(newList);
    setErrorMsg("");
  };

  const handleDeleteAllTags = () => {
    setTags([]);
    setErrorMsg("");
  };

  const handleFocusInputField = () => {
    onFocus();
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (prevTags !== tags) {
      debouncedSaveAllTags();
    }
  }, [prevTags, tags, debouncedSaveAllTags]);

  const isLimitTags = tags.length > 19;

  return (
    <Paper className={baseClasses.section}>
      <Typography variant={"h3"} className={baseClasses.sectionTitle}>
        {dictionary.venues.venueTags.title}
      </Typography>
      <div className={classes.removeAllButtonWrapper}>
        <Typography variant={"subtitle1"}>
          {dictionary.venues.venueTags.helpfulText}
        </Typography>
        {tags.length > 1 && (
          <Button
            size="small"
            variant="text"
            onClick={() => setIsConfirmationOpen(true)}
            classes={{ text: classes.removeAllText }}
            className={classes.removeAllButton}>
            {dictionary.venues.venueTags.removeAllBtnLabel(tags.length)}
          </Button>
        )}
      </div>
      <Box className={classes.tagsContainer}>
        {tags.length !== 0 &&
          tags.map((tag) => (
            <Chip
              onDelete={() => handleDeleteTag(tag)}
              key={tag}
              label={tag}
              className={classes.tag}
              deleteIcon={<CloseChipIcon />}
            />
          ))}
        <TextField
          required
          inputRef={inputRef}
          onBlur={onBlur}
          InputProps={{
            classes: {
              notchedOutline: classes.inputBase,
              root: classes.inputField,
              input: classes.outlinedInput,
            },
            style: {
              width: (tagsInput.length + 1) * 8,
              marginRight: focusedInput ? 8 : 0,
            },
          }}
          name="tags"
          error={!!errorMsg}
          onKeyDown={handleClickEnter}
          onChange={handleChangeTags}
          type="text"
          value={tagsInput}
          variant="outlined"
        />
        <Tooltip
          title={
            isLimitTags ? dictionary.venues.venueTags.limitTagsTooltip : ""
          }
          placement="top-start">
          <div className={classes.tooltipContainer}>
            <Button
              startIcon={isLimitTags ? <DisabledAddIcon /> : <AddIcon />}
              onClick={handleFocusInputField}
              variant="text"
              disabled={isLimitTags}
              style={{ marginLeft: focusedInput ? 8 : 0 }}
              className={classes.addTagButton}>
              {dictionary.venues.venueTags.inputPlaceholder}
            </Button>
          </div>
        </Tooltip>
      </Box>
      <FormHelperText className={classes.errorMsg}>{errorMsg}</FormHelperText>
      <ConfirmDialog
        open={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleDeleteAllTags}
        message={
          dictionary.venues.venueTags.confirmationDeletingAllTagsPopupMsg
        }
        type={ConfirmModalType.YES_DELETE}
        yesTypeAdditionalText={
          dictionary.venues.venueTags.confirmationDeletingAllTagsText
        }
      />
      {loading && <LoadingIndicator withMask />}
    </Paper>
  );
};

export default VenueTags;
