import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import React, { FC, useContext } from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ButtonCancel from "../../../../common/components/ButtonCancel";
import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import OcietyDialogTitle from "../../../../common/components/dialogs/OcietyDIalogTitle";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import dictionary from "../../../../i18n/en_US/dictionary";
import { CANCEL_PLAN_PACKAGE } from "./api";
import Props from "./Props";

const CancelPackageModal: FC<Props> = (props) => {
  const { open, onClose, children, currentCancelPackage, onSucceed, ...rest } =
    props;
  const venueId = useCurrentVenueId();
  const { showAlert } = useContext(AlertContext);
  const [, cancelPlanSubscription] = useRestApi(
    CANCEL_PLAN_PACKAGE(venueId, currentCancelPackage?.id || 0),
    "DELETE"
  );

  if (!currentCancelPackage) {
    return null;
  }

  const handleClose = () => {
    if (onClose) {
      // @ts-ignore
      onClose();
    }
  };

  const handleConfirm = () => {
    cancelPlanSubscription()
      .then(() => {
        showAlert(dictionary.plan.cancelPackageSucceed, "success");

        if (onSucceed) {
          onSucceed();
        }
      })
      .catch(() => {
        showAlert(dictionary.plan.cancelPackageError, "error");
      })
      .finally(handleClose);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      {...rest}>
      <OcietyDialogTitle id="mfa-call-dialog-title" onClose={handleClose}>
        {dictionary.plan.cancelPackagePopupTitle}
      </OcietyDialogTitle>
      <DialogContent dividers style={{ maxWidth: 500 }}>
        {children}
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={handleClose}>
          {dictionary.plan.cancelAction}
        </ButtonCancel>
        <ButtonSuccess onClick={handleConfirm}>
          {dictionary.plan.confirmAction}
        </ButtonSuccess>
      </DialogActions>
    </Dialog>
  );
};

export default CancelPackageModal;
