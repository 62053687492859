import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { AlertContext } from "../../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../../common/components/LoadingIndicator";
import { useForm } from "../../../../../common/hooks";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { updateRole } from "./api";
import { createRoleForm, schema, UpdateRoleFormField } from "./form";
import Props from "./Props";
import { useStyles } from "./styles";

const EditRoleDialog: FC<Props> = (props: Props) => {
  const { isOpen, setOpen, onSuccessUpdate, role } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const isRoleSet = useRef(false);
  const { showAlert } = useContext(AlertContext);

  const handleClose = () => {
    setOpen(false);
  };
  const { form, setFormValues, setAndValidate, validateForm } = useForm(
    schema,
    createRoleForm
  );

  const handleChangeFormTextField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;

    setAndValidate(key as UpdateRoleFormField, value);
  };

  const setFormValuesTest = useCallback(() => {
    if (role && !isRoleSet.current) {
      setFormValues({
        name: role.name,
        description: role.description,
      });
      isRoleSet.current = true;
    }
  }, [role, setFormValues]);

  const handleUpdateRole = async () => {
    if (!role) {
      return;
    }
    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);

      await updateRole(Number(role.id), {
        name: form.values.name,
        description: form.values.description,
      });
      onSuccessUpdate && onSuccessUpdate();
      showAlert(dictionary.RBAC.roles.edit.successMessage, "success");

      handleClose();
    } catch (e) {
      if (!e) {
        return;
      }

      handleClose();
      showAlert(dictionary.RBAC.roles.edit.updaterRoleError, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFormValuesTest();
  }, [setFormValuesTest]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="alert-dialog-title">{"Edit role"}</DialogTitle>
      <DialogContent className={classes.content}>
        <form>
          <TextField
            className={classes.textField}
            required
            error={!!form.errors.name}
            fullWidth
            helperText={form.errors.name}
            label={"Name"}
            name="name"
            onChange={handleChangeFormTextField}
            type="text"
            value={form.values.name || ""}
            variant="outlined"
          />
          <TextField
            required
            error={!!form.errors.description}
            fullWidth
            helperText={form.errors.description}
            label={
              dictionary.promotions.createPromotionForm.descriptionFieldLabel
            }
            name="description"
            onChange={handleChangeFormTextField}
            type="text"
            value={form.values.description || ""}
            variant="outlined"
          />
        </form>
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={() => handleClose()}>
          {dictionary.promotions.createPromotionForm.cancelButton}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={!form.isValid}
          onClick={handleUpdateRole}>
          {"Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRoleDialog;
