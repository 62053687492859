import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type MultipleSelectClassNames =
  | "root"
  | "formControl"
  | "chips"
  | "select"
  | "checkboxIcon"
  | "noLabel";

export const useStyles: () => ClassNameMap<MultipleSelectClassNames> =
  makeStyles((theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
        padding: theme.spacing(3, 0, 0),
      },
      formControl: {
        width: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        height: "100%",
      },
      chips: {
        minHeight: 42,
        display: "flex",
        flexWrap: "wrap",
        margin: "0 20px 0 5px",
        zIndex: 1,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
      select: {
        height: "100%",
        minHeight: 50,
      },
      checkboxIcon: {
        marginRight: theme.spacing(2),
      },
    })
  );
