import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../../common/components/Alert/AlertContextProvider";
import ButtonPrimary from "../../../../../common/components/ButtonPrimary";
import LoadingIndicator from "../../../../../common/components/LoadingIndicator";
import HttpStatusCodes from "../../../../../common/httpStatusCodes";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { changePromotionStatus, getPromotionsAll } from "../../../common/api";
import { DiscountStatus } from "../../../common/models/DiscountStatus";
import { IPromotion } from "../../../common/models/IPromotion";
import PromotionsGrid from "../../../common/PromotionsGrid";
import PromotionCategoriesSelect from "./components/PromotionCategoriesSelect";
import SearchPromotion from "./components/SearchPromotion";
import IPromotionsAllResponse from "./models/IPromotionsAllResponse";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionsList: FC<Props> = (props: Props) => {
  const ROWS_PER_PAGE = 5;
  const {
    venueId,
    openCreateDialog,
    openEditDialog,
    openDeleteDialog,
    refreshToken,
    refresh,
    openDuplicateDialog,
  } = props;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [promotions, setPromotions] = useState<IPromotion[]>([]);
  const [promotionsCount, setPromotionsCount] = useState<number>(0);
  const [searchValue, setSearchValue] = useState("");
  const [currentCategoryId, setCurrentCategoryId] = useState<number>();
  const [page, setPage] = useState(1);
  const [isAllActivePromotionChecked, setIsAllActivePromotionChecked] =
    useState(false);
  const activeStatus = DiscountStatus.ENABLED;
  const skip = (page - 1) * 5;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPromotionsAll(
      venueId,
      ROWS_PER_PAGE,
      skip,
      searchValue,
      currentCategoryId,
      isAllActivePromotionChecked ? activeStatus : undefined
    )
      .then((data: IPromotionsAllResponse) => {
        if (data) {
          setPromotions(data.promotions);
          setPromotionsCount(data.count);
        }
      })
      .catch((e) => {
        if (e.status !== HttpStatusCodes.SERVER_ERROR) {
          showAlert(
            dictionary.promotions.createPromotionForm.errorFetchData,
            "error"
          );
        }
      });
  }, [
    showAlert,
    venueId,
    searchValue,
    skip,
    activeStatus,
    currentCategoryId,
    refreshToken,
    isAllActivePromotionChecked,
  ]);

  const onlyActivePromotions = () => {
    setIsAllActivePromotionChecked((prevState) => !prevState);
    setPage(1);
  };

  const onChangePromotionStatus = async (
    promotionId: number,
    status: DiscountStatus
  ) => {
    try {
      setLoading(true);
      const result = await changePromotionStatus(venueId, promotionId, status);

      if (!result) {
        showAlert(
          dictionary.promotions.createPromotionForm.incorrectResponse,
          "error"
        );
      }
      refresh();
      showAlert(dictionary.promotions.successfullySaved, "success");
    } catch (e) {
      if (!e) {
        return;
      }
      showAlert(e.data, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.wrapper}>
        <Grid container spacing={1}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Typography variant="h3" component="h3" className={classes.title}>
              {dictionary.promotions.titlePage}
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.checkboxWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isAllActivePromotionChecked}
                  onChange={onlyActivePromotions}
                  name="onlyActivePromotions"
                />
              }
              className={classes.checkbox}
              label={
                <Typography variant="body2">
                  {dictionary.promotions.activePromotion}
                </Typography>
              }
            />
          </Grid>
          <Grid item lg={3} xl={2} xs={12} md={6} className={classes.search}>
            <SearchPromotion onChange={setSearchValue} />
          </Grid>
          <Grid item lg={2} xl={4} xs={12} md={6} />
          <Grid item lg={1} xl={2} xs={12} md={6} justify="space-between" />
          <Grid
            item
            xl={4}
            lg={6}
            xs={12}
            md={6}
            direction="row"
            justify="space-between"
            style={{ textAlign: "right" }}>
            <PromotionCategoriesSelect
              currentCategoryId={currentCategoryId}
              setCurrentCategoryId={setCurrentCategoryId}
              setPage={setPage}
            />
            <ButtonPrimary
              style={{ padding: 17, marginTop: -7 }}
              onClick={openCreateDialog}>
              {dictionary.promotions.addBtnTxt}
            </ButtonPrimary>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {loading && <LoadingIndicator withMask />}
            <PromotionsGrid
              rowsPerPage={ROWS_PER_PAGE}
              page={page}
              setPage={setPage}
              promotions={promotions}
              count={promotionsCount}
              onEditPromotion={openEditDialog}
              onChangePromotionStatus={onChangePromotionStatus}
              onDeletePromotion={openDeleteDialog}
              onDuplicatePromotion={openDuplicateDialog}
              refresh={refresh}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default PromotionsList;
