import React, { FC, useContext, useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartContainer from "../../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../../common/components/ChartTooltip";
import { VenueReportingContext } from "../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../common/hooks/useVenueHasPacakage";
import { PackageUID } from "../../../../../store/currentVenue/types";
import { colors } from "../../../../../theme/colors";
import {
  LegendDefaultProps,
  LineDefaultProps,
  XAxisDefaultProps,
  YAxisDefaultProps,
} from "../../../../Reporting/defaultProps";
import {
  GET_PROMOTION_USAGES_VIEWS_BY_MONTH,
  GetPromotionUsagesViewsByMonthResponse,
} from "./api";
import { chartDataMock } from "./mock";
import { ChartDataItem } from "./models";

const PromotionUsagesViewsChart: FC = () => {
  const { filters, handlePackageName } = useContext(VenueReportingContext);
  const [chartData, setChartData] = useState(chartDataMock);
  const hasPackage = useVenueHasPackage(PackageUID.PROMOTIONS);

  const { response, loading } =
    useRequestByPeriod<GetPromotionUsagesViewsByMonthResponse>(
      GET_PROMOTION_USAGES_VIEWS_BY_MONTH,
      filters,
      hasPackage // havePermission(ReportingPromotions.VIEWS_USAGES)
    );

  const prepareChartData = (stats: ChartDataItem[]) => {
    setChartData(
      stats.map((viewsData) => ({
        ...viewsData,
        month: viewsData.month.slice(0, 3),
      }))
    );
  };

  useEffect(() => {
    if (response) {
      prepareChartData(response.stats);
    }
    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [response, hasPackage]);

  const isChartEmpty = !chartData.some(
    (data) => (data.usages || data.views) !== 0
  );

  return (
    <ChartContainer
      loading={loading}
      title={"Promotions usage and views"}
      isChartEmpty={isChartEmpty}
      locked={!hasPackage}
      chartType={"line-with-y-axis-and-legend-bottom"}
      packageName={handlePackageName(PackageUID.PROMOTIONS)}>
      <LineChart data={chartData}>
        <CartesianGrid
          strokeDasharray="3 3"
          strokeOpacity={0.6}
          color={colors.grey.light}
          vertical={false}
        />
        <Line {...LineDefaultProps} dataKey={"views"} unit={"views"} />
        <Line
          {...LineDefaultProps}
          stroke={"#E5E7EF"}
          dataKey={"usages"}
          unit={"usages"}
        />
        <XAxis {...XAxisDefaultProps} dataKey="month" />
        <YAxis {...YAxisDefaultProps} />
        <Tooltip content={<ChartTooltip />} />
        <Legend {...LegendDefaultProps} />
      </LineChart>
    </ChartContainer>
  );
};

export default PromotionUsagesViewsChart;
