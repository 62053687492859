import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import ConfirmDialog from "../../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../../common/components/ConfirmDialog/Props";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import OcietyTooltip from "../../../../common/components/OcietyTooltip";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useIsManager } from "../../../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../../../common/hooks/useIsSuperAdmin";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { VenueMenu } from "../../../../common/models";
import { ReactComponent as PdfFile } from "../../../../common/svg/icons/pdfFile.svg";
import { ReactComponent as TrashIcon } from "../../../../common/svg/icons/trash.svg";
import { ReactComponent as WarningIcon } from "../../../../common/svg/icons/warning-circle.svg";
import { ReactComponent as PdfLogo } from "../../../../common/svg/pdf.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  SAVE_VENUE_PENDING_MEDIA,
  SaveVenuePendingMediaRequest,
} from "../../../VenuesChangesPending/components/VenuesChangesPendingTable/api";
import { useStyles as useBaseStyles } from "../../styles";
import { VENUE_MENU } from "./api";
import { useStyles } from "./styles";

const VenuePdfMenu: FC = () => {
  const baseClasses = useBaseStyles();
  const venueId = useCurrentVenueId();
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const menuInputRef = useRef<HTMLInputElement>(null);
  const [menuFile, setMenuFile] = useState<File | null>(null);
  const [, deleteMenu] = useRestApi(VENUE_MENU(venueId), "DELETE");
  const [{ data: menu, error, loading }, getVenueMenu] = useRestApi<VenueMenu>(
    VENUE_MENU(venueId),
    "GET",
    undefined,
    true
  );
  const [, updateMenu] = useRestApi<any, FormData>(VENUE_MENU(venueId), "POST");
  const [, savePendingMenu] = useRestApi<any, SaveVenuePendingMediaRequest>(
    SAVE_VENUE_PENDING_MEDIA(venueId),
    "POST"
  );
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const superAdmin = useIsSuperAdmin();
  const manager = useIsManager();

  const getSizeInMb = (bytes: number) =>
    Math.round((bytes / (1024 * 1024)) * 100) / 100;

  const handleMenuChangeClick = () => menuInputRef?.current?.click();
  const fetchMenu = useCallback(() => {
    getVenueMenu().catch((e) => console.error(e));
  }, [getVenueMenu]);
  const hasMenu = Boolean(menu && !error);

  const handleMenuRemove = () => {
    deleteMenu()
      .then(() => {
        fetchMenu();

        if (menuInputRef.current?.value) {
          menuInputRef.current.value = "";
          setMenuFile(null);
        }
      })
      .catch((e) => {
        console.error(e);
        fetchMenu();
      });
  };

  const handleMenuChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file: File | null = e.target.files && e.target.files[0];
    setMenuFile(file);
  };

  const handleSavePendingMenu = async () => {
    await savePendingMenu({ mediaType: "menu" });
    fetchMenu();
  };

  useEffect(() => {
    fetchMenu();
  }, [fetchMenu]);

  useEffect(() => {
    if (menuFile) {
      const formData = new FormData();

      formData.append("menu", menuFile);
      updateMenu(formData)
        .then(() => {
          fetchMenu();
          showAlert(dictionary.venues.venueForm.menuSaveSuccess, "success");
        })
        .catch((e) => {
          showAlert(e.response.data, "error");
        });
    }
  }, [updateMenu, menuFile, getVenueMenu, showAlert, fetchMenu]);

  return (
    <Paper className={baseClasses.section}>
      <Typography variant={"h3"} className={baseClasses.sectionTitle}>
        {dictionary.venues.venueForm.menuTitle}
      </Typography>
      <Grid
        container
        direction="column"
        alignItems={hasMenu ? "flex-start" : "center"}
        spacing={2}
        className={classes.menuSectionContainer}>
        <input
          type="file"
          style={{ display: "none" }}
          ref={menuInputRef}
          onChange={handleMenuChange}
          accept="application/pdf"
        />
        {isConfirmDialogOpen && (
          <ConfirmDialog
            open={isConfirmDialogOpen}
            onClose={() => setIsConfirmDialogOpen(false)}
            onConfirm={handleMenuRemove}
            type={ConfirmModalType.DELETE}
            message={dictionary.venues.venueForm.deleteMenuConfirmationMessage}
          />
        )}
        {menu && !error ? (
          <Grid container direction="row">
            <Paper className={classes.menuFileCard}>
              <PdfFile className={classes.pdfFileIcon} />
              <div className={classes.menuFileMetadata}>
                <Typography variant="h3">{menu.fileName}</Typography>
                <Typography variant="subtitle2">
                  {getSizeInMb(menu.size)} MB
                </Typography>
              </div>
              <IconButton
                className={classes.removeMenuButton}
                onClick={() => setIsConfirmDialogOpen(true)}>
                <TrashIcon />
              </IconButton>
            </Paper>
            {!menu.active && (
              <div className={classes.warningIconContainer}>
                <OcietyTooltip
                  title={
                    superAdmin || manager
                      ? dictionary.venues.venueForm.pendingTooltipSuperAdmin
                      : dictionary.venues.venueForm.pendingTooltipVenueAdmin
                  }>
                  <WarningIcon />
                </OcietyTooltip>
              </div>
            )}
            <div className={classes.uploadMenuButtonContainer}>
              <ButtonPrimary
                className={clsx(classes.saveButton, classes.uploadMenuButton)}
                onClick={handleMenuChangeClick}>
                {!hasMenu
                  ? dictionary.venues.menuUploadFile
                  : dictionary.venues.menuChangeFile}
              </ButtonPrimary>
            </div>
          </Grid>
        ) : (
          <div className={classes.uploaderContainer}>
            <Grid item className={classes.pdfIcon}>
              <PdfLogo />
            </Grid>
            <Grid item>
              <Typography variant="h2">
                {dictionary.venues.uploadMenu}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.label} variant={"body2"}>
                {dictionary.venues.menuFileSize}
              </Typography>
            </Grid>
            <ButtonPrimary
              className={clsx(classes.saveButton, classes.uploadMenuButton)}
              onClick={handleMenuChangeClick}>
              {!hasMenu
                ? dictionary.venues.menuUploadFile
                : dictionary.venues.menuChangeFile}
            </ButtonPrimary>
          </div>
        )}
        {(superAdmin || manager) && menu && !menu?.active && (
          <div className={classes.saveButton}>
            <ButtonSuccess onClick={handleSavePendingMenu}>
              {dictionary.venues.venueForm.save}
            </ButtonSuccess>
          </div>
        )}
      </Grid>
      {loading && <LoadingIndicator withMask />}
    </Paper>
  );
};

export default VenuePdfMenu;
