import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import { PackageUID } from "../../../store/currentVenue/types";
import { GET_ALL_PACKEGES } from "../../../views/Plan/api";
import { Package } from "../../../views/Plan/models";
import { useRestApi } from "../../hooks/useRestApi";
import { AnalyticsPeriod, Gender } from "../../models";

interface Props {
  children?: React.ReactElement;
}

interface VenueReportingContextInterface {
  period: AnalyticsPeriod;
  gender?: Gender;
  ageGroupId?: number;
  filters: {
    gender?: Gender;
    ageGroup?: number;
  };
  handlePackageName: (packUID: PackageUID) => string | undefined;
  setPeriod: Dispatch<SetStateAction<AnalyticsPeriod>>;
  setGender: Dispatch<SetStateAction<Gender | undefined>>;
  setAgeGroupId: Dispatch<SetStateAction<number>>;
}

export const VenueReportingContext =
  React.createContext<VenueReportingContextInterface>({
    period: AnalyticsPeriod.LastSixMoths,
    filters: {},
    setPeriod: () => undefined,
    setGender: () => undefined,
    setAgeGroupId: () => undefined,
    handlePackageName: () => undefined,
  });

const VenueReportingContextProvider: FC<Props> = ({ children }) => {
  const [period, setPeriod] = useState(AnalyticsPeriod.LastSixMoths);
  const [ageGroupId, setAgeGroupId] = useState<number>(0);
  const [gender, setGender] = useState<Gender>();
  const filters = useMemo(() => ({ gender, ageGroupId }), [gender, ageGroupId]);
  const [{ data, loading }, getPackages] = useRestApi<Package[]>(
    GET_ALL_PACKEGES,
    "GET"
  );

  useEffect(() => {
    getPackages().catch((e) => console.error(e));
  }, [getPackages]);

  const handlePackageName = (packUID: PackageUID) => {
    if (data) {
      const currentPackage = data.find((pack) => pack.packageUid === packUID);
      return currentPackage ? currentPackage.name : "";
    }
  };

  return (
    <VenueReportingContext.Provider
      value={{
        period,
        setPeriod,
        gender,
        setGender,
        filters,
        ageGroupId,
        setAgeGroupId,
        handlePackageName,
      }}>
      {children}
    </VenueReportingContext.Provider>
  );
};

export default VenueReportingContextProvider;
