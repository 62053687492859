import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import ButtonBlack from "../../../../../../common/components/ButtonBlack";
import useReplyToReview from "../../../../../../common/hooks/useReplyToReview";
import { ReactComponent as EditIcon } from "../../../../../../common/svg/icons/edit.svg";
import { ReactComponent as MessageIcon } from "../../../../../../common/svg/icons/message.svg";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../utils/dateTimeUtils";
import ReviewRatingBlock from "./components/ReviewRatingBlock";
import ReviewUserBlock from "./components/ReviewUserBlock";
import Props from "./Props";
import { useStyles } from "./styles";

const RecentReviewsSlide: FC<Props> = (props: Props) => {
  const { review, isBar } = props;
  const {
    username,
    userType,
    userAvatar,
    possibleResponse,
    food,
    service,
    userId,
    createdAt,
    updatedAt,
  } = review;
  const classes = useStyles();

  const handleClickReply = useReplyToReview({ userId, userAvatar, username });

  return (
    <div className={classes.root}>
      <div className={classes.reviewContainer}>
        <ReviewUserBlock
          firstName={username}
          userType={userType}
          avatar={userAvatar}
        />
        <ReviewRatingBlock food={food} service={service} isBar={isBar} />
        {createdAt < updatedAt && (
          <div className={classes.updatedLabel}>
            <EditIcon className={classes.editIcon} />
            <Typography variant={"body2"} className={classes.updateText}>
              {dictionary.reviews.updatedReviewText(
                getFormattedDate(updatedAt)
              )}
            </Typography>
          </div>
        )}
        <div className={classes.actionsToolbar}>
          <ButtonBlack onClick={handleClickReply} disabled={!possibleResponse}>
            <MessageIcon className={classes.messageIcon} />
            {possibleResponse
              ? dictionary.reviews.recentReviewReply
              : dictionary.reviews.recentReviewReplyDisabled}
          </ButtonBlack>
        </div>
      </div>
    </div>
  );
};

export default RecentReviewsSlide;
