import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "changeMainPhoto"
  | "radio"
  | "radioNotSelected"
  | "text"
  | "uncheckedIcon"
  | "checkedIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      changeMainPhoto: {
        zIndex: 1,
        position: "absolute",
        height: 40,
        maxWidth: 150,
        backgroundColor: "#FFFFFF",
        borderRadius: 2,
        left: 17,
        bottom: 6,
      },
      radio: {
        paddingRight: 7,
      },
      radioNotSelected: {
        color: colors.grey.disabled,
      },
      text: {
        marginRight: 8,
        color: colors.grey.primary,
      },
      uncheckedIcon: {
        width: 20,
        height: 20,
        borderRadius: "50%",
        border: `1px solid ${colors.grey.disabled}`,
      },
      checkedIcon: {
        width: 20,
        height: 20,
        borderRadius: "50%",
        boxShadow: "0px 1px 3px rgba(254, 84, 84, 0.3)",
        border: `5px solid ${colors.red.primary}`,
      },
    })
);
