import {
  GetChartDataByPeriodRequest,
  PromotionCategory,
} from "../../../../../../common/models";

export const GET_PROMOTION_FILTERED_CATEGORIES =
  "promotions/get-filtered-categories";
export const GET_MOST_POPULAR_PROMOTIONS =
  "reporting/get-most-popular-promotions";

export interface GetMostPopularPromotionsResponse {
  stats: PopularStatsItem[];
}

export interface GetMostPopularPromotionsRequest
  extends GetChartDataByPeriodRequest {
  categoryId?: number;
}

export interface PopularStatsItem {
  name: string;
  usages: number;
  id: number;
}

export interface GetFilteredCategoriesResponse {
  categories: PromotionCategory[];
}
