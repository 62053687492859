import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames = "root";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        textAlign: "center",
        minWidth: 150,
        borderRadius: 3,
        padding: theme.spacing(1.25, 2),
        backgroundColor: colors.grey.primary,
        color: colors.white.primary,
        textTransform: "none",
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        "&:hover, &:active, &:focus": {
          backgroundColor: colors.grey.primary,
          boxShadow: "0 0 25px 0 rgba(0,0,0,0.25)",
        },

        "&:hover $smallArrowIcon": {
          [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(1),
          },
        },
        "&:$disabled": {
          backgroundColor: colors.white.primary,
          border: `1px solid ${colors.grey.medium}`,
          color: colors.grey.disabled,
        },
      },
    })
);
