import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type TableClassNames = "root" | "head" | "body";
export type ClassNames = "cell";
export const useTabelStyles: () => ClassNameMap<TableClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        borderBottom: 0,
      },
      head: {
        backgroundColor: "#fff",
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        color: "#C2C4C7",
      },
      body: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
        color: "#73859B",
        position: "relative",
        margin: 20,
        "&:after": {
          content: "''",
          display: "block",
          width: "100%",
          position: "absolute",
          height: "10px",
          backgroundColor: theme.palette.background.default,
          boxShadow: "0px 0px 38px rgba(59, 72, 118, 0.08)",
          left: 0,
          bottom: 0,
        },
      },
    })
);

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      cell: {},
    })
);
