import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "icon" | "input";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        borderRadius: 4,
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        display: "flex",
        flexBasis: 394,
        background: theme.palette.background.default,
        border: "1.5px solid #E4E6EA",
      },
      icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary,
      },
      input: {
        padding: theme.spacing(1),
        flexGrow: 1,
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "-0.05px",
      },
    })
);
