import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "selectWrapper" | "select" | "selectLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginLeft: 20,
      },
      selectWrapper: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        boxSizing: "border-box",
        backgroundColor: "transparent",
        [theme.breakpoints.up("sm")]: {
          minWidth: 279,
        },
      },
      select: {
        padding: theme.spacing(14 / 8, 20 / 8),
      },
      selectLabel: {
        visibility: "hidden",
      },
    })
);
