import { FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, { FC, useEffect } from "react";

import { useAsyncState } from "../../../../../../../common/hooks/useAsyncState";
import { PromotionCategory } from "../../../../../../../common/models";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { GET_PROMOTION_FILTERED_CATEGORIES } from "./api";
import CategoriesResponse from "./models/CategoriesResponse";
import Props from "./Props";
import { useSelectStyles, useStyles } from "./styles";

const PromotionCategoriesSelect: FC<Props> = (props: Props) => {
  const { currentCategoryId, setCurrentCategoryId, setPage } = props;
  const classes = useStyles();
  const selectClasses = useSelectStyles();
  const [{ response }, setCategories] = useAsyncState<CategoriesResponse>(
    GET_PROMOTION_FILTERED_CATEGORIES
  );

  useEffect(() => {
    setCategories().then();
  }, [setCategories]);

  const handleChangeCategoryFilter = (e: any) => {
    setCurrentCategoryId(e.target.value);
    setPage && setPage(1);
  };

  useEffect(() => {
    setCurrentCategoryId(
      response?.categories[response?.categories.length - 1].id
    );
  }, [response, setCurrentCategoryId]);

  if (!response?.categories || currentCategoryId === undefined) {
    return null;
  }
  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.filterSelect, "select-no-legend")}>
      <Select
        classes={selectClasses}
        id="popular_promotions_category"
        value={currentCategoryId}
        label={dictionary.reporting.promotionCategorySelectLabel}
        onChange={handleChangeCategoryFilter}>
        <MenuItem value="" disabled>
          <em>{dictionary.reporting.promotionCategorySelectLabel}</em>
        </MenuItem>
        {response.categories.map((promotionCategory: PromotionCategory) => (
          <MenuItem key={promotionCategory.id} value={promotionCategory.id}>
            {promotionCategory.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default PromotionCategoriesSelect;
