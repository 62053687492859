import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../../../common/models";
import { colors } from "../../../../../../../../../../../theme/colors";

export type ClassNames = "root" | "wrapper" | "wrapperActive";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      wrapper: {
        display: "flex",
        position: "relative",
        width: "100%",
        height: 73,
        borderRadius: 5,
        textAlign: "center",
        border: `1px solid ${colors.grey.medium}`,
        [theme.breakpoints.down("sm")]: {
          height: 66,
        },
      },
      wrapperActive: {
        backgroundColor: colors.grey.medium,
        border: `1px solid ${colors.grey.secondary}`,
      },
    })
);
