import { http } from "../../../../../../../../../config/api";
import { PromotionType } from "../../../../../../enums";
import IPromotionCategory from "./models/IPromotionCategory";

const PROMOTIONS_CATEGORIES_FOOD = `promotions/categories/food`;
const PROMOTIONS_CATEGORIES_DRINK = `promotions/categories/drink`;
export const PROMOTION_DIETARY_RESTRICTIONS = "promotions/dietary-restrictions";

const PROMOTIONS_SUB_CATEGORIES_DRINK = (parentId: number) =>
  `promotions/sub-categories/${parentId}`;

export const getPromotionsCategories = async (
  type: PromotionType
): Promise<IPromotionCategory[]> => {
  return http
    .get(
      type === PromotionType.DRINK
        ? PROMOTIONS_CATEGORIES_DRINK
        : PROMOTIONS_CATEGORIES_FOOD
    )
    .then((response) => [...response.data.categories])
    .catch((error) => {
      throw error.response;
    });
};

export const getPromotionsSubCategories = async (
  parentId: number
): Promise<IPromotionCategory[]> => {
  return http
    .get(PROMOTIONS_SUB_CATEGORIES_DRINK(parentId))
    .then((response) => [...response.data.categories])
    .catch((error) => {
      throw error.response;
    });
};
