import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import dictionary from "../../../../i18n/en_US/dictionary";
import { ReactComponent as DropZoneIcon } from "../../../svg/icons/dropZoneIcon.svg";
import LinearProgressWithLabel from "../../LinearProgressWithLabel";
import Props from "./Props";
import { useStyles } from "./styles";

const DEFAULT_ACCEPT = ".mp4,image/*";

const Dropzone: FC<Props> = (props: Props) => {
  const {
    onDrop,
    isLock,
    accept,
    classes,
    className,
    description,
    dropZoneIcon,
    label,
    multiple = false,
  } = props;
  const defaultClasses = useStyles();
  const [progressUpload, setProgressUpload] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const progressUpdate = (progressValue: number) => {
    if (progressValue > 0) {
      setProgressUpload(progressValue);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept === "all" ? "" : accept || DEFAULT_ACCEPT,
    onDrop: (files: any[]) => {
      if (!isLock) {
        onDrop(files, progressUpdate, () => {
          setTimeout(() => {
            setProgressUpload(0);
          }, 1000);
        });
      }
    },
    multiple,
  });

  return (
    <div
      {...getRootProps()}
      className={clsx(defaultClasses.root, className, classes?.root)}>
      {!isLock && <input ref={inputRef} {...getInputProps()} />}

      <div className={defaultClasses.dropzoneLabel}>
        {dropZoneIcon || <DropZoneIcon />}
        <div className={defaultClasses.labelWrapper}>
          <Typography className={defaultClasses.label}>
            {label || dictionary.dropzone.label}
          </Typography>
          <Typography variant={"body2"} className={defaultClasses.description}>
            {description || dictionary.venues.mediaContent.maxPhotoSize}
          </Typography>
        </div>
      </div>

      <LinearProgressWithLabel
        hidePercents
        value={progressUpload}
        className={defaultClasses.loadingIndicator}
      />
    </div>
  );
};

export default Dropzone;
