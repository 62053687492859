import React, { FC, useContext, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartContainer from "../../../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../../../common/components/ChartTooltip";
// eslint-disable-next-line max-len
import { VenueReportingContext } from "../../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../../store/currentVenue/types";
import {
  chartAdditionalColor,
  chartPrimaryColor,
  chartSecondaryColor,
  colors,
} from "../../../../../../theme/colors";
import {
  BarDefaultProps,
  LegendDefaultProps,
  XAxisDefaultProps,
  YAxisDefaultProps,
} from "../../../../defaultProps";
import {
  GET_VENUE_PROMOTION_UNIQUE_VIEWS,
  GetVenuePromotionUniqueViewsResponse,
  PromotionUniqueViewsData,
} from "./api";
import { chartDataMock } from "./mock";

const PromotionUniqueViews: FC = () => {
  const [chartData, setChartData] = useState(chartDataMock);
  const { filters, handlePackageName } = useContext(VenueReportingContext);
  const hasPackage = useVenueHasPackage(PackageUID.PROMOTIONS);
  const { response, loading } =
    useRequestByPeriod<GetVenuePromotionUniqueViewsResponse>(
      GET_VENUE_PROMOTION_UNIQUE_VIEWS,
      filters,
      hasPackage
    );

  const prepareChartData = (stats: PromotionUniqueViewsData[]) => {
    setChartData(
      stats.map((viewsData) => ({
        ...viewsData,
        month: viewsData.month.slice(0, 3),
      }))
    );
  };

  useEffect(() => {
    if (response) {
      prepareChartData(response.stats);
    }
    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [response, hasPackage]);

  const isChartEmpty = !chartData.some(
    (data) => (data.new && data.general) !== 0
  );

  return (
    <ChartContainer
      loading={loading}
      locked={!hasPackage}
      isChartEmpty={isChartEmpty}
      title={dictionary.reporting.promotionUniqueViewsTitle}
      chartType={"bar-with-y-axis-and-legend-bottom"}
      packageName={handlePackageName(PackageUID.PROMOTIONS)}>
      <BarChart data={chartData}>
        <CartesianGrid
          strokeDasharray="3 3"
          strokeOpacity={0.6}
          color={colors.grey.light}
          vertical={false}
        />
        <Bar
          {...BarDefaultProps}
          dataKey={"new"}
          fill={chartPrimaryColor}
          unit={dictionary.reporting.venueUniqueViewsNewUnit}
        />
        <Bar
          {...BarDefaultProps}
          dataKey={"general"}
          fill={chartSecondaryColor}
          unit={dictionary.reporting.venueUniqueViewsGeneralUnit}
        />
        <XAxis {...XAxisDefaultProps} dataKey="month" />
        <YAxis {...YAxisDefaultProps} />
        <Tooltip
          content={<ChartTooltip />}
          cursor={{
            stroke: chartAdditionalColor,
            strokeWidth: 2,
            opacity: 0.3,
          }}
        />
        <Legend {...LegendDefaultProps} />
      </BarChart>
    </ChartContainer>
  );
};

export default PromotionUniqueViews;
