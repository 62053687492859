import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import OcietyIcon from "../../../../common/components/OcietyIcon";
import HttpStatusCodes from "../../../../common/httpStatusCodes";
import dictionary from "../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../store/rootReducer";
import { setUserAvatar } from "../../../../store/user/actions";
import {
  AVATAR_PLACEHOLDER_PATH,
  UserState,
} from "../../../../store/user/reducer";
import { uploadAvatar } from "../../../VenueAdminProfile/components/VenueAdminMainForm/components/AvatarUpload/api";
import { deleteAvatar } from "./api";
import Props from "./Props";
import { useStyles } from "./styles";

const Photo: FC<Props> = (props: Props) => {
  const photoInput = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { avatar } = useSelector<AppState, UserState>((state) => state.user);
  const { showAlert } = useContext(AlertContext);
  useEffect(() => {
    if (props.photoUrl) {
      dispatch(setUserAvatar(props.photoUrl));
    }
  }, [dispatch, props.photoUrl]);

  const openImageSelector = () => {
    // @ts-ignore
    photoInput.current.click();
  };

  const handleFileChange = (event: any) => {
    uploadAvatar(event.target.files[0])
      .then((response) => response.data)
      .then((avatarUrl: any) => {
        // @ts-ignore
        photoInput.current.value = null; // Clear input after successfully uploading
        dispatch(setUserAvatar(avatarUrl));
        showAlert("Photo is updated", "success");
      })
      .catch((e) => {
        const status = e?.response?.status;
        if (status === HttpStatusCodes.BAD_REQUEST) {
          showAlert(dictionary.profile.photo.uploadPhotoTxt1, "error");
        }
        if (status === HttpStatusCodes.SERVER_ERROR) {
          showAlert(dictionary.profile.photo.uploadPhotoTxt2, "error");
        }
      });
  };

  const handleDeletePhoto = async () => {
    try {
      await deleteAvatar();
      dispatch(setUserAvatar(AVATAR_PLACEHOLDER_PATH));
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert("Error", "error");
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography variant={"h3"}>{dictionary.profile.photo.title}</Typography>
      </div>
      <Grid container spacing={5}>
        <Grid item xl={2} lg={3} md={3} sm={12} xs={12}>
          <div className={classes.photo}>
            {avatar !== AVATAR_PLACEHOLDER_PATH && (
              <div className={classes.deleteBtn}>
                <IconButton
                  style={{ padding: 0 }}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleDeletePhoto}>
                  <OcietyIcon
                    name={"profileDeletePhotoIcon"}
                    style={{
                      width: 36,
                      height: 36,
                    }}
                    viewBox="0 0 36 36"
                  />
                </IconButton>
              </div>
            )}
            <Avatar style={{ width: 157, height: 157 }} src={avatar} />
            <input
              ref={photoInput}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
              id="photo-selector-input"
              type="file"
            />
          </div>
        </Grid>
        <Grid item xl={6} lg={5} md={5} sm={12} xs={12}>
          <Button
            className={classes.uploadBtn}
            size={"small"}
            onClick={openImageSelector}
            variant="outlined">
            {dictionary.profile.photo.uploadBtn}
          </Button>
          <div className={classes.uploadPhotoTxt}>
            <Typography variant={"body2"}>
              {dictionary.profile.photo.uploadPhotoTxt1}
              <br />
              {dictionary.profile.photo.uploadPhotoTxt2}
            </Typography>
          </div>
        </Grid>
        <Grid item xl={6} lg={4} md={4} sm={12} xs={12}></Grid>
      </Grid>
    </div>
  );
};
export default Photo;
