import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";

import EmptyChart from "../../../../../../common/components/EmptyChart";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import LockedChartMask from "../../../../../../common/components/LockedChartMask";
import { VenueReportingContext } from "../../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import { useGlobalStyles } from "../../../../../../common/styles";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PromotionsReporting } from "../../../../../../permissions/Reporting/PromotionsReporting";
import { PackageUID } from "../../../../../../store/currentVenue/types";
import { havePermission } from "../../../../../../utils/userUtils";
import { useStyles } from "../../../../styles";
import {
  GET_MOST_POPULAR_PROMOTIONS,
  GetMostPopularPromotionsRequest,
  GetMostPopularPromotionsResponse,
  PopularStatsItem,
} from "./api";
import PromotionCategoriesFilter from "./PromotionCategoriesFilter";

const MostPopularPromotions: FC = () => {
  const [currentCategoryId, setCurrentCategoryId] = useState<number>();
  const [usedPromotionsList, setUsedPromotionsList] = useState<
    PopularStatsItem[]
  >([]);
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { filters, handlePackageName } = useContext(VenueReportingContext);
  const haveAccess = havePermission(
    PromotionsReporting.MOST_POPULAR_PROMOTIONS
  );
  const hasPackage = useVenueHasPackage(PackageUID.PROMOTIONS);
  const { response, loading } = useRequestByPeriod<
    GetMostPopularPromotionsResponse,
    GetMostPopularPromotionsRequest
  >(
    GET_MOST_POPULAR_PROMOTIONS,
    useMemo(
      () => ({ categoryId: currentCategoryId, ...filters }),
      [currentCategoryId, filters]
    ),
    hasPackage
  );

  useEffect(() => {
    if (hasPackage) {
      setUsedPromotionsList(response ? response.stats : []);
    } else {
      setUsedPromotionsList([]);
    }
  }, [response, hasPackage]);

  return (
    <Paper className={globalClasses.chartWrapper}>
      <div className={classes.root}>
        {loading && <LoadingIndicator withMask />}
        {!hasPackage && (
          <LockedChartMask
            withMask
            packageName={handlePackageName(PackageUID.PROMOTIONS)}
          />
        )}
        <div className={globalClasses.flexRowBetweenCenter}>
          <Typography variant={"h3"} className={classes.title}>
            {dictionary.reporting.promotionMostPopularTitle}
          </Typography>
          <PromotionCategoriesFilter
            currentCategoryId={currentCategoryId}
            setCurrentCategoryId={setCurrentCategoryId}
          />
        </div>
        {usedPromotionsList.length ? (
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableCellHead}>
                  {dictionary.reporting.promotionPopularTableNameTitle}
                </TableCell>
                <TableCell align={"center"} className={classes.tableCellHead}>
                  {dictionary.reporting.promotionPopularTableUsagesTitle}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usedPromotionsList.map(({ name, usages, id }) => (
                <TableRow key={id} className={classes.tableCell}>
                  <TableCell className={classes.tableCell}>{name}</TableCell>
                  <TableCell align={"center"} className={classes.tableCell}>
                    {usages}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyChart chartType={"bar-with-y-axis"} />
        )}
      </div>
    </Paper>
  );
};

export default MostPopularPromotions;
