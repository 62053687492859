import { Paper, Typography, useTheme } from "@material-ui/core";
import React, { FC, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Legend, RadialBar, RadialBarChart } from "recharts";

import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import OcietyIcon from "../../../../common/components/OcietyIcon";
import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import { useGlobalStyles } from "../../../../common/styles";
import dictionary from "../../../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../../../store/currentVenue/reducer";
import { AppState } from "../../../../store/rootReducer";
import {
  GET_VENUE_PROMOTIONS_USAGE_SATISFACTION,
  ScoreTotal,
} from "../../models";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionsFeedbackScore: FC<Props> = (props: Props) => {
  const { period } = props;
  const theme = useTheme();
  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (appState) => appState.currentVenue
  );
  const widgetWrapperRef = useRef<HTMLDivElement>(null);
  const [asyncState, setAsyncState] = useAsyncState<ScoreTotal>(
    GET_VENUE_PROMOTIONS_USAGE_SATISFACTION(currentVenue?.id || 0, period)
  );
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const score = asyncState.response ? asyncState.response.score : 0;

  useEffect(() => {
    if (!!currentVenue?.id) {
      setAsyncState().then();
    }
  }, [setAsyncState, currentVenue?.id]);

  const data = [
    {
      uv: score,
      pv: 100,
      fill: "#FFCF5D",
    },
    {
      uv: 100,
      pv: 100,
      fill: theme.palette.background.paper,
    },
  ];

  const renderLegend = () => (
    <div>
      <OcietyIcon
        name={"thumbUpGrey"}
        viewBox="0 0 61 61"
        className={classes.icon}
      />
      <div className={globalClasses.flexRowBetweenEnd}>
        <Typography className={classes.percent}>0%</Typography>
        <Typography className={classes.score}>{score + "%"}</Typography>
        <Typography className={classes.percent}>100%</Typography>
      </div>
    </div>
  );

  return (
    <Paper>
      <div className={classes.root} ref={widgetWrapperRef}>
        <Typography variant={"h3"} className={classes.title}>
          {dictionary.dashboard.promotionFeedbackTitle}
        </Typography>
        {asyncState.loading && <LoadingIndicator withMask />}
        <RadialBarChart
          style={{ maxHeight: 200, margin: "auto" }}
          width={346}
          height={346}
          innerRadius="70%"
          outerRadius="100%"
          data={data}
          startAngle={180}
          endAngle={0}>
          <RadialBar label={false} background dataKey="uv" />
          <Legend
            wrapperStyle={{
              bottom: "10px",
            }}
            iconSize={10}
            width={275}
            height={100}
            content={renderLegend()}
            layout="vertical"
            verticalAlign="middle"
            align="center"
          />
        </RadialBarChart>
      </div>
    </Paper>
  );
};

export default PromotionsFeedbackScore;
