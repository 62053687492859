import { MobileAppUserType } from "../../../../common/models";

export const GET_VENUE_REVIEWS_SCORING = "reviews/scoring";

export interface VenueReviewsScoring {
  userType: MobileAppUserType;
  reviewsPercent: number;
  averageRating: number;
}

export interface GetVenueReviewsScoringResponse {
  stats: VenueReviewsScoring[];
}
