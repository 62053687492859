import { http } from "../../../../../../../../config/api";
const UPLOAD_VENUE_PHOTOS = (venueId: string) => `/venue/${venueId}/photos`;
export const uploadPhoto = (
  photo: any,
  tag: string,
  venueId: string,
  progressUpdate: (progressPercent: number) => void
) => {
  const formData = new FormData();
  formData.append("photo", photo);
  formData.append("tag", tag);
  return http.post(UPLOAD_VENUE_PHOTOS(venueId), formData, {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressUpdate(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    },
  });
};
