import { FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, { ChangeEvent, FC, useEffect } from "react";

import { PackageUID } from "../../../store/currentVenue/types";
import { useRestApi } from "../../hooks/useRestApi";
import { useVenueHasPackage } from "../../hooks/useVenueHasPacakage";
import { GenderSelectOption, GET_GENDER_FILTER_OPTIONS } from "./api";
import Props from "./Props";
import { useStyles } from "./styles";

const GenderSelect: FC<Props> = (props: Props) => {
  const { gender, onChangeGender, className } = props;
  const classes = useStyles();
  const hasPackage = useVenueHasPackage(PackageUID.CUSTOMER);
  const [{ data }, getGenderOptions] = useRestApi<Array<GenderSelectOption>>(
    GET_GENDER_FILTER_OPTIONS,
    "GET"
  );
  useEffect(() => {
    if (hasPackage) {
      getGenderOptions().catch((e) => console.error(e));
    }
  }, [getGenderOptions, hasPackage]);

  const genderOptions = data || [];

  const handleChangeGender = (e: ChangeEvent<any>) => {
    const { value } = e.target;

    onChangeGender(value === "0" ? undefined : value);
  };

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.root, "select-no-legend", className)}>
      <Select
        disabled={!hasPackage}
        value={gender || "0"}
        onChange={handleChangeGender}
        classes={{ select: classes.select }}>
        <MenuItem key={"none"} value={"0"} selected>
          {"Gender"}
        </MenuItem>
        {genderOptions.map((genderOption) => (
          <MenuItem key={genderOption.value} value={genderOption.value}>
            {genderOption.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GenderSelect;
