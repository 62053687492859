import { APP_NAME, CURRENCY_SYMBOL, isPackagesDisabled } from "../../config";
import {
  VenueBoostPrices,
  VenueBoostSize,
} from "../../views/Plan/components/PackageCardGroup/getVenueBoostPriceLabel";

export default {
  app: {
    networkError:
      "Network problem. Please, check your internet connection " +
      "or try again later.",
    permissionsError: "Sorry, you haven't permissions to see that content",
    unauthorizedError: "Session expired. Please Log in to your account.",
    oopsError: "Opps",
    success: "Success",
  },
  notifications: {
    clearError:
      "Can't clear notifications at the moment. Please, try again later",
    dropdownTitle: "Notifications",
    venueChanged: (venueName: string) =>
      `Current venue has been changed to the "${venueName}"`,
    clearButton: "Clear all",
    emptyText: "There are no new notifications",
    insultingMessageError:
      "Inappropriate keywords are prohibited. Please try again.",
  },
  plan: {
    activeLabel: "Active",
    addPackageButton: "Add Package",
    total: "Total:",
    venueBoostWillNotify: "You will be notified when the package finishes",
    clicksLeft: (clicksQty: number) => `${clicksQty} clicks left`,
    venueBoostOptionLabel: (price: VenueBoostPrices, size: VenueBoostSize) =>
      `${
        size === VenueBoostSize.PRO
          ? "Pro"
          : size === VenueBoostSize.STANDARD
          ? "Standard"
          : "Basic"
      } - ${size} clicks ${isPackagesDisabled ? "" : CURRENCY_SYMBOL + price}`,
    totalAmountWithSymbol: (symbol: string, amount: number) =>
      `${symbol} ${amount}`,
    addPaymentMethod: "Add payment method",
    changePlan: "Change Plan",
    saveDefaultPaymentMethodButton: "Save",
    changePaymentMethod: "Change Payment Method",
    nextPaymentDate: (nextPaymentDate: string) => `on ${nextPaymentDate}`,
    cardExpires: (expirationDate: string) => `Expires ${expirationDate}`,
    packageAlertButton: "OK",
    noOtherPackagesWithFullAccess: (packageName: string) =>
      `Package "${packageName}" can't be purchased with the "Full Access" package. To add "${packageName}" to your cart, please, remove "Full Access" package from your cart first`,
    noFullAccessWithOtherPackages:
      '"Full Access" package can\'t be purchased with other packages. To add "Full Access" package to your cart, please, remove other packages from cart first',
    clearCart: "Clear Cart",
    purchaseSucceedTitle: "Thank you",
    reviewPackages: "Review Packages",
    takeToPackages: "Take me to packages",
    defaultPaymentMethod: "Payment Method",
    purchaseSucceedMessage: "Payment was successful!",
    purchaseSucceedButtonPrimary: "Okay",
    emptyCartMessage:
      "There are no items in your cart, Review Packages and Add",
    fullAccessWithOtherPackagesConflictWarning: (
      currentPackagesNames: string[]
    ) =>
      `After purchasing "Full Access" package, subscription for all your current packages (${currentPackagesNames.join(
        ", "
      )}) will be automatically removed. But all their benefits will be still available in the scope of "Full Access" package`,
    cartItemsTitle: (itemsQty: number) =>
      `You have ${itemsQty} ${
        itemsQty > 1 ? "items" : "item"
      } in your cart for the venue `,
    cancelAction: "Cancel",
    packagePurchaseSucceed: "Package has been purchased successfully",
    packagePurchaseError: "Something went wrong. Please, try again later",
    cancelPackageSucceed: "Package has been cancelled successfully",
    cancelPackagePopupTitle: "Cancel Package",
    cancelPackageError:
      "Something went wrong. Package is not cancelled. please, try again later",
    confirmAction: "Confirm",
    packageBuyButton: "Purchase",
    whatIncluded: "What is included in the package",
    packageCancelButton: "Cancel",
    paymentConfirmationPopupTitle: "Shopping Cart",
    noCurrentPackageMessage: "There is no current package",
    pageTitle: "Membership Plans",
    cancelPackageConfirmationMessage: (packageName = "") =>
      `Are you sure you want to cancel package ${packageName}?`,
    fullAccessDiscountLabel: (discountPercent: number) =>
      `Save ${discountPercent}%`,
    cancelPackage: "Cancel Package",
    defaultPackage: "Default Package",
    inCart: "In Cart ✓",
    nextPayment: "Next Payment",
    periodLabel: "/month",
    tabLabelAnalytics: "Analytics",
    tabLabelProfileUpgrades: "Profile Upgrades",
    unlockFullAccess: "Unlock Full Access",
    lockFullAccess: "Cancel Full Access",
    packagesConflict: "Packages Conflict",
    packageNotExist: "Sorry, this package doesn't available",
    fullAccessPurchased: (packageName: string) =>
      `Sorry, you can't activate "${packageName}" package, because you already have enabled "Full Access" package, which included all features from this package. If you want to enable only benefits from "${packageName}" package, please, disable "Full Access" first.`,
    fullAccessPlanConflict:
      'Sorry, you can\'t activate those packages, because you already have enabled "Full Access" package, which included all features from this package. If you want to enable only benefits from package, please, disable "Full Access" first.',
    toAccessPurchasePackageTooltip: (packageName?: string) =>
      `Chart is locked and data on it is not real data from your venue. Access to this chart and real reporting available in "${
        packageName === "Promotions Package" ? packageName : packageName
      }" package. Click to activate it.`,
    toAccessFieldPurchasePackageTooltip:
      'To fill this field activate "Profile Upgrades" package.',
  },
  reviews: {
    pageTitle: "Reviews",
    allReviewsSectionTitle: "Reviews",
    userTypeSelectLabel: "User type",
    foodRatingSelectLabel: "Food rating",
    serviceRatingSelectLabel: "Service rating",
    allReviewsSearchInputPlaceholder: "Search user",
    allReviewsTablePhotoColumnName: "Photo",
    allReviewsTableUserColumnName: "User",
    allReviewsTableUserTypeColumnName: "User Type",
    allReviewsTableCreatedDateColumnName: "Created at",
    allReviewsTableUpdatedDateColumnName: "Updated at",
    allReviewsTableFoodRatingColumnName: "Food rating",
    allReviewsTableServiceRatingColumnName: "Service rating",
    allReviewsTableActionColumnName: "Action",
    recentTitle: "Recent Reviews",
    recentEmpty:
      "There are no reviews for your venue yet. Please, check back soon",
    recentReviewReply: "Message",
    allReviewsReply: "Message",
    recentReviewReplyDisabled: "No permission from user to reply",
    allReviewsReplyDisabled: "No permission from user to reply",
    foodLabel: "Food",
    foodDrinksLabel: "Food & Drinks",
    serviceLabel: "Service",
    seeAllReviewsButton: "See all reviews",
    criticsReviewsTrendUnit: "critics reviews",
    scoringUserUnit: "Users",
    scoringCriticUnit: "Critics",
    usersReviewsTrendUnit: "users reviews",
    reviewsTrendChartTitle: "Reviews Trend",
    reviewsScoringChartTitle: "Reviews Scoring",
    reviewsImprovementsChartTitle: "Reviews Improvements",
    updatedReviewText: (date: string) => `Updated on ${date}`,
  },
  currentVenue: {
    notActiveMessage:
      "This venue is not yet active. Please complete all required fields to activate.",
  },
  venueClaimPending: {
    getRequestError:
      "An error occurred, during getting Venue claim request. Claim request does not exist",
    approveButton: "Approve Claim",
    rejectButton: "Deny request",
    approveSuccess: "Claim request has been approved successfully",
    approveError: "An error occurred, during approving Venue claim request",
    rejectSuccess: "Claim request has been rejected successfully",
    rejectError: "An error occurred, during rejecting Venue claim request",
    listTitle: "Venues Claim Pending",
    requestViewTitle: "Venue Claim Pending",
    table: {
      headingVenueName: "Venue",
      headingView: "View",
      headingDate: "Date",
      headingUser: "User",
    },
    adminInformation: {
      blockTitle: "Admin information",
      firstNameLabel: "First Name",
      lastNameLabel: "Last Name",
      titleLabel: "Title",
      companyLabel: "Company",
      phoneNumberLabel: "Phone Number",
      emailLabel: "Email",
      commentLabel: "Comment",
    },
    informationForAdmin: {
      title: "Information for client",
      commentLabel: "Comment",
      commentPlaceholder: "Enter your comment",
      commentHelper: "This will be included in an email to venue admin",
    },
  },
  mediaRequests: {
    pageTitle: "Media Requests",
    photoRequestLabel: "Requests for Venues",
    searchPlaceholder: "Search user",
    activeRequest: (activeRequests: number) =>
      `${activeRequests} Active ${
        activeRequests === 1 ? "Request" : "Requests"
      }`,
    numberOfSelectedPhotos: (selected: number, count: number) =>
      `${selected}/${count} Selected`,
    approveSuccessPopup: "Photos approved successfully",
    declineSuccessPopup: "Photos declined",
    approveErrorPopup: "Something happened during photos approving",
    declineErrorPopup: "Something happened during photos declining",
    noPhotosToApproveTitle: "There Is No Media Request at the Moment",
    noPhotosToApproveMsg:
      "Requests will appear as soon as one of the users send a photo to the mobile application.",
    fullScreenPhotoTitle: "Photo",

    tableColumnNames: {
      photoColumn: "Photo",
      venueColumn: "Venue",
      usernameColumn: "Username",
      dateColumn: "Date",
      categoryColumn: "Category",
      actionsColumn: "Actions",
    },
    actions: {
      accept: "Accept",
      decline: "Decline",
    },
    selectCategoryLabel: "Select Category",
    confirmDeclineDialogMessage: (activeRequests: number) =>
      `Are you sure you'd like to decline ${
        activeRequests === 1 ? "this image" : "these images"
      }? This cannot be undone.`,
  },
  reportedMedia: {
    pageTitle: "Reported Media",
    reportHeader: "Reports",
    selectedReportsOutOf: (selected: number, outOf: number) =>
      `${selected}/${outOf} Selected`,
    activeRequest: (activeRequests: number) =>
      `${activeRequests} Active ${
        activeRequests === 1 ? "Request" : "Requests"
      }`,
    confirmDeclineDialogMessage: (activeRequests: number) =>
      `Are you sure you'd like to decline ${
        activeRequests === 1 ? "this report" : "these reports"
      }? This cannot be undone.`,
    acceptSuccessPopup: "The media has been removed successfully",
    declineSuccessPopup: "Reports has been declined successfully",
    acceptErrorPopup: "Something happened during reports accepting",
    declineErrorPopup: "Something happened during reports deleting",
    noReportedMediaTitle: "There Is No Reported Media at the Moment ",
    noReportedMediaMsg:
      "Media will appear as soon as one of users report a photo/video in the mobile application.",

    tableColumnNames: {
      mediaColumn: "Media",
      venueColumn: "Venue",
      dateColumn: "Date",
      emailColumn: "Email",
      commentColumn: "Comment",
      actionsColumn: "Actions",
    },
    actionHeaderTooltip:
      'If the report has convincing comments, then tap on "Accept" button to delete media forever; if not, tap on the "Decline" button to delete the report and keep the media.',
    actions: {
      accept: "Accept",
      decline: "Decline",
    },
  },
  confirmDialog: {
    title: "Are you sure?",
    cancelButton: "Cancel",
    deleteButton: "Yes, delete",
    yesDeleteButton: (additionalText: string) => `Yes, ${additionalText}`,
    confirmButton: "Confirm",
  },
  successDialog: {
    title: "Success!",
    okayButton: "Okay",
  },
  pos: {
    saveDefaultSuccess: "Default POS has been updated successfully",
    saveDefaultError: "An error occurred, during updating default POS",
    selectPosLabel: "Select POS to configure",
    authorization: {
      appModeLabel: "Application Mode",
      productionModeLabel: "Production",
      sandboxModeLabel: "Sandbox",
      squareSaveTokenSuccess: "SquareUp POS has been connected successfully",
      vendSaveTokenSuccess: "Vend POS has been connected successfully",
      squareSaveTokenError:
        "An error occurred, during connecting Square POS. Please, check your credentials and application mode",
      vendSaveTokenError:
        "An error occurred, during connecting Vend POS. Please, check your credentials and application mode",
      cloverSaveTokenSuccess: "Clover POS has been connected successfully",
      cloverSaveTokenError:
        "An error occurred, during saving access token. Please, check your credentials and application mode",
      disconnectSuccess: "POS has been disconnected successfully",
      disconnectError:
        "An error occurred, during disconnection POS system. please, try again later",
      title: "Venue POS Configuration",
      squareUpAuthSuccess: "SquareUp POS has been connected successfully",
      selectSquareUpLocationLabel: "Location Id",
      selectVendLocationLabel: "Location Id",
      defaultPOSSelectLabel: "Select Default POS",
      defaultPOSSelectNoneLabel: "None",
      cloverTabName: "Clover",
      cloverDialogTitle: "Clover authorization",
      cloverDialogCancel: "Cancel",
      copyUrlToClipboardSuccess:
        "The link copied to the clipboard successfully",
      cloverDialogAuthButton: "Go to Auth",
      defaultPOSSave: "Save",
      saveButton: "Save",
      saveTokenButton: "Save Access Token",
      squareUpAuthError:
        "An error occurred during connection SquareUp POS. Please try again later",
      squareUpTabName: "SquareUp",
      cloverAppSecretFieldLabel: "App Secret",
      cloverAppIdFieldLabel: "App Id",
      squareUpAccessTokenFieldLabel: "Access Token",
      vendAccessTokenFieldLabel: "Access Token",
      connectButton: "Connect",
      disconnectButton: "Disconnect",
      connectedText: "Connected",
    },
  },
  contactUs: {
    companyNameFieldLabel: "Company name",
    emailAddressLabel: "Email Address",
    emailFieldLabel: "Email",
    pageTitle: "Contact Us",
    phoneNumberLabel: "Phone Number",
    phoneNumberFieldLabel: "Phone number",
    locationLabel: "Location",
    messageFieldLabel: "Message",
    sendEmailSuccessMessage: "Letter has been sent successfully",
    sendEmailErrorMessage: "An error occurred, during sending letter",
    sendMessageTitle: "Send Message",
    subjectFieldLabel: "Subject",
    submitFormButton: "Send",
    yourNameFieldLabel: "Your name",
  },
  dropzone: {
    label: "Drag your file here",
  },
  termsAndConditions: { pageTitle: "Terms & Conditions" },
  dashboard: {
    emptyVenueRequests: "There are no new requests yet",
    totalUsers: "Users",
    totalVenues: "Venues",
    totalActivePromotions: "Active Promotions",
    totalActiveSubscriptions: "Active Subscriptions",
    mostActiveDaysTitle: "Most Active Days",
    pageTitle: "Dashboard",
    popularPromotionEmpty: "There are no active promotions yet",
    createPromotionText: "Create",
    popularPromotionTitle: "Most Popular Promotion",
    promotionFeedbackTitle: "User Promotion Feedback Score",
    widgetPromotionsActiveDescription: "Total Active Promotions",
    widgetPromotionsUsedDescription: "Total Promotions Used",
    widgetRecommendationsDescription: "Total Recommendations",
    widgetReviewsDescription: "Total Reviews",
    venueRequestsTitle: "Venue Latest Requests",
  },

  webpush: {
    subscribeSuccess: "Subscription was saved successfully",
  },
  auth: {
    blockedError:
      "Your account is blocked due to many wrong login attempts." +
      " We sent you an email with instructions on how to get access back",
    lastAttemptBeforeBlockError:
      "If you enter the password incorrectly again, we will temporarily" +
      " lock your account and send you an email to reset your password",
    inactiveError:
      "Your account is inactive now. Please, contact an Ociety support for more info",
    credentialsError:
      "Invalid credentials. Check your email and password and try again.",
    deletedError:
      "Your account is deleted. Please contact an Ociety support for more info",
    authError: "Something went wrong. Please try again later",
    enterMfaToSignIn:
      "The code was sent to the default MFA device you had selected.",
    forgotPasswordLink: "Forgot Password",
    loginDescription: "A web management portal for venues and events",
    loginTitle: "Login as Venue Admin",
    loginOGTitle: "Ociety Business Login",
    loginDescriptionTag:
      "Explore critical customer data, control promotions and grow your business on this easy-to-use admin panel",
    mfaCodeFieldLabel: "One-Time Code",
    passwordFieldLabel: "Password",
    sendMFACodeButtonText: "Submit",
    cancelMfa: "Cancel",
    logIn: "Log in",
    mfaTitle: "One-Time Passcode",
    toYourAccount: " to Ociety",
    rememberMe: "Authorize this device for 60 days",
    registerLink: "Register here",
    registerQuestion: "Don't have an account?",
    signInButtonText: "Log in",
    emailFieldLabel: "Email",
    validationError: "Invalid credentials, please check and try again",
    unknownError: "Something went wrong. Please, try again later",
    welcomeMessage: "Welcome to the Ociety.com",
  },
  forgotPassword: {
    usernameFieldLabel: "Email",
    ok: "OK",
    title: "Forgot Password",
    username: "Login",
    requestBtnText: "Request",
    continue: "Continue",
    cancel: "Cancel",
    emailDescription:
      "Enter your email address you signed up with and we will send instructions on how to reset your password",
    forgotPasswordError: "Invalid forgot password request",
    forgotPasswordSuccess: "Password changed successfully.",
    msgEmailSend: (email: string) =>
      `Message with reset password link sent to your email: ${email}`,
    password: "New Password",
    confirmNewPassword: "Confirm New Password",
    code: "One-Time Passcode",
    doesNotMeetRequirements: "Password does not meet the requirements",
    change: "Change password",
    useMethod: "Get one-time password using which method?",
    passwordChanged: "Your password has been changed, your can",
    passwordRequirementsTitle: "Password must meet the following requirements:",
    login: "log in",
    enterAndConfirm: "Enter a new password below and re-enter to confirm.",
    sentSecurityCode: (phone: string) => `A one-time passcode was sent to 
      your default MFA device ${phone}. Enter this into the one-time passcode 
      box below.`,
    stepTitle: (step: number) => `Step ${step}`,
    withLetters: "Should contain Latin letters",
    withNumbers: "Should contain numbers",
    withSpecial: "At least one special symbol",
    withCapital: "At least one capital letter",
    withMinimum: (qty: number) => `Minimum ${qty} characters`,
  },
  notFound: {
    title: "Page not found",
    description: "We can`t seem to find the page you`re looking for",
    toHomeButton: "Home",
  },
  promotions: {
    titlePage: "Promotions",
    addBtnTxt: "Add new promotion",
    deletePromotonError:
      "An error occurred, during delete promotion, Please try again later",
    deletePromotionSuccess: "Promotion successfully deleted",
    changeMainPromotonError:
      "An error occurred during setting promotion as primary, please try again later",
    applyToAllLabel: "Apply to all",
    drinkCategoriesLabel: "Select drink category",
    foodCategoriesLabel: "Select food category",
    subCategoriesLabel: "Select sub-category",
    categoriesLabel: "Select category",
    activePromotion: "Show only active promotions",
    noPromotions: "No promotions at the moment",
    quantityLimitationTitle: "Promotion Quantity",
    maximumUsagesAcrossAllTitle: "Max Usages Across All Customers",
    maximumUsagesPerCustomerTitle: "Max Usages per Customer",
    notificationTitle: "Notify When Only This Many Left",
    typeQuantityLabel: "Type Quantity",
    analytics: {
      promotionsUsagesAndViewsQtyChartTitle:
        "Venue Promotions views and usages Qty analytics",
      promotionsUsagesAndViewsPercentsChartTitle:
        "Venue Promotions views and usages percentage analytics",
      usagesPercentsSeriesName: "Usages %",
      viewsPercentsSeriesName: "Views %",
      usagesQtySeriesName: "Usages Qty",
      viewsQtySeriesName: "Views Qty",
      forbiddenError:
        "Sorry, you haven't permissions to see analytics for this venue.",
      error: "Something went wrong, please try again later.",
    },
    tableHeaders: {
      photo: "Photo",
      primary: "Primary",
      name: "Name",
      venue: "Venue",
      views: "Views",
      usage: "Usage",
      startDate: "Start date",
      expirationDate: "Expiration date",
      status: "Status",
      actions: "Actions",
      activeHours: "Active hours",
    },
    createPromotionForm: {
      category: "Category",
      subCategory: "Sub-category",
      modalTitleEdit: "Edit promotion",
      modalTitleCreate: "Create new promotion",
      promotionAmount: "Promotion Amount",
      promotionSingleItemLimit: "Single item",
      promotionItemsGroupLimit: "Items group",
      productName: "Product Name",
      groupName: "Group Name",
      productPrice: `Product Price (${CURRENCY_SYMBOL})`,
      averagePrice: `Average Price (${CURRENCY_SYMBOL})`,
      percent: "Percent (%)",
      maxAmount: `Max Amount (${CURRENCY_SYMBOL})`,
      promotionTypeLabel: "Applies to",
      fullOrderPromotionTypeLabel: "Entire Order",
      foodPromotionTypeLabel: "Only Food Items",
      drinkPromotionTypeLabel: "Only Drink Items",
      disabledFullOrderTooltip:
        'To enable possibility to use "Entire Order" promotion you need to upload your venue pdf menu',
      msgSuccessUpdated: "Promotion successfully updated",
      msgSuccessCreated: "Promotion successfully created",
      msgSuccessDeleted: "Promotion successfully deleted",
      msgErrorUpdated: "An error occurred, during promotion updating",
      msgErrorCreation: "An error occurred, during promotion creation",
      msgErrorDeleting: "An error occurred, during promotion deleting",
      selectAgeGroup: "Target Audience",
      allAgeGroupLabel: "All Age Groups",
      selectAgeGroupInfo:
        "Target audience. Promotion will be applied to all selected age groups",
      promotionCodeLabel: "Promotion Code",
      descriptionFieldLabel: "Description",
      startDateFieldLabel: "Start date and time",
      endDateFieldLabel: "End date and time",
      title: "Create New Promotion",
      successMessage: "A promotion has been created successfully.",
      cancelButton: "Cancel",
      createButton: "Create",
      createPromotionError:
        "An error occurred during creation " +
        "new promotion. Please try again later",
      startLabel: "Start",
      endLabel: "End",
      unlimitedLabel: "Unlimited",
      limitedLabel: "Limited",
      errorFetchData: "Error fetch data",
      incorrectResponse: "Incorrect response",
      promotionWithoutUploadingHint:
        "You can proceed with the creation of promotion without uploading a media. We will set it for you based on chosen category",
      popupWarningOnClose:
        "Are you sure you want to close this page? Progress will not be saved.",
      popupWarningOnDeletePromotionAndProgress:
        "Are you sure you want to delete your progress? Promotion will not be saved.",
      uploadPromotionMedia: "Upload Media for Your Promotion (Optional)",
      usableOnSpecifiedDays: "Useable only during these days & times",
      chooseDayAndTime: "Choose days and hours of your promotion",
      backButtonLabel: "Back",
      cancelButtonLabel: "Cancel",
      deleteButtonLabel: "Delete promotion",
      descriptionTitle: "Description (Optional)",
      dietaryRestrictionsTitle: "Dietary Restrictions: Is This Food/Drink...? ",
      optionalLabel: " (Optional)",
    },
    formatTime: (isDay: boolean) => (isDay ? "PM" : "AM"),
    duplicatePromotion: {
      createdPromotionDuplicate:
        "Promotion successfully duplicated. Please change the promotion period.",
      errorPromotionDuplicate:
        "An error occurred during duplicate promotion. Please try again later",
    },
    promotionsUsages: {
      titlePage: (name: string) => `${name} / Promo codes`,
    },
    primaryPromotionTooltip:
      "Selected promotion will be displayed in venues list in mobile app",
    activeHoursTooltip:
      "Some of the promotions have limited active hours, for your convince we added those hours into table below",
    refresh: "Refresh data",
    openDeleteDialog: "Open delete dialog",
    openDuplicateDialog: "Open duplicateDialog",
    successfullySaved: "Successfully saved",
    activeHours: "Active Hours",
    onDeleteMessage:
      "Are you sure you would like to delete this? This cannot be undone.",
    onDuplicateMessage:
      "Are you sure you would like to duplicate this promotion?",
  },
  managerPromotions: {
    tabs: {
      active: "Active",
      history: "History",
    },
    headers: {
      activeHours: "Active hours",
      startDate: "Start date",
      expirationDate: "Expiration date",
      daysAndHours: "Active Days and Hours",
    },
    statusOptions: {
      pending: "Pending",
      approved: "Approved",
      paused: "Paused",
      expired: "Expired",
      cancelled: "Cancelled",
    },
    duplicateBtn: "Duplicate",
    greeting: (name: string) => `Hello, ${name}`,
  },
  messages: {
    pageTitle: "Messages",
    emptyTitle: "No Messages",
    emptyMessage: "Chat messages will appear here",
    youPrefix: "You: ",
  },

  Password: {
    spaces: "Without spacecs",
    digits: "Must have digits",
    lowercase: "Must have lowercase letters",
    uppercase: "Must have uppercase letters",
    max: (length: number) => `Maximum length ${length}`,
    min: (length: number) => `Minimum length ${length}`,
  },
  reporting: {
    ageFilterAll: "All",
    ageFilterMale: "Men",
    ageFilterFemale: "Women",
    ageFilterNonBinary: "Non-binary",
    pageTitle: "Reporting",
    friTrialPlanButton: "Start 14-day free trial",
    tabLabelGeneral: "General",
    tabLabelLiveStats: "Live stats",
    tabLabelPromotion: "Promotion",
    tabLabelRecommendations: "Recommendations (BETA)",
    venueGenderTitle: "Gender",
    venueImpressionsSearchTitle: "Impressions venue nearby search",
    venueImpressionsSearchUnit: "Impressions",
    venueMediaContentViewsTitle: "Media content views",
    venueMediaContentViewsVideosUnit: "Videos views",
    venueMediaContentViewsImagesUnit: "Images views",
    venueUniqueViewsTitle: "Venue unique views (Unique user view)",
    venueUniqueViewsNewUnit: "New views",
    venueUniqueViewsGeneralUnit: "General views",
    venueViewsTitle: "Venue views",
    venueViewsAgeTitle: "Age range",
    venueViewsUnit: "views",
    venueViewsByDeviceAndroidUnit: "% Android",
    venueViewsByDeviceIphoneUnit: "% iPhone",
    venueViewsByDeviceTitle: "Venue views by device",
    mostPopularNearbyVenuesUnit: (venueName: string) => `${venueName} views`,
    promotionCategorySelectLabel: "Category",
    promotionMostPopularTitle: "Most popular promotions",
    promotionPopularTableUsagesTitle: "Usages",
    promotionPopularTableNameTitle: "Name",
    promotionPopularEmptyText:
      "There are no used promotions for the selected category and period.",
    promotionViewsTitle: "Promotion views",
    promotionUniqueViewsTitle: "Promotion unique views (open, click)",
    promotionUsagesByDeviceTitle: "Promotion usages by device",
    promotionUsagePopularHoursTitle: "Promotion usage popular hours",
    promotionViewsFoodUnit: "Food promotion views",
    promotionViewsDrinksUnit: "Drinks promotion views",
    promotionViewsBillUnit: "Full bill promotion views",
    promotionRecommendationPositionTitle:
      "Average position on recommendations screen",
    promotionRecommendationsImpressionsTitle:
      "Impressions on recommendation screen",
    recommendationsPopularNearbyVenuesTitle: "Most popular venues nearby",
    unlockReportingButton: "Unlock Reporting",
    noDataEmptyChartTitle: "No data available",
    noDataEmptyChartText:
      "There is currently not enough data to populate this graph.",
    reportingRecommendationBetaDialogTitle: "Beta Version",
    reportingRecommendationBetaDialogMessage:
      "Our recommendation engine is currently in a beta version.",
    reportingRecommendationBetaDialogText:
      "With further usage and more developed AI, these recommendations will improve!",
  },
  reportingLiveStats: {
    title: "Current active users",
    filterUserTypeAll: "All",
    filterUserTypeUser: "User",
    filterUserTypeCritic: "Critic",
    filterUserTypeLabel: "User type",
    activeUser: "active user",
    activeUsers: "active users",
    searchUser: "Search user",
    headerUserType: "User Type",
    headerScreens: "Screens",
    atLocation: "At location",
    views: "Views",
    promotionUsed: "Promotion Used",
    pushPromotion: "Push a Promotion",
    errorFetchData: "Error fetch data",
    unknown: "Unknown",
    yes: "Yes",
    no: "No",
    userTypeFilter: "User type",
  },
  footer: {
    aboutUs: "About Us",
    copyright: `Copyright ${new Date().getFullYear()} ${APP_NAME}`,
    termsAndConditions: "Terms & Conditions",
    privacy: "Privacy Policy",
    termsOfSale: "Terms of Sale",
    termsOfUse: "Terms of Use",
    caPrivacy: "CA Privacy",
    contactUs: "Contact us",
  },
  settings: {
    resetChangesButton: "Reset to defaults",
    saveButton: "Save Changes",
    successMessage: "Config was saved successfully.",
  },
  sidebar: {
    billingInformationLink: "Billing Information",
    dashboardLink: "Dashboard",
    messagesLink: "Messages",
    promotionsLink: "Promotions",
    reportingLink: "Reporting",
    venuesChangesLink: "Venues Changes",
    userPhotosLink: "Media Requests",
    reportedMediaLink: "Reported Media",
    venuesCreationRequests: "New Venues",
    venuesClaimRequests: "Venues Claims",
    recommendationsLink: "Recommendations",
    reviewsLink: "Reviews",
    planLink: "Membership Plans",
    venueSettingsLink: "Venue Settings",
    venuesLink: "Venues",
    upgradeButton: "Upgrade",
    upgradeDescription:
      "You can improve your profile by purchasing the full package" +
      " or separately the graphics you need",
    upgradeTitle: "Upgrade",
  },
  stepsIndicator: {
    step: (index: number) => `Step ${index}`,
  },
  venueAdmin: {
    profile: {
      title: "Profile settings",
      uploadPhoto: "Upload Photo",
      mainForm: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        phone: "Phone",
        birthday: "Birthday",
        timezone: "Timezone",
        country: "Country",
        city: "City",
        birthDayHelperText: "Choose your date in calendar",
      },
      changePassword: {
        title: "Change password",
        currentPassword: "Current password",
        newPassword: "New password",
        confirmPassword: "Confirm password",
        btnText: "Change password",
        successMessage: "Success password changed",
      },
      mfa: {
        title: "MFA",
        disableMFAError:
          "An error occurred during disable " +
          "MFA method. Please try again later",
        disableMFASuccess: "MFA method disabled",
        enableBtn: "Enable",
        default: "default",
        disableBtn: "Disable",
        changeDefaultMethodError:
          "An error occurred during change default " +
          "MFA method. Please try again later",
        dialogs: {
          sms: {
            title: "Verify you phone number via SMS",
            text: "Enter the phone number to receive a verification code",
            cancelButton: "Cancel",
            closeButton: "Close",
            continueButton: "Continue",
            countrySelect: "Country",
            successSendCodeMessage: "SMS code sent successfully",
            errorSendCodeMessage:
              "An error occurred while sending the sms code. Please try again later",
            phoneNumberInput: "Phone number",
            errorPhoneNumber: "Invalid phone number",
            sentSmsSecurityCode: (phone: string) =>
              ` Security code sent to you phone ${phone}`,
            mfaSuccessEnable: "MFA by phone number via SMS enabled",
            successSaveMFAMethodMessage: "MFA enabled",
            errorSaveMFAMethodMessage:
              "An error occurred while saving mfa method. Please try again later",
            codeInput: "Code",
            invalidCode: "Invalid code",
          },
          call: {
            title: "Verify you phone number via call",
            text: "Enter the phone number to receive a verification code via call",
            cancelButton: "Cancel",
            closeButton: "Close",
            continueButton: "Continue",
            countrySelect: "Country",
            phoneNumberLabel: "Phone number",
            errorPhoneNumber: "Invalid phone number",
            mfaSuccessEnable: "MFA by phone number via call enabled",
            codeLabel: "Code",
            invalidCode: "Invalid code",
            errorResponseMsg: "Invalid response",
            successSendCodeMessage: "Security code sent successfully",
            errorSendCodeMessage:
              "An error occurred while sending security code. Please try again later",
            successSaveMFAMethodMessage: "MFA enabled",
            errorSaveMFAMethodMessage:
              "An error occurred while saving mfa method. Please try again later",
          },
          email: {
            title: "Verify email",
            text: "Enter email to receive a verification code",
            closeButton: "Close",
            continueButton: "Continue",
            emailLabel: "Email",
            errorPhoneNumber: "Invalid email",
            mfaSuccessEnable: "MFA by email enabled",
            codeLabel: "Code",
            invalidCode: "Invalid code",
            errorResponseMsg: "Invalid response",
            successSendCodeMessage: "Security code sent successfully",
            errorSendCodeMessage:
              "An error occurred while sending security code. Please try again later",
          },
          totp: {
            title: "Authenticator application",
            passwordLabel: "Account password",
            descLabel: "Description",
            otpLabel: "One time password",
            closeButton: "Close",
            continueButton: "Continue",

            mfaSuccessEnable: "MFA by application 2FA enabled",
            codeLabel: "Code",
            invalidCode: "Invalid code",
            errorResponseMsg: "Invalid response",
            successRequestQRcode: "QR code request success",
            errorRequestQRcode:
              "An error occurred while  request qr code. Please try again later",
            errorSave:
              "An error occurred while save method. Please try again later",
          },
        },
      },
      managePayments: {
        title: "Manage Payments",
        btnMethod: "Manage Payments Methods",
      },
    },
  },
  topBar: {
    contactLink: "Contact Us",
    helpLink: "Help Articles",
    homeLink: "Home",
    loginLink: "Login",
    logoutLink: "Logout",
    myAccountLink: "My Account",
    profileLink: "Profile",
    rbacLink: "Roles",
    venueStaffLink: "Venue Team",
    settingsLink: "Settings",
    RBAC: "RBAC",
    venuesLink: "Venues",
  },
  venues: {
    trademarkText: " Venues data provided by",
    venueSettingsPageTitle: "Venue Settings",
    uploadMenu: "Upload a digital menu for your venue",
    menuFileSize: "Up to 25MB in size",
    menuUploadFile: "Upload file",
    menuChangeFile: "Change file",
    attachmentsTitle: "Attachments",
    ownVenues: {
      errorGettingOwnVenues: "Error occurred during getting your venues list",
      venueSelectPlaceholder: "Select your venue",
      yourVenueLabel: "Your venue ",
    },
    mediaContent: {
      errorVideoUpload:
        // eslint-disable-next-line max-len
        "An error occurred during uploading video. Maybe your file is too large or unsupported file format.",
      errorFileType: "Incorrect file type",
      errorPhotoUpload:
        // eslint-disable-next-line max-len
        "An error occurred during uploading photo. Maybe your file is too large or unsupported file format.",
      errorPhotoUploadNoPackage:
        "You are not allowed to upload more than 5 photos. Do not have purchased the 'Profile Upgrades' package.",
      errorPhotoUploadMaxLimit: "You can only upload up to 100 media files",
      videoUploadHint:
        "Video upload is only available with Profile Upgrades package",
      photoUploadHint:
        "To upload more photos, purchase a Profile Upgrades package",
      uploadVenueMedia: "Upload media for your venue",
      successPhotoUpload: "Uploaded successfully",
      successVideoUpload: "Uploaded successfully",
      tabLabelInterior: "Interior",
      tabLabelExterior: "Exterior",
      tabLabelMenu: "Menu",
      uploadVideo: "Upload Video",
      maxPhotoSize: "Up to 5MB in size",
      maxVideoSize: "MP4, 4:3, Up to 25MB in size",
      maxMediaSize:
        "Up to 5MB in size for photo and up to 25MB in size for video",
      informationPopup: "Information",
      informationPopupContent:
        'If you want to upload more photos than your limit permits, please purchase "Profile Upgrades" package',
      uploadedFiles: "Uploaded Files",
      maxPhotoQuantity: "Available to upload 5 files",
    },
    venueTags: {
      title: "Tags",
      helpfulText:
        "Tags you add here help users find your venue in the mobile app.",
      inputPlaceholder: "Add tag",
      saveBtn: "Save",
      savedTagsLabel: "Your saved tags",
      removeAllBtnLabel: (tags: number) => `Remove all (${tags}/20)`,
      limitTagsTooltip: "You have used all your tags",
      confirmationDeletingAllTagsPopupMsg:
        "Your saved tags will be removed and this cannot be undone.",
      confirmationDeletingAllTagsText: " remove tags",
      sendSuccessVenueTags: "Tags are saved successfully",
      sendErrorVenueTags:
        "Something went wrong during saving tags. Please, try again later",
    },
    venueForm: {
      typesValidationError:
        "At least one type of bars or restaurants is required",
      workdaysValidationError: "Please specify Working hours of your venue",
      deletePhotoNoVenueIdError:
        "Sorry, you can't delete a photo for this venue at the moment. please try again later",
      deletePhotoError:
        "Something went wrong during photo deletion. Please, try again later",
      deletePhotoSuccess: "The photo has been deleted successfully",
      deletePhotoConfirmationMessage:
        "This media item will no longer be displayed on the screen",
      deleteMenuConfirmationMessage:
        "Menu will no longer exist on the venue settings",
      deleteVideoNoVenueIdError:
        "Sorry, you can't delete a video for this venue at the moment. please try again later",
      deleteVideoError:
        "Something went wrong during video deletion. please, try again later",
      deleteVideoSuccess: "The video has been deleted successfully",
      deleteVideoConfirmationMessage:
        "This media item will no longer be displayed on the screen",
      changeMainPhotoNoVenueIdError:
        "Sorry, you can't change primary photo for this venue at the moment. Please try again later",
      changeMainPhotoSuccess: "The primary photo has been changed successfully",
      changeMainPhotoError:
        "Something went wrong during setting photo as primary. Please, try again later",
      benefitsTitle: "Venue benefits",
      benefitsBasicSectionTitle: "General",
      benefitsMealsSectionTitle: "Meals",
      benefitsDrinksSectionTitle: "Drinks",
      benefitsAdditionalSectionTitle: "Additional",
      menuTitle: "Menu",
      menuSaveSuccess: "Menu file is uploaded successfully",
      benefitsSaveSuccess: "Benefits are saved successfully",
      benefitsSaveError:
        "An error occurred during saving benefits. Please, try again later",
      commonInfoTitle: "Venue information",
      venueName: (name: string) => `Venue: ${name}`,
      editVenuew: " ",
      btnCancel: "Cancel",
      btnSave: "Save changes",
      holidaysAddButton: "Add new holiday",
      holidaysChangeButton: "List holidays",
      holidaysCancelDialog: "Cancel",
      holidaysSaveDialog: "Save",
      holidaysAddDialog: "Add",
      holidaysListTitle: "List holidays",
      holidaysNameColumn: "Name",
      holidaysDateColumn: "Date",
      holidaysActionsColumn: "Actions",
      holidaysEditModeTitle: "Edit holiday",
      holidaysAddModeTitle: "Add new  holiday",
      holidaysLoadButton: "Load holidays",
      holidaysSaveSuccessMessage: "Your holidays are saved successfully",
      holidaysLoadTooltip:
        // eslint-disable-next-line max-len
        "Load holidays for the rest of current year (Need to define a country and state on the venue settings form first)",
      holidaysListModeTitle: "List holidays",
      holidaysNameFieldLabel: "Name",
      holidaysSelectDateTitle: "Select date",
      holidaysSetTodayDateButton: "Today",
      labelName: "Name",
      labelDescription: "Description",
      labelCity: "City",
      labelState: "State",
      labelBarTypes: "Bar types",
      labelRestaurantTypes: "Restaurant types",
      labelCountry: "Country",
      labelAddress: "Address",
      labelAddress2: "Address Line 2",
      labelLatitude: "Latitude",
      labelLongitude: "Longitude",
      labelPrice: "Select price range",
      mediaContentTitle: "Media content",
      pendingTooltipVenueAdmin:
        "We will check your website and social media to verify changes",
      pendingTooltipSuperAdmin: "Changes pending approval",
      openLabel: "Open",
      closeLabel: "Close",
      workDaysSavedSuccess: "Working days and hours were successfully saved",
      saveSuccess: "Venue was saved successfully",
      saveError: "Error occurred, during saving venue. Please, try again later",
      stateHelper: "Please, select country first",
      workingHoursAllOff: "No Active Schedule",
      workingHoursAllOn: "24/7/365",
      workingHoursCancelDialog: "Cancel",
      workingHoursCustom: "Custom Schedule",
      workingHoursDialogTitle: "Manage working hours",
      workingHoursSaveDialog: "Save",
      workingHoursChangeButton: "Manage working hours",
      uploadVenuePhotos: "Upload photos for your venue",
      setPrimary: "Set Primary",
      primary: "Primary",
      save: "Save",
    },
    assignVenueForm: {
      assignButton: "Assign",
      companyFieldLabel: "Company",
      emailFieldLabel: "Company Email",
      firstNameFieldLabel: "First Name",
      lastNameFieldLabel: "Last Name",
      title: "Assign Venue",
      titleFieldLabel: "Title",
      titleFieldPlaceholder: "CEO, CTO, VenueStaffManager, etc...",
      cancelButton: "Cancel",
      phoneNumberFieldLabel: "Location Phone Number",
    },
    assignVenueToUserForm: {
      assignButton: "Assign",
      cancelButton: "Cancel",
      selectUserPlaceholder: "Select User",
      successMessage: "SuccessStep, this dialog will close soon.",
      title: "Assign Venue",
      isWaitlistAdmin: "Waitlist Admin",
    },
    claimVenueDialog: {
      cancelButton: "Cancel",
      commentFieldLabel: "Comment",
      companyFieldLabel: "Company",
      titleFieldLabel: "Title",
      claimButton: "Claim",
      claimError: "Something went wrong. Please, try again later.",
      popupWarningOnClose:
        "Are you sure you want to close this page? Progress will not be saved.",
      popupWarningOnCloseDataSaved: "Are you sure you want to close this page?",
      dialogTitle: "Claim Venue",
      successClaimingMessagePopup:
        "Your venue has been successfully claimed. Please check your email to proceed the log in process.",
      errorPopupIfEmailIsRegisteredTitle:
        "Sorry, user with email or phone number already registered.",
      errorPopupIfEmailIsRegisteredText: "Please, try another email or phone.",
      dialogTitleTypeSelection:
        "Select how you want to confirm restaurant ownership",
      dialogTitleAttachmentLabel: "Did Not Receive Code?",
      confirmOwnershipLabel: "Confirm your ownership",
      dialogAttachmentNoMatch:
        "The phone number and email you specified don't match with our record.",
      dialogTitleAttachmentInfo:
        "Please, upload one of the suggested documents: ",
      dialogTitleAttachmentInfoFirst: "Utility bills showing the address ",
      dialogTitleAttachmentInfoSecond:
        "Rental/purchase agreement showing the name/address (first page is fine)",
      dialogTitleAttachmentInfoThird:
        "Articles of incorporation/business registration or license (may be publicly available) to confirm your ownership.",
      attachmentDropZoneInfo: "JPG, JPEG, PNG, PDF",
      dialogAttachmentSuccessMessage: "Your file attached successfully",
      dialogAttachmentErrorMessage:
        "An error occurred during attaching file. Maybe your file is too large or unsupported file format.",
      emailFieldLabel: "Company Email",
      phoneNumberFieldValidationError:
        "Phone number is invalid or not allowed. Format should be +1XXXXXXXXX",
      firstNameFieldLabel: "First Name",
      successSentCodeEmail: (email?: string) =>
        `Verification code sent to ${
          email ? "the email: " + email : `your email`
        }. If you didn't get the code or you don't have access to your email, write us to `,
      successSentCodePhone: (phoneNumber?: string) =>
        `Verification code sent to ${
          phoneNumber ? "the phone number: " + phoneNumber : "your phone number"
        }. If you didn't get the code or you don't have access to your phone, write us to `,
      lastNameFieldLabel: "Last Name",
      phoneNumberFieldLabel: "Company Phone Number",
      locationPhoneNumberFieldLabel: "Location Phone Number",
      successForManualApproveTitle:
        "Thank you, for claiming! Your request will be reviewed soon.",
      successForManualApproveDialogMsg:
        "We will notify you when your claim request be approved",
      successForManualApproveMsg: "Please wait for the confirmation email",
      titlePlaceholder: "CEO, CTO, VenueStaffManager, etc...",
      verificationCodeFieldLabel: "Verification Code",
      verifyCodeButton: "Verify",
      resendLabel: "Resend code",
      recallLabel: "Recall",
      didNotGetCode: "Did not receive code?",
      didNotGet: "Did not get?",
      clickHereLabel: "Click here ",
      sendDocumentLabel: "to send documents",
      codeTimer: (counter: number) =>
        counter < 10 ? `00:0${counter}` : `00:${counter}`,
      selectConfirmationMethodButton: "Select",
      includeWaitlist: "Include queueing feature",
      tou: "ToU",
      tos: "ToS",
      privacyPolicy: " Privacy Policy",
      privatePolicyAgreement1: "Agree to ",
      privatePolicyAgreement2: " and ",
      contactPermission:
        "I authorize Ociety, Inc. to contact me at the phone number I provided (if applicable) via phone and/or text message using automated dialing or email me at the provided email address to confirm this venue’s information. Message and data rates may apply.",
      isBusinessOwner:
        "I am the owner of this business, or have been given consent by the owner to claim this venue on his / her behalf",
      verificationDialogTitle:
        "Select how you want to confirm restaurant ownership",
      emailLabel: "Email",
      phoneNumberLabel: "Phone Number",
      linkTooltip:
        "Information entered by you will be saved and can be shared with other users",
      linkCopiedTooltip: "Link copied!",
    },
    list: {
      createVenueAdminStepTitle: "Your information",
      createVenueDialogBackButton: "Previous",
      createVenueDialogCancelButton: "Cancel",
      createVenueDialogNextButton: "Next",
      createVenueSuccess:
        "Venue creation request has been proceed successfully. You will receive an email with validation result soon",
      createVenueError:
        "An error occurred, during validation your request, please check your input data or try again later",
      createVenueDialogValidateButton: "Validate",
      createVenueValidationAttachmentsLabel: "Drag your files here",
      createVenueValidationAttachmentsMessage:
        "Attachments is required. Please, upload photos of the ownership document",
      createVenueValidationAttachmentsList: (attachments: string) =>
        `Attached files: ${attachments}`,
      createVenuePhotosAttached: (filesQty: number) =>
        `${filesQty > 1 ? "Files were" : "File was"} attached`,
      createVenueValidationAttachmentsDescription:
        "Please upload photos of your driver license and utility bills to confirm your ownership",
      createVenueVenueStepTitle: "Venue information",
      createVenueTypesValidationError:
        "At least one restaurant or bar type should be selected",
      createVenueValidationStepTitle: "Validation",
      createVenueValidationStepAttachmentsTitle: "Attachments",
      createVenueValidationStepYourInformationTitle: "Your information",
      createVenueValidationStepVenueInformationTitle: "Venue information",
      createVenueValidationStepFirstNameLabel: "First name",
      createVenueValidationStepLastNameLabel: "Last name",
      createVenueValidationStepCompanyLabel: "Company",
      createVenueValidationStepTitleLabel: "Title",
      createVenueValidationStepPhoneLabel: "Phone number",
      createVenueValidationStepEmailLabel: "Email",
      createVenueValidationStepNameLabel: "Name",
      createVenueValidationStepCountryLabel: "Country, state",
      createVenueValidationStepCityLabel: "City",
      createVenueValidationStepVenuePhoneLabel: "Venue phone number",
      createVenueValidationStepAddressLabel: "Address",
      createVenueValidationStepVenueEmailLabel: "Venue email",
      createVenueValidationStepBarTypesLabel: "Bar types",
      createVenueValidationStepRestaurantTypesLabel: "Restaurant types",
      createVenueButton: "Create a new venue",
      createVenueDialogTitle: "Create venue",
      createVenueLoginQuestion: "If you already have an account, please ",
      createVenueLoginLink: "log in",
      creatVenueNameFieldLabel: "Name",
      creatVenueCityFieldLabel: "City",
      creatVenueStateFieldLabel: "State",
      creatVenueCountryFieldLabel: "Country",
      creatVenueAddressFieldLabel: "Address",
      creatVenueEmailFieldLabel: "Venue email",
      creatVenueNamePhoneNumberFieldLabel: "Location phone number",
      pageTitle: "Venues list",
      headingAssign: "Assign",
      headingAssignToUser: "Assign to user",
      headingActions: "Actions",
      headingPhoto: "Photo",
      headingAddress: "Address",
      headingZipCode: "ZIP code",
      headingName: "Name",
      headingClaim: "Claim",
      searchEmptyTitle:
        "Unfortunately, no results were found for your search keywords",
      searchAndCreationNotion:
        "Enter a venue name below to search for an existing venue in our system to claim. If you don't find it, you will be given an option to submit a new venue.",
      searchInputPlaceholder: "Search venue by name or address",
      searchEmptyNotion:
        "Please try alternative search keywords. Or if it does not exist already, you can click the button “Create a new venue” down below and then we will contact you and add it to our system",
    },
  },
  RBAC: {
    roles: {
      edit: {
        successMessage: "An role has been updated successfully.",
        updaterRoleError:
          "An error occurred during updating role. Please try again later",
      },
    },
    admins: {
      editRoles: {
        updaterRolesError:
          "An error occurred during updating " +
          "admin roles. Please try again later",
      },
    },
  },
  venueStaff: {
    managers: {
      addManager: "Add User",
      form: {
        firstName: "Enter Name",
        email: "Enter Email",
      },
      table: {
        name: "Name",
        email: "Email",
        roles: "Roles",
        actions: "Actions",
      },
      roles: {
        waitlistManager: "Waitlist Manager",
        discountManager: "Discount Manager",
      },
    },
    tabs: {
      all: "All",
      waitlistManagers: "Waitlist Managers",
      discountManagers: "Discount Managers",
    },
    messages: {
      createdSuccess: "A manager has been created successfully.",
      updatedSuccess: "The manager has been updated successfully.",
      createdError:
        "An error occurred during manager creation. Please try again later",
      updatedError:
        "An error occurred during manager creation. Please try again later",
      gettingError:
        "An error occurred during managers retrieval. Please try again later",
    },
    pageTitle: "Venue Team",
    search: "Search by name or email",
  },
  profile: {
    profileSavingError:
      "An error occurred during saving your profile. Please, try again later",
    profileInvalidBirthdayError:
      "Date of birth should not be less than 18 years",
    pageTitle: "Profile settings",
    confirmationDeleteProfileButtonLabel: " delete profile",
    popupMessageOnDeleteProfile:
      "Are you sure you would like to delete this? This cannot be undone.",
    photo: {
      title: "Photo",
      uploadBtn: "Choose File",
      uploadPhotoTxt1:
        "Image with an extension other than jpg, png, will not be uploaded.",
      uploadPhotoTxt2: "Image with size more than 5 MB will not be uploaded.",
    },

    changePassword: "Change password",
    forgotPassword: "Forgot your password?",
    notifictions: {
      title: "Notifications",
      notificationEmail: "Notifications via Email",
    },
    mfa: {
      title: "MFA",
      country: "Country",
      bySMScode: "by SMS code",
      byCallCode: "by phone call",
      byEmailCode: "by Email code",
      byApplication: "Authentication app",
      phoneNumber: "Phone Number",
      email: "Email",
      code: "Enter the code",
      resend: "Resend",
      didnotget: "Didn't get?",
      descriptionText:
        "After activating this you will need to enter" +
        " verification code from sms when you log in",
      descriptionTextCall:
        "After activating this you will need to enter" +
        " verification code from call when you log in",
      descriptionTextEmail:
        "After activating this you will need to enter " +
        "verification code from email when you log in",
      descriptionTextOTP:
        "After activating this you will need to enter verification" +
        " code from app when you log in",
      enableBtn: "Enable",
      disableBtn: "Disable",
      cancelBtn: "Cancel",
      backBtn: "Back",
      continuBtn: "Continue",
      enableMFABtn: "Enable MFA",
      submitBtn: "Submit",
      addBtn: "Add",
      errorFetchData: "Error fetch MFA data",
      verifyPhoneNumber: "Verify your phone number",
      verifyEmail: "Verify your email",
      enterPassowrd: "Enter password",
      scanQRCode: "Scan QR code",
      scanQRCodeNote:
        "Please open the authentication app you use in your smartphone/tablet. Now connect the " +
        "app to your account by scanning the QR code. If it is not possible to read the QR code, " +
        "please enter the following string manually in your app.",
      letsSetupYouPhone: "Let’s set up your phone!",
      letsSetupYouEmail: "Let’s set up your email!",
      confirmCodeTitle: "Confirm that it works!",
      confirmHelpText:
        "Ociety has just sent a text message with a verification code to ",
      confirmCallHelpText:
        "Ociety has just sent a call with a verification code to ",
      confirmEmailHelpText:
        "Ociety has just sent a email with a verification code to ",
      enterPhoneNumber: "Enter the phone number to receive a verification code",
      enterEmail: "Enter email to receive a verification code",
      disableMFAError:
        "An error occurred during disable " +
        "MFA method. Please try again later",
      disableMFASuccess: "MFA method disabled",
      disableCallMFASuccess: "Call MFA method disabled",
      disableEmailMFASuccess: "Email MFA method disabled",
      invalidEmailErrorMessage: "Email is not valid/allowed",
      sequrityTxt: "Ociety will use this number for account security only.",
      incorrectResponse: "Incorrect response",
      successSendCodeMessage: "SMS code sent successfully",
      successSendCallCodeMessage: "Call with security code sent successfully",
      errorSendCodeMessage:
        "An error occurred while sending the sms code. Please try again later",
      enterAccountPassword: "Enter your account password",
      accountPassword: "Account password",
      scanQRCodeWarning: "Scan the QR code with your camera.",
      errorResponseMsg: "Invalid response",
      descriptionFieldLabel: "Description",
      otpFieldLabel: "Enter one-time password",
      otpYourPassword: "Your password",
      otpQRCode: "QR",
      codeError: "Invalid OTP code",
      descripionEmptyError: "Description empty",
      successMFAMethodEnabled: "MFA by phone number (sms) enabled",
      successCallMFAMethodEnabled: "MFA by phone number  (call) enabled",
      successEmailMFAMethodEnabled: "MFA by email enabled",
      errorSaveMFAMethodMessage:
        "An error occurred while saving mfa method. Please try again later",
      phoneNumberStep: "Phone number",
      emailStep: "Email",
      confirmStep: "Confirm",
      mobileDevice: "Mobile device",
    },
    profileSaved: "Changes saved",
  },
  billingInformation: {
    pageTitle: "Billing Information",
    paymentMethodUpdateSuccess:
      "Your Billing Information has been updated successfully",
    changePaymentMethodSuccessMessage:
      "Payment Method was changed successfully",
  },
  steppers2step: {
    step1: "Step 1",
    step2: "Step 2",
  },
  venuePending: {
    pageTitle: "Venue Creation Request",
    adminInformation: {
      blockTitle: "Admin information",
      firstNameLabel: "First Name",
      lastNameLabel: "Last Name",
      titleLabel: "Title",
      companyLabel: "Company",
      phoneNumberLabel: "Phone Number",
      emailLabel: "Email",
      commentLabel: "Comment From Venue Admin",
    },
    venueForm: {
      approveButton: (isApproving: boolean) =>
        `${isApproving ? "Approving..." : "Approve & Create"}`,
      rejectButton: "Deny request",
      approveSuccess: "Venue was approved and created successfully",
      approveError:
        "An error occurred, during creation venue. please, try again later",
      rejectSuccess: "Venue creation request has been deleted successfully",
      rejectError:
        "An error occurred, during rejecting venue creation request. please, try again later",
    },
    informationForAdmin: {
      title: "Information for client",
      commentLabel: "Comment",
      commentPlaceholder: "Enter your comment",
      commentHelper: "This will be included in an email to venue admin",
    },
  },
  venuesChangesPending: {
    pageTitle: "Pending venues changes",
    table: {
      headingName: "Name",
      headingChangedFields: "Changed fields",
      headingView: "View",
    },
  },
  venuesPending: {
    pageTitle: "Pending venues list",
    table: {
      headingName: "Name",
      headingAddress: "Address",
      headingView: "View",
    },
    addForm: {
      name: "Venue name",
      email: "Venue email",
      phone: "Venue phone number",
      address: "Venue address",
      city: "City",
      country: "Country",
      state: "State",
      latitude: "Latitude",
      longitude: "Longitude",
      website: "Website URL",
    },
  },
};
