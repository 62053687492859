import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames = "root" | "actionsToolbar" | "emptyContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        minHeight: 484,
        position: "relative",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(5, 3),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(3, 2),
        },
      },
      actionsToolbar: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(1.5),
        padding: theme.spacing(0, 1.5),
      },
      emptyContainer: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    })
);
