import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import {
  FOOTER_HEIGHT,
  FOOTER_HEIGHT_MOBILE,
} from "../../common/components/Footer";
import { OcietyTheme } from "../../common/models";
import { colors } from "../../theme/colors";

export type LoginClassNames =
  | "actionsWrapper"
  | "actionsSecondaryContainer"
  | "aside"
  | "content"
  | "contentHeader"
  | "checkboxChecked"
  | "checkboxLabel"
  | "errorMessage"
  | "errorMessageWrapper"
  | "errorMessageContent"
  | "mfaDescription"
  | "contentBody"
  | "forgotPasswordLink"
  | "forgotPasswordLinkText"
  | "form"
  | "mfaTitle"
  | "loginTitle"
  | "logo"
  | "registerActionWrapper"
  | "registerLink"
  | "registerLinkText"
  | "registerLabel"
  | "title"
  | "titleRight"
  | "textField"
  | "signInButton";

export const useStyles: () => ClassNameMap<LoginClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      actionsWrapper: {
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(10),
        },
      },
      actionsSecondaryContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
          margin: theme.spacing(2, 0),
        },
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(0, 0, 1),
        },
      },
      errorMessageWrapper: {
        marginBottom: theme.spacing(4),
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      },
      errorMessageContent: {
        width: "max-content",
        background: "rgba(254, 84, 84, 0.06)",
        border: "1px solid rgba(254, 84, 84, 0.3)",
        borderRadius: 6,
        padding: theme.spacing(2),
      },
      aside: {
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
        [theme.breakpoints.up("md")]: {
          minHeight: `calc(100vh - ${FOOTER_HEIGHT})`,
          background: "url('/images/backgrounds/login-bg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "56%",
        },
      },
      checkboxChecked: {
        color: colors.red.primary,
      },
      checkboxLabel: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(15),
        color: colors.grey.secondary,
      },
      mfaDescription: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(2),
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
          width: "450px",
          marginLeft: "-55px", // Just easiest way to make it centered =)
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: theme.spacing(6),
        },
      },
      titleRight: {
        color: colors.grey.secondary,
      },
      content: {
        flex: 1,
        display: "flex",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          minHeight: `calc(100vh - ${FOOTER_HEIGHT})`,
        },
        [theme.breakpoints.down("sm")]: {
          minHeight: `calc(100vh - ${FOOTER_HEIGHT_MOBILE})`,
        },
        [theme.breakpoints.down("md")]: {
          alignItems: "center",
          justifyContent: "center",
        },
      },
      contentHeader: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      contentBody: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
          justifyContent: "center",
        },
        [theme.breakpoints.up("md")]: {
          width: "44%",
          minHeight: `calc(100% - ${FOOTER_HEIGHT})`,
        },
      },
      errorMessage: {},
      forgotPasswordLink: {
        color: colors.red.primary,
        margin: theme.spacing(2, 0, 2, "auto"),
      },
      forgotPasswordLinkText: {
        fontSize: theme.typography.pxToRem(15),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: "bold",
        color: colors.red.primary,
      },
      logo: {
        "& > g": {
          fill: colors.red.primary,
        },
      },
      loginTitle: {
        [theme.breakpoints.up("md")]: {
          margin: theme.spacing(4, 1, 4),
        },
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(3, "auto"),
        },
      },
      mfaTitle: {
        [theme.breakpoints.up("md")]: {
          marginTop: theme.spacing(1),
        },
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(3, "auto"),
        },
      },
      registerActionWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      registerLink: {
        color: colors.red.primary,
        marginLeft: 5,
      },
      registerLinkText: {
        fontSize: theme.typography.pxToRem(15),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: "bold",
        color: colors.red.primary,
      },
      registerLabel: {
        fontWeight: 600,
        color: colors.grey.secondary,
        fontSize: theme.typography.pxToRem(15),
        lineHeight: theme.typography.pxToRem(20),
      },
      form: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        flexDirection: "column",
        margin: theme.spacing(2, "auto"),
        [theme.breakpoints.up("lg")]: {
          maxHeight: 700,
        },
        [theme.breakpoints.down("sm")]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.up("md")]: {
          width: 330,
        },
        [theme.breakpoints.down("md")]: {
          justifyContent: "center",
        },
      },
      title: {
        fontWeight: "bold",
        textAlign: "center",
      },
      textField: {
        marginBottom: theme.spacing(6),
      },
      signInButton: {
        minHeight: 52,
        marginBottom: theme.spacing(1.88),
      },
    })
);
