import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState } from "react";

import IOption from "./IOption";
import Props from "./Props";
import { useStyles } from "./style";

const MultipleSelect: FC<Props> = (
  props: Props
): ReactElement<any, any> | null => {
  const classes = useStyles();
  const { options, selected, label, helperText, error, required, onSelected } =
    props;

  const [selectedList, setSelectedList] = useState(selected);
  const haveError = !!error;

  useEffect(() => {
    if (selected) {
      setSelectedList(selected);
    }
  }, [selected]);

  const handleChange = (event: any) => {
    const values = event.target.value.filter((value: any) => !!value);
    setSelectedList(values);
    onSelected(values);
  };

  const handleDelete = (chipToDelete: string) => {
    const newList = selectedList.filter((chip) => chip !== chipToDelete);
    setSelectedList(newList);
    onSelected(newList);
  };

  const getChipLabel = (chipId: string) => {
    for (const option of options) {
      if (option.value === chipId) {
        return option.label;
      }
    }

    return chipId;
  };

  const renderOptions = () => (
    <div className={classes.chips}>
      {selectedList.map((value: string) => (
        <Chip
          onDelete={() => handleDelete(value)}
          key={value}
          label={getChipLabel(value)}
        />
      ))}
    </div>
  );

  return (
    <div className={classes.root}>
      {renderOptions()}
      <FormControl
        fullWidth
        required={required}
        className={classes.formControl}
        error={haveError}>
        <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
        <Select
          className={classes.select}
          multiple
          fullWidth
          displayEmpty
          value={selectedList}
          inputProps={{
            style: { height: "100%" },
          }}
          classes={{ selectMenu: classes.select }}
          onChange={handleChange}
          renderValue={() => null}>
          {options.map((option: IOption) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={selectedList.includes(option.value)}
                color="primary"
                className={classes.checkboxIcon}
              />
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};
export default MultipleSelect;
