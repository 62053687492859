import { CardContent, Grid, Paper } from "@material-ui/core";
import React, { FC } from "react";

import Props from "../../Venues/Props";
import { useStyles } from "../styles";

const TermsOfSale: FC<Props> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <CardContent className={classes.cardContainer}>
          <Grid container justify="center" spacing={3}>
            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <h1 className={classes.title}>
                  Terms and Conditions for The Online Sale of Goods and Services
                </h1>
                <br />
                <strong>
                  1. THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING
                  YOUR RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS,
                  LIMITATIONS, AND EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE
                  READ IT CAREFULLY.
                  <br />
                  <br />
                  THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL
                  BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS
                  ACTIONS.
                  <br />
                  <br />
                  BY PLACING AN ORDER FOR PRODUCTS OR SERVICES FROM THIS APP,
                  YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS
                  AGREEMENT, AND YOU ACCEPT AND ARE BOUND BY THESE TERMS AND
                  CONDITIONS. YOU AFFIRM THAT IF YOU PLACE AN ORDER ON BEHALF OF
                  AN ORGANIZATION OR COMPANY, YOU HAVE THE LEGAL AUTHORITY TO
                  BIND ANY SUCH ORGANIZATION OR COMPANY TO THESE TERMS AND
                  CONDITIONS.
                  <br />
                  <br />
                  YOU MAY NOT ORDER OR OBTAIN PRODUCTS OR SERVICES FROM THIS APP
                  IF YOU (A) DO NOT AGREE TO THESE TERMS, (B) ARE NOT THE OLDER
                  OF (i) AT LEAST 18 YEARS OF AGE OR (ii) LEGAL AGE TO FORM A
                  BINDING CONTRACT WITH OCIETY, INC., OR (C) ARE PROHIBITED FROM
                  ACCESSING OR USING THIS APP OR ANY OF THIS APP&apos;S
                  CONTENTS, GOODS OR SERVICES BY APPLICABLE LAW.
                </strong>
                <br />
                <br />
                <p>
                  These terms and conditions (these &quot;<strong>Terms</strong>
                  &quot;) apply to the purchase and sale of products and
                  services from Ociety, Inc. through www.ociety.com and/or the
                  related Ociety application (the &quot;App&quot;) (the website
                  and the App being referred to collectively herein as the
                  App&quot;). These Terms are subject to change by Ociety, Inc.
                  (referred to as &quot;<strong>us</strong>&quot;, &quot;
                  <strong>we</strong>&quot;, or &quot;<strong>our</strong>&quot;
                  as the context may require) without prior written notice at
                  any time, in our sole discretion. Any changes to the Terms
                  will be in effect as of the &quot;Last Updated Date&quot;
                  referenced on the App. You should review these Terms prior to
                  purchasing any product or services that are available through
                  this App. Your continued use of this App after the &quot;Last
                  Updated Date&quot; will constitute your acceptance of and
                  agreement to such changes.
                </p>
                <br />
                <p>
                  These Terms are an integral part of the Website Terms of Use
                  that apply generally to the use of our App. You should also
                  carefully review our Privacy Policy before placing an order
                  for products or services through this App (see 39).
                </p>
                <br />
                <p>
                  2. <u>Order Acceptance and Cancellation.</u> You agree that
                  your order is an offer to buy, under these Terms, all products
                  and services listed in your order. All orders must be accepted
                  by us or we will not be obligated to sell the products or
                  services to you. We may choose not to accept orders at our
                  sole discretion, even after we send you a confirmation email
                  with your order number and details of the items you have
                  ordered.{" "}
                </p>
                <br />
                <p>
                  <u>3. Prices and Payment Terms.</u>
                </p>
                <br />
                <p>
                  (a) All prices, discounts, and promotions posted on this App
                  are subject to change without notice. The price charged for a
                  product or service will be the price in effect at the time the
                  order is placed and will be set out in your order confirmation
                  email or Ociety wallet. Price increases will only apply to
                  orders placed after such changes. Posted prices will include
                  taxes or other charges or other charges as determined by the
                  venue. All charges will be added to your total cost and will
                  be itemized in your order confirmation email or Ociety wallet.
                  We strive to display accurate price information, however we
                  may, on occasion, make inadvertent typographical errors,
                  inaccuracies or omissions related to pricing and availability.
                  We reserve the right to correct any errors, inaccuracies, or
                  omissions at any time and to cancel any orders arising from
                  such occurrences.
                </p>
                <br />
                <p>
                  (b) We may offer from time to time promotions on the App that
                  may affect pricing and that are governed by terms and
                  conditions separate from these Terms. If there is a conflict
                  between the terms for a promotion and these Terms, the
                  promotion terms will govern.{" "}
                </p>
                <br />
                <p>
                  (c) Terms of payment are within our sole discretion and,
                  unless otherwise agreed by us in writing, payment must be
                  received by us before our acceptance of an order. You
                  represent and warrant that (i) the credit card information you
                  supply to us is true, correct and complete, (ii) you are duly
                  authorized to use such credit card for the purchase, (iii)
                  charges incurred by you will be honored by your credit card
                  company, and (iv) you will pay charges incurred by you at the
                  posted prices, including shipping and handling charges and all
                  applicable taxes, if any, regardless of the amount quoted on
                  the App at the time of your order.{" "}
                </p>
                <br />
                <p>
                  4. <u>Title and Risk of Loss.</u> Title and risk of loss pass
                  to you upon delivery. We are not liable for any delays in
                  delivery.
                </p>
                <br />
                <p>
                  5. <u>Returns and Refunds.</u> Except for any products
                  designated on the App as final sale or non-returnable, we will
                  a refund of your purchase price when the promotion is no
                  longer available from the venue at the time of purchase or
                  upon our bona fide mistake or operational error.
                </p>
                <br />
                <p>
                  Refunds to your Ociety wallet may also result where 1) your
                  promotion usage time frame has expired, and 2) you did not
                  select the &quot;allow promotion usage after expiration&quot;
                  option upon creating the promotion in question. In this
                  scenario, your refund will be issued to you in credits within
                  your Ociety wallet. Users may also opt to receive a direct
                  cash refund to their authenticated bank account.
                </p>
                <br />
                <p>
                  Credit refunds are processed within approximately one business
                  day of your request or the qualifying expired promotion;
                  direct cash refunds are processed within approximately one
                  week of your request. Your refund will be credited to your
                  Ociety wallet in United States Dollars. Credits in your Ociety
                  wallet can be used toward future purchases.
                </p>
                <br />
                <p>
                  6. <u>Warranty and Disclaimers.</u> We do not provide any
                  warranties with respect to the products or services offered on
                  our App.
                </p>
                <br />
                <strong>
                  ALL PRODUCTS AND SERVICES OFFERED ON THIS APP ARE PROVIDED “AS
                  IS” WITHOUT ANY WARRANTY WHATSOEVER, INCLUDING, WITHOUT
                  LIMITATION, ANY (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY
                  OF FITNESS FOR A PARTICULAR PURPOSE; OR (C) WARRANTY AGAINST
                  INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY;
                  WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE
                  OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.
                  <br />
                  SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF
                  IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT
                  APPLY TO YOU.
                  <br />
                  YOU AFFIRM THAT WE SHALL NOT BE LIABLE, UNDER ANY
                  CIRCUMSTANCES, FOR ANY BREACH OF WARRANTY CLAIMS OR FOR ANY
                  DAMAGES ARISING OUT OF THE MANUFACTURER&apos;S FAILURE TO
                  HONOR ITS WARRANTY OBLIGATIONS TO YOU.
                </strong>
                <br />
                <br />
                <p>
                  7. <u>Limitation of Liability.</u>
                  <strong>
                    {" "}
                    IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR
                    CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
                    PUNITIVE OR ENHANCED DAMAGES, LOST PROFITS OR REVENUES OR
                    DIMINUTION IN VALUE, ARISING OUT OF, OR RELATING TO, AND/OR
                    IN CONNECTION WITH ANY BREACH OF THESE TERMS, REGARDLESS OF
                    (A) WHETHER SUCH DAMAGES WERE FORESEEABLE, (B) WHETHER OR
                    NOT WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND
                    (C) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR
                    OTHERWISE) UPON WHICH THE CLAIM IS BASED.
                    <br />
                    OUR SOLE AND ENTIRE MAXIMUM LIABILITY, FOR ANY REASON, AND
                    YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY CAUSE WHATSOEVER,
                    SHALL BE LIMITED TO THE ACTUAL AMOUNT PAID BY YOU FOR THE
                    PRODUCTS AND SERVICES YOU HAVE ORDERED THROUGH OUR APP.
                  </strong>
                </p>
                <br />
                <p>
                  The limitation of liability set forth above shall: (i) only
                  apply to the extent permitted by law.
                </p>
                <br />
                <p>
                  8. <u>Goods Not for Resale or Export.</u> You represent and
                  warrant that you are buying products or services from the App
                  for your own personal or business use only, and not for
                  resale.
                </p>
                <br />
                <p>
                  9. <u>Privacy.</u> We respect your privacy and are committed
                  to protecting it. Our{" "}
                  <a href="/privacy-policy">Privacy Policy</a>, governs the
                  processing of all personal data collected from you in
                  connection with your purchase of products or services through
                  the App.
                </p>
                <br />
                <p>
                  10. <u>Force Majeure.</u> We will not be liable or responsible
                  to you, nor be deemed to have defaulted or breached these
                  Terms, for any failure or delay in our performance under these
                  Terms when and to the extent such failure or delay is caused
                  by or results from acts or circumstances beyond our reasonable
                  control, including, without limitation, acts of God, flood,
                  fire, earthquake, explosion, governmental actions, war,
                  invasion or hostilities (whether war is declared or not),
                  terrorist threats or acts, riot or other civil unrest,
                  national emergency, revolution, insurrection, epidemic,
                  lockouts, strikes or other labor disputes (whether or not
                  relating to our workforce), or restraints or delays affecting
                  carriers or inability or delay in obtaining supplies of
                  adequate or suitable materials, materials or telecommunication
                  breakdown or power outage.
                </p>
                <br />
                <p>
                  11. <u>Governing Law and Jurisdiction.</u> This App is
                  operated from the U.S. All matters arising out of or relating
                  to these Terms are governed by and construed in accordance
                  with the internal laws of the State of New York without giving
                  effect to any choice or conflict of law provision or rule
                  (whether of the State of New York or any other jurisdiction)
                  that would cause the application of the laws of any
                  jurisdiction other than those of the State of New York.
                </p>
                <br />
                <p>
                  12. <u>Dispute Resolution and Binding Arbitration.</u>
                </p>
                <br />
                <strong>
                  (a) YOU AND OCIETY, INC. ARE AGREEING TO GIVE UP ANY RIGHTS TO
                  LITIGATE CLAIMS IN A COURT OR BEFORE A JURY, OR TO PARTICIPATE
                  IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A
                  CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT
                  MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.
                  <br />
                  ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT
                  OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT OR FUTURE, AND
                  INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW,
                  INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE CLAIMS) BETWEEN YOU
                  AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR PURCHASE OF
                  PRODUCTS OR SERVICES THROUGH THE APP, WILL BE RESOLVED
                  EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.
                </strong>
                <br />
                <br />
                <p>
                  (b) The arbitration will be administered by the American
                  Arbitration Association (&quot;<strong>AAA</strong>&quot;) in
                  accordance with the Consumer Arbitration Rules (the &quot;
                  <strong>AAA Rules</strong>&quot;) then in effect, except as
                  modified by this 412. (The AAA Rules are available at
                  www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.)
                  The Federal Arbitration Act will govern the interpretation and
                  enforcement of this section.
                </p>
                <br />
                <p>
                  The arbitrator will have exclusive authority to resolve any
                  dispute relating to arbitrability and/or enforceability of
                  this arbitration provision, including any unconscionability
                  challenge or any other challenge that the arbitration
                  provision or the agreement is void, voidable, or otherwise
                  invalid. The arbitrator will be empowered to grant whatever
                  relief would be available in court under law or in equity. Any
                  award of the arbitrator(s) will be final and binding on each
                  of the parties, and may be entered as a judgment in any court
                  of competent jurisdiction.
                </p>
                <br />
                <p>
                  (c) You agree to an arbitration on an individual basis. In any
                  dispute,{" "}
                  <strong>
                    NEITHER YOU NOR OCIETY, INC. WILL BE ENTITLED TO JOIN OR
                    CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN COURT OR
                    IN ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A
                    CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY
                    GENERAL CAPACITY.
                  </strong>{" "}
                  The arbitral tribunal may not consolidate more than one
                  person&apos;s claims, and may not otherwise preside over any
                  form of a representative or class proceeding. The arbitral
                  tribunal has no power to consider the enforceability of this
                  class arbitration waiver and any challenge to the class
                  arbitration waiver may only be raised in a court of competent
                  jurisdiction.
                </p>
                <br />
                <p>
                  If any provision of this arbitration agreement is found
                  unenforceable, the unenforceable provision will be severed and
                  the remaining arbitration terms will be enforced.
                </p>
                <br />
                <p>
                  13. <u>Assignment.</u> You will not assign any of your rights
                  or delegate any of your obligations under these Terms without
                  our prior written consent. Any purported assignment or
                  delegation in violation of this 513 is null and void. No
                  assignment or delegation relieves you of any of your
                  obligations under these Terms.
                </p>
                <br />
                <p>
                  14. <u>No Waivers.</u> The failure by us to enforce any right
                  or provision of these Terms will not constitute a waiver of
                  future enforcement of that right or provision. The waiver of
                  any right or provision will be effective only if in writing
                  and signed by a duly authorized representative of Ociety, Inc.
                </p>
                <br />
                <p>
                  15. <u>No Third-Party Beneficiaries.</u> These Terms do not
                  and are not intended to confer any rights or remedies upon any
                  person other than you.
                </p>
                <br />
                <p>
                  16. <u>Notices.</u>
                </p>
                <br />
                <p>
                  (a) <u>To You</u>. We may provide any notice to you under
                  these Terms by: (i) sending a message to the email address you
                  provide or (ii) by posting to the App. Notices sent by email
                  will be effective when we send the email and notices we
                  provide by posting will be effective upon posting. It is your
                  responsibility to keep your email address current.
                </p>
                <br />
                <p>
                  (b) <u>To Us</u>. To give us notice under these Terms, you
                  must contact us as follows: by personal delivery, overnight
                  courier, or registered or certified mail to Ociety, Inc., 205
                  Hudson Street, New York, NY 10013, United States. We may
                  update the address for notices to us by posting a notice on
                  the App. Notices provided by personal delivery will be
                  effective immediately. Notices provided by overnight courier
                  will be effective one business day after they are sent.
                  Notices provided by registered or certified mail will be
                  effective three business days after they are sent.
                </p>
                <br />
                <p>
                  17. <u>Severability.</u> If any provision of these Terms is
                  invalid, illegal, void or unenforceable, then that provision
                  will be deemed severed from these Terms and will not affect
                  the validity or enforceability of the remaining provisions of
                  these Terms.
                </p>
                <br />
                <p>
                  18. <u>Entire Agreement.</u> Our order confirmation
                  information, these Terms, our Website Terms of Use and our
                  Privacy Policy will be deemed the final and integrated
                  agreement between you and us on the matters contained in these
                  Terms.
                </p>
                <br />
                <p></p>
                <br />
                <p></p>
                <br />
                <p></p>
                <br />
                <p></p>
                <br />
                <p></p>
                <br />
                <p></p>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </div>
  );
};

export default TermsOfSale;
