import { List, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import CounterIcon from "../../../../../../common/components/CounterIcon";
import OcietyIcon from "../../../../../../common/components/OcietyIcon";
// eslint-disable-next-line max-len
import { OcietyIconName } from "../../../../../../common/components/OcietyIcon/iconsMapping";
import OcietyNavLink from "../../../../../../common/components/OcietyNavLink";
import { useCurrentVenueId } from "../../../../../../common/hooks/useCurrentVenueId";
import { useIsManager } from "../../../../../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../../../../../common/hooks/useIsSuperAdmin";
import { OcietyTheme } from "../../../../../../common/models";
import { isPackagesDisabled } from "../../../../../../config";
import { Route } from "../../../../../../config/router";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../../../store/rootReducer";
import { getNewMessagesQty } from "../../../../../../utils/messagingUtils";
import { sortByOrder } from "../../../../../../utils/objectArrayUtils";
import Props from "./Props";
import { useStyles } from "./styles";

interface LinkItem {
  route: Route;
  withEntityId?: boolean;
  order?: number;
  label: string;
  icon: OcietyIconName;
  iconActive: OcietyIconName;
}

const superAdminPages: Route[] = [
  Route.Dashboard,
  Route.Promotions,
  Route.Venues,
  Route.UserPhotos,
  Route.ReportedMedia,
  Route.VenuesPending,
  Route.VenuesClaimRequestsPending,
  Route.VenuesChangesPending,
];

let linksMapping: LinkItem[] = [
  {
    route: Route.Dashboard,
    order: 10,
    withEntityId: true,
    iconActive: "dashboardActive",
    icon: "dashboard",
    label: dictionary.sidebar.dashboardLink,
  },
  {
    route: Route.Messages,
    order: 20,
    iconActive: "messagesActive",
    icon: "messages",
    label: dictionary.sidebar.messagesLink,
  },
  {
    route: Route.Promotions,
    order: 30,
    withEntityId: true,
    iconActive: "promotionActive",
    icon: "promotion",
    label: dictionary.sidebar.promotionsLink,
  },
  {
    route: Route.Reporting,
    order: 40,
    withEntityId: true,
    iconActive: "circleDiagramActive",
    icon: "circleDiagram",
    label: dictionary.sidebar.reportingLink,
  },
  // {
  //   route: Route.Recommendations,
  //   order: 50,
  //   withEntityId: true,
  //   iconActive: "thumbUpActive",
  //   icon: "thumbUp",
  //   label: dictionary.sidebar.recommendationsLink,
  // },
  {
    route: Route.Reviews,
    order: 60,
    withEntityId: true,
    iconActive: "starActive",
    icon: "star",
    label: dictionary.sidebar.reviewsLink,
  },
  {
    route: Route.Plan,
    order: 70,
    iconActive: "priceLabelActive",
    icon: "priceLabel",
    label: dictionary.sidebar.planLink,
  },
];

const SidebarNav: FC<Props> = (props: Props) => {
  const { className, toggleSidebar, isSidebarOpen, ...rest } = props;
  const theme: OcietyTheme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const venueId = useCurrentVenueId();
  const superAdmin = useIsSuperAdmin();
  const isManager = useIsManager();
  const newMessagesQty = useSelector<AppState, number>((state) =>
    getNewMessagesQty(state.messaging.chatData)
  );

  useEffect(() => {
    const messagesLink = linksMapping.find(
      (link) => link.route === Route.Messages
    );

    if (messagesLink) {
      messagesLink.iconActive = newMessagesQty
        ? "activeUnreadMessages"
        : "messagesActive";
      messagesLink.icon = newMessagesQty ? "unreadMessages" : "messages";
    }
  }, [newMessagesQty]);

  if (superAdmin || isManager) {
    linksMapping = linksMapping.filter((link) =>
      superAdminPages.includes(link.route)
    );

    if (!linksMapping.some((link) => link.route === Route.Venues)) {
      linksMapping.push({
        route: Route.Venues,
        order: 80,
        withEntityId: true,
        iconActive: "venuesActive",
        icon: "venuesGrey",
        label: dictionary.sidebar.venuesLink,
      });
    }

    if (!linksMapping.some((link) => link.route === Route.VenuesPending)) {
      linksMapping.push({
        route: Route.VenuesPending,
        order: 90,
        withEntityId: true,
        iconActive: "venuesNewPendingActive",
        icon: "venuesNewPending",
        label: dictionary.sidebar.venuesCreationRequests,
      });

      if (!linksMapping.some((link) => link.route === Route.UserPhotos)) {
        linksMapping.push({
          route: Route.UserPhotos,
          order: 100,
          withEntityId: true,
          iconActive: "userPhotosActive",
          icon: "userPhotos",
          label: dictionary.sidebar.userPhotosLink,
        });
      }

      if (!linksMapping.some((link) => link.route === Route.ReportedMedia)) {
        linksMapping.push({
          route: Route.ReportedMedia,
          order: 110,
          withEntityId: true,
          iconActive: "reportedPhotosActive",
          icon: "reportedPhotos",
          label: dictionary.sidebar.reportedMediaLink,
        });
      }

      if (
        !linksMapping.some(
          (link) => link.route === Route.VenuesClaimRequestsPending
        )
      ) {
        linksMapping.push({
          route: Route.VenuesClaimRequestsPending,
          order: 120,
          withEntityId: true,
          iconActive: "venuesClaimActive",
          icon: "venuesClaim",
          label: dictionary.sidebar.venuesClaimRequests,
        });
      }
    }

    if (
      !linksMapping.some((link) => link.route === Route.VenuesChangesPending)
    ) {
      linksMapping.push({
        route: Route.VenuesChangesPending,
        order: 130,
        withEntityId: true,
        iconActive: "settingsActive",
        icon: "settings",
        label: dictionary.sidebar.venuesChangesLink,
      });
    }
  } else {
    linksMapping = linksMapping.filter(
      (link) =>
        link.route !== Route.Venues ||
        // @ts-ignore
        link.route !== Route.VenuesPending ||
        link.route !== Route.VenuesChangesPending ||
        link.route !== Route.VenuesClaimRequestsPending
    );

    if (!linksMapping.some((link) => link.route === Route.VenueSettings)) {
      linksMapping.push({
        route: Route.VenueSettings,
        order: 80,
        withEntityId: true,
        iconActive: "settingsActive",
        icon: "settings",
        label: dictionary.sidebar.venueSettingsLink,
      });
    }

    if (!isPackagesDisabled) {
      if (
        !linksMapping.some((link) => link.route === Route.BillingInformation)
      ) {
        linksMapping.push({
          route: Route.BillingInformation,
          order: 90,
          iconActive: "billingInformationActive",
          icon: "billingInformation",
          label: dictionary.sidebar.billingInformationLink,
        });
      }
    }
  }

  const handleLinkClick = () => {
    if (isSidebarOpen && isMobile) {
      toggleSidebar();
    }
  };

  const renderLink = ({
    route,
    withEntityId,
    label,
    icon,
    iconActive,
  }: LinkItem) => (
    <OcietyNavLink
      to={withEntityId && venueId ? [route, String(venueId)] : route}
      key={route}
      className={classes.link}
      linkTextClassName={clsx(classes.linkText)}
      onClick={handleLinkClick}
      icon={
        <>
          <OcietyIcon className={classes.icon} name={icon} />
          <OcietyIcon className={classes.iconActive} name={iconActive} />
        </>
      }>
      {label}
      {route === Route.Messages ? (
        <CounterIcon items={newMessagesQty} className={classes.counterIcon} />
      ) : null}
    </OcietyNavLink>
  );

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {linksMapping.sort(sortByOrder).map(renderLink)}
    </List>
  );
};

export default SidebarNav;
