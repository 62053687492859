import { Typography } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { AlertContext } from "../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../common/components/LoadingIndicator";
import dictionary from "../../../i18n/en_US/dictionary";
import IRole from "../roles/models/IRole";
import Props from "../roles/Props";
import { getAllPermissions, getRole, savePermissionRole } from "./api";
import RolesGroupPermissionList from "./components/RolesGroupPermissionList";
import { IPermissionGroup } from "./models/IPermissionGroup";
import { IRolePermission } from "./models/IRolePermission";
import { useStyles } from "./styles";

const RBACRolePermissions: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const roleId: number = parseInt(props.match.params.number);
  const { showAlert } = useContext(AlertContext);
  const initPermmissions: IPermissionGroup[] = [];
  const [permissions, setPermissions] = useState(initPermmissions);
  const initRolePermissions: Permissions[] = [];
  const [rolePermissions, setRolePermissions] = useState(initRolePermissions);

  const [loading, setLoading] = useState(false);

  const [role, setRole] = useState<IRole | null>(null);

  const handleToggle = (permission: Permissions) => {
    const currentIndex = rolePermissions.indexOf(permission);
    const newChecked = [...rolePermissions];

    let status = false;
    if (currentIndex === -1) {
      status = true;
      newChecked.push(permission);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRolePermissions(newChecked);
    saveRolePermission(permission, status);
  };

  const saveRolePermission = async (
    permission: Permissions,
    status: boolean
  ) => {
    try {
      setLoading(true);

      const result = await savePermissionRole(roleId, permission, status);

      if (result) {
        showAlert("TTT", "success");
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(
        dictionary.promotions.createPromotionForm.createPromotionError,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPermissions()
      .then((permissions: IPermissionGroup[]) => {
        if (permissions) {
          console.log("permissions", permissions);
          setPermissions(permissions);
        }
      })
      .catch(() => {
        showAlert(
          dictionary.promotions.createPromotionForm.createPromotionError,
          "error"
        );
      });
    getRole(roleId)
      .then((role: IRole) => {
        if (role) {
          setRole(role);
          const permissions: Permissions[] = role.permissions.map(
            (perm: IRolePermission) => {
              return perm.permission;
            }
          );
          setRolePermissions(permissions);
        }
      })
      .catch(() => {
        showAlert(
          dictionary.promotions.createPromotionForm.createPromotionError,
          "error"
        );
      });
  }, [roleId, showAlert]);

  const renderPermissionGroup = () =>
    permissions.map((permissionGroup: IPermissionGroup) => (
      <RolesGroupPermissionList
        key={permissionGroup.key}
        rolePermissions={rolePermissions}
        handleToggle={handleToggle}
        permissionGroup={permissionGroup}
      />
    ));

  return (
    <div className={classes.root}>
      {loading && <LoadingIndicator withMask />}
      {role && (
        <Typography variant="h1" component="h2">
          {role.name}
        </Typography>
      )}
      <div className={classes.wrapper}>{renderPermissionGroup()}</div>
    </div>
  );
};
export default withRouter(RBACRolePermissions);
