import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "content"
  | "actions"
  | "subtitle"
  | "titleWrapper"
  | "title"
  | "message"
  | "actionButtonContainer"
  | "actionButton";

const PADDING = 40;

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      titleWrapper: {
        padding: `10px 10px 10px ${PADDING}px`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #e1e2e3",
        [theme.breakpoints.down("sm")]: {
          padding: 20,
        },
      },
      title: {
        fontWeight: 600,
        fontSize: 20,
        color: "#273950",
      },
      content: {
        padding: `${PADDING}px ${PADDING}px 10px`,
        maxWidth: 544,
        [theme.breakpoints.down("sm")]: {
          padding: `${PADDING}px 20px 10px 20px`,
        },
      },
      actions: {
        padding: PADDING,
        maxWidth: 544,
        [theme.breakpoints.down("sm")]: {
          padding: "10px 20px",
        },
      },
      subtitle: {
        fontWeight: 600,
        marginBottom: 10,
        textAlign: "center",
      },
      message: {
        fontSize: 18,
        fontWeight: 600,
        color: colors.grey.secondaryLight,
        textAlign: "center",
      },
      actionButtonContainer: {
        justifyContent: "flex-end",
        display: "flex",
      },
      actionButton: {
        [theme.breakpoints.down("sm")]: {
          marginBottom: 16,
          width: "100%",
        },
      },
    })
);
