import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { ChangeEvent, FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../../common/components/LoadingIndicator";
import { useForm } from "../../../../../common/hooks";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { createRole } from "./api";
import { createRoleForm, CreateRoleFormField, schema } from "./form";
import Props from "./Props";
import { useStyles } from "./styles";

const CreateRoleDialog: FC<Props> = (props: Props) => {
  const { isOpen, setOpen, onSuccessCreate } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const { showAlert } = useContext(AlertContext);

  const handleClose = () => {
    setOpen(false);
  };
  const { form, setAndValidate, validateForm } = useForm(
    schema,
    createRoleForm
  );

  const handleChangeFormTextField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;

    setAndValidate(key as CreateRoleFormField, value);
  };

  const handleCreateRole = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);

      await createRole(form.values);

      showAlert(
        dictionary.promotions.createPromotionForm.successMessage,
        "success"
      );
      // dispatch(getVenuePromotions(venueId));
      onSuccessCreate && onSuccessCreate();
      handleClose();
    } catch (e) {
      if (!e) {
        return;
      }

      handleClose();
      showAlert(
        dictionary.promotions.createPromotionForm.createPromotionError,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="alert-dialog-title">{"Create new role"}</DialogTitle>
      <DialogContent className={classes.content}>
        <form>
          <TextField
            className={classes.textField}
            required
            error={!!form.errors.name}
            fullWidth
            helperText={form.errors.name}
            label={"Name"}
            name="name"
            onChange={handleChangeFormTextField}
            type="text"
            value={form.values.name || ""}
            variant="outlined"
          />
          <TextField
            required
            error={!!form.errors.description}
            fullWidth
            helperText={form.errors.description}
            label={
              dictionary.promotions.createPromotionForm.descriptionFieldLabel
            }
            name="description"
            onChange={handleChangeFormTextField}
            type="text"
            value={form.values.description || ""}
            variant="outlined"
          />
        </form>
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={() => handleClose()}>
          {dictionary.promotions.createPromotionForm.cancelButton}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={!form.isValid}
          onClick={handleCreateRole}>
          {dictionary.promotions.createPromotionForm.createButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRoleDialog;
