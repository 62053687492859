import MomentUtils from "@date-io/moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { AlertContext } from "../../../../../../../../../common/components/Alert/AlertContextProvider";
import OcietyDatePicker from "../../../../../../../../../common/components/forms/OcietyDatePicker";
import OcietyBlockWrapperForRadioButton from "../../../../../../../../../common/components/OcietyBlockWrapperForRadioButton";
import OcietyTooltip from "../../../../../../../../../common/components/OcietyTooltip";
import { useCurrentVenueId } from "../../../../../../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../../../../../../common/hooks/useRestApi";
import { OcietyTheme } from "../../../../../../../../../common/models";
import { ReactComponent as InfoTooltip } from "../../../../../../../../../common/svg/icons/info-tooltip.svg";
import dictionary from "../../../../../../../../../i18n/en_US/dictionary";
import { colors } from "../../../../../../../../../theme/colors";
import { PromotionLimitType, PromotionType } from "../../../../../../enums";
import {
  limitItemsNameBaseRules,
  limitPriceBaseRules,
  PromotionMainFormField,
} from "../../../../forms/main";
import { IDayHours } from "../../../../models/IDayHours";
import { PromotionFormDialogContext } from "../../../../PromotionFormDialogContextProvider";
import { GET_IS_ALLOWED_FUll_ORDER_PROMOTION_TYPE } from "../BasicInfoPromotionStep/api";
import {
  getPromotionsCategories,
  getPromotionsSubCategories,
  PROMOTION_DIETARY_RESTRICTIONS,
} from "./api";
import PromotionDayHoursRow from "./components/PromotionDayHoursRow";
import IPromotionCategory from "./models/IPromotionCategory";
import IPromotionDietaryRestriction from "./models/IPromotionDietaryRestriction";
import Props from "./Props";
import { useStyles } from "./styles";

const MainInfoPromotionStep: FC<Props> = (props: Props) => {
  const { setDietaryRestrictions, dietaryRestrictions } = props;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const focusOnError = useRef<HTMLDivElement>(null);
  const errorHasFocus = useRef<boolean>(false);
  const venueId = useCurrentVenueId();
  const { mainForm, daysHours, setDaysHours } = useContext(
    PromotionFormDialogContext
  );
  const isMobile = useMediaQuery((theme: OcietyTheme) =>
    theme.breakpoints.down("sm")
  );
  const [, getPromotionsDietaryRestrictions] = useRestApi<
    any,
    IPromotionDietaryRestriction[]
  >(PROMOTION_DIETARY_RESTRICTIONS, "GET");
  const [{ data: isAllowedFullOrderResponse }, getIsAllowedFullOrderType] =
    useRestApi<{
      allowed: boolean;
    }>(GET_IS_ALLOWED_FUll_ORDER_PROMOTION_TYPE(venueId), "GET");

  const { form, setAndValidate, setSchemaRules, setFormValue, clearErrors } =
    mainForm;
  const currentPromotionType = form.values.type as PromotionType;

  let endValue = form.values.end;
  const isSingleItem = form.values.limitType === PromotionLimitType.SINGLE;

  if (moment(form.values.start).unix() > moment(form.values.end).unix()) {
    endValue = form.values.start;
  }

  const [promotionCategories, setPromotionCategories] = useState<
    Array<IPromotionCategory>
  >([]);

  const [promotionDietaryRestrictions, setPromotionDietaryRestrictions] =
    useState<Array<IPromotionDietaryRestriction>>([]);

  const [promotionSubCategories, setPromotionSubCategories] = useState<
    Array<IPromotionCategory>
  >([]);

  const [applyAllDays, setApplyAllDays] = useState<{
    enable: boolean;
    start: string;
    end: string;
  }>({ enable: false, start: "00:00:00", end: "23:59:59" });

  const getCategoryValue = () => {
    if (form.values.categoryId) {
      return form.values.categoryId;
    }
  };

  const currentCategoryId = getCategoryValue();

  useEffect(() => {
    getIsAllowedFullOrderType().catch((e) => console.error(e));
  }, [getIsAllowedFullOrderType]);

  useEffect(() => {
    if (!mainForm.form.isValid) {
      focusOnError?.current?.focus();
      errorHasFocus.current = true;
    }
  }, [mainForm.form.isValid]);

  useEffect(() => {
    if (
      currentPromotionType === PromotionType.FOOD ||
      currentPromotionType === PromotionType.DRINK
    ) {
      setSchemaRules({
        categoryId: {
          fieldName: dictionary.promotions.createPromotionForm.category,
          type: "number",
          required: true,
        },
        subCategoryId: {
          fieldName: dictionary.promotions.createPromotionForm.subCategory,
          type: "number",
          required: true,
        },
      });
    } else {
      setAndValidate("limitType", PromotionLimitType.GROUP);
      setSchemaRules({
        categoryId: {
          type: "number",
        },
        subCategoryId: {
          type: "number",
        },
      });
    }
  }, [currentPromotionType, setAndValidate, setSchemaRules]);
  const formPromotionLimitTypeRef = useRef<PromotionLimitType>(
    form.values.limitType as PromotionLimitType
  );

  useEffect(() => {
    const limitType = form.values.limitType;

    if (limitType !== formPromotionLimitTypeRef.current) {
      formPromotionLimitTypeRef.current = limitType as PromotionLimitType;
      clearErrors(["limitMaxAmount", "limitItemsName", "limitPrice"]);

      if (limitType === PromotionLimitType.SINGLE) {
        setSchemaRules({
          limitItemsName: {
            ...limitItemsNameBaseRules,
            fieldName: dictionary.promotions.createPromotionForm.productName,
          },
          limitPrice: {
            ...limitPriceBaseRules,
            fieldName: dictionary.promotions.createPromotionForm.productPrice,
          },
        });
      } else if (limitType === PromotionLimitType.GROUP) {
        setSchemaRules({
          limitItemsName: {
            ...limitItemsNameBaseRules,
            fieldName: dictionary.promotions.createPromotionForm.groupName,
          },
          limitPrice: {
            ...limitPriceBaseRules,
            fieldName: dictionary.promotions.createPromotionForm.averagePrice,
          },
        });
      }
    }
  }, [form.values.limitType, clearErrors, setSchemaRules]);

  useEffect(() => {
    setPromotionSubCategories([]);
    getPromotionsCategories(currentPromotionType)
      .then((categories) => {
        if (categories.length) {
          setPromotionCategories(categories);
        }
      })
      .catch(() => {
        console.log("ERROR");
      });

    getPromotionsDietaryRestrictions()
      .then((restrictions) => {
        if (restrictions.length) {
          setPromotionDietaryRestrictions(restrictions);
        }
      })
      .catch(() => {
        console.log("ERROR");
      });
    // eslint-disable-next-line prettier/prettier
  }, [
    setPromotionSubCategories,
    currentPromotionType,
    setFormValue,
    getPromotionsDietaryRestrictions,
  ]);

  useEffect(() => {
    setPromotionSubCategories([]);
    if (currentCategoryId) {
      getPromotionsSubCategories(Number(currentCategoryId))
        .then((categories: IPromotionCategory[]) => {
          if (categories.length) {
            setPromotionSubCategories(categories);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [currentCategoryId, currentPromotionType]);

  const validateDaysHoursEnable = (daysHours: IDayHours[]): boolean => {
    for (const dh of daysHours) {
      if (dh.isActive) {
        return true;
      }
    }
    return false;
  };

  const toggleDay = (id: string) => {
    const newDaysHours = daysHours.map((DH: IDayHours) => {
      if (DH.id === id) {
        return { ...DH, isActive: !DH.isActive };
      }
      return { ...DH };
    });

    if (validateDaysHoursEnable(newDaysHours)) {
      setDaysHours(newDaysHours);
    } else {
      showAlert("Must be included for at least 1 day", "error");
    }
  };

  const toggleApplyAll = () => {
    const enable = !applyAllDays.enable;

    if (enable) {
      const newDaysHours = daysHours.map((DH: IDayHours) => {
        return { ...DH, start: applyAllDays.start, end: applyAllDays.end };
      });

      setDaysHours(newDaysHours);
    }

    setApplyAllDays({ ...applyAllDays, enable });
  };

  const editTime = (id: string, timeType: "start" | "end", newTime: string) => {
    const newDaysHours = daysHours.map((DH: IDayHours) => {
      if (DH.id === id) {
        return { ...DH, [timeType]: newTime };
      }

      return { ...DH };
    });

    setDaysHours(newDaysHours);
    setApplyAllDays({ ...applyAllDays, enable: false });
  };

  const editApplyAllTime = (
    id: string,
    timeType: "start" | "end",
    newTime: string
  ) => {
    const newDaysHours = daysHours.map((DH: IDayHours) => {
      return { ...DH, [timeType]: newTime };
    });

    if (applyAllDays.enable) {
      setDaysHours(newDaysHours);
    }

    setApplyAllDays({ ...applyAllDays, [timeType]: newTime });
  };

  const handleChangeLimitType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAndValidate(
        "limitType",
        (event.target as HTMLInputElement).value || ""
      );
    },
    [setAndValidate]
  );

  const displayDays = () =>
    daysHours?.map((day: IDayHours) => (
      <PromotionDayHoursRow
        key={day.id}
        {...day}
        toggleDay={toggleDay}
        editTime={editTime}
      />
    ));

  const handleChangeCategory = (e: any) => {
    const value = e.target.value;
    if (value) {
      setAndValidate("categoryId", String(value));
      setAndValidate("subCategoryId", "");
    }
  };

  const handleChangeSubCategory = (e: any) => {
    const value = e.target.value;

    if (value) {
      setAndValidate("subCategoryId", String(value));
    }
  };

  const handleStartDate = useCallback(
    (date: MaterialUiPickersDate) => {
      setAndValidate("start", moment(date).format("YYYY-MM-DD"));
    },
    [setAndValidate]
  );

  const handleEndDate = useCallback(
    (date: MaterialUiPickersDate) => {
      // setAndValidate("expire", (event.target as HTMLInputElement).value || "");
      setAndValidate("end", moment(date).format("YYYY-MM-DD"));
    },
    [setAndValidate]
  );

  const getCategoryLabel = () => {
    if (currentPromotionType === PromotionType.DRINK) {
      return dictionary.promotions.drinkCategoriesLabel;
    }

    if (currentPromotionType === PromotionType.FOOD) {
      return dictionary.promotions.foodCategoriesLabel;
    }

    return dictionary.promotions.categoriesLabel;
  };

  const handleChangeFormField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const pattern = new RegExp(/^[0-9]*\.?(\d{1,2})?$/i);
    if (pattern.test(event.target.value.toString())) {
      setAndValidate(
        event.target.name as PromotionMainFormField,
        event.target.value
      );
    }
  };

  const handleChangeFormNameField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAndValidate(
      event.target.name as PromotionMainFormField,
      event.target.value
    );
  };

  const clearValueFieldOnClick = (e: any) => {
    e.target.value = "";
  };

  const datePickers = () => {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <PromotionDayHoursRow
          key={"applyalldays"}
          day={"Apply to all"}
          id={"applyalldays"}
          isActive={applyAllDays.enable}
          start={applyAllDays.start}
          end={applyAllDays.end}
          toggleDay={toggleApplyAll}
          editTime={editApplyAllTime}
        />
        {displayDays()}
      </MuiPickersUtilsProvider>
    );
  };

  const handleChangeType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAndValidate("type", (event.target as HTMLInputElement).value || "");
      mainForm.setFormValue("categoryId", "");
      mainForm.setFormValue("subCategoryId", "");
    },
    [setAndValidate, mainForm]
  );

  const renderItem = (item: IPromotionDietaryRestriction) => {
    const handleChange = () => {
      const current = dietaryRestrictions || [];
      const index = dietaryRestrictions?.map((i) => i.id).indexOf(item.id);
      if (current && index !== undefined && index !== -1) {
        current.splice(index, 1);
      } else {
        current.push(item);
      }

      setDietaryRestrictions(current);
      setFormValue(
        "dietaryRestrictions",
        current?.map((item) => item.id).join()
      );
    };

    return (
      <FormControlLabel
        control={
          <Checkbox
            color={"primary"}
            checked={Boolean(
              dietaryRestrictions?.find((i) => i.name === item.name)
            )}
            onChange={handleChange}
            name={item.name}
          />
        }
        classes={{ label: classes.dietaryLabel }}
        label={item.name}
      />
    );
  };

  return (
    <>
      <Grid container spacing={4} className={classes.root}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h3">
            {dictionary.promotions.createPromotionForm.promotionTypeLabel}
          </Typography>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            style={{ marginTop: 10 }}
            value={form.values.type}
            onChange={handleChangeType}
            defaultValue={PromotionType.FOOD}>
            <Grid container spacing={1}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <OcietyBlockWrapperForRadioButton
                  isActive={form.values.type === PromotionType.FOOD}
                  label={
                    dictionary.promotions.createPromotionForm
                      .foodPromotionTypeLabel
                  }
                  value={PromotionType.FOOD}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <OcietyBlockWrapperForRadioButton
                  isActive={form.values.type === PromotionType.DRINK}
                  label={
                    dictionary.promotions.createPromotionForm
                      .drinkPromotionTypeLabel
                  }
                  value={PromotionType.DRINK}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <OcietyBlockWrapperForRadioButton
                  isActive={form.values.type === PromotionType.ORDER}
                  disabled={!isAllowedFullOrderResponse?.allowed}
                  label={
                    dictionary.promotions.createPromotionForm
                      .fullOrderPromotionTypeLabel
                  }
                  value={PromotionType.ORDER}>
                  <OcietyTooltip
                    iconClasses={{
                      iconContainer: classes.fullOrderTooltipIconContainer,
                    }}
                    title={
                      dictionary.promotions.createPromotionForm
                        .disabledFullOrderTooltip
                    }
                    placement={"bottom-start"}>
                    <InfoTooltip style={{ marginTop: 10, marginLeft: 24 }} />
                  </OcietyTooltip>
                </OcietyBlockWrapperForRadioButton>
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        {currentPromotionType !== PromotionType.ORDER && (
          <>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormControl
                fullWidth
                className={classes.formControl}
                variant={"outlined"}
                error={!!form.errors.categoryId}
                required>
                <InputLabel id="promotion-category-label">
                  {getCategoryLabel()}
                </InputLabel>
                <Select
                  id="promotion-category"
                  value={form.values.categoryId}
                  labelId={"promotion-category-label"}
                  label={getCategoryLabel()}
                  onChange={handleChangeCategory}
                  autoFocus={!!form.errors.categoryId}>
                  {promotionCategories.map(({ id, name }) => (
                    <MenuItem key={"cat-" + id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText className={classes.helperText}>
                {form.errors.categoryId}
              </FormHelperText>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormControl
                fullWidth
                className={classes.formControl}
                variant={"outlined"}
                error={!!form.errors.subCategoryId}
                disabled={!currentCategoryId}
                required>
                <InputLabel id="promotion-sub-category-label">
                  {dictionary.promotions.subCategoriesLabel}
                </InputLabel>
                <Select
                  id="promotion-sub-category"
                  value={form.values.subCategoryId}
                  labelId={"promotion-sub-category-label"}
                  label={dictionary.promotions.subCategoriesLabel}
                  onChange={handleChangeSubCategory}
                  autoFocus={!!form.errors.subCategoryId}>
                  {promotionSubCategories.map(({ id, name }) => (
                    <MenuItem key={"subcat-" + id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText className={classes.helperText}>
                {form.errors.subCategoryId}
              </FormHelperText>
            </Grid>
          </>
        )}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <OcietyDatePicker
            label={"Start Date"}
            minDate={new Date()}
            value={form.values.start}
            onChange={handleStartDate}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <OcietyDatePicker
            label={"Expiration Date"}
            minDate={moment(form.values.start).add(1, "days").toDate()}
            value={endValue}
            onChange={handleEndDate}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.promotions.createPromotionForm.descriptionTitle}
            name="description"
            error={!!form.errors.description}
            helperText={form.errors.description}
            onChange={handleChangeFormNameField}
            type="text"
            value={form.values.description}
            variant="outlined"
            multiline={true}
            rows={3}
          />
        </Grid>
        {!isMobile && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Accordion defaultExpanded={true} className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionHeader}
                aria-controls="panel1a-content">
                <Typography variant="h3">
                  {
                    dictionary.promotions.createPromotionForm
                      .usableOnSpecifiedDays
                  }
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Grid container className={classes.promotionDaysWrapper}>
                  {datePickers()}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {isMobile && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3">
              {dictionary.promotions.createPromotionForm.usableOnSpecifiedDays}
            </Typography>
            <Grid container className={classes.promotionDaysWrapper}>
              {datePickers()}
            </Grid>
          </Grid>
        )}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.dietaryRestrictionsSection}>
          <Accordion
            defaultExpanded={true}
            className={classes.dietaryRestrictionsContainer}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              className={classes.dietaryRestrictionsHeader}>
              <Typography variant="h3">
                {
                  dictionary.promotions.createPromotionForm
                    .dietaryRestrictionsTitle
                }
              </Typography>
              <Typography variant="h3" style={{ color: colors.grey.secondary }}>
                {dictionary.promotions.createPromotionForm.optionalLabel}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.dietaryRestrictionsDetails}>
              <Grid
                container
                spacing={3}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.dietaryRestrictionItem}>
                {promotionDietaryRestrictions.map((item) => (
                  <Grid key={item.id} lg={4} md={4} sm={4} xs={6}>
                    {renderItem(item)}
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h3">
            {dictionary.promotions.createPromotionForm.promotionAmount}
          </Typography>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            onChange={handleChangeLimitType}
            className={classes.promotionAmountWrapper}
            value={form.values.limitType}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <OcietyBlockWrapperForRadioButton
                  isActive={form.values.limitType === PromotionLimitType.SINGLE}
                  label={
                    dictionary.promotions.createPromotionForm
                      .promotionSingleItemLimit
                  }
                  value={PromotionLimitType.SINGLE}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <OcietyBlockWrapperForRadioButton
                  isActive={form.values.limitType === PromotionLimitType.GROUP}
                  label={
                    dictionary.promotions.createPromotionForm
                      .promotionItemsGroupLimit
                  }
                  value={PromotionLimitType.GROUP}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item container spacing={2}>
          <Grid
            item
            lg={isSingleItem ? 12 : 6}
            md={isSingleItem ? 12 : 6}
            sm={12}
            xs={12}>
            <TextField
              fullWidth
              label={
                isSingleItem
                  ? dictionary.promotions.createPromotionForm.productName
                  : dictionary.promotions.createPromotionForm.groupName
              }
              name={"limitItemsName"}
              error={!!form.errors.limitItemsName}
              helperText={form.errors.limitItemsName}
              onChange={handleChangeFormNameField}
              type="text"
              value={form.values.limitItemsName}
              required
              variant="outlined"
              inputRef={
                errorHasFocus.current && form.errors.limitItemsName
                  ? focusOnError
                  : null
              }
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              fullWidth
              label={
                isSingleItem
                  ? dictionary.promotions.createPromotionForm.productPrice
                  : dictionary.promotions.createPromotionForm.averagePrice
              }
              name={"limitPrice"}
              error={!!form.errors.limitPrice}
              helperText={form.errors.limitPrice}
              onChange={handleChangeFormField}
              onClick={clearValueFieldOnClick}
              className={isSingleItem ? classes.promotionAmountMarginTop : ""}
              type="text"
              value={form.values.limitPrice}
              variant="outlined"
              inputRef={
                errorHasFocus.current && form.errors.limitPrice
                  ? focusOnError
                  : null
              }
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              fullWidth
              label={dictionary.promotions.createPromotionForm.percent}
              name="limitPercent"
              error={!!form.errors.limitPercent}
              helperText={form.errors.limitPercent}
              className={classes.promotionAmountMarginTop}
              onChange={handleChangeFormField}
              onClick={clearValueFieldOnClick}
              type="text"
              value={form.values.limitPercent}
              variant="outlined"
              inputRef={
                errorHasFocus.current && form.errors.limitPercent
                  ? focusOnError
                  : null
              }
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {form.values.limitType == PromotionLimitType.GROUP && (
              <TextField
                fullWidth
                label={dictionary.promotions.createPromotionForm.maxAmount}
                name="limitMaxAmount"
                error={!!form.errors.limitMaxAmount}
                className={classes.promotionAmountMarginTop}
                helperText={form.errors.limitMaxAmount}
                onChange={handleChangeFormField}
                onClick={clearValueFieldOnClick}
                type="text"
                value={form.values.limitMaxAmount}
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MainInfoPromotionStep;
