import React from "react";

import { ReactComponent as PromotionIcon } from "./PROMOTION.svg";
import { ReactComponent as RecommendationIcon } from "./RECOMMENDATION.svg";
import { ReactComponent as ReviewIcon } from "./REVIEW.svg";

const icons = {
  PROMOTION: PromotionIcon,
  RECOMMENDATION: RecommendationIcon,
  VENUE_REVIEW: ReviewIcon,
  VENUE_POS_CONNECT: PromotionIcon,
  VENUE_POS_DISCONNECT: PromotionIcon,
  VENUE_MENU_DELETE: PromotionIcon,
};

export default icons;
