import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "icon"
  | "actionsWrapper"
  | "planButton"
  | "periodSelect";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginBottom: theme.spacing(30 / 8),
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      icon: {
        width: 70,
        height: 70,
      },
      actionsWrapper: {
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
        },
      },
      planButton: {
        marginRight: theme.spacing(20 / 8),
        [theme.breakpoints.up("lg")]: {
          minWidth: 227,
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: theme.spacing(2),
          width: "100%",
        },
      },
      periodSelect: {
        [theme.breakpoints.down("xs")]: {
          marginBottom: theme.spacing(2),
          width: "100%",
        },
      },
    })
);
