import { TableRow } from "@material-ui/core";
import React, { FC } from "react";

import Avatar from "../../../../../../common/components/Avatar";
import ButtonBlack from "../../../../../../common/components/ButtonBlack";
import OcietyTooltip from "../../../../../../common/components/OcietyTooltip";
import TD from "../../../../../../common/components/TD";
import useReplyToReview from "../../../../../../common/hooks/useReplyToReview";
import { ReactComponent as MessageIcon } from "../../../../../../common/svg/icons/message.svg";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../utils/dateTimeUtils";
import { formatUserTypeToString } from "../../../../../../utils/userUtils";
import RatingStars from "../../../RatingStars";
import Props from "./Props";
import { useStyles } from "./styles";

const AllReviewsTableRow: FC<Props> = (props: Props) => {
  const {
    review: {
      userAvatar,
      userType,
      service,
      food,
      possibleResponse,
      username,
      createdAt,
      updatedAt,
      userId,
    },
  } = props;
  const classes = useStyles();
  const handleClickReply = useReplyToReview({ userId, userAvatar, username });

  return (
    <TableRow hover>
      <TD>
        <Avatar imagePath={userAvatar} />
      </TD>
      <TD>{username}</TD>
      <TD>{formatUserTypeToString(userType)}</TD>
      <TD>{getFormattedDate(createdAt)}</TD>
      <TD align={"center"}>
        {createdAt !== updatedAt ? getFormattedDate(updatedAt) : "-"}
      </TD>
      <TD>
        <RatingStars numberOfStars={food} />
      </TD>
      <TD>
        <RatingStars numberOfStars={service} />
      </TD>
      <TD>
        <div className={classes.actionsRow}>
          <div>
            <ButtonBlack
              disabled={!possibleResponse}
              onClick={handleClickReply}>
              <MessageIcon className={classes.messageIcon} />
              {dictionary.reviews.allReviewsReply}
            </ButtonBlack>
          </div>
          {!possibleResponse && (
            <OcietyTooltip title={dictionary.reviews.allReviewsReplyDisabled} />
          )}
        </div>
      </TD>
    </TableRow>
  );
};

export default AllReviewsTableRow;
