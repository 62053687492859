import { AtLocationStatus, ScreenView, UserType } from "../../models/enums";
import ILiveUserData from "../../models/ILiveUserData";

export const userName = (row: ILiveUserData) => {
  if (row.userIsAnonymous) {
    return "Anonymous";
  }

  if (row.firstName || row.lastName) {
    return `${row.firstName ? row.firstName : ""} ${
      row.lastName ? row.lastName : ""
    }`;
  }

  return row.username;
};
export const userType = (row: ILiveUserData) => {
  if (row.userType === UserType.USER) {
    return "User";
  }

  if (row.userType === UserType.CRITIC) {
    return "Critic";
  }

  return row.userType;
};

export const userScreen = (row: ILiveUserData) => {
  if (row.screen === ScreenView.VIEW_VENUE) {
    return "Venue Details";
  }

  if (row.screen === ScreenView.VIEW_PROMOTION) {
    return "Promotion Details";
  }

  return row.screen;
};

export const userAtLocation = (row: ILiveUserData) => {
  if (row.atLocation === AtLocationStatus.UNKNOWN) {
    return "Unknown";
  }

  return row.atLocation;
};
