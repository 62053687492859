import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent, FC, useContext, useState } from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import OcietyNavLink from "../../../../common/components/OcietyNavLink";
import { useForm } from "../../../../common/hooks";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import ChangePasswordRequest from "../../../VenueAdminProfile/components/ChangePasswordForm/models/ChangePasswordRequest";
import IChangePasswordRequest from "../../../VenueAdminProfile/components/ChangePasswordForm/models/IChangePasswordRequest";
import { changePassword } from "./api";
import { changePasswordForm, ChangePasswordFormField, schema } from "./form";
import Props from "./Props";
import { useStyles } from "./styles";

const ChangePassword: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { form, setAndValidate, validateForm } = useForm(
    schema,
    changePasswordForm
  );
  const [errorMsg, setErrorMsg] = useState("");

  const [loading, setLoading] = useState(false);
  const { showAlert } = useContext(AlertContext);

  const handleChangeFormTextField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;

    if (errorMsg) {
      setErrorMsg("");
    }

    setAndValidate(key as ChangePasswordFormField, value);
  };

  const handleChangePassword = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);

      const changePasswordRequest: IChangePasswordRequest =
        new ChangePasswordRequest(form.values.currentPass, form.values.newPass);

      await changePassword(changePasswordRequest);

      showAlert(
        dictionary.venueAdmin.profile.changePassword.successMessage,
        "success"
      );
    } catch (e) {
      if (!e) {
        return;
      }

      if (e.data) {
        showAlert(e.data, "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const btnDisable: boolean = !form.isValid || !form.values.confirmPass;

  return (
    <div className={classes.root}>
      <div style={{ padding: 10 }}>
        <Typography variant={"h3"}>
          {dictionary.profile.changePassword}
        </Typography>
        {loading && <LoadingIndicator withMask />}
        <div style={{ marginTop: 30 }}>
          <Grid container spacing={7}>
            <Grid item xs={4} spacing={5}>
              <TextField
                fullWidth
                id="filled-firstName"
                label={"Current password"}
                name="currentPass"
                error={!!form.errors.currentPass}
                helperText={form.errors.currentPass}
                onChange={handleChangeFormTextField}
                style={{ marginTop: "1rem" }}
                value={form.values.currentPass}
                type="password"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4} spacing={5}>
              <TextField
                fullWidth
                id="filled-firstName"
                label={"New password"}
                name="newPass"
                error={!!form.errors.newPass}
                helperText={form.errors.newPass}
                onChange={handleChangeFormTextField}
                style={{ marginTop: "1rem" }}
                type="password"
                value={form.values.newPass}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4} spacing={5}>
              <TextField
                fullWidth
                id="filled-firstName"
                label={"Confirm password"}
                name="confirmPass"
                error={!!form.errors.confirmPass}
                helperText={form.errors.confirmPass}
                onChange={handleChangeFormTextField}
                style={{ marginTop: "1rem" }}
                value={form.values.confirmPass}
                type="password"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4} spacing={1}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: "50%" }}>
                  <Button
                    color="primary"
                    // className={classes.uploadBtn}
                    // size={"small"}
                    disabled={btnDisable}
                    onClick={handleChangePassword}
                    variant="contained">
                    Save Changes
                  </Button>
                </div>
                <div style={{ flex: "50%" }}>
                  <OcietyNavLink
                    linkTextClassName={classes.forgotPasswordText}
                    to={Route.ForgotPassword}>
                    {dictionary.profile.forgotPassword}
                  </OcietyNavLink>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
