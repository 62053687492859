import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "reviewContainer"
  | "actionsToolbar"
  | "buttonBlackIcon"
  | "messageIcon"
  | "editIcon"
  | "updatedLabel"
  | "updateText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        padding: theme.spacing(1.5),
      },
      reviewContainer: {
        borderRadius: 5,
        boxShadow: "0px 0px 16px rgba(59, 72, 118, 0.08)",
        padding: theme.spacing(5, 6),
      },
      buttonBlackIcon: {
        marginRight: 10,
      },
      actionsToolbar: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
      },
      editIcon: {
        opacity: 0.3,
      },
      updatedLabel: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(3),
        padding: theme.spacing(1, 0),
        backgroundColor: `${colors.grey.medium}40`,
      },
      updateText: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        color: colors.grey.additional,
        marginLeft: theme.spacing(1),
      },
      messageIcon: {
        marginRight: 10,
      },
    })
);
