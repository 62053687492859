import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import {
  FOOTER_HEIGHT,
  FOOTER_HEIGHT_MOBILE,
} from "../../common/components/Footer";
import { OcietyTheme } from "../../common/models";

export type ClassNames =
  | "header"
  | "contactLink"
  | "root"
  | "content"
  | "loginButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        minHeight: "100vh",
        flex: 1,
        paddingTop: 72,
      },
      content: {
        minHeight: `calc(100% - ${FOOTER_HEIGHT})`,
        [theme.breakpoints.down("sm")]: {
          minHeight: `calc(100% - ${FOOTER_HEIGHT_MOBILE})`,
          padding: theme.spacing(1),
        },
        display: "flex",
      },
      header: {
        top: 0,
        left: 0,
        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(4, 5, 1),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(2),
        },
      },
      contactLink: {
        borderWidth: 0,
      },
      loginButton: {
        borderRadius: "3px !important",
        minWidth: "63px !important",
      },
    })
);
