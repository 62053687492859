import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { useForm, UseFormTypes } from "../../../../../common/hooks";
import { FileUrlWithId } from "../../../../../common/models";
import { weekDaysFullName } from "../../../../../utils/dateTimeUtils";
import {
  IPromotionAdvancedForm,
  PromotionAdvancedForm,
  PromotionAdvancedFormSchema,
  PromotionAdvancedFormSchemaType,
} from "./forms/advanced";
import {
  IPromotionBasicForm,
  PromotionBasicForm,
  PromotionBasicFormSchema,
  PromotionBasicFormSchemaType,
} from "./forms/basic";
import {
  IPromotionMainForm,
  PromotionMainForm,
  PromotionMainFormSchema,
  PromotionMainFormSchemaType,
} from "./forms/main";
import { IDayHours } from "./models/IDayHours";
interface Props {
  children?: React.ReactElement;
}

interface PromotionFormDialogContextInterface {
  basicForm: UseFormTypes<PromotionBasicFormSchemaType, IPromotionBasicForm>;
  mainForm: UseFormTypes<PromotionMainFormSchemaType, IPromotionMainForm>;
  advancedForm: UseFormTypes<
    PromotionAdvancedFormSchemaType,
    IPromotionAdvancedForm
  >;

  currentStep: number;
  isNextDisabled: boolean;
  loading: boolean;
  promotionId: number;
  setPhotosWithIds: Dispatch<SetStateAction<FileUrlWithId[]>>;
  setVideosWithIds: Dispatch<SetStateAction<FileUrlWithId[]>>;
  photosWithIds: FileUrlWithId[];
  videosWithIds: FileUrlWithId[];
  setPromotionId: Dispatch<SetStateAction<number>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  daysHours: IDayHours[];
  setDaysHours: Dispatch<SetStateAction<IDayHours[]>>;
  resetForm: () => void;
}
export const daysHoursDefault: IDayHours[] = weekDaysFullName.map(
  (day: string) => {
    return {
      id: day.slice(0, 3),
      day,
      isActive: true,
      start: "00:00:00",
      end: "23:59:59",
    };
  }
);

export const PromotionFormDialogContext =
  React.createContext<PromotionFormDialogContextInterface>({
    basicForm: {
      form: PromotionBasicForm,
      setAndValidate: () => undefined,
      setFormValue: () => undefined,
      setFormValues: () => undefined,
      setSchemaRules: () => undefined,
      clearErrors: () => undefined,
      validateForm: () => true,
    },
    mainForm: {
      form: PromotionMainForm,
      setAndValidate: () => undefined,
      setFormValue: () => undefined,
      setFormValues: () => undefined,
      setSchemaRules: () => undefined,
      clearErrors: () => undefined,
      validateForm: () => true,
    },
    advancedForm: {
      form: PromotionAdvancedForm,
      setAndValidate: () => undefined,
      setFormValue: () => undefined,
      setFormValues: () => undefined,
      setSchemaRules: () => undefined,
      clearErrors: () => undefined,
      validateForm: () => true,
    },
    loading: false,
    currentStep: 1,
    promotionId: 0,
    daysHours: [],
    isNextDisabled: false,
    setPhotosWithIds: () => undefined,
    setVideosWithIds: () => undefined,
    photosWithIds: [],
    videosWithIds: [],
    setPromotionId: () => undefined,
    setCurrentStep: () => undefined,
    setLoading: () => undefined,
    setDaysHours: () => undefined,
    resetForm: () => undefined,
  });

const PromotionFormDialogContextProvider: FC<Props> = ({ children }) => {
  const basicForm = useForm(PromotionBasicFormSchema, PromotionBasicForm);
  const mainForm = useForm(PromotionMainFormSchema, PromotionMainForm);
  const advancedForm = useForm(
    PromotionAdvancedFormSchema,
    PromotionAdvancedForm
  );

  const [promotionId, setPromotionId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [daysHours, setDaysHours] = useState<IDayHours[]>(daysHoursDefault);
  const [photosWithIds, setPhotosWithIds] = useState<FileUrlWithId[]>([]);
  const [videosWithIds, setVideosWithIds] = useState<FileUrlWithId[]>([]);

  useEffect(() => {
    setIsNextDisabled(
      (currentStep === 1 && !basicForm.form.isValid) ||
        (currentStep === 2 && !mainForm.form.isValid) ||
        (currentStep === 3 && !advancedForm.form.isValid)
    );
  }, [
    currentStep,
    basicForm.form.isValid,
    mainForm.form.isValid,
    advancedForm.form.isValid,
  ]);

  const resetForm = () => {
    basicForm.setFormValues(PromotionBasicForm.values);
    mainForm.setFormValues(PromotionMainForm.values);
    advancedForm.setFormValues(PromotionAdvancedForm.values);

    setPromotionId(0);
    setCurrentStep(1);
  };

  return (
    <PromotionFormDialogContext.Provider
      value={{
        basicForm,
        mainForm,
        advancedForm,
        currentStep,
        isNextDisabled,
        setLoading,
        loading,
        setCurrentStep,
        daysHours,
        setDaysHours,
        promotionId,
        setPromotionId,
        resetForm,
        setPhotosWithIds,
        setVideosWithIds,
        photosWithIds,
        videosWithIds,
      }}>
      {children}
    </PromotionFormDialogContext.Provider>
  );
};
export default PromotionFormDialogContextProvider;
