import { FormControl, InputLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import { AnalyticsPeriod, AnalyticsPeriodSelectOption } from "../../models";
import SelectIcon from "../SelectIcon";
import Props from "./Props";
import { useStyles } from "./styles";

const defaultPeriodOptions: AnalyticsPeriodSelectOption[] = [
  {
    value: AnalyticsPeriod.LastMonth,
    label: "Last month",
  },
  {
    value: AnalyticsPeriod.LastThreeMoths,
    label: "Last 3 months",
  },
  {
    value: AnalyticsPeriod.LastYear,
    label: "Last year",
  },
  {
    value: AnalyticsPeriod.AllTime,
    label: "All time",
  },
];

const PeriodSelect: FC<Props> = (props: Props) => {
  const { className, setPeriod, period, periodOptions, disabled } = props;
  const options = periodOptions || defaultPeriodOptions;
  const classes = useStyles();

  const changeCurrentVenue = (e: any) => {
    if (!options) {
      return;
    }

    for (const currentPeriod of options) {
      if (currentPeriod.value === e.target.value) {
        setPeriod(e.target.value);
        break;
      }
    }
  };

  return (
    <FormControl
      variant="outlined"
      className={clsx(className, classes.root, "select-no-legend")}>
      <InputLabel
        id="demo-simple-select-outlined-label"
        className={classes.selectLabel}>
        {dictionary.venues.ownVenues.venueSelectPlaceholder}
      </InputLabel>
      <Select
        disabled={!!disabled}
        IconComponent={(iconProps) => <SelectIcon {...iconProps} />}
        classes={{ select: classes.select }}
        className={classes.selectWrapper}
        id="current_venue_select"
        value={period}
        label={dictionary.venues.ownVenues.venueSelectPlaceholder}
        onChange={changeCurrentVenue}>
        {options.map((periodOption: AnalyticsPeriodSelectOption) => (
          <MenuItem key={periodOption.value} value={periodOption.value}>
            {periodOption.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PeriodSelect;
