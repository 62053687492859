import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import React, { FC, useState } from "react";

import OcietyIcon from "../OcietyIcon";
import { Color } from "./enums";
import Props from "./Props";
import { ClassNames, useStyles } from "./styles";

const OcietySplitButton: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { options, onChangeValue, value, isExpired } = props;

  let defaultSelectedIndex = 0;

  if (value) {
    defaultSelectedIndex = options.findIndex(
      (option) => option.value === value
    );
  }
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] =
    React.useState(defaultSelectedIndex);

  const colorsMap = {
    [Color.GREEN]: "Green",
    [Color.BLACK]: "Black",
    [Color.BLUE]: "Blue",
    [Color.RED]: "Red",
    [Color.YELLOW]: "Yellow",
  };

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    if (!isExpired) {
      setSelectedIndex(index);
    }
    setOpen(false);
    onChangeValue(options[index].value);
  };

  const currentOption = options[selectedIndex]
    ? options[selectedIndex]
    : options[defaultSelectedIndex];
  const className = `button${colorsMap[currentOption.color]}`;
  const classNameArrow = `button${colorsMap[currentOption.color]}Arrow`;

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          className={classes.buttonGrouped}
          variant="contained"
          ref={anchorRef}
          aria-label="split button">
          <Button
            className={classes[className as ClassNames]}
            onClick={handleClick}>
            {currentOption.label}
          </Button>
          <Button
            className={classes[classNameArrow as ClassNames]}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}>
            <OcietyIcon
              name={"arrowDownWhite"}
              style={{ marginTop: 15, marginLeft: 10 }}
            />
          </Button>
        </ButtonGroup>
        <Popper
          style={{ zIndex: 10, minWidth: 150 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}>
              <Paper style={{ boxShadow: "none" }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        disabled={options.length === 1}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};
export default OcietySplitButton;
