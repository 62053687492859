import { Dialog } from "@material-ui/core";
import React, { FC, useState } from "react";

import ConfirmDialog from "../../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../../common/components/ConfirmDialog/Props";
import OcietyDialogTitle from "../../../../common/components/dialogs/OcietyDIalogTitle";
import dictionary from "../../../../i18n/en_US/dictionary";
import CreateVenueDialogActions from "./components/CreateVenueDialogActions";
import CreateVenueDialogContent from "./components/CreateVenueDialogContent";
import CreateVenueDialogContextProvider from "./CreateVenueDialogContextProvider";
import Props from "./Props";

const CreateVenueDialog: FC<Props> = (props: Props) => {
  const { open, closeDialog } = props;
  const [closeConfirm, setCloseConfirm] = useState(false);

  return (
    <CreateVenueDialogContextProvider>
      <Dialog
        open={open}
        disableBackdropClick
        aria-labelledby="form-dialog-title"
        onClose={() => {
          setCloseConfirm(true);
        }}>
        <OcietyDialogTitle
          id="alert-dialog-title"
          onClose={() => {
            setCloseConfirm(true);
          }}>
          {dictionary.venues.list.createVenueDialogTitle}
        </OcietyDialogTitle>
        <CreateVenueDialogContent />
        <CreateVenueDialogActions
          closeDialog={() => {
            setCloseConfirm(true);
          }}
          closeAfterSuccessValidation={closeDialog}
        />
        <ConfirmDialog
          open={closeConfirm}
          onClose={() => {
            setCloseConfirm(false);
          }}
          onConfirm={() => closeDialog()}
          type={ConfirmModalType.CONFIRM}
          message={dictionary.venues.claimVenueDialog.popupWarningOnClose}
        />
      </Dialog>
    </CreateVenueDialogContextProvider>
  );
};

export default CreateVenueDialog;
