import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "select";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        minWidth: 246,
      },
      select: {
        padding: theme.spacing(14 / 8, 20 / 8),
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
      },
    })
);
