import { Divider } from "@material-ui/core";
import React, { FC, useContext, useState } from "react";

import ConfirmDialog from "../../../../../../../common/components/ConfirmDialog";
import { ConfirmModalType } from "../../../../../../../common/components/ConfirmDialog/Props";
import OcietyDialogTitle from "../../../../../../../common/components/dialogs/OcietyDIalogTitle";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { PromotionFormDialogContext } from "../../PromotionFormDialogContextProvider";
import Props from "./Props";

const PromotionFormDialogTitle: FC<Props> = (props: Props) => {
  const { editMode, closeDialog } = props;
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { resetForm } = useContext(PromotionFormDialogContext);

  return (
    <div>
      <OcietyDialogTitle
        id="alert-dialog-title"
        onClose={() => setIsConfirmDialogOpen(true)}>
        {editMode
          ? dictionary.promotions.createPromotionForm.modalTitleEdit
          : dictionary.promotions.createPromotionForm.modalTitleCreate}
      </OcietyDialogTitle>
      <Divider />
      <ConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={() => {
          closeDialog();
          resetForm();
        }}
        subtitle={dictionary.promotions.createPromotionForm.popupWarningOnClose}
        type={ConfirmModalType.CONFIRM}
        message={" \n  "}
      />
    </div>
  );
};
export default PromotionFormDialogTitle;
