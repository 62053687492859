import { MobileAppUserType } from "../../../../common/models";
import { VenueReviewsScoring } from "./api";

export const chartDataMock: VenueReviewsScoring[] = [
  {
    userType: MobileAppUserType.CRITIC,
    reviewsPercent: 28,
    averageRating: 4.5,
  },
  {
    userType: MobileAppUserType.USER,
    reviewsPercent: 72,
    averageRating: 4.9,
  },
];
