import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { AlertContext } from "../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import PageTitle from "../../common/components/PageTitle";
import { useRestApi } from "../../common/hooks/useRestApi";
import { Route } from "../../config/router";
import dictionary from "../../i18n/en_US/dictionary";
import VenuePendingAttachments from "../VenuePending/components/VenuePendingAttachments";
import { GET_CLAIM_REQUEST, VenueClaimRequestData } from "./api";
import VenueClaimPendingInformationForAdmin from "./components/VenueClaimPendingInformationForAdmin";
import VenueClaimPendingUserData from "./components/VenueClaimPendingUserData";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueClaimPending: FC<Props> = (props: Props) => {
  const [isWaitlistAdmin, setIsWaitlistAdmin] = useState(false);

  const requestId = parseInt(props.match.params.id);
  const { showAlert } = useContext(AlertContext);
  const [{ data, loading }, getVenueClaimRequest] =
    useRestApi<VenueClaimRequestData>(GET_CLAIM_REQUEST(requestId), "GET");

  const classes = useStyles();

  useEffect(() => {
    getVenueClaimRequest()
      .then((data) => data && setIsWaitlistAdmin(!!data.isWaitlistAdmin))
      .catch(() => {
        showAlert(dictionary.venueClaimPending.getRequestError, "error");
      });
  }, [getVenueClaimRequest, showAlert]);

  return (
    <div className={classes.root}>
      <PageTitle backURL={Route.VenuesClaimRequestsPending}>
        {dictionary.venueClaimPending.requestViewTitle}
      </PageTitle>
      <Paper className={classes.content}>
        {loading && <LoadingIndicator withMask />}
        {data && (
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <VenueClaimPendingUserData
                    comment={data.comment}
                    userCompany={data.company}
                    userEmail={data.email}
                    userFirstName={data.firstName}
                    userLastName={data.lastName}
                    userPhoneNumber={data.phoneNumber}
                    userTitle={data.title}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isWaitlistAdmin}
                        onChange={(event) =>
                          setIsWaitlistAdmin(event.target.checked)
                        }
                        name="isWaitlistAdmin"
                        color="primary"
                      />
                    }
                    label={
                      dictionary.venues.assignVenueToUserForm.isWaitlistAdmin
                    }
                    className={classes.waitlistCheckbox}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  {data.documentPath && (
                    <VenuePendingAttachments
                      attachments={[data.documentPath.fileUrlForAdmin]}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <VenueClaimPendingInformationForAdmin
                requestId={data.id}
                isWaitlistAdmin={isWaitlistAdmin}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </div>
  );
};

export default withRouter(VenueClaimPending);
