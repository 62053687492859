import { Paper } from "@material-ui/core";
import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import VenueClaimRequestsPendingTable from "./components/VenueClaimRequestsPendingTable";
import { useStyles } from "./styles";

const VenueClaimRequestsPending: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageTitle>{dictionary.venueClaimPending.listTitle}</PageTitle>
      <Paper className={classes.content}>
        <VenueClaimRequestsPendingTable />
      </Paper>
    </div>
  );
};

export default VenueClaimRequestsPending;
