import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames = "root" | "forgotPasswordText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flexGrow: 1,
        marginBottom: 20,
        marginTop: 20,
      },
      forgotPasswordText: {
        color: "#FE5454",
        fontSize: 16,
        fontWeight: "bold",
        paddingTop: 10,
      },
    })
);
