import { Role } from "../../models";
import { Permission } from "../../permissions/permission";

export interface LoginResponse {
  roles: Role[];
  permissions: Permission[];
}

export interface MFAResponse {
  token: string;
  type: string;
  twoFactor: boolean;
}

export enum TwoFactoryAuthTypeEnum {
  DISABLE = "DISABLE",
  CALL = "CALL",
  SMS = "SMS",
  EMAIL = "EMAIL",
  TOTP = "TOTP",
}

export const LOGIN_PENDING = "LOGIN.PENDING";
export const LOGIN_FULFILLED = "LOGIN.FULFILLED";
export const LOGIN_REJECTED = "LOGIN.REJECTED";
export const LOGIN_CLEAR_ERROR = "LOGIN.ClEAR_ERROR";
export const LOGOUT = "LOGOUT";
export const SET_MFA_TOKEN = "SET_MFA_TOKEN";
export const CLEAR_MFA_TOKEN = "CLEAR_MFA_TOKEN";
export const VERIFY_MFA_CODE_PENDING = "VERIFY_MFA_CODE_PENDING";
export const TOGGLE_REMEMBER_ME = "TOGGLE_REMEMBER_ME";

export interface LoginPendingAction {
  type: typeof LOGIN_PENDING;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_FULFILLED;
  roles: Role[];
  permissions: Permission[];
}

export interface LoginFailAction {
  type: typeof LOGIN_REJECTED;
  error: string;
}

export interface LoginClearErrorAction {
  type: typeof LOGIN_CLEAR_ERROR;
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export interface SetMFATokenAction {
  type: typeof SET_MFA_TOKEN;
  mfaToken: string;
  mfaType: string;
}

export interface ClearMFATokenAction {
  type: typeof CLEAR_MFA_TOKEN;
}

export interface VerifyMFACodePendingAction {
  type: typeof VERIFY_MFA_CODE_PENDING;
}

export interface ToggleRememberMeAction {
  type: typeof TOGGLE_REMEMBER_ME;
}

export type AuthActionTypes =
  | LoginPendingAction
  | LoginSuccessAction
  | LoginFailAction
  | LoginClearErrorAction
  | LogoutAction
  | SetMFATokenAction
  | ClearMFATokenAction
  | VerifyMFACodePendingAction
  | ToggleRememberMeAction;
