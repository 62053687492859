import axios from "axios";

import HttpStatusCodes from "../common/httpStatusCodes";
import dictionary from "../i18n/en_US/dictionary";
import { store } from "../store";
import { logout } from "../store/auth/actions";
import { isUnauthorized } from "../utils/userUtils";

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/admin/`,
  timeout: 1000 * 60, // TODO change to lover value in the future
  withCredentials: true,
});

http.interceptors.request.use((config) => {
  return { ...config };
});

export const subscribeResponse = (
  showErrorAlert: (message: string) => void
) => {
  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { status } = error.response;

        if (isUnauthorized(status) && store.getState().auth.hasToken) {
          store.dispatch(logout());
          showErrorAlert(dictionary.app.unauthorizedError);
        } else if (status === HttpStatusCodes.FORBIDDEN) {
          showErrorAlert(dictionary.app.permissionsError);
        }
      } else {
        // showErrorAlert(dictionary.app.networkError);
      }

      return Promise.reject(error);
    }
  );
};
