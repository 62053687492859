import { Paper, Typography } from "@material-ui/core";
import React, { FC, useEffect, useRef } from "react";
import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from "recharts";

import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import dictionary from "../../../../i18n/en_US/dictionary";
import { colors } from "../../../../theme/colors";
import {
  GET_VENUE_MOST_ACTIVE_DAYS,
  PopularDaysResponseData,
} from "../../models";
import Props from "./Props";
import { useStyles } from "./styles";

const PopularDaysWidget: FC<Props> = (props: Props) => {
  const { period, venueId } = props;
  const widgetWrapperRef = useRef<HTMLDivElement>(null);
  const [asyncState, setAsyncState] = useAsyncState<PopularDaysResponseData>(
    GET_VENUE_MOST_ACTIVE_DAYS(venueId, period)
  );
  const classes = useStyles();
  const chartWidth = useRef(0);

  useEffect(() => {
    if (widgetWrapperRef.current) {
      chartWidth.current = widgetWrapperRef.current.offsetWidth - 40;
    }
  });

  const parseDataForChart = () => {
    if (!asyncState.response) {
      return [];
    }

    return Object.entries(asyncState.response.summary).map(([key, value]) => ({
      name: key[0].toUpperCase() + key.slice(1, key.length),
      value,
    }));
  };

  const chartData = parseDataForChart();

  useEffect(() => {
    if (!!venueId) {
      setAsyncState().then();
    }
  }, [setAsyncState, venueId]);

  const getBarFillColor = (index: number) => {
    const valuesArray = chartData.map(({ value }) => value);
    const isHighestValue = Math.max(...valuesArray) === valuesArray[index];

    return isHighestValue ? "rgba(254, 84, 84, 0.8)" : colors.grey.light;
  };

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.root} ref={widgetWrapperRef}>
        <Typography variant={"h3"} className={classes.title}>
          {dictionary.dashboard.mostActiveDaysTitle}
        </Typography>
        {asyncState.loading && <LoadingIndicator withMask />}
        <BarChart
          width={chartWidth.current}
          height={250}
          data={chartData}
          className={classes.chart}>
          <CartesianGrid
            strokeDasharray="3 3"
            strokeOpacity={0.6}
            color={colors.grey.light}
            vertical={false}
          />
          <Bar dataKey={"value"} fill={colors.grey.light}>
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getBarFillColor(index)} />
            ))}
          </Bar>
          <XAxis
            dataKey="name"
            tick={{
              fontSize: 12,
              fill: colors.grey.primary,
              fontWeight: 600,
              paddingTop: 10,
              fontFamily: "Nunito Sans",
            }}
            stroke={colors.grey.light}
          />
          <YAxis
            stroke={colors.grey.light}
            tick={{
              fontSize: 12,
              fill: "#BAC4D1",
              fontWeight: "bold",
              fontFamily: "Nunito Sans",
            }}
          />
        </BarChart>
      </div>
    </Paper>
  );
};

export default PopularDaysWidget;
