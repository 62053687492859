import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames = "root";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row-reverse",
    },
  })
);

export const legendWrapperStyle = {
  top: 0,
  position: "relative",
  width: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  lineHeight: 3,
  paddingLeft: 40,
};

export const tooltipWrapperStyle = {
  width: "auto",
  top: 0,
  left: 0,
};
